import { Injectable }        from '@angular/core';

// import {
//     BehaviorSubject,
//     Observable,
//     Subscription,

//     filter,
//     take
// }                            from 'rxjs';

import { DeviceGroup }       from '@ObjElements/';

import {
    // Messages,
    MessageService,
    MessageProcessingService 
}                            from '@Messaging/';
import {
    DataService,
    // DataServiceEvents
}                            from '@Misc/Services/';


@Injectable({
    providedIn: 'root'
})
export class DeviceGroupsService extends DataService
{
    protected static readonly _title: string = "Device Groups";


    public constructor(MessageProcessingService: MessageProcessingService,
                       MessageService:           MessageService)
    {
        super(
            MessageService,
            DeviceGroupsService._title,
            MessageService.messages.msgTypesInfo.devicegroups,
            MessageProcessingService.getObs$(MessageService.messages.events.devicegroup) // Will listen for these events
        ); // super()

        // Don't call from within initialise() as that is called by parent constructor and required this.MessageService not available then
        this.refresh(); // default this to quantityOnly initially
        // this.refresh(true);

        // // Listen for events
        // const obs: Observable<any> | undefined = this.MessageProcessingService.getObs$(Messages.events.devicegroup);
        // if (obs instanceof Observable) this.sub = obs
        //     .subscribe((d: any): void => {
        //         console.error(d)
        //         this.process(d, false);
        //         // if (this.process(d, false)) this.sizeI = this.size + 1;
        //         this.updateObservables(false);
        //     }); // subscribe
    }

    
    //
    // Interface
    //

    // Override
    public override get(id: string | number): object
    {
        return this.getEl(id as string);
    }


    // Override
    protected override processMsgData(d: any[]): void
    {
        super.processMsgData(d);

        if (Array.isArray(d)) {
            console.debug("Updating " + this.Title + " service with data: " + d.length);
            this.clear(false);
            Object.values(d).forEach((v): void => {
                this.process(v, false);
            }); // forEach
            this.updateObservables(false);
        }
        else {
            this.process(d);
            // console.warn("No data received to update " + this.Title + " service");
        }
    }


    //
    // Private methods
    //
    private getEl(id: string): DeviceGroup
    {
        return this.getElement(id) as DeviceGroup;
    }


    private process(d: any, update: boolean = true): boolean
    {
        const s: DeviceGroup | undefined = DeviceGroup.get(d);
        if (s instanceof DeviceGroup) {
            // Check if already exists; update if so
    update=true
            let ret: boolean = !! this.add(s.id, s, update);
            if (! ret && !! this.update(s)) this.elementUpdated$?.next(s.id);

            return ret;
        }

        return false;
    }


    private update(d: DeviceGroup): DeviceGroup | undefined
    {
        const s: DeviceGroup | undefined = (d instanceof DeviceGroup) ? this.getEl(d.id) : undefined;
        if (s instanceof DeviceGroup) s._merge(d);
        return s;
        // return ((d instanceof DeviceGroup) ? this.getEl(d.id) : undefined)?._merge(d);
    }


// @Injectable({
//     providedIn: 'root'
// })
// export class DeviceGroupsService extends BaseService
// {
//     private _groups: any                                  = [];
//     private _instances: any                               = [];
//     private _instanceCurrent: any                         = {};

//     private numGroupsSubject:    BehaviorSubject<number>;
//     private numInstancesSubject: BehaviorSubject<number>;


//     constructor(private readonly MessageProcessingService: MessageProcessingService,
//                 private readonly MessageService:           MessageService)
//     { 
//         super();

//         this.numGroupsSubject    = new BehaviorSubject<number>(this._groups.length);
//         this.numInstancesSubject = new BehaviorSubject<number>(this._instances.length);

        
//         // Subscribe to be informed about socket reconnection; if/when it
//         // it happens, refresh this service to ensure is up-to-date
//         let obs: Observable<boolean> | undefined = this.MessageService.connected$;
//         if (obs instanceof Observable) this.sub = obs
//             .pipe(filter((recon: boolean): boolean => recon == true))
//             .subscribe((recon: boolean): void => {
//                 this.refresh();
//             }); // subscribe


//         // Listen for events
//         obs = this.MessageProcessingService.getObs$(Messages.msgTypesInfo.groupinstances);
//         if (obs instanceof Observable) this.sub = obs
//             .subscribe((d: any): void => {
//                 this.updateInstances(d);
//                 // if (d) this.refresh();
//             }); // subscribe
//     }
    

//     // getters and setters
//     get groups(): any
//     {
//         return this._groups;
//     }


//     set groups(grps: any)
//     {
//         this._groups = grps;
//     }


//     get instance(): any
//     {
//         return this._instanceCurrent;
//     }


//     set instance(inst: any)
//     {
//         this._instanceCurrent = inst;
//     }


//     get instances(): any
//     {
//         return this._instances;
//     }


//     set instances(inst: any)
//     {
//         this._instances = inst;
//     }


//     get updatedGroups(): Observable<number>
//     {
//         return this.numGroupsSubject.asObservable();
//     }


//     get updatedInstances(): Observable<number>
//     {
//         return this.numInstancesSubject.asObservable();
//     }


//     //
//     // Public functions
//     //
//     public getInstance(instance: any): Observable<any> | undefined
//     {
//         if (instance) {
//             let device: any = instance.lead;
//             if (! device && instance.devices && instance.devices.length > 0 && instance.devices[0]) {
//                 device = instance.devices[0].serialNum;
//             }

//             const obj: {} = {
//                 type:      Messages.msgTypesSub.devicelocations,
//                 device:    device,
//                 serialNum: device,
//                 startDate: instance.startDate,
//                 endDate:   instance.endDate,
//                 operator:  instance.leadOperator
//             };

//             // Return observable
//             return this.MessageService.sendMsg(Messages.msgTypes.getdata, obj);
//         }

//         return undefined;
//     }

 
//     public refresh(): void
//     {
//         console.debug("Refreshing DeviceGroups service - getting current device groups and instances");

//         // Use observable
//         let obs: Observable<any> | undefined = this.MessageService.sendMsg(
//             Messages.msgTypes.getdata,
//             {
//                 type: Messages.msgTypesSub.devicegroups
//             }
//         );

//         if (obs instanceof Observable) this.sub = obs
//             .pipe(take(1))
//             .subscribe((data: any): void => {
//                 this.updateGroups(data);
//             }); // subscribe
 

//         // Use observable
//         obs = this.MessageService.sendMsg(
//             Messages.msgTypes.getdata,
//             {
//                 type: Messages.msgTypesSub.groupinstances
//             }
//         );

//         if (obs instanceof Observable) this.sub = obs
//             .pipe(take(1))
//             .subscribe((data: any): void => {
//                 this.updateInstances(data);
//             }); // subscribe
//     }


//     public updateGroups(data: any): void
//     {
//         if (data && data.data) {
//             console.debug("Updating DeviceGroups service with groups data: " + data.data.length);
//             this.groups = data.data;
//             this.updateObservables();
//         }
//     }


//     public updateInstances(data: any): void
//     {
//         if (data && data.instances) {
//             console.debug("Updating DeviceGroups service with instance data: " + data.instances.length);

//             if (data.total) {
//                 this.instances = data.instances;
//             }
//             else {
//                 // Add incremental - sort and de-dupe by ID = ES6-only browswers
//                 this.instances = this.instances.concat(data.instances).sort((a: any, b: any) => {
//                      return a.startDate == b.startDate ? 0 : +(a.startDate < b.startDate) || -1;
//                 })
//                 .filter((s: any, index: any, self: any): any =>
//                     index === self.findIndex((t: any): any => ( t.id === s.id ))
//                 );
//             }
//             this.updateObservables();
//         }
//     }


//     //
//     // Protected functions
//     //
//     // protected cleanUp(): void
//     // {
//     //     super.cleanUp();

//     //     this.mSubs.forEach((item: Subscription, index: number): void => {
//     //         if (item) item.unsubscribe();
//     //         this.mSubs.splice(index, 1);
//     //     });
//     // }


//     protected override initialise(): void
//     {
//         super.initialise();

//         console.debug("Initialising DeviceGroups service");
//     }


//     //
//     // Private functions
//     //
//     private updateObservables(): void
//     {
//         this.numGroupsSubject.next(this.groups.length);
//         this.numInstancesSubject.next(this.instances.length);
//     }
}