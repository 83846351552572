import { Routes }                 from '@angular/router';

import { AuthenticationGuard }    from '@Login/';

import { NotificationComponent }  from './notification/notification.component';
import { NotificationsComponent } from './notifications.component';

import { routeNames }             from '../app.routing.names';


export const notificationsRoutes: Routes = [
    {
        path:        '',
        component:   NotificationsComponent, // list
        data:        { animation: routeNames.notifications },
        canActivate: [ AuthenticationGuard ]
    },

    {
        path:        ':id',
        component:   NotificationComponent,  // individual
        data:        { animation: 'notification' },
        canActivate: [ AuthenticationGuard ]
    }
];