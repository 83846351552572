import { Component }     from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
   selector:     'qp-devices-container',
   templateUrl:  'devices-container.component.html',
   styleUrls:   ['devices-container.component.css']
})
export class DevicesContainerComponent extends BaseComponent
{
   // Used in routes.ts files; can't currently be used in template too as
   // Angular router-outlet doesn't support binding to 'name'
   // Make sure this name matches that in the template
   public static readonly outlet: string = "devices";
}