import { IconsService }     from '@Icons/';
import { DataServiceI }     from '@Misc/Services/';

import { MapLayersService } from '../map-layers.service';
import { MapService }       from '../../map.service';

import { MapLayerElements } from './map-layer-elements.class';

 
export class MapLayerSurvey extends MapLayerElements
{
    public constructor(MapService:       MapService,
                       MapLayersService: MapLayersService,
                       DataService:      DataServiceI | undefined,
                       IconsService:     IconsService,
                       name?:            string,
                       displayName?:     string)
    {
        super(MapService, MapLayersService, DataService, IconsService, name, displayName);
    }


    static override get(MapService: MapService,
                        MapLayerService: MapLayersService,
                        DataService:     DataServiceI | undefined,
                        IconsService:    IconsService,
                        name?:           string,
                        displayName?:    string): MapLayerSurvey
    {
        return new MapLayerSurvey(
            MapService,
            MapLayerService,
            DataService,
            IconsService,
            name,
            displayName
        ); // MapLayerSurvey
    }
}