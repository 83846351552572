<!-- <button mat-button *ngIf="icon" (click)="button()"
    matTooltip="{{ tooltip }}"
    matTooltipClass="tooltip-multiline">
    <mat-icon aria-label="Mobile cell">{{ icon }}</mat-icon>
</button> -->

<div class="mat-button">
 <!-- [ngSwitch]="technology"> -->
    <!-- <qp-mobilecell-level-icon-gsm  *ngSwitchCase="mobileTechnology.gsm"  [mobilecell]="mobilecell"></qp-mobilecell-level-icon-gsm>
    <qp-mobilecell-level-icon-lte  *ngSwitchCase="mobileTechnology.lte"  [mobilecell]="mobilecell"></qp-mobilecell-level-icon-lte>
    <qp-mobilecell-level-icon-nr   *ngSwitchCase="mobileTechnology.nr"   [mobilecell]="mobilecell"></qp-mobilecell-level-icon-nr>
    <qp-mobilecell-level-icon-umts *ngSwitchCase="mobileTechnology.umts" [mobilecell]="mobilecell"></qp-mobilecell-level-icon-umts>

    <div *ngSwitchDefault> -->
        <button mat-button
            [ngClass]="ngClass"
            matTooltip="{{ tooltip }}"
            matTooltipClass="tooltip-multiline"
            #tt="matTooltip"
            (click)="tt.show()">

    <!-- (mouseenter)="$event.stopImmediatePropagation()" -->
    <!-- (mouseleave)="$event.stopImmediatePropagation()"  -->

            <mat-icon class="icon-1-5x" aria-label="Mobile cell">{{ icon }}</mat-icon>
        </button>
    <!-- </div> -->
</div>