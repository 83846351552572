import {
    Component,
    Host,
    HostBinding,
    Input
}                                       from '@angular/core';
import { MatRow }                       from '@angular/material/table';

import {
    ActivatedRoute,
    Router 
}                                       from '@angular/router';

import { ListDataComponent }            from '@GuiElements/';
import {
    ObjectElementsComponent,
    Organisation
}                                       from '@ObjElements/';
import { ProgressSpinnerDialogService } from '@Misc/Services/';

import { OrganisationsService }         from './organisations.service'; 


@Component({
    selector:     'qp-organisations',
    templateUrl:  'organisations.component.html',
    styleUrls:   ['../Misc/Components/list.component.css']
})
export class OrganisationsComponent extends ObjectElementsComponent
{
    @HostBinding('class')
    private        readonly _cls:      Host     = 'fill2';

    public static readonly _title:     string   = "Organisations";


    @Input()
    public                  attrs:     string[]; // optionally passed in from parent HTML


    public         readonly attrOrder: string   = 'name';

    public constructor(private readonly ActRoute:                     ActivatedRoute,
                       private readonly Router:                       Router,

                                        ProgressSpinnerDialogService: ProgressSpinnerDialogService,
                                        OrganisationsService:         OrganisationsService)
    {
        super(OrganisationsService, ProgressSpinnerDialogService);

        if (! this.attrs || ! this.attrs.length) this.attrs = [
            ListDataComponent.stateAttr,
            'name',
            'sites'
        ];
    }


    //
    // Getters
    //
    public get title(): string
    {
        return OrganisationsComponent._title;
    }


    //
    // Public methods
    //

    // Override
    public override select(row: MatRow): Promise<boolean> | undefined
    {
        super.select(row);
        
        return (row instanceof Organisation && (row as Organisation).id)
            ? this.Router.navigate([ (row as Organisation).id ], { relativeTo: this.ActRoute })
            : undefined;
    }

    
    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp(true); // force full re-load; needed by dashboard
    }
}