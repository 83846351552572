import { IconsService }    from '@Icons/';
import { Element }         from '@ObjElements/';
import { DataServiceI }    from '@Misc/Services/';

import {
    MapElementInfoWin,
    MapElementOverlayView
}                          from '../../map-elements/';

import { MapService }      from '../../map.service';

import { MapLayer }        from './map-layer.class'
import { MapLayerType }    from './map-layer-type.class';


export class MapLayerServices extends MapLayer
{
    public _type = MapLayerType.serviceResults;

    public constructor(                 MapService:   MapService,
                                        DataService:  DataServiceI,
                       private readonly IconsService: IconsService)
    {
        super(MapService, DataService);
    }


    // Interface
    protected getDataForMapPoint(id: string): Element | undefined
    {
        console.log("[TBD]");

        return undefined;
    }


    //
    // Public Functions
    //
    public mapLayerServicesFactory(map: any, data: any): any
    {
        // Overlay
        const layer = MapElementOverlayView.get();
        const info  = new MapElementInfoWin();

        return this.addTestResults(map, layer, data);
    }


    //
    // Private functions
    //
    //

    // Override
    protected override initialise(): boolean
    {
        super.initialise();

        console.log("Initialising MapLayerServices service");

        return true;
    }

  
    private addTestResults(map: any, layer: any, data: any): any
    {
        console.log(data);

        // [TBD]
        return ((data && data.data && data.data.length > 0) ? layer : null);
    }


    private addTestResults2(results: any): void
    {
        // if (results && results.length > 0) {            
        //     mapLayers.testResults.layer = getMapOverlay();

        //     console.log("Adding " + results.length + " test result(s) to map layer '" + mapLayers.testResults.name + "'");
        //     for (var i = 0, len = results.length; i < len; ++i) {
        //         if (results[i]) {
        //             // WeightedLocation
        //             const data = {
        //                 location: getLoc(results[i].latitude, results[i].longitude),
        //                 weight:   10
        //             }
        //             mapLayers.testResults.layer.path.push(data);
        //         } // if
        //     } // for
        //     console.log("Added " + results.length + " test result(s) to map layer '" + mapLayers.testResults.name + "'");
        // }

        // if (mapLayers.testResults.layer.path && mapLayers.testResults.layer.path.length > 0) {

        //     // Create heatmap
        //     const heatMap = new google.maps.visualization.HeatmapLayer();
        //     heatMap.setData(mapLayers.testResults.layer.path);
        //     mapLayers.testResults.layer.addOverlay(heatMap);
        //     //mapLayers.testResults.layer.setMap(qp_MapFactory.getMap()); 
        // }
        // else {
        //     mapLayerTypes.testResults.layer.setMap(null);   
        // }
    }
}