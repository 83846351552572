import { 
    Observable,
    Subject
}               from 'rxjs';


export class MapElementStreetViewPanorama extends google.maps.StreetViewPanorama
{
    private _visible: Subject<boolean> = new Subject<boolean>();


    private constructor(container: HTMLElement, opts?: google.maps.StreetViewPanoramaOptions)
    {
        super(container, opts);

        MapElementStreetViewPanorama.get(this);
    }

 
    //
    // Getters
    //
    public get visibility(): Observable<boolean> | undefined
    {
        return MapElementStreetViewPanorama.getVisible(this);
    }


    //
    // Static methods
    //
    public static get(pan: google.maps.StreetViewPanorama | MapElementStreetViewPanorama): MapElementStreetViewPanorama
    {
        if (!(pan as MapElementStreetViewPanorama)._visible) (pan as MapElementStreetViewPanorama)._visible = new Subject<boolean>();

        if (!(pan as any).visibility || typeof (pan as any).visibility != 'function') (pan as any).visibility = (pan: google.maps.StreetViewPanorama): any => {
            console.log("here")
            return MapElementStreetViewPanorama.getVisible(pan);
        };

        pan.setPov(/** @type {google.maps.StreetViewPov} */({
            heading: 265,
            pitch: 0
        }) );


        //
        // Panorama Listeners
        //
        pan.addListener('pano_changed',     (event: any): void => {
            console.log("Panorama pano changed");
            console.log(pan.getPano());
        });

        pan.addListener('position_changed', (event: any): void => {
            console.log("Panorama positon changed");
            console.log(pan.getPosition());
        });

        pan.addListener('pov_changed',      (event: any): void => {
            //console.log("Panorama pov changed");
            //console.log(this.getPov());
        });

        pan.addListener('visible_changed',  (event: any): void => {
            console.log("Panorama visible: " + pan.getVisible());
            if ((pan as MapElementStreetViewPanorama)._visible) {console.log("here99");(pan as MapElementStreetViewPanorama)._visible.next(pan.getVisible());}
        });

        return (pan as MapElementStreetViewPanorama);
    }


    private static getVisible(obj: google.maps.StreetViewPanorama | MapElementStreetViewPanorama): Observable<boolean> | undefined
    {
        return obj && (obj as MapElementStreetViewPanorama)._visible && (obj as MapElementStreetViewPanorama)._visible instanceof Subject
            ? (obj as MapElementStreetViewPanorama)._visible.asObservable()
            : undefined;
    }
}