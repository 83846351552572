import { Injectable }     from '@angular/core';

import { MessageService } from '@Messaging/';

declare var angular: any;


@Injectable({
    providedIn: 'root'
})
export class NetworkScoreService
{
    private          scores: any[] = []; // array of newtwork score collections
    private readonly invalidScore  = -1;

    constructor()
    {
        this.initialise();
    }


    //
    // Public functions
    //
    public getGlobalScore(plmn: string, operator: string): any
    {
        return this.getScoreAttr(plmn, operator, 'global');
    }


    public getVoiceScore(plmn: string, operator: string): any
    {
        return this.getScoreAttr(plmn, operator, 'voice');
    }


    public getDataScore(plmn: string, operator: string): any
    {
        return this.getScoreAttr(plmn, operator, 'data');
    }


    public getICMPScore(plmn: string, operator: string): any
    {
        return this.getScoreAttr(plmn, operator, 'icmp');
    }


    public getFTPScore(plmn: string, operator: string): any
    {
        return this.getScoreAttr(plmn, operator, 'ftp');
    }


    public getURLScore(plmn: string, operator: string): any
    {
        return this.getScoreAttr(plmn, operator, 'url');
    }


    public addScore(score: any, forceUpdate: boolean): boolean
    {
        if (score) {
            if (! score.operator) score.operator = score.plmn;

            const i: number | undefined = this.findScore(score.plmn, score.operator, score.dataConnectionType);
            if (! isNaN(Number(i)) && Number(i) >= 0 && Number(i) < this.scores.length) {
                //console.log("Score for network '" + score.plmn + " - " + score.operator + "'', '" + score.dataConnectionType + "' already exists at pos " + i);
                if (forceUpdate && score.scores.global > this.invalidScore) return this.updateScore(score);
            }
            else {
                if (score.plmn && score.scores) {
                    if (score.scores.global > this.invalidScore) {
                        var newScore = Object.assign({}, score);
                        // Do this to get column correct for display purposes
                        newScore.scores = {};
                        newScore.scores.global = score.scores.global;
                        newScore.scores.icmp   = score.scores.icmp;
                        newScore.scores.url    = score.scores.url;
                        newScore.scores.voice  = score.scores.voice;
                        newScore.scores.ftp    = score.scores.ftp;
                        this.scores.push(newScore);
                        //console.log("Score for network '" + score.plmn + " - " + score.operator + "', '" + score.dataConnectionType + "' not found; now added");
                    }
                }
                else {
                    console.log("Invalid network provided; cannot create new Score object");
                    return false;
                }
            }
        }

        //console.log(scores);

        return true;
    }


    public updateScore(score: any): boolean
    {
        if (score) {
            if (! score.operator) score.operator = score.plmn;

            const i: number | undefined = this.findScore(score.plmn, score.operator, score.dataConnectionType);
            if (! isNaN(Number(i)) && Number(i) >= 0 && Number(i) < this.scores.length) {
                angular.extend(this.scores[Number(i)], score); // add/update elements to found object
                console.debug("Score for network '" + score.plmn + " - " + score.operator + "', '" + score.dataConnectionType + "' found and updated");
                return true;
            }
            else {
                console.debug("Score for network '" + score.plmn + " - " + score.operator + "', '" + score.dataConnectionType + " not found; cannot update");
            }
        }

        return false;
    }


    public deleteScore(score: any): boolean
    {
        if (score) {
            if (! score.operator) score.operator = score.plmn;

            const i: number | undefined = this.findScore(score.plmn, score.operator, score.dataConnectionType);
            if (! isNaN(Number(i)) && Number(i) >= 0 && Number(i) < this.scores.length) {
                this.scores.splice(Number(i), 1); // remove found element from array
                console.debug("Score for network '" + score.plmn + " - " + score.operator + "', '" + score.dataConnectionType + "' found and deleted");
                return true;
            }
            else {
                console.debug("Score for network '" + score.plmn + " - " + score.operator + "', '" + score.dataConnectionType + "' not found; cannot delete");
            }
        }

        return false;
    }


    public getScore(plmn: string, operator: string, networkType: string = '', num: number = 0): any
    {
        if (plmn) {
            if (! operator) operator = plmn;

            const i: number | undefined = this.findScore(plmn, operator, networkType);
            if (! isNaN(Number(i)) && Number(i) >= 0 && Number(i) < this.scores.length) return this.scores[Number(i)];
        }
        else {
            if (num >= 0 && num < this.scores.length && this.scores[num]) return this.scores[num];
        }

        return null;
    }


    public getScores(doOperatorsOnly: boolean, mobileOnly: boolean): any[]
    {
        let networks: any[] = [];

        for (let i = 0, len = this.scores.length; i < len; i++) {
            let add = false;
            if (this.scores[i] && this.scores[i].plmn && this.scores[i].operator) {
                if (! doOperatorsOnly || (this.scores[i].plmn != this.scores[i].operator)) {
                    if (mobileOnly && mobileOnly == true) {
                        if (this.scores[i].dataConnectionType == 'mobile') add = true;
                    }
                    else {
                        add = true;
                    }
                }
                else {
                    add = true;
                }
            }

            if (add == true) networks.push(this.scores[i]);
        }

        return networks;
    }


    public getNetworks(doOperatorsOnly: boolean, mobileOnly: boolean): any[]
    {
        let networks: any[] = [];
        for (let i = 0, len = this.scores.length; i < len; i++) {
            let add = false;
            if (this.scores[i] && this.scores[i].plmn && this.scores[i].operator) {
                if (! doOperatorsOnly || (this.scores[i].plmn != this.scores[i].operator)) {
                    if (mobileOnly && mobileOnly == true) {
                        if (this.scores[i].dataConnectionType == 'mobile') add = true;
                    }
                    else {
                        add = true;
                    }
                }
                else {
                    add = true;
                }
            }

            if (add == true) networks.push({plmn: this.scores[i].plmn, operator: this.scores[i].operator, network: this.scores[i].dataConnectionType});
        }

        return networks;
    }


    //
    // Private functions
    //
    private initialise(): boolean
    {
        console.log("Initialising NetworkScore service");

        this.refresh();

        return true;
    }


    private findScore(plmn: string, operator: string, networkType: string): number | undefined
    {
        if (plmn) {
            // Set operator to plmn if none provided
            if (! operator) operator = plmn;
        
            for (let i = 0, len = this.scores.length; i < len; i++) {
//console.log(scores[i]);
                if (this.scores[i] && this.scores[i].plmn && this.scores[i].operator && this.scores[i].dataConnectionType) {
                    if ( (this.scores[i].plmn                             == plmn) &&
                         (this.scores[i].operator.toLowerCase()           == operator.toLowerCase()) &&
                         (this.scores[i].dataConnectionType.toLowerCase() == networkType.toLowerCase()) ) {
                        return i;
                    }
                }
            } // for
        }

        return undefined;
    }


    private getScoreAttr(plmn: string, operator: string, attr: string): any
    {
        const score = this.getScore(plmn, operator);
        if (score && score.scores && score.scores[attr]) return score.scores[attr];

        return null;
    }


    private refresh(): void
    {
        console.debug("Refreshing NetworkScore Factory - getting current scores");
        //getPhonesConnected({status: 'online'})
        //.then(function(data) {
        //    for (var i = 0, len = data.length; i < len; i++) {
        //        addPhone(data[i]);
        //    }
        //    $rootScope.$emit('phonesPopulated'); // need to use $rootScope from factory
        //})
        //.catch(function() {
        //    console.log("Error: can't access database");;
        //});
    }
}