import { Injectable } from '@angular/core';

//import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
//import * as amc from "@amcharts/amcharts3-angular";
declare var AmCharts: any;


@Injectable({
    providedIn: 'root'
})
export class ChartPieService
{
    constructor() {
        //constructor(private AmCharts: AmChartsService) {
        this.initialise();
    }


    //
    // Public Functions
    //
    public getChart(title: string, valueAxisTitle: string, data: any, chartOptions: any, graphOptions: any): object
    {

                //console.log(AmCharts);
                //console.log(amc);

        let chart = new AmCharts.AmPieChart(chartOptions.theme);
        if (chart) {
            Object.assign(chart, chartOptions);
            
            chart.dataProvider = data;
            chart.titleField   = 'service';
            chart.valueField   = 'result';
        }

        return chart;
    }


    //
    // Private functions
    //
    private initialise(): boolean
    {
        console.debug("Initialising ChartPie service");

        return true;
    }
}