import { Component }          from '@angular/core';
import {
    ActivatedRoute,
    ParamMap,
    Router
}                             from '@angular/router';
import { Observable }         from 'rxjs';

import { BaseComponent }      from '@Base/';
import {
    StatusIndicatorComponent,
    StatusIndicatorState
}                             from '@GuiElements/';
import {
    User,
    UserService
}                             from '@Login/';
import {
    Connection,
    Device,
    DeviceMobilePhone,
    DeviceWithWifi,
    // ElementHelper,
    // ElementInfoComponent,
    ElementState,
    ElementStatus,
    MobileCell,
    Power
}                             from '@ObjElements/';

import { routeNames }         from '../../app.routing.names';

import { DeviceService }      from './device.service';

export { Device }             from '@ObjElements/';


// [TBD]
//* Pass in var to dynamic children of map infowin to have them change their default text colour
//* Add alarm list to device sub-route
//* Add alarm info page, with clear button?
//* Delete/re-add device issue
//* Re-add repeater issue
//* Orgs/Sites to map, infoWin and info screen
// Groups phones and repeaters under sites
// Re-work toast for A9
// Modify device list to only get quantity, and to get list on demand, or when map is shown
//* Update device.service to read device data from array which may have multiple device types
//* Also, if requesting specific phone, don't re-send all repeaters

@Component({
   selector:      'qp-device',
   templateUrl:   'device.component.html',
   styleUrls:   [ 'device.component.css' ],
   providers:   [ DeviceService ]
})
export class DeviceComponent extends BaseComponent
{
    // Used in routes.ts files; can't currently be used in template too as
    // Angular router-outlet doesn't support binding to 'name'
    // Make sure this name matches that in the template
    public static readonly outlet:         string = "device-sub";
    
    public           _data:                Device; // [TBD] leave as type 'any' as HTML needs to access attrs not in base Device class
    public  readonly deviceButtonsEnabled: boolean              = true;
    public  readonly name:                 string               = "Device";
    public  readonly routeBack:            string[] = [];

    private          _authorised:          boolean              = false;
    private          _id:                  number | string;
    private          _state:               ElementState | StatusIndicatorState = StatusIndicatorState.None;

    public           subView:              boolean              = false; // whether to display sub-routes or not (i.e. chart)

    public readonly  notifAttrs:           string[]             = [
        'date',
        'severity',
        'name',
        'description'
    ];


    public constructor(private readonly ActRoute:                 ActivatedRoute,
                       private readonly Router:                   Router,

                       private readonly DeviceService:            DeviceService,
                       private readonly UserService:              UserService)
    {
        super();

        const nav: any = this.Router.getCurrentNavigation();
        if (nav && nav.extras && nav.extras.state && nav.extras.state.parent) {
            this.routeBack.push(nav.extras.state.parent);//console.log(nav.extras.state);
        }

        // [TBD]
        const obs: Observable<User | undefined> | undefined = this.UserService.user$;
        if (obs instanceof Observable) this.sub = obs
            .subscribe((user: User | undefined) => {
                this._authorised = (user instanceof User && user.userrole === "administrator" ? true : false);
            }); // subscribe
    }


    //
    // Getters and setters
    //
    public get actionButtonsEnabled(): boolean
    {
        return !! (this.data instanceof DeviceMobilePhone && this.data.statusI === ElementStatus.Online);
    }


    public get authorised(): boolean
    {
        return this._authorised;
    }


    public get battery(): Power | undefined
    {
        return (this.data instanceof DeviceMobilePhone && this.data.battery instanceof Power)
            ? this.data.battery 
            : undefined;
    }


    public get connection(): Connection | undefined
    {
        return (this.data instanceof DeviceMobilePhone && this.data.connection instanceof Connection)
            ? this.data.connection 
            : undefined;
    }


    public get data(): Device
    {
        return this._data;
    }

    private set dataI(d: Device)
    {
        this._data = d;

        if (this.data) {
            this.state = this.data.state;//StatusIndicatorComponent.getState(this.data.state);
            // this.open(this.data);
        }
    }


    private get id(): number | string
    {
        return this._id;
    }

    private set id(d: number | string)
    {
        this._id = (d ? d : this._id);
        this.refresh();
    }


    public get mobileCells(): MobileCell[] | undefined
    {
        return (this.data instanceof DeviceMobilePhone)
            ? (Array.isArray(this.data.mobileCells)
                ? this.data.mobileCells
                : undefined
            )
            
            : undefined;
    }


    public get state(): ElementState | StatusIndicatorState
    {
        return this._state;
    }

    public set state(s: ElementState | StatusIndicatorState)
    {
        this._state = s;
    }


    public get wifi(): DeviceWithWifi | undefined
    {
        return (this.data && this.data.wifi instanceof DeviceWithWifi)
            ? this.data.wifi 
            : undefined;
    }


    //
    // Public methods
    //
    public buttonPower(state: string): Observable<any> | undefined
    {
        console.debug("Button pressed - changing power state for device '" + this.id + "': " + state);
        return this.DeviceService.sendPower(state);
    }


    public buttonShowOnMap(): void
    {
        this.showOnMap();
    }


    public buttonAlert(): Observable<any> | undefined
    {
        console.debug("Button pressed - alerting device '" + this.id + "'");
        return this.DeviceService.sendAlert();
    }


    // public buttonBattery(): void
    // {
    //     console.debug("Battery button pressed");
    // }


    public buttonConfig(): Observable<any> | undefined
    {
        console.debug("Button pressed - send config to selected device '" + this.id + "'");
        return this.DeviceService.sendConfig();
    }


    public buttonDisconnect(): Observable<any> | undefined
    {
        console.debug("Button pressed - disconnecting selected device '" + this.id + "'");
        return this.DeviceService.disconnect(true); // reconnect allowed
    }


    // public buttonMobileCell(): void
    // {
    //     console.debug("Mobile cell button pressed");
    //     //this.Router.navigate([ routeNames.deviceChart ], { relativeTo: this.ActRoute });
    // }


    public refresh(): void
    {
        console.debug("Refreshing device: " + this.id);
        this.DeviceService.id = this.id; // triggers data look-up
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();
        
        // this.dataI = undefined;
        // // Set device service ID to ensure next time this component opens, no stale date
        // this.DeviceService.id = undefined;
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising " + this.name + " component");

        //this.dataI = new DeviceMobilePhone(); // Placeholder for HTML
        this.sub = this.ActRoute.paramMap
            .subscribe((params: ParamMap): void => {
                const s: string | null = params && params.has('id') ? params.get('id') : "";
                if (s) this.id = s;  // triggers call to refresh();
            }); // subscribe

        this.sub = this.DeviceService.updated$
            .subscribe((d: Device | undefined): void => {
                if (d instanceof Device) this.dataI = d;
            }); // subscribe
    }


    //
    // Private methods
    //

    // private open(d: Device): void
    // {
    //     // Get appropriate dynamic component type
    //     const t: typeof ElementInfoComponent | undefined
    //         = d instanceof Device ? ElementHelper.getInfoType(d) : undefined;

    //     if (t && (! (this._compRef instanceof ComponentRef) || this._compRef.componentType !== t)) {
    //         // Clean up any existing dynamic component
    //         if (this._compRef instanceof ComponentRef) this._compRef.destroy();

    //         // Dynamically create component if not already created
    //         const compFactory: ComponentFactory<ElementInfoComponent>
    //             = this.ComponentFactoryResolver.resolveComponentFactory(t);

    //         // Create new component
    //         if (this.container && compFactory instanceof ComponentFactory)
    //             this._compRef = this.container.createComponent(compFactory)
    //     }

    //     // Pass data to new component
    //     if (this._compRef instanceof ComponentRef) this._compRef.instance.data = d;
    // }

    
    private showOnMap(): void
    {
        // console.log("Show device " + this.data.id + ":" + (<any>this).data.name + "on map");
        // this.TabsService.tabActiveReq = 'map';
        if (this.data instanceof Device) {
            //this.Router.navigate([ routeNames.map , Device.tag + ':' + this.id] );
            this.Router.navigate([ routeNames.map ], { queryParams: { 'element': Device.tag, 'id': this.id } });
        }
    }
}