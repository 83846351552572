import { 
    BehaviorSubject,
    Subject,
    Observable,
    Subscription
}                                       from 'rxjs';

import {
    Icons,
    IconsService
}                                       from '@Icons/';
import {
    Element,
    ElementHelper,
    ElementMainCommon as ElementMain,
    Geolocation
}                                       from '@ObjElements/';
import { Utils }                        from '@Utils/';

import { MapElementLatLng }             from './map-element-latlng/';
import { MapElementPoint }              from './map-element-point/';

import { MapElementAnimation }          from './map-element-animation.type';
import { MapElementCircle }             from './map-element-circle.class';
import { MapElementIcon }               from './map-element-icon.interface';
import { MapElementMarkerLabel }        from './map-element-markerlabel.interface';
import { MapElementMouseEvent }         from './map-element-mouseevent.interface';
import { MapElementMapsEventListener }  from './map-element-mapseventlistener.interface';
import { MapElementStreetViewPanorama } from './map-element-streetview-panorama.class';

import { MapElementMap }                from './map-element-map.class';


export class MapElementMarker extends google.maps.Marker
{
    public  static readonly outputDecimalPlaces:      number                                     = 5;
    public  static readonly mapDblClickZoom:          number                                     = 15;

    public  static readonly labelConfig:              MapElementMarkerLabel                      = {
        color:      'red',
        //fontFamily: undefined,
        fontSize:   '20px',
        fontWeight: 'bold',
        text:       "undefined"
    };

    public  static readonly labelPos:                 MapElementPoint                            = MapElementPoint.get(25,25);

    private static readonly timerMarkerBouncingStart: number = 0.5; // secs
    private static readonly timerMarkerBouncingStop:  number = 5;   // secs
    private static readonly timerMarkerNormal:        number = 7;   // secs

    private static readonly eventClick:               string = 'click';
    private static readonly eventDblClick:            string = 'dblclick';

    private                 _click$:                  Subject<boolean>;
    private                 _circle:                  MapElementCircle | undefined               = undefined;
    private                 _clickNotif:              Subscription;
    private        readonly _dataNotif:               Subscription;
    private                 _deleting$:               Subject<MapElementMarker>;
    private        readonly _elements:                Map<Element | ElementMain, Subscription>;//   = new Map(); // Map multiple elements to be represented by same marker
    private                 _geoLocChangeNotif:       Subscription;
    private                 _geoLocChanged$:          Subject<Element | ElementMain>;
    private                 _isNew:                   boolean                                    = false;
    private        readonly _listeners:               MapElementMapsEventListener[]              = [];
    private        readonly _sub:                     Subscription
    private                 _update$:                 BehaviorSubject<Element | ElementMain | undefined>;
    private                 _timeout:                 number; // used to window.setTimeout()


    public constructor(args?: any)
    {
        super(args ? args.loc : null);

        this._circle         = this.createCircle(args ? args.position : undefined);
        this._dataNotif      = new Subscription();
        this._elements       = new Map(); // '= new Map()' above is only executed after constructor is finished (hoisted) so do here instead

        this._click$         = new Subject<boolean>();
        this._deleting$      = new Subject<MapElementMarker>();
        this._geoLocChanged$ = new Subject<Element | ElementMain>();
        this._sub            = new Subscription();
        this._update$        = new BehaviorSubject<Element | ElementMain | undefined>(undefined);


        //
        // Listeners
        //
        this.listener = this.addListener(MapElementMarker.eventClick,    (event: MapElementMouseEvent): void => {
            // console.log("Mouse click");
            this._click$.next(true);
        }); // addListener

        this.listener = this.addListener(MapElementMarker.eventDblClick, (event: MapElementMouseEvent): void => {
            const map: MapElementMap = this.getMap() as MapElementMap;
            if (map) {
                const pos: google.maps.LatLng | null | undefined = this.getPosition();
                if (pos instanceof google.maps.LatLng) map.setCenter(pos);
                map.setZoom(MapElementMarker.mapDblClickZoom);
            }
        }); // addListener

        // this.listener = this.addListener('mouseover', (event: MapElementMouseEvent): void => {
        //     console.debug("Mouse over");
        //     console.debug(this);
        //     //if (event) event.stop(); // don't propogate up if being used properly
        // }); // addListener

        // this.listener = this.addListener('mouseout',  (event: MapElementMouseEvent): void => {
        //     console.log("Mouse out");
        //     //if (event) event.stop(); // don't propogate up if being used properly
        // }); // addListener


        if (args) {
            if (args.element) this.element = args.element;// addElement(args.device);
            //     this._update$ = new BehaviorSubject<Element>(this.element = args.device);
            //     // Listen for updates
            //     if (args.device.notification) this.dataNotif = this.element.notification.subscribe(
            //         function(this: MapElementMarker, d: Element): void {
            //             console.log("h4");
            //             //this.element = d;
            //             this.updateMarker();
            //             this._update$.next(this.element);
            //         }.bind(this),
            //     );
            // }

            this.isNew = args.isNew;
        } // args
    }


    //
    // Static methods
    //
    public static get(d: Element | ElementMain, isNew?: boolean): MapElementMarker | undefined
    {
        return (d instanceof Element || d instanceof ElementMain)
            ? new MapElementMarker(
                {
                    element: d,
                    isNew:   isNew,
                    loc:     d.geolocation
                }
            )
            : undefined;
    }


    //
    // Getters and setters
    //
    public get action(): any // [TBD]
    {
        return this.element ? (this.element as any).action : undefined;
    }


    public get click(): Observable<boolean>
    {
        return this._click$.asObservable();
    }


    public get clickNotif(): Subscription | undefined
    {
        return this._clickNotif;
    }

    public set clickNotif(d: Subscription | undefined)
    {
        if (this._clickNotif) this._clickNotif.unsubscribe();
        if (d instanceof Subscription) this._clickNotif = d;
    }


    public get circle(): MapElementCircle | undefined
    {
        return this._circle;
    }


    public get dataNotif(): Subscription | undefined
    {
        return this._dataNotif;
    }

    public set dataNotif(d: Subscription | undefined)
    {
        if (d instanceof Subscription) this._dataNotif.add(d);
        else                           this._dataNotif.unsubscribe();
    }


    private get delete(): Subject<MapElementMarker>
    {
        return this._deleting$;
    }


    public get deleting(): Observable<MapElementMarker>
    {
        return this.delete.asObservable();
    }


    public get element(): Element | ElementMain
    {
        return this._elements.keys().next().value; // return first key in Map
        // for (const k of this._elements.keys()) {
        //     if (k) return k;
        // } // for

        // return undefined;
    }

    public set element(d: Element | ElementMain)
    {
        if (d) {
            const sub = this.addElement(d);
            if (sub) this._elements.set(d, sub);
            
            // Don't do this, as sometimes returns Subscriber, not Subscription
            // if (sub instanceof Subscription) this._elements.set(d, sub);
        }

        this.setLabel("" + (this._elements.size > 1 ? this._elements.size : ""));
    }

    public get elements(): Map<Element | ElementMain, Subscription>
    {
        return this._elements;
    }

    
    public get geoLocChanged(): Observable<Element | ElementMain>
    {
        return this._geoLocChanged$.asObservable();
    }


    public get geoLocChangedNotif(): Subscription | undefined
    {
        return this._geoLocChangeNotif
    }

    public set geoLocChangedNotif(d: Subscription | undefined)
    {
        if (this._geoLocChangeNotif instanceof Subscription) this._geoLocChangeNotif.unsubscribe;
        if (d instanceof Subscription) this._geoLocChangeNotif = d;
    }


    public get id(): string
    {
        return (this.element) ? this.element.id : "";
    }


    private get isNew(): boolean
    {
        return this._isNew;
    }

    private set isNew(d: boolean)
    {
        if (d != undefined) this._isNew = d;
    }


    private get listeners(): MapElementMapsEventListener[] | undefined
    {
        return this._listeners;
    }

    private set listener(d: MapElementMapsEventListener | undefined)
    {
        if (d) this._listeners.push(d)
        else {
            this._listeners.forEach((l: MapElementMapsEventListener): void => {
                if (l) this.removeListener(l);
            }); // forEach
            this._listeners.length = 0;
        }
    }


    public get notification(): Observable<Element | ElementMain | undefined> | undefined
    {
        return (this._update$ instanceof Subject) ? this._update$.asObservable() : undefined;
    }


    private get sub(): Subscription | undefined
    {
        return this._sub;
    }

    private set sub(d: Subscription | undefined)
    {
        if (d instanceof Subscription) this._sub.add(d);
        else                           this._sub.unsubscribe();
    }


    public get timeout(): number | undefined
    {
        return this._timeout;
    }

    public set timeout(d: number | undefined)
    {
        if (this._timeout) window.clearTimeout(this._timeout);
        if (d) this._timeout = d;
    }


    // private get update(): BehaviorSubject<Element>
    // {
    //     return this._update$;
    // }


    //
    // Public functions
    //
    public cleanUp(): void
    {
        this.delete.next(this);

        this.setMap(null);

        this._click$.complete();
        this._deleting$.complete();
        this._geoLocChanged$.complete()
        this._update$.complete();

        this.clickNotif          = undefined; // calls unsubscribe()
        this.dataNotif           = undefined; // calls unsubscribe()
        this.geoLocChangedNotif  = undefined; // calls unsubscribe()

        this.timeout             = undefined; // calls clearTimeout;
        this.listener            = undefined; // will call remove on each listener
        this.sub                 = undefined; // will trigger unsubscribe via setter

        this.elements.clear();
    }


    public delElement(d: Element | ElementMain): Element | ElementMain
    {
        if (d) {
            const s: Subscription | undefined = this.elements.get(d);
            if (s instanceof Subscription) s.unsubscribe();
            const i: boolean = this.elements.delete(d);

            this.updateMarker();
        }

        return d;
    }


    public getElement(d: string) : Element | ElementMain | undefined
    {
        // Note: not '===' due to string <-> number comparisons
        return [...this.elements.keys()].find(e => e.id == d);
    }


    public infoWinContent(): string
    {
        if (this.elements.size > 1) {
            console.log("Multiple elements represented by this marker " + this.id);
        }

        let contentStr = "";
        for (const k of this.elements.keys()) {
            if (k instanceof Element) contentStr += k.infoWinContent() + "\n"; 
        } // for

        return contentStr;//(this.device) ? this.device.infoWinContent() : null;
    }


    // Override - must keep public
    public override setIcon(icon: string | null | object | undefined, anim?: any): void
    {
        const i: MapElementIcon = {
            labelOrigin: MapElementMarker.labelPos,
            url:         typeof icon === "string" ? icon : (icon as any).url
        };

        const j: any = this.getIcon();
        if (! j
            || ((typeof j === "string" && j !== i.url)
                || ('url' in j && j.url !== i.url)) ) {
            // [TBD] temp to investigate icon issue
            if (typeof icon !== "string") console.error(icon);

            super.setIcon(i);
            this.setAnimation(anim
                ? anim
                : (!! (icon as any).anim ? MapElementAnimation.BOUNCE : undefined)
            ); // setAnimation
        }
    }


    // Override
    public override setLabel(d: string): void
    {
        const ml: MapElementMarkerLabel = MapElementMarker.labelConfig;
        ml.text = d;

        return super.setLabel(d ? ml : d); // set text directly if null
    }


    // Override

    // [TBD] check types
    public override setMap(m:
        MapElementMap |
        MapElementStreetViewPanorama |
        google.maps.Map |
        google.maps.StreetViewPanorama |
        null |
        undefined): void
    {
        super.setMap(m instanceof MapElementMap ? m : null); // must be NULL, not undefined

        if (this.circle) this.circle.setMap(m instanceof MapElementMap ? m : null); // must be NULL. not undefined

        if (m instanceof MapElementMap) this.setIconI();
        //     // Set icon image and animation
        //     if (this.isNew) this.setIconNew();
        //     else            this.setIconStandard();
        // }
    }


    // Override - must keep public
    public override setPosition(l: MapElementLatLng, force: boolean = true): MapElementLatLng | undefined
    {
        const l2: MapElementLatLng | undefined = (l instanceof MapElementLatLng) ? l : MapElementLatLng.get2(l);
        if (l2 instanceof MapElementLatLng && (force || this.getPosition() !== l2)) {
            super.setPosition(l2);
            if (this.circle) this.circle.setCenter(l2);
            this.updateTitle();
        }

        return l2;
    }


    //
    // Private functions
    //
    private addElement(d: Element | ElementMain): Subscription | undefined
    {
        //this._update$ = (dnew BehaviorSubject<Element>(this.element = d);

        if (d) {
            // Listen for updates
            const obs: Observable<any> | undefined = d.notification;
            if (obs instanceof Observable) {
                const sub: Subscription = obs.subscribe(
                    // function() instead of => as bind() used
                    function(this: MapElementMarker, e: Element | ElementMain): void {
                        this.updateMarker(d);
                        this._update$.next(e);

                        //this._geoLocChanged$.next(d);
                    }.bind(this),
                ); // subscribe 

                // Listen separately to each Element's geolocation changed
                if (d instanceof Element && sub instanceof Subscription) sub.add(
                    d.geolocationChanged.subscribe((d: Element | ElementMain): void => {
                        // Notify parent layer so it can manage marker combining, only if this marker
                        // represents more than one element
                        if (this.elements.size > 1) this._geoLocChanged$.next(d);
                    }) // subscribe
                ); // add

                return sub;
            }
        }
        
        return undefined;
    }


    private createCircle(pos: MapElementLatLng, force: boolean = false): MapElementCircle | undefined
    {
        return (force || ! this._circle)
            ? new MapElementCircle({
                  center:        pos,
                  fillColor:     '#FF0000',
                  fillOpacity:   0.2,
                  map:           this.getMap() as MapElementMap,
                  radius:        pos ? pos.accuracy : 0,
                  strokeColor:   '#FF0000',
                  strokeOpacity: 0.2,
                  strokeWeight:  2
              }) // MapElementCircle

            : undefined;
    }


    private removeListener(l: MapElementMapsEventListener): MapElementMapsEventListener
    {
        if (l) l.remove();
        return l;
    }


    private setIconI(): any
    {
        return this.isNew ? this.setIconNew() : this.setIconStandard();
    }


    private setIconActionTest(): any
    {
        return this.setIcon(Icons.deviceNew, MapElementAnimation.BOUNCE);
    }


    private setIconNew(): any
    {
        // Drop icon down on to map
        const icon: any = this.setIcon(Icons.deviceNew, MapElementAnimation.DROP);
        this.isNew = false; // *MUST* do before calling setIconStandard()

        // Then start bouncing icon
        this.timeout = window.setTimeout((): void => {
            this.setAnimation(MapElementAnimation.BOUNCE);
            
            // Then stop icon bouncing
            this.timeout = window.setTimeout((): void => {
                this.setAnimation(null);

                // Finally change to normal icon
                this.timeout = window.setTimeout((): void => {
                    this.setIconStandard(true);
                }, MapElementMarker.timerMarkerNormal * 1000); // normal icon - 7s

            }, MapElementMarker.timerMarkerBouncingStop * 1000); // stop bouncing - 5s

        }, MapElementMarker.timerMarkerBouncingStart * 1000); // start bouncing - 0.5s

        return icon;
    }


    private setIconStandard(force?: boolean): any
    {
        let icon: any = undefined;
        if (this.element instanceof Element || this.element instanceof ElementMain) {
            if      ((this as any).element.pendingDelete) {
                console.log("Pending delete so greying out icon");

                // If pendingDelete, set grey static icon
                this.timeout = undefined; // clear any timeout, might happen during isNew
                icon = Icons.deviceDeleting;
                this.setAnimation(undefined);
            }

            // Don't update if still in newly-added state
            else if (! this.isNew || force) {
                icon = Icons.deviceDefault;//IconsService.mobilePhoneDefaultIcon;

// [TBD] Move below to Icons module?

                // console.error(this.element.icon);
                
                if      (this.element instanceof Element && this.element.icon) icon = this.element.icon;// IconsService.getOperatorIcon(null, this.element.icon) 

                // Nextivity (Cel-Fi) repeater
                //else if ((this as any).element.manufacturer   === "cel-fi")    icon = this.element.iconStr;

                // Special case for (non-phone) Raspberry Pi
                else if ((this as any).element.manufacturer   === "raspberry") icon = Icons.server;

                // Special case for non-Raspberry Pi device with no SIM but connected via Wifi
                else if ((this as any).element.connectionType === "wifi")      icon = Icons.wifi;

                // Special case for non-Raspberry Pi device with no SIM but connected via Ethernet
                else if ((this as any).element.connectionType === "ethernet")  icon = Icons.ethernet;

                else if (this.element instanceof ElementMain) icon = IconsService.get(this.element);
            }
        }

        return icon ? this.setIcon(icon) : undefined;
    }


    private title2(d?: Element | ElementMain): string
    {
        let t: string = (d instanceof Element || d instanceof ElementMain) ? ElementHelper.getTitle(d)  : "";
        if (! t) {
            for (const d2 of this.elements.keys()) {
                // console.error(ElementHelper.getTitle(d2));
                t += Utils.stringCapFirstLetter(ElementHelper.getTitle(d2)) + "\n";
                // if (d instanceof Element) t += Utils.stringCapFirstLetter(d.markerTitle) + "\n";
            } // for
        }

        return Utils.stringCapFirstLetter(t);
    }


    private updateMarker(d?: Element | ElementMain): void
    {
        d = (d instanceof Element || d instanceof ElementMain) ? d : this.element;

        this.updateTitle(d);
        this.setIconI(); // update icon if necessary

        // Set marker position to first element in Map
        if (d && d.geolocation) this.setPosition(d.geolocation); // sets accuracy circle too
    }


    private updateTitle(d?: Element | ElementMain): string
    {
        d = (d instanceof Element || d instanceof ElementMain) ? d : this.element;

        const t: string = this.title2(d);
        if (t) this.setTitle(t);
        return t;
    }
}