import { Component }     from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
   selector:     'qp-sites-container',
   templateUrl:  'sites-container.component.html',
   styleUrls:   ['sites-container.component.css']
})
export class SitesContainerComponent extends BaseComponent
{
   
}