<qp-window-header
    [title]="name"
    [value]="data.name"
    [state]="state"
    [statusEnabled]=true
    [geolocationEnabled]="!!(data && data.geolocation)"
    (geolocation)="buttonShowOnMap()"
    (refresh)="refresh()">
</qp-window-header>

<!-- <a [routerLink]="routeBack" *ngIf="routeBack.length > 0"><h4>Back to organisation</h4></a> -->

<qp-route-back [route]="routeBack"></qp-route-back>

<!-- class="right" *ngIf="data && data.geolocation" (pressed)="buttonShowOnMap()"> -->
<!-- <qp-geolocation-button
    *ngIf="data && data.geolocation" (pressed)="buttonShowOnMap()">
</qp-geolocation-button> -->

<!-- <qp-organisation-info
    [data]=data>
</qp-organisation-info> -->
<qp-element-info-container
    [data]="data" [showType]="false">
</qp-element-info-container>


<br><br>


<qp-devices *ngIf="data.deviceList.length > 0"
    [data]=data.deviceList [parentId]=+data.id>
</qp-devices>