import {
    Component,
    ViewChild
}                                from '@angular/core';
// import { MatCheckboxChange }     from '@angular/material/checkbox';
import {
    ActivatedRoute,
    ParamMap
}                                from '@angular/router';
import {
    Observable,
    
    filter
}                                from 'rxjs';

import { BaseComponent }         from '@Base/';
import { IconsMaterial }         from '@Icons/';
import {
    MatCheckboxChange,
    // MatOptionSelectionChange,
    MatSelect
}                                from '@Material/';
import { DataServiceEvents }     from '@Misc/Services/';
import { Utils }                 from '@Utils/';

import { MapElementOverlayView } from '../../map-elements/map-element-overlayview.class';
import { MapLayer }              from '../map-layer/map-layer.class';
import { MapService }            from '../../map.service';

import {
    MapLayerHighlightData,
    MapLayersService
}                                from '../map-layers.service';


@Component({
    selector:     'qp-map-layers-control',
    templateUrl:  'map-layers-control.component.html',
    styleUrls:   ['map-layers-control.component.css']
    //providers:   [ MapLayersService ] // non-singleton, will destroy service when component destroyed
})
export class MapLayersComponent extends BaseComponent
{
    public readonly icon:   string = IconsMaterial.map_layers;
 
    public          layers: MapLayer[] = [];
    private         _val:   MapLayer[] = [];

    @ViewChild(MatSelect, { static: false })
    private        _select: MatSelect;

    
    public constructor(private readonly ActRoute:         ActivatedRoute,
                       private readonly MapService:       MapService,
                       private readonly MapLayersService: MapLayersService)
    {
        super();
    }


    // Override - don't let base class call this.initialise()
    public override ngOnInit(): void
    {
        // Call this.initialise() in ngAfterContentInit() to ensure view fully set up
    }


    // Override
    public override ngAfterViewInit(): void // don't use ngAfterViewInit() as that can produce ExpressionChangedAfterItHasBeenCheckedError error
    {
        // super.ngAfterContentInit();
        super.ngAfterViewInit();

        // Do here to call only after view fully set up
        this.initialise();

        if (this._select instanceof MatSelect) this.sub = this._select.optionSelectionChanges.subscribe((d: any) => {
            console.error(d);
        }); // subscribe
    }


    // // Override
    // ngOnDestroy()
    // {
    //     console.log("Destroying MapLayersComponent");

    //     super.ngOnDestroy();

    //     // Not needed if MapLayersService in this component's 'providers' array
    //     //if (this.MapLayersService) this.MapLayersService.cleanUp(); // clean up service
    // }


    //
    // Getters and setters
    //

    // public get layers(): MapLayer[]
    // {
    //     return this.MapLayersService
    //         ? this.MapLayersService.layers.sort((a, b) => {
    //             return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0);
    //         })
    //         : undefined
    // }


    public get layersEnabled(): boolean
    {
        return this.MapLayersService ? this.MapLayersService.layersEnabled : false
    }

    
    public get val(): MapLayer[]
    {
        return this._val;
    }

    public set val(d: MapLayer[])
    {
        this._val = d;
    }


    public get options(): any
    {
        return this._val;
    }

    public set options(d: any)
    {
        console.error(d);
    }


    //
    // Public functions
    //
    public changedState2(event: Event, d: MapLayer | undefined): void
    {
        console.error(event)
        console.error(d)
        // e.checked is new, target value
        if (event && this.MapLayersService) {
            this.val.forEach((l: MapLayer) => this.MapLayersService.setLayerMap(l, true))
        }
    }


    public changedState(event: MatCheckboxChange, l: MapLayer): void
    {
        // e.checked is new, target value
        if (event && this.MapLayersService) this.MapLayersService.setLayerMap(l, event.checked);
    }


    public isChecked(l: MapLayer): boolean 
    {
        return this.MapLayersService.getLayerState(l);
    }


    public isLayer(l: MapLayer): MapElementOverlayView | undefined
    {
        return (l instanceof MapLayer) ? l.layer : undefined;
    }


    public toggleLayersAll(): void
    {
        this.MapLayersService.toggleLayersAll();
    }


    //
    // Protected functions
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Map Layers component");

        // Wait for MapService to be configured, then trigger configuration of MapLayersService
        // Cannot do purely in MapLayersService, as MapService not guaranteed to be available
        // during MapLayersService construction (which calls initialise()), and as a service, no
        // other lifecycle triggers to use.  Hence, trigger from component, as part of
        // ngAfterViewInit() trigger

        if (this.MapLayersService instanceof MapLayersService) {
            if (this.MapService instanceof MapService) {
                this.sub = this.MapService.initialised
                    .pipe(filter((available: boolean): boolean => available === true))
                    // .pipe(take(1)) // don't use take, need to find out when MapSevice reinitialised
                    .subscribe((available: boolean) : void => {
                        this.MapLayersService.configure();

                        const obs: Observable<MapLayer[]> | undefined = this.MapLayersService[DataServiceEvents.data];
                        if (obs instanceof Observable) this.sub = obs
                            .subscribe((d: MapLayer[]) => {
                                if (Array.isArray(d)) {
                                    // setTimeout() workaround for ExpressionChangedAfterItHasBeenCheckedError error sometimes seen
                                    // window.setTimeout((): void => {
                                    Utils.delaySecs((): void => {
                                        this.layers = d.sort((a: MapLayer, b: MapLayer) => {
                                            return a instanceof MapLayer && b instanceof MapLayer && a.name && b.name
                                                ? a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)
                                                : 0;
                                        }); // sort
                                    }); // Utils.delay// setTimeout
                                }
                            }); // subscribe

                        // Used to highlight device in map in response to route from elsewhere
                        // Deprecate
                        this.sub = this.ActRoute.paramMap
                            .subscribe((params: ParamMap): void => {
                                const s: string | null = params && params.has('id') ? params.get('id') : "";
                                const [type, id] =  s ? s.split(':', 2) : [];
                                if (type && id) {
                                    const hd: MapLayerHighlightData = { type: type, id: id };
                                    console.debug(hd);
                                    this.MapLayersService.highlight(hd);
                                }
                            }); // subscribe ActRoute

                        this.sub = this.ActRoute.queryParamMap
                            .subscribe((params: ParamMap): void => {
                                // Note: calling this here will fail if params from direct URL navigation
                                // as data may well not have been loaded yet
                                if (params) {
                                    const type: string | null = params.has('element') ? params.get('element') : "";
                                    const id:   string | null = params.has('id')      ? params.get('id') : "";
                                    if (type && id) {
                                        const hd: MapLayerHighlightData = { type: type, id: id };
                                        console.debug(hd);
                                        this.MapLayersService.highlight(hd);
                                    }
                                }
                            }); // subscribe ActRoute
                    }); // subscribe MapService
            }
        }
        else {
            console.warn("Unable to configure Map Layers Service");
        }

        console.debug("Initialised Map Layers component");
    }
}