import { Routes }                    from '@angular/router';

import { AuthenticationGuard }       from '@Login/';
import { 
    notificationsRoutes,
    NotificationsContainerComponent 
}                                    from '@Notifications/';

import {
    DeviceComponent,
    deviceRoutes
}                                    from './device/';
import { DevicesComponent }          from './devices.component';
import { DevicesContainerComponent } from './devices-container.component';

import { routeNames }                from '../app.routing.names';


export const devicesRoutes: Routes = [
    {
        path:        '',
        component:   DevicesComponent, // list
        data:        { animation: routeNames.devices },
        canActivate: [ AuthenticationGuard ]
        //outlet:      DevicesContainerComponent.outlet
    },

    {
        path:        ':id',
        component:   DeviceComponent,  // individual
        data:        { animation: routeNames.devices },
        canActivate: [ AuthenticationGuard ],
        //outlet:      DevicesContainerComponent.outlet,
        children:    deviceRoutes
    },

    {
        path:        ':id/' + routeNames.notifications,
        component:   NotificationsContainerComponent,
        data:        { animation: routeNames.notifications },
        canActivate: [ AuthenticationGuard ],
        //outlet:      NotificationsContainerComponent.outlet,//'primary',//DevicesContainerComponent.outlet, // essential
        children:    notificationsRoutes
    }
];