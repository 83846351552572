import { Subscription }                from 'rxjs';

import { MapElementMapsEventListener } from './map-element-mapseventlistener.interface';


// Note: this is a Mixin class, as 'derived' classes also need to inherit from google.maps
// class, and multiple class inheritence is not supported in Typescipt.  Hence, this class
// will be 'mixed-in' to the 'derived' classes
//export const MapElementBaseMixin = <T extends AnyConstructor<AlreadyImplements>>(base: T) =>
export const MapElementBaseMixin = <T extends AnyConstructor<object>>(base: T) =>
    class MapElementBase extends base
    {
        // private readonly _listeners: MapElementMapsEventListener[] = [];
        readonly _listeners: MapElementMapsEventListener[] = [];
        // private readonly _sub:       Subscription                  = new Subscription();
        readonly _sub:       Subscription                  = new Subscription();


        //
        // Getters and setters
        //
        // protected get listeners(): MapElementMapsEventListener[]
        get listeners(): MapElementMapsEventListener[]
        {
            return this._listeners;
        }


        // protected set listener(d: MapElementMapsEventListener | undefined)
        set listener(d: MapElementMapsEventListener | undefined)
        {
            if (d) {
                this._listeners.push(d)
            }
            else
            {
                this._listeners.forEach((l: MapElementMapsEventListener): void => {
                    this.removeListener(l);
                }); // forEach
                this._listeners.length = 0;
            }
        }


        // protected get sub(): Subscription | undefined
        get sub(): Subscription | undefined
        {
            return this._sub;
        }


        // protected set sub(s: Subscription | undefined)
        set sub(s: Subscription | undefined)
        {
            if (s) this._sub.add(s);
            else   this._sub.unsubscribe();
        }


        //
        // Protected functions
        //

        // Override
        // protected cleanUp()
        cleanUp()
        {
            this.listener = undefined; // will call remove on each listener
            this.sub      = undefined; // will trigger unsubscribe via setter
        }


        //
        // Private functions
        //
        // private removeListener(l: MapElementMapsEventListener)
        removeListener(l: MapElementMapsEventListener)
        {
            if (l) l.remove();
        }
    } // class - auto-return this class without needing  { } and return statment

export type AnyConstructor<A = object>   = new (...input: any[]) => A
export type AnyFunction<A = any>         = (...input: any[]) => A
export type Mixin<T extends AnyFunction> = InstanceType<ReturnType<T>>
export type MapElementBaseType           = Mixin<typeof MapElementBaseMixin>