import { Routes }              from '@angular/router';

import { AuthenticationGuard } from '@Login/';

import { MapComponent }        from './map.component';


export const mapRoutes: Routes = [
    {
        path:        '',
        component:   MapComponent,
        data:        { animation: 'map' },
        canActivate: [ AuthenticationGuard ]
    },

    {
        path:        ':id',
        component:   MapComponent,
        data:        { animation: 'map' },
        canActivate: [ AuthenticationGuard ]
    }
];