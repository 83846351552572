<mat-dialog-content>

<!--
        <div class="buttonAndTextInner2" style="min-width: 40%">
            <div class="input-group" style="width: 100%">
                <input type="text" class="form-control" placeholder="Search table" data-ng-model="search">
            </div>      
        </div>
    </div> -->

    <qp-window-header [title]="name" [value]="data.length" (refresh)="refresh()"></qp-window-header>
    <p>

    <!--<div flex="100" class="flex flex-col">
    <md-content md-theme="input" layout="column" >
    <md-input-container style="padding-bottom: 0; margin-bottom: 0">
    <md-icon class="material-icons">&#xE8B6;</md-icon>
    <input type="text" md-maxlength="50" data-ng-model="search" placeholder="Search table">
    </md-input-container>
    </md-content>
    </div>
    -->

    <div class="pagCtrl">
        <pagination-controls
            [autoHide]="true"
            [directionLinks]="true"
            (page-change)="pageChanged(newPageNumber, oldPageNumber)"
            [responsive]="true"
            id="'tab7'">
        </pagination-controls>
    </div>

    <div style="overflow-x: auto; vertical-align: middle;">
        <h2 *ngIf="data.length <= 0">No data</h2>
        <table class="tab table" *ngIf="data.length > 0">
            <!--<caption>Connected Phones</caption>-->
            <thead>
                <tr>
                    <th></th> <!-- blank column heading for checkbox -->
                    <th *ngFor="let key of Object.keys(data[0])">
                        <button mat-button (click)="sortBy(key)" matTooltip="Click to change order">
                            <mat-icon aria-label="Change order">{{ propertyName == key && ! doReverse ? 'arrow_drop_down' : 'arrow_drop_up' }}</mat-icon>
                            <!-- 'titlecase' is Angular's built-in TitleCasePipe from CommonModule -->
                            {{ (key === 'sync' ? 'driveTest?' : key) | titlecase }}
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let group of data | orderBy:propertyName:doReverse | paginate: { itemsPerPage: 20, currentPage: currentPage, id: 'tab7'}" (click)="showDetails($event, group)">
                    <td><input type="checkbox" (click)="groupSelected(group)" [disabled]="! compareAvailable && ! selected[group.name]"/></td>
                    <!--<td data-ng-class="getColour(key, value)" *ngFor="let (key, value) of group track by $index">{{ key === 'sync' ? (value === 1 ? 'yes' : 'no') : (key === 'devices' ? Object.keys(value).length : value) }}</td> -->
                    <td *ngFor="let key of Object.keys(group)" [ngClass]="getColour(key, group[key])">
                        {{ key === 'sync' ? (group[key] === 1 ? 'yes' : 'no') : (key === 'devices' ? Object.keys(group[key]).length : group[key]) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="pagCtrl">
        <pagination-controls
            [autoHide]="true"
            [directionLinks]="true"
            (page-change)="pageChanged(newPageNumber, oldPageNumber)"
            [responsive]="true"
            id="'tab7'">
        </pagination-controls>
    </div>

    <button mat-raised-button color="primary" (click)="compare()" [disabled]="! compareCheckboxesAvailable" matTooltip="Compare device groups">
        <mat-icon aria-label="Compare">compare</mat-icon>
    </button>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close color="primary" (click)="close()" matTooltip="Close">Close</button>
</mat-dialog-actions>