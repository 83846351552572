// Need to emulate enum of objects for type safety as TypeScript
// only supports enums of numbers or strings
export class MapLayerType
{
    private static readonly _layers           = new Map<string, MapLayerType>();

    public static readonly liveDevices        = MapLayerType.get("liveDevices",        { name: 'liveDevices',        displayName: 'Live devices',                        default: false });
    // public static readonly liveDevices        = new MapLayerType("liveDevices",        { name: 'liveDevices',        displayName: 'Live devices',                        default: true  });
    public static readonly liveCells          = MapLayerType.get("liveCells",          { name: 'liveCells',          displayName: 'Live cells',                          default: false });
    public static readonly liveNetworks       = MapLayerType.get("liveNetworks",       { name: 'liveNetworks',       displayName: 'Live mobile networks',                default: false });
    //public static readonly liveRepeaters      = MapLayerType.get("liveRepeaters",      { name: 'liveRepeaters',      displayName: 'Live mobile repeaters',               default: false });
    public static readonly campaign           = MapLayerType.get("campaign",           { name: 'campaign',           displayName: 'Mobility test camapaign',             default: false });
    public static readonly connectivity       = MapLayerType.get("connectivity",       { name: 'connectivity',       displayName: 'Device connectivity',                 default: false });
    public static readonly dataCon            = MapLayerType.get("dataCon",            { name: 'dataCon',            displayName: 'Data connection',                     default: false });
    public static readonly devices            = MapLayerType.get("Devices",            { name: 'Devices',            displayName: 'Devices',                             default: false });
    public static readonly geoRoute           = MapLayerType.get("geoRoute",           { name: 'geoRoute',           displayName: 'Geographic route',                    default: false });
    public static readonly geoRouteReports    = MapLayerType.get("geoRouteReports",    { name: 'geoRouteReports',    displayName: 'Geographic route reports',            default: false });
    public static readonly geoRouteSpeed      = MapLayerType.get("geoRouteSpeed",      { name: 'geoRouteSpeed',      displayName: 'Geographic route speed',              default: false });
    public static readonly mobileCellChanges  = MapLayerType.get("mobileCellChanges",  { name: 'mobileCellChanges',  displayName: 'Mobile cell changes',                 default: false });
    public static readonly mobileCellReports  = MapLayerType.get("mobileCellReports",  { name: 'mobileCellReports',  displayName: 'Mobile cell reportss',                default: false });
    public static readonly mobileCellReportsH = MapLayerType.get("mobileCellReportsH", { name: 'mobileCellReportsH', displayName: 'Mobile cell reports (Heatmap)',       default: false });
    public static readonly mobileSignal       = MapLayerType.get("mobileSignal",       { name: 'mobileSignal',       displayName: 'Mobile signal strength',              default: false });
    public static readonly mobileTech         = MapLayerType.get("mobileTech",         { name: 'mobileTech',         displayName: 'Mobile technology',                   default: false });
    public static readonly organisations      = MapLayerType.get("organisations",      { name: 'organisations',      displayName: 'Organisations',                       default: true  });
    public static readonly serviceResults     = MapLayerType.get("serviceResults",     { name: 'serviceResults',     displayName: 'Service results',                     default: false });
    public static readonly sites              = MapLayerType.get("sites",              { name: 'sites',              displayName: 'Sites',                               default: true  });
    public static readonly survey             = MapLayerType.get("survey",             { name: 'survey',             displayName: 'Survey',                               default: false  });
    public static readonly testResults        = MapLayerType.get("testResults",        { name: 'testResults',        displayName: 'Test results',                        default: false });
    public static readonly voiceResults       = MapLayerType.get("voiceResults",       { name: 'voiceResults',       displayName: 'Voice call results',                  default: false });
    public static readonly wifiAPChanges      = MapLayerType.get("wifiAPChanges",      { name: 'wifiAPChanges',      displayName: 'Wifi access point changes',           default: false });
    public static readonly wifiAPReports      = MapLayerType.get("wifiAPReports",      { name: 'wifiAPReports',      displayName: 'Wifi access point reports',           default: false });
    public static readonly wifiAPReportsH     = MapLayerType.get("wifiAPReportsH",     { name: 'wifiAPReportsH',     displayName: 'Wifi access point reports (Heatmap)', default: false });
    public static readonly wifiSignal         = MapLayerType.get("wifiSignal",         { name: 'wifiSignal',         displayName: 'Wifi signal strength',                default: false });
  
    // Private to disallow creating other instances of this type
    private constructor(public readonly key: string, public readonly value: any)
    { 
    }

  
    //
    // Static methods
    //
    public static get defaults(): string[]
    {
        return [...this._layers.keys()].filter((s: string, i: number) => {
            const l: MapLayerType | undefined = this._layers.get(s);
            return (l instanceof MapLayerType) && l.value && l.value.default;
            // return s instanceof MapLayerType && s.value && s.value.default;
        }); // keys
        // return [...this._layers.keys()].filter((s: string, i: number) => {
        //     return this._layers.get(s) && this._layers.get(s).value && this._layers.get(s).value.default;
        // }); // keys
    }


    public static get(key: string | MapLayerType | undefined, value?: object): MapLayerType | undefined
    {
        if (key) {
            if (key instanceof MapLayerType) return key;
            if (value) this._layers.set(key, new this(key, value));

            return this._layers.get(key)
        }
        
        // return value ? this._layers.set(key, new this(key, value)) : this._layers.get(key);
        return undefined;
    }


    public static get types(): object[]
    {
        return [...this._layers.values()];
    }
 
 
    //
    // Public methods
    //
    public toString(): string
    {
        return this.key;
    }
}
//export type MapLayerTypeKeys = Exclude<keyof typeof MapLayerType, 'prototype'>;