import { CommonModule }            from '@angular/common';
import { NgModule }                from '@angular/core';
import { NgxPaginationModule }     from 'ngx-pagination';
import { OrderModule }             from 'ngx-order-pipe';
import { RouterModule }            from '@angular/router';

// qp-devices
import { DevicesModule }           from '@Devices/';

// qp-list-data, qp-refresh-button, qp-status-indicator, qp-title, qp-window-header
import { GuiElementsModule }       from '@GuiElements/';
import { MaterialCustomModule }    from '@Material/';

import { SitesComponent }          from './sites.component';
import { SitesContainerComponent } from './sites-container.component';


@NgModule({
    imports: [
        CommonModule,
        NgxPaginationModule,
        OrderModule,
        RouterModule,

        DevicesModule,
        GuiElementsModule,
        MaterialCustomModule
    ],
    
    declarations: [
        SitesComponent,
        SitesContainerComponent
    ],

    providers: [
    ],

    exports: [
        SitesComponent,
        SitesContainerComponent
    ]
})
export class SitesModule { }