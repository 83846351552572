import {
    Component,
    ElementRef,
    HostListener,
    Input,
    ViewChild, 
}                             from '@angular/core';
import {
    Subscription,

    distinctUntilChanged
    // filter,
    // take
}                             from 'rxjs';

import { BaseComponent }      from '@Base/';
import { GeoLocationService } from '@Misc/Services/';

import { MapElementLatLng }   from './map-elements/';
import { MapService }         from './map.service';
import { MapLayersService }   from './map-layers/';


@Component({
    selector:     'qp-map',
    templateUrl:  'map.component.html',
    styleUrls:   ['map.component.css']
})
export class MapComponent extends BaseComponent
{
    private static readonly eventResize = "resize";


    // private readonly divMap:    string = "map";
    // private readonly divMapKey: string = "mapKey";
    // private          _domListener: any;
    private readonly _gMaps:       any  = google.maps;


    private _data: any[] | undefined;

    @ViewChild('map',         { static: false })
    private elemMap:         ElementRef;
    @ViewChild('mapControls', { static: false })
    private elemMapControls: ElementRef;
    @ViewChild('mapLegend',   { static: false })
    private elemMapLegend:   ElementRef;


    public constructor(private readonly GeoLocationService: GeoLocationService,
                       private readonly MapLayersService:   MapLayersService,
                       private readonly MapService:         MapService)
    {
        super();
    }


    // Override
    public override ngOnInit(): void
    {
        // Prevent calling initialise() here
    }


    // Override
    public override ngAfterViewInit(): void
    {
        super.ngAfterViewInit();

        this.initialise();
    }


    @HostListener('document:keydown', ['$event'])
    //@HostListener('document:keypress', ['$event'])
    public onKeydownHandler(event: KeyboardEvent): void
    {
        if (event && (event.key === "Escape" || event.key === "Esc")) {
            console.debug("Escape pressed");
            this.MapService.infoClose();
        }
    }


    // Need to ensure HTNML doesn't include controls, which trigger load of all other element service date
    @Input()
    public get data(): any[] | undefined
    {
        return this._data;
    }

    public set data(d: any[] | undefined)
    {
        this._data = d;

        // Need to wait for MapService to be initialised
        if (this.MapLayersService) {
            const sub: Subscription = this.MapService.initialised
                // .pipe(
                //     filter((d2: boolean): boolean => d2 === true)
                // )
                // .pipe(
                //     take(1)
                // )
                .pipe(
                    distinctUntilChanged()
                )
                .subscribe((d2: boolean) => {
                    if (d2) this.MapLayersService.data2 = this._data;
                }); // subscribe
            }
    }


    //
    // Public methods
    //
    //public setScopeListeners()
    //{
        // this.$scope.$on('phoneAdded',                                                this.processReceivedEvent);
        // this.$scope.$on('phonePendingDelete',                                        this.processReceivedEvent);
        // this.$scope.$on('phoneDeleted',                                              this.processReceivedEvent);
        // this.$scope.$on('phoneUpdated',                                              this.processReceivedEvent);
        // this.$scope.$on(this.MessageService.messages.msgTypesSub.testresults,         this.processReceivedEvent);
        // this.$scope.$on('testStarted',                                               this.processReceivedEvent);
        // this.$scope.$on('testCompleted',                                             this.processReceivedEvent);
        // this.$scope.$on('phonesPopulated',                                           this.processReceivedEvent);

        // this.$scope.$on(this.MessageService.messages.msgTypesSub.devicelocations,     this.processReceivedEvent2);
        // this.$scope.$on(this.MessageService.messages.msgTypesSub.mobilecellsightings, (event, data) => {
        //     this.refresh();
        //     return this.processReceivedEvent2(event, data);
        // });

        // this.$scope.$on('servicesoneshot', (event, data) => {
        //     this.refresh();
        //     return this.processReceivedEvent(event, data);
        // });

        // this.$scope.$on('servicesperiodic', (event, data) => {
        //     this.refresh();
        //     return this.processReceivedEvent(event, data);
        // });

        // this.$scope.$on(this.MessageService.messages.msgTypesSub.wifiapsightings, (event, data) => {
        //     this.refresh();
        //     return this.processReceivedEvent2(event, data);
        // });

        // this.$scope.$on('map', (event, data) => {
        //     if (data) {
        //         switch (data.cmd) {
        //             case 'showdevice':
        //                 this.MapLayersService.highlight('device', data);
        //                 break;

        //             case 'showcell':
        //                 this.MapLayersService.highlight('cell',   data);
        //                 break;

        //             default:
        //                 console.log("Unknown cmd received: " + data.cmd);
        //         } // switch
        //     }
        // });

        // this.$scope.$on(this.MessageService.messages.msgTypesSub.mobilecells, (event, data) => {
        //     if (data.cells && data.cells.length > 0) {
        //         console.log("Received 'cells' - update map: " + data.cells.length);

        //         for (var i = 0, len = data.cells.length; i < len; ++i) {
        //             if (data.cells[i].latitude != null && data.cells[i].longitude != null) {
        //                 this.MapLayersService.update('cell', {cell: data.cells[i]});
        //                 // [TBD] Add cell tower to map
        //             }
        //         } // for
        //     }
        // });
    //}


    public showLegend(): any
    {
        return this.MapService.showLegend();
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        if (window && window.removeEventListener instanceof Function) {
            window.removeEventListener(MapComponent.eventResize, this.handleWindowEvent);
        }

        this.MapLayersService.data2 = (this._data = undefined);
        this.MapLayersService.cleanUp();
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();
        
        console.debug("Initialising Map component");

        // Do as inline function, as Edge browser seems to have problems accessing factories at initialisation time
        // google.maps.event.addDomListener(document.getElementById("map"), 'load', (): void => {
        //     this.MapLayersService.refresh();
        // });

        // Get GeoLocation
        const pos = this.GeoLocationService.geolocation;
        const mapPosInitial: MapElementLatLng | undefined = pos ? MapElementLatLng.get(pos.coords.latitude, pos.coords.longitude) : undefined;
        if (mapPosInitial instanceof MapElementLatLng) {
            console.debug("Obtained geolocation of this device for map; storing in service");
            console.debug(mapPosInitial.toString());
        }
        else {
            console.debug("Unable to obtain geolocation of this device");
        }
        //if (this.MapService) this.MapService.center(mapPosInitial);


        // Re-initialise service with DOM element
        if (this.MapService) {
            console.debug("Explicitly re-configuring MapService");
            this.MapService.configure(this.elemMap, this.elemMapControls, this.elemMapLegend);
            
            if (mapPosInitial instanceof MapElementLatLng) this.MapService.MapPosInitial = mapPosInitial; // set inital geolocation
        }


        // 2022-04-07 google.maps.event.addDomListener() deprecated
        // https://developers.google.com/maps/deprecations?hl=en#googlemapseventadddomlistener_and_googlemapseventadddomlisteneronce_deprecated_on_april_7_2022
        if (window && window.addEventListener instanceof Function) window.addEventListener(MapComponent.eventResize, this.handleWindowEvent, { passive: true });

        // if (this._gMaps && this._gMaps.event && this._gMaps.event.addDomListener instanceof Function) {
        //     this._domListener = this._gMaps.event.addDomListener(window, MapComponent.eventResize, (): void => {
        //         if (this.MapService) this.MapService.resize();
        //     }); // gMaps addDomListener
        // }
      
        console.debug("Map component initialised");
    }


    //
    // Private methods
    //
    private handleWindowEvent(evt: Event)
    {
        if (evt) {
            // console.debug(evt)

            switch (evt.type) {
                case MapComponent.eventResize:
                    if (this.MapService) this.MapService.resize();   
                    break;

                default:
                    // Do nothing
            } // switch
        }
    }


//     private processReceivedEvent(event: any, data: any): any
//     {
// console.log("Received '" + event.name + "'" + (data.serialNum ? " (" + data.serialNum + ")" : "") + " (1)");
//         return this.MapLayersService.update(event.name, data);
//     }


//     private processReceivedEvent2(event: any, data: any): any
//     {
//         console.log("Received '" + event.name + "'" + (data.serialNum ? " (" + data.serialNum + ")" : "") + " (2)");

//         // this.qp_ProgressCircularFactory.startProgress(null, (err: any, result: any): any => {
//         //     if (! err) {
//         //         this.processReceivedEvent(event, data);
//         //         return this.qp_ProgressCircularFactory.stopProgress();
//         //     }
//         // });
//     }

} // class


    // const filter = ($filter) => {
    //     return function(isoDate) {
    //         var date = new Date(isoDate);
    //         if (date.getTime() <= 0) {
    //             //date = isoDate;
    //             date = "In progress";
    //         }
    //         else {
    //             date = date.toLocaleString();
    //         }
    //         return $filter('date')(date);
    //     };
    // }


    // angular
    //     .module('qpProMobileApp')
    //     .component('qpMap', MapComponent)
    //     .filter('localdatetime', ['$filter',  filter]);
