<div class="content">
    <mat-drawer-container
        autosize="true"
        [hasBackdrop]="false"
        >

        <mat-drawer #devicegroupssidenav
            [opened]="false"
            [disableClose]="false"
            [mode]="'over'"
            [position]="'end'"
        >
            <button mat-icon-button (click)="onClick($event)" matTooltip="Close">
                <mat-icon>{{ iconClose }}</mat-icon>
            </button>

            <!-- Can't bind name new feature  n A15+ -->
            <router-outlet name="devicegroup"></router-outlet>
        </mat-drawer>

                
        <mat-drawer-content>
            <qp-list [title]="title" [service]="service" [attrs]="attrs" [order]="attrOrder" [pageSize]=10 [orderDesc]="attrOrderDesc" (selected)="select($event)"></qp-list>
            <!-- [pageSize]=5 -->
            <!-- <qp-list [vals]="vals" [title]="title" [attrs]="attrs" [service]="service" [order]="attrOrder" [orderDesc]="attrOrderDesc" [pageSize]="10" (selected)="select($event)"></qp-list> -->
        </mat-drawer-content>
    </mat-drawer-container>
</div>