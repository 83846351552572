export * from './devicegroup/';

export * from './device-groups.service';

export * from './device-groups.component';
export * from './device-groups-list.component';

// export { devicesRoutes } from './device-groups.routes';

export * from './device-groups.module';
export * from './device-groups.module-lazy-load';