import { CommonModule }           from '@angular/common';
import { NgModule }               from '@angular/core';

import { NgbModule }              from '@ng-bootstrap/ng-bootstrap';

import { MaterialCustomModule }   from '@Material/';

// qp-map-layers-control-container
import { MapLayerControlsModule } from '../map-layers/map-layer-controls/map-layers-control.module';

import { MapControlsComponent }   from './map-controls.component';



@NgModule({
    imports: [
        CommonModule,
        MaterialCustomModule,
        NgbModule,

        MapLayerControlsModule
    ],
    
    declarations: [
        MapControlsComponent        
    ],

    providers: [
    ],

    exports: [
        MapControlsComponent,
        MapLayerControlsModule
    ]
})
export class MapControlsModule { }