import { Injectable }     from '@angular/core';

import { ChartService }   from '@Charts/';
// import { KpiNamesModule } from '@Common/qp_KpiNames';
import { KpiTypes }         from '@Common/';


@Injectable({
    providedIn: 'root'
})
export class KpisChartLayoutService
{
    private readonly col: number     = 4;
    private readonly rowIncr: number = 14;
    
    private row: number              = 0;

    private charts: any;

    constructor(private readonly qp_ChartsFactory:   ChartService)
                // private readonly qp_KpiNamesFactory: KpiNamesModule)
    {
        this.initialise();
    }


    //
    // Public functions
    //
    public getAllChartsDetails(): any
    {
        return this.charts;
    }


    public getAllChartsNames(): any
    {
        return Object.keys(this.charts);
    }


    public getChartDetails(name: string): any
    {
        if (name && this.charts[name]) return this.charts[name];
    }


    //
    // Private functions
    //
    private initialise(): boolean
    {
        console.log("Initialising KpisChartLayout service");


this.charts = {
            timeOnRatType:
                {
                    row:        this.row,
                    column:     this.col,
                    chartTitle: "Time On Each Technology",
                    yTitle:     "%",
                    type:       this.qp_ChartsFactory.chartTypes.barStacked,
                    threeD:     true
                },

            [KpiTypes.voiceCallDropRate]:
                {
                    row:        this.row += this.rowIncr,
                    column:     this.col,
                    chartTitle: "Voice Dropped Call Rate",
                    yTitle:     "%",
                    type:       this.qp_ChartsFactory.chartTypes.bar,
                    threeD:     true
                },

            [KpiTypes.dataDnsLatencyAvg]:
                {
                    row:        this.row += this.rowIncr,
                    column:     this.col,
                    chartTitle: "DNS response time (Avg)",
                    yTitle:     "ms",
                    type:       this.qp_ChartsFactory.chartTypes.bar,
                    threeD:     true
                },

            [KpiTypes.dataHttpDlThroughputAvg]:
                {
                    row:        this.row += this.rowIncr,
                    column:     this.col,
                    chartTitle: "Http Dl Throughput (Avg)",
                    yTitle:     "Kbps",
                    type:       this.qp_ChartsFactory.chartTypes.bar,
                    threeD:     true
                },

        //  'dataHttpDlSuccessRate':
        //       {
        //            row:        56,
        //            column:     4,
        //            chartTitle: "Http Dl Sucess Rate"
        //            yTitle:     "%",
        //            threeD:     true
        //       },

            [KpiTypes.dataIcmpDlLatencyAvg]:
                {
                    row:        this.row += this.rowIncr,
                    column:     this.col,
                    chartTitle: "Icmp Dl Latency (Avg)",
                    yTitle:     "ms",
                    type:       this.qp_ChartsFactory.chartTypes.bar,
                    threeD:     true
                },

            [KpiTypes.dataIcmpDlDroppedPktRate]:
                {
                    row:        this.row += this.rowIncr,
                    column:     this.col,
                    chartTitle: "Icmp Dl Dropped Packet Rate",
                    yTitle:     "%",
                    type:       this.qp_ChartsFactory.chartTypes.bar,
                    threeD:     true
                },

            timeOnLte:
                {
                    row:        this.row += this.rowIncr,
                    column:     this.col,
                    chartTitle: "Time On 4G",
                    yTitle:     "%",
                    type:       this.qp_ChartsFactory.chartTypes.bar,
                    threeD:     true
                },

            timeOnUmts:
                {
                    row:        this.row += this.rowIncr,
                    column:     this.col,
                    chartTitle: "Time On 3G",
                    yTitle:     "%",
                    type:       this.qp_ChartsFactory.chartTypes.bar,
                    threeD:     true
                },

            timeOnGsm:
                {
                    row:        this.row += this.rowIncr,
                    column:     this.col,
                    chartTitle: "Time On 2G",
                    yTitle:     "%",
                    type:       this.qp_ChartsFactory.chartTypes.bar,
                    threeD:     true
                },

            [KpiTypes.mobileTechnologyOverTime]:
                {
                    row:        this.row += this.rowIncr,
                    column:     this.col,
                    chartTitle: "Mobile Technology over Time",
                    yTitle:     "Date/time",
                    type:       'gantt'
                },

            [KpiTypes.ipNetworkConnectivityOverTime]:
                {
                    row:        this.row += this.rowIncr,
                    column:     this.col,
                    chartTitle: "IP Address over Time",
                    yTitle:     "Date/time",
                    type:       'gantt'
                }
        };

        
        return true;
    }
}