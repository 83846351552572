import {
    BehaviorSubject,
    Observable
}                                  from 'rxjs';

import { IconsService }            from '@Icons/';

import { Device  }                 from '../device/';
import { Element }                 from '../../element/element/';
import { Power }                   from '../attributes/';
import { MobileCell }              from '../../mobile-cells/';
import { Sim }                     from '../sim/';

import { DeviceMobilePhoneCommon } from './';


export class DeviceMobilePhone extends Device
{   
    // private   _sims:            object = {};

    public    testResultType:  string = "";
    public    testServiceName: string = "";

    protected _update$: BehaviorSubject<DeviceMobilePhone>;

    
    public constructor(d?: any, iconsService?: IconsService)
    {
        super(d, iconsService);

        this.merge(d);

        this._update$ = new BehaviorSubject<DeviceMobilePhone>(this);
    }

    
    // Factory
    public static get(d: any, iconsService?: IconsService)
    {
        return new DeviceMobilePhone(d, iconsService);
    }


    //
    // Getters and setters
    //
    public get battery(): Power
    {
        return this.elementC instanceof DeviceMobilePhoneCommon
            ? (this.elementC as DeviceMobilePhoneCommon).power
            : "";
    }


    // Override
    public get notification(): Observable<DeviceMobilePhone>
    {
        return this._update$.asObservable();
    }


    public get mobileCells(): MobileCell[]
    {
        return this.elementC instanceof DeviceMobilePhoneCommon
            ? (this.elementC as DeviceMobilePhoneCommon).mobileCells
            : [];
    }


    public get operator(): string
    {
        const s: Sim | undefined = this.getSim();
        return (s instanceof Sim) ? s.operator : "";
    }


    public get plmn(): number | undefined
    {
        const s: Sim | undefined= this.getSim();
        return (s instanceof Sim) ? s.plmn : undefined;
    }


    public get serialNum(): string
    {
        return this.id;
    }

    // public set serialNum(d: string)
    // {
    //     this.setAttr('id', d);
    //     // this.id = d;
    //     // this.notify();
    // }


    public set sim(d: Sim | any)
    {
        if (d != null && d.slotNum >= 0 && this.sims) {
            const s = this.sims[d.slot];
            if (s) s._merge(d);
            else this.sims[d.slot] = (d instanceof Sim) ? d : new Sim(d);

            if (Object.keys(this.sims).length > 1) {console.log(d); console.log(d.slot); console.log(this.sims);}
            this.notify(d);
        }
    }


    public get sim(): Sim | undefined
    {
        return this.sims
            ? Object.values(this.sims).find((d: Sim): boolean => {
                return d !== null;
            })

            : undefined;
    }


    public get sims(): { [key: string]: Sim} | undefined
    {
        return this.elementC && this.elementC instanceof DeviceMobilePhoneCommon
            ? (this.elementC as DeviceMobilePhoneCommon).sims
            : undefined;
    }

    public get simsA(): Sim[]
    {
        return this.sims ? Object.values(this.sims) : [];
    }


    // Override
    public get update$(): BehaviorSubject<any>
    {
        return this._update$;
    }

    
    // public set sims(d: object)
    // {
    //     if (d != undefined) this._sims = d;
    //     else                this._sims = {};
    //     this.notify();
    // }


    //
    // Public methods
    //
    public getSim(d?: number): Sim | undefined
    {
        return this.sims
            ? ((d != undefined) ? this.sims[d]: this.simsA.find((e: Sim) => e != undefined))
            : undefined;
    }


    // Override
    public override infoWinContent(): string
    {
        let contentStr: string = super.infoWinContent();

        if (this.sims) {
            const sims: Sim[] = Object.values(this.sims);
            sims.forEach((d: Sim): void => {
                if (d) {
                    contentStr += "<br><br><b><b><i>Mobile Cell</i></b></b> ";
                    if (sims.length > 1 || (sims.length == 1 && d.slot > 1)) contentStr += " Sim " + d.slot + "</b>";
                    // contentStr += d.infoWinContent();
                } // d
            }); // forEach
        } // device.sims

        if (this.testResultType) {
            contentStr += "<br><br><b>Test in progress</b>: ";

            if (this.testResultType.indexOf("status") < 0) {
                    contentStr += this.testResultType.replace("start", "").replace("progress", ""); // remove 'start' and 'progress' from display

                //if (device.testResultType == 'progressupdateurl'    || device.testResultType == 'statusurl' ||
                //       device.testResultType == 'progressupdateftp' || device.testResultType == 'statusftp') {
                //    contentStr +=
                //        '<br><b>Duration (secs)</b>: '                + device.duration / 1000 +
                //        '<br><b>Transfered (bytes)</b>: '             + device.sizeTxfr;

                //    var txfrRate = device.sizeTxfr / (device.duration / 1000);
                //    if      (txfrRate > (1024 * 1024) ) {
                //        contentStr +=
                //            '<br><b>Avg txfr rate (Mbytes/sec)</b>: ' + txfrRate / (1024 * 1024);
                //    }
                //    else if (txfrRate > 1024) {
                //        contentStr +=
                //            '<br><b>Avg txfr rate (Kbytes/sec)</b>: ' + txfrRate / 1024;
                //    }
                //    else {
                //        contentStr +=
                //            '<br><b>Avg txfr rate (Kbytes/sec)</b>: ' + txfrRate / 1024;
                //    }

                //    if (device.testResultType == 'statusurl' || device.testResultType == 'statusftp' || device.testResultType == 'statusicmp') {
                //        device.testResultType = null;
                //    }
                //}
            }
            else {
                contentStr += "None";
                this.testResultType = "";//delete this.testResultType; // clear this to ensure marker icon is changed as appropriate

                // [TBD] Show last test results?
            }
        }

        return (contentStr += "</p></div>");
    }


    // Override
    public override merge(d: any, doNotify: boolean = true): Element
    {
        super.merge(d, false);

        if (d) {
            if (d.sims) Object.values(d.sims).forEach((v: any): void => {
                if (v) this.sim = v;
            }); // forEach

            if (doNotify) this.notify();
        }

        return this;
    }


    //
    // Protected methods
    //
        
    // Override
    protected override getIcon(cb: (d: any) => void): void 
    {
        return super.getIcon((d2: any) => {
            // Get action icon
            if (d2) {
                if (cb instanceof Function) cb(d2);
            }
            else {
                // First try and get network operator icon
                if (this.IconsService) {
                    const s: Sim | undefined = this.sim;
                    if (this.IconsService.getIconMobileNetwork instanceof Function && s instanceof Sim) {
                        this.IconsService.getIconMobileNetwork(s.plmn, s.operator, (d2: any): void => {
                            if (cb instanceof Function) cb(d2);
                        }); // getIconMobileNetwork
                    }

                    // Else get default mobilephone icon
                    else if (this.IconsService.getIconDeviceDefault instanceof Function) {
                        this.IconsService.getIconDeviceDefault((d2: any) => {
                            if (cb instanceof Function) cb(d2);
                        }); // getIconDeviceDefault
                    }
                }
                else {
                    if (cb instanceof Function) cb(undefined);
                }
            }
        }); // getIcon()
    }
}