import { CommonModule }              from '@angular/common';
import { NgModule }                  from '@angular/core';
import { NgxPaginationModule }       from 'ngx-pagination'; 
import { OrderModule }               from 'ngx-order-pipe';
import { RouterModule }              from '@angular/router';

import { MaterialCustomModule }      from '@Material/';
import { UtilsModule }               from '@Utils/';

// qp-list-data, qp-refresh-button, qp-status-indicator, qp-window-header
import { GuiElementsModule }         from '@GuiElements/';
// qp-list
import { MiscModule }                from '@Misc/';
import { ObjElementsModule }         from '@ObjElements/';

import { DeviceModule }              from './device/';
import { DevicesComponent }          from './devices.component';
import { DeviceInfoComponent }       from './device-info.component';
import { DevicesListComponent }      from './devices-active.component';
import { DeviceCollectionService }   from './devices-active.service';
import { DevicesContainerComponent } from './devices-container.component';
import { DevicesService }            from './devices.service';

import { devicesRoutes }             from './devices.routes';


@NgModule({
    imports: [
        CommonModule,
        NgxPaginationModule,
        ObjElementsModule,
        OrderModule,
        RouterModule,

        GuiElementsModule,
        MaterialCustomModule,
        MiscModule,
        UtilsModule,

        DeviceModule
    ],
    
    declarations: [
        DevicesComponent,
        DevicesContainerComponent,
        DeviceInfoComponent,
        DevicesListComponent
    ],

    providers: [
    ],

    exports: [
        DevicesComponent
        // DevicesContainerComponent
    ]
})
export class DevicesModule { }