import { Colours }    from '@Common/';

import {
    MapElementLatLng,
    MapElementMarker
}                     from '../../map-elements/';

 
export class MapLayerUtils
{
    public static readonly unknownStr: string = "<Unknown>";

    private static readonly connectivityColour: { [key: string]: any } = {
        connected: {
            ethernet:  '#00ff99', // green
            mobile:    '#0eb7f6', // light blue
            wifi:      '#ffff66', // yellow
        },
        suspended:     '#990000', // dark red
        disconnected:  '#ff0000', // red
        unknown:       '#000000'  // black
    };


    private static readonly routeColour: { [key: string]: any } = Colours.mobileTechnologyColour
    // {
    //     lte:           '#0eb7f6', // light blue
    //     umts:          '#ffff66', // yellow
    //     gsm:           '#00ff99', // green
    //     unknown:       '#000000'
    // };

    
    private static readonly signalColour: { [key: string]: any } = Colours.mobileSignalColour
    // {
    //     good:          '#990000',
    //     average:       '#ff0000',
    //     poor:          '#ffcccc',
    //     none:          '#000000'
    // };


    private static readonly speedColour: { [key: string]: any } = {
        fast:          '#990000',
        average:       '#ff0000',
        slow:          '#ffcccc',
        stopped:       '#000000'
    };


    // return {
    //     createMapPointOther:      createMapPointOther,
    //     getConnectivityColour:    getConnectivityColour,
    //     getLoc:                   getLoc,
    //     getObjectValues:          getObjectValues,
    //     getRouteSpeedCat:         getRouteSpeedCat,
    //     getRouteSpeedCatColour:   getRouteSpeedCatColour,
    //     getRouteSpeedColour:      getRouteSpeedColour,
    //     getSignalStrengthColour:  getSignalStrengthColour,
    //     getSignalStrengthColour2: getSignalStrengthColour2,
    //     getTechnologyColour:      getTechnologyColour,
    //     getWeighting:             getWeighting,
    //     mapLayerFactory:          mapLayerFactory,
    //     mapLayerTypes:            mapLayerTypes
    // };


    //
    // Public Functions
    //
    public static getObjectValues(obj: any): any
    {
        // Do this as some browsers don't support Object.values() (e.g. IE 11)
        //if (Object.values instanceof Function) { // don't use for primitive type
        return (typeof Object.values === "function")
            ? Object.values(obj)
            : function values (object: any): any {
                return Object.keys(obj).map(key => object[key]);
            }; // function values
    }


    // [TBD]
    public static createMapPointOther2(map: any, lat: number, lng: number, icon: any, title: string, streetview: boolean): any
    {
        return this.createMapPointOther(map, lat, lng, icon, title);
    }


    public static createMapPointOther(map: any, lat: number, lng: number, icon: any, title: string): MapElementMarker | undefined
    {
        let marker: MapElementMarker | undefined = undefined;

        if (lat && lng) {
            marker = new MapElementMarker(
                {
                    position: MapElementLatLng.get(lat, lng)//{lat: parseFloat(lat), lng: parseFloat(long)}
                }
            ); // MapElementMarker

            // Add a listener that checks for clicks
            // google.maps.event.addListener(marker, 'click', function(event) {
            //     // When clicked, open the selected marker's message
      
            //     if (! info.getMap()) {
            //         info.setContent(title);
            //         info.q_serialNum = null;
            //         info.open(map, marker);
            //         qp_MapSidebarFactory.openNav('right', info.getContent());                    
            //     }
            //         info.q_serialNum = null;
            //         info.close();
            //         qp_MapSidebarFactory.closeNav('right');
            //     }

            //     // Close marker if info is clicked
            //     google.maps.event.addListener(info, 'closeclick', function(event) {
            //         //console.log("info window closed");
            //         //info.close();
            //     });

            //     // Close marker if map is clicked
            //     google.maps.event.addListener(map, 'click', function(event) {
            //         info.q_serialNum = null;
            //         info.close();    
            //         qp_MapSidebarFactory.closeNav('right');
            //     });
            // });

            if (marker instanceof MapElementMarker) {
                if (icon) marker.setIcon(icon);

                //if (! isNaN(marker.getPosition().lat()) && ! isNaN(marker.getPosition().lng())) {
                //    marker.setMap(map);
                //}
                marker.setTitle(title ? title : null);
            }
        }

        return marker;
    }


    public static getConnectivityColour(connected: string, technology?: string): string
    {
        let colour = this.connectivityColour['unknown'];
        if (connected) {
            colour = connected == "connected" && technology
                ? this.connectivityColour[connected][technology]
                : this.connectivityColour[connected];
        }

        return colour;
    }


    public static getRouteSpeedCat(speed: number): string
    {
        let speedCat: string = 'stopped';

        // Speed in m/s
        const speeds = {
            fast:    30,
            average: 10,
            slow:    1,
            stopped: 0
        }; // speeds

        if (speed) {
            if      (speed > speeds['fast'])    speedCat = 'fast'
            else if (speed > speeds['average']) speedCat = 'average'
            else if (speed > speeds['slow'])    speedCat = 'slow';
        }

        return speedCat;
    }


    public static getRouteSpeedColour(speed: number): string
    {
        return (
            speed
            ? this.speedColour[this.getRouteSpeedCat(speed)]
            : this.speedColour['stopped']
        );
    }


    public static getRouteSpeedCatColour(speedCat: string): string
    {
        return (
            speedCat
            ? this.speedColour[speedCat]
            : this.speedColour['stopped']
        );
    }


    public static getSignalStrengthColour(technology: string, strength: number): string
    {
        let colour = this.signalColour['none'];

        const sigSstrengths: { [key: string]: any } = {
            lte: {
                good:    -95,
                average: -110
            },
            umts: {
                good:    -85,
                average: -95
            },
            gsm: {
                good:    -85,
                average: -95
            }
        }

        const sigQualities: { [key: string]: any } = {
            lte: {
                good:    -8,
                average: -13
            },
            umts: {
                good:    -10,
                average: -15
            },
            gsm: {
                good:    -10,
                average: -15
            }
        }

        if (technology && sigSstrengths[technology] && strength) {
            if      (strength >= sigSstrengths[technology]['good'])    colour = this.signalColour['good']
            else if (strength >= sigSstrengths[technology]['average']) colour = this.signalColour['average']
            else                                                       colour = this.signalColour['poor'];
        }

        return colour;
    }


    public static getSignalStrengthColour2(type: string): string
    {
        return this.signalColour[type];
    }


    public static getTechnologyColour(technology: string): string
    {
        return this.routeColour[technology];
    }


    public static getWeighting(technology: string, type: string, value: number): number
    {
        const offset: number = 200;

        return (value ? offset + value : offset - offset); // value usually -ve
    }


    //
    // Private functions
    //
//     private getContentString(serialNum: string): any
//     {
//         const device: any = this.DeviceCollectionService.getDevice(serialNum);

//         if (! device) {
//             //return;
//         }

//         let contentStr = 
//             "<p><b>Serial number</b>: "                 + device.serialNum;
        
//         if (! device) return contentStr;

//         contentStr += 
//             "<br><b>Manufacturer</b>: "                 + device.manufacturer +
//             "<br><b>Model</b>: "                        + device.model;

//         if (device.phoneSoftwareLoad) {
//             contentStr += " (" + device.deviceType + " " + device.phoneSoftwareLoad + ")";
//         }
            
//         contentStr +=
//             "<br><b>Description</b>: "                  + device.description;

//         if (device.batteryLevel) {
//             contentStr += "<br><b>Battery level</b>: "  + device.batteryLevel + " %";

//             if (device.batteryCharging && (device.batteryCharging == 1 || device.batteryCharging == "true") && device.batteryConnected) {
//                 contentStr += " (" + device.batteryConnected + ")";
//             }
//         }

//         contentStr += "<br><b>Status</b>: " + device.status;
//         if (device.status == "online") {
//             if (device.reconnectedDate) {
//                 contentStr += " (since " + device.reconnectedDate + ") (*)";
//             }
//             else if (device.connectedDate) {
//                 contentStr += " (since " + device.connectedDate + ")";
//             }
//         }

//         if (device.connectionType) {
//             contentStr += "<br><b>Connected via</b>: " + device.connectionType;
//         }


//         if (device.sims && Object.keys(device.sims).length > 0) {
//             //for (var i = 0, len = device.sims.length; i < len; ++i) {
//             Object.keys(device.sims).forEach((i: string): void => {
//                 if (device.sims[i]) {
//                     contentStr += "<br>";

//                     if (Object.keys(device.sims).length > 1 ||
//                             (Object.keys(device.sims).length == 1 && ! device.sims[1]) ) {
//                         contentStr +=
//                             "<br><b>Sim "                 + device.sims[i].simSlotNum + "</b>";
//                     }

//                     contentStr +=
//                         "<br><b>Imei</b>: "               + device.sims[i].imei +
//                         "<br><b>Network</b>: ";

//                     if (device.sims[i].operator) {
//                         contentStr += device.sims[i].operator + " (";
//                         if (device.sims[i].operatorCountry) {
//                             contentStr += device.sims[i].operatorCountry + " ";
//                         }
//                         contentStr += device.sims[i].plmn  + ")";
//                     }
//                     else {
//                         contentStr += device.sims[i].plmn;
//                     }
                    
//                     contentStr +=
//                         "<br><b>Network technology</b>: " + device.sims[i].rat +
//                         "<br><b>Global cell ID</b>: "     + device.sims[i].gcid;

//                     if      (device.sims[i].rat == "umts") {
//                         contentStr +=
//                             "<br><b>Local cell ID</b>: "  + (device.sims[i].gcid & 0xffff) +
//                             "<br><b>RNC ID</b>: "         + ((device.sims[i].gcid >> 16) & 0xffff);
//                     }
//                     else if (device.sims[i].rat == "lte") {
//                         contentStr +=
//                             "<br><b>Local cell ID</b>: "  + (device.sims[i].gcid & 0xff) +
//                             "<br><b>eNodeB ID</b>: "      + (device.sims[i].gcid >> 8);
//                     }

//                     contentStr += "<br><b>Area code</b>: "
//                     if (device.sims[i].areaCode) {
//                         contentStr += device.sims[i].areaCode;
//                     }
//                     else {
//                         contentStr += "N/A";
//                     }

//                     // No cell code on GSM
//                     if (device.sims[i].rat != "gsm") {
//                         contentStr += "<br><b>Cell code</b>: ";
//                         if (device.sims[i].cellCode && device.sims[i].cellCode >= 0) {
//                             contentStr += device.sims[i].cellCode;
//                         }
//                         else {
//                             contentStr += "N/A";
//                         }
//                     }

// //                        if (device.sims[i].cellCode) {
// //                            contentStr += "<br><b>Cell code</b>: ";
// //                            if (device.sims[i].cellCode >= 0) {
// //                                contentStr += device.sims[i].cellCode;
// //                            }
// //                            else {
// //                                contentStr += "N/A";
// //                            }
// //                        }

//                     if (device.sims[i].sigStrengthValue && device.sims[i].sigStrengthValue != 0) {
//                         contentStr +=
//                             "<br><b>"                     + device.sims[i].sigStrengthType.toUpperCase() +
//                             "</b>: "                      + device.sims[i].sigStrengthValue;
//                     }
//                     else {
//                         contentStr +=
//                             "<br><b>Signal Strength</b>: " + "N/A"
//                     }
                    
//                     if (device.sims[i].sigQualityValue && device.sims[i].sigStrengthValue != 0) {
//                         contentStr +=
//                             "<br><b>"                     + device.sims[i].sigQualityType.toUpperCase() + 
//                             "</b>: "                      + device.sims[i].sigQualityValue;
//                     }
//                 }
//             });
//         }
//         contentStr += '<br>';

//         if (device.latitude || device.longitude) {
//             contentStr +=
//                 "<br><b>Latitude</b>: "     + device.latitude + " deg" +       
//                 "<br><b>Longitude</b>: "    + device.longitude + " deg";

//                 if (device.accuracy) {
//                     contentStr +=
//                         "<br><b>Accuracy</b>: " + device.accuracy + " m";
//                 }

//                 if (device.speed) {
//                     contentStr +=
//                         "<br><b>Speed</b>: "   + device.speed + " m/s";
//                 }
//         }
//         else {
//             contentStr +=
//                 "<br><b>Latitude</b>: "     + "N/A" +        
//                 "<br><b>Longitude</b>: "    + "N/A";
//         }


//         contentStr += "<br><br><b>Test in progress</b>: ";
//         if (device.testResultType && device.testResultType.indexOf("status") < 0) {
//             contentStr += device.testResultType.replace("start", "").replace("progress", ""); // remove 'start' and 'progress' from display

//             //if (device.testResultType == 'progressupdateurl'    || device.testResultType == 'statusurl' ||
//             //       device.testResultType == 'progressupdateftp' || device.testResultType == 'statusftp') {
//             //    contentStr +=
//             //        '<br><b>Duration (secs)</b>: '                + device.duration / 1000 +
//             //        '<br><b>Transfered (bytes)</b>: '             + device.sizeTxfr;

//             //    var txfrRate = device.sizeTxfr / (device.duration / 1000);
//             //    if      (txfrRate > (1024 * 1024) ) {
//             //        contentStr +=
//             //            '<br><b>Avg txfr rate (Mbytes/sec)</b>: ' + txfrRate / (1024 * 1024);
//             //    }
//             //    else if (txfrRate > 1024) {
//             //        contentStr +=
//             //            '<br><b>Avg txfr rate (Kbytes/sec)</b>: ' + txfrRate / 1024;
//             //    }
//             //    else {
//             //        contentStr +=
//             //            '<br><b>Avg txfr rate (Kbytes/sec)</b>: ' + txfrRate / 1024;
//             //    }

//             //    if (device.testResultType == 'statusurl' || device.testResultType == 'statusftp' || device.testResultType == 'statusicmp') {
//             //        device.testResultType = null;
//             //    }
//             //}
//         }
//         else {
//             contentStr += "None";
//             delete device.testResultType; // clear this to ensure marker icon is changed as appropriate

//             // [TBD] Show last test results?
//         }
//         contentStr += "</p>";

//         return contentStr;
//     }


    // private static getTitleString(marker: any): string
    // {
    //     const titleString =
    //             "Phone: " + marker ? marker.q_serialNum         : MapLayerUtils.unknownStr
    //         + ", Lat = "  + marker ? marker.getPosition().lat() : MapLayerUtils.unknownStr
    //         + ", Long = " + marker ? marker.getPosition().lng() : MapLayerUtils.unknownStr;
        
    //     return titleString;
    // }


    private outputMapPoint(serialNum: string): void
    {
        // if (this.markers[serialNum]) {
        //     console.log("Marker data for device '" + serialNum + "':");

        //     const m: any = this.markers[serialNum];
        //     for (var p in m) {
        //         console.log(p + ': ' + m[p]);
        //     } // for
        // }
        // else {
        //     console.log("No marker exists for device '" + serialNum + "'");
        // }
    }


    private clearMapPoints(): void
    {
        //this.setAllMap(null); // remove markers from map but does not delete them
    }
}