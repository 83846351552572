import { Routes }                        from '@angular/router';

import { AuthenticationGuard }           from '@Login/';

// import { mobilecellsRoutes }             from '@MobileCells/mobilecells.routes';
// import { MobileCellsContainerComponent } from '@MobileCells/mobilecells-container.component';

import { DevicesContainerComponent }     from '../devices-container.component';

import { DeviceChartComponent }          from './device-chart.component';
import { DeviceComponent }               from './device.component';

import { routeNames }                    from '../../app.routing.names';


export const deviceRoutes: Routes = [
    {    
        path:        '',
        component:   DeviceChartComponent,
        data:        { animation: 'device' },
        canActivate: [ AuthenticationGuard ],
        outlet:      DeviceComponent.outlet
    },

    {    
        path:        routeNames.deviceChart,
        component:   DeviceChartComponent,
        data:        { animation: 'device' },
        canActivate: [ AuthenticationGuard ],
        outlet:      DeviceComponent.outlet
    },

    {
        path:        routeNames.mobilecells,
        // component:   MobileCellsContainerComponent,
        data:        { animation: routeNames.mobilecells },
        canActivate: [ AuthenticationGuard ],
        outlet:      'primary',//DevicesContainerComponent.outlet, // essential
        // loadChildren: async () => (await import('@MobileCells/mobile-cells.module')).MobileCellsModule, // better - needs Ivy
        loadChildren: () => import('@MobileCells/mobile-cells.module').then(m => m.MobileCellsModule),
        // children:    mobilecellsRoutes
    // },

    // {
    //     path:        routeNames.notifications,
    //     component:   NotificationsContainerComponent,
    //     data:        { animation: routeNames.notifications },
    //     canActivate: [ AuthenticationGuard ],
    //     outlet:      'primary',//DevicesContainerComponent.outlet, // essential
    //     children:    notificationsRoutes
    }
];