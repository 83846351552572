import { CommonModule }           from '@angular/common';
import { NgModule }               from '@angular/core';

import { KpisChartService }       from './kpis-chart.service'
import { KpisChartLayoutService } from './kpis-chart-layout.service'
import { KpisDataService }        from './kpis-data.service'


@NgModule({
    imports: [
        CommonModule
    ],
    
    declarations: [
    ],

    providers: [
    ],

    exports: [
    ]
})
export class KpisModule { }