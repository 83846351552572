import {
    Component,
    Inject
}                                  from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef
}                                  from "@angular/material/dialog";

import { BaseComponent }           from '@Base/';
import { MessageService }          from '@Messaging/';
//import { TabsService }             from '@Tabs/';
// import { qp_WindowService }        from '@Utils-ajs/qp_WindowFactory';

import { DeviceCollectionService } from './devices-active.service';


//  Update all service onservable to functions that return them
//  Propogate device updates, and specific device to prevent needing to reload all, esp. map
// Class inheritance - QPservice, QPDialogComponent, etc
// Org table, 1:m locations 1:m devices/groups
// All device types, send separate lists to web, incl, offline

@Component({
   selector:     'qp-device-info',
   templateUrl:  'device-info.component.html',
   styleUrls:   ['device-info.component.css']
})
export class DeviceInfoComponent extends BaseComponent
{
    public  Object:     any    = Object; // required for HTML to use Object.keys()

    public  deviceInfo: any    = {};//qp_DeviceCollectionFactory.getPhonesForTable(false, args.serialNum.val);
    public  name:       string = "Device";


    public constructor(private readonly DeviceCollectionService: DeviceCollectionService,
                       private readonly qp_MsgFactory:           MessageService,
                    //    private readonly qp_WindowFactory:        qp_WindowService,
                       //private readonly TabsService:             TabsService,
                       private readonly dialogRef:               MatDialogRef<DeviceInfoComponent>,

                       @Inject(MAT_DIALOG_DATA)
                       private readonly args:                    any)
    {
        super();
    }


    //
    // Public functions
    //


    // angular
    // .module('qpProMobileApp')
    // .controller('qp_DeviceInfoController',
    //     [
    //         'qp_MsgFactory',
    //         'args',
    //         qp_DeviceInfoController
    //     ]
    // );


    public refresh(): void
    {
        const data: any = this.DeviceCollectionService.getDevicesForTable(false, this.args.serialNum.val, true);
        if (data && data.length > 0 && data[0]) {
            //qp_TimeoutFactory.processViaTimeout(function() {
                this.deviceInfo = data[0];
            //});
        }
    }


    public buttonAlert(): any
    {
        if (this.args) {
            console.debug("Button pressed - alerting device '" + this.args.serialNum.val + "'");
            return this.sendAlert(this.args.serialNum.val);
        }
    }


    public buttonConfig(): any
    {
        if (this.args) {
            console.debug("Button pressed - send config to selected device '" + this.args.serialNum.val);
            return this.sendConfig(this.args.serialNum.val);
        }
    }


    public buttonDisconnect(): any
    {
        if (this.args) {
            console.debug("Button pressed - disconnecting selected device '" + this.args.serialNum.val + "'");
            return this.disconnect(this.args.serialNum.val, true); // reconnect allowed
        }
    }

    public buttonGetDebugLog(): any
    {
        if (this.args) {
            console.debug("Button pressed - request debug log from selected device '" + this.args.serialNum.val + "'");
            return this.getDebugLog(this.args.serialNum.val);
        }
    }


    public buttonOnMap(): any
    {
        if (this.args) {
            console.debug("Button pressed - showing device on map (if possible) for selected device '" + this.args.serialNum.val + "'");
            if (this.deviceInfo && this.deviceInfo.latitude.val && this.deviceInfo.longitude.val) {
                // All controllers see this
                return this.showOnMap(this.args.serialNum.val);
            }
            else {
                console.warn("No valid lat/long for device '" + this.args.serialNum.val + "'; cannot open on map");
            }
        }
    }


    public buttonShowEvents($event?: object): any
    {
        if (this.args) {
            console.debug("Button pressed - showing events for device '" + this.args.serialNum.val + "'");
            return this.showEvents($event, this.args.serialNum.val);
        }
    }


    public close(): void
    {
        if (this.dialogRef) this.dialogRef.close();
    }


    public showOnMap(serialNum: any): any 
    {
        console.debug("Device '" + serialNum + "' selected for map");

        //this.$rootScope.$broadcast('ap', {cmd: 'showdevice', serialNum: serialNum});
        this.close();
        //return this.TabsService.tabActiveReq = 'map';
    }


    //
    // Protected functions
    //
    protected override initialise(): void
    {
        console.debug("Initialising DeviceInfo component");

        // $scope.$on('phonePendingDelete', refresh);
        // $scope.$on('phoneDeleted',       refresh);
        // $scope.$on('phoneUpdated',       refresh);

        this.refresh();
    }


    //
    // Private functions
    //
    private disconnect(serialNum: any, reconnect: boolean): any
    {
        return this.sendCmd(this.qp_MsgFactory.messages.msgTypesSub.disconnect, serialNum, {reconnect: reconnect});
            //cancelRefreshTimer();
    }


    private getDebugLog(serialNum: any): any
    {
        return this.sendCmd(this.qp_MsgFactory.messages.msgTypesSub.getLogDebug, serialNum);
    }


    private sendAlert(serialNum: any): any
    {
        return this.sendCmd(this.qp_MsgFactory.messages.msgTypesSub.find, serialNum);
    }


    private sendCmd(subType: any, serialNum: any, extras?: any): any
    {
        if (serialNum && subType) {
            const msgArgs: any = {type: subType, serialNum: serialNum};

            if (extras && Object.keys(extras) && Object.keys(extras).length > 0) {
                Object.keys(extras).forEach((key) => {
                    if (key && extras[key]) msgArgs[key] = extras[key];
                });
            }
            return this.qp_MsgFactory.sendMsg(this.qp_MsgFactory.messages.msgTypes.devicecmd, msgArgs);
        }
    }


    private sendConfig(serialNum: any): any
    {
        return this.sendCmd(this.qp_MsgFactory.messages.msgTypesSub.configSend, serialNum);
    }


    private showEvents($event: object | undefined, serialNum: any): any
    {
        // return this.qp_WindowFactory.showWindow($event, 'qp_DeviceEventsController', 'window-device-events.html', serialNum, null, null, true);//, angular.element(document.querySelector('#test')) );
    }
}