import { 
    Component,
    Input
}                            from '@angular/core';
import { MatRow }            from '@angular/material/table';
import {
    ActivatedRoute,
    Router 
}                            from '@angular/router';

import { BaseComponent }     from '@Base/';
import { IconsMaterial }     from '@Icons/';
import { ListDataComponent } from '@GuiElements/';
import { Site }              from '@ObjElements/';

import { routeNames }        from '../app.routing.names';


@Component({
    selector:     'qp-sites',
    templateUrl:  'sites.component.html',
    styleUrls:   ['sites.component.css']
})
export class SitesComponent extends BaseComponent
{
    public readonly icon:   string = IconsMaterial.sites;
    public readonly title:  string = "Sites";


    @Input()
    public          attrs:    string[]; // optionally passed in from parent HTML

    @Input()
    public          data:     any[];    // optionally passed in from parent HTML

    @Input()
    public          parentId: number;   // optionally passed in from parent HTML


    constructor(private readonly ActRoute: ActivatedRoute,
                private readonly Router:   Router)
    {
        super();

        if (! this.attrs || ! this.attrs.length) this.attrs = [
            ListDataComponent.stateAttr,
            'name',
            'address',
            'devices'
        ]; // attrs
    }


    //
    // Public functions
    //
    public select(row: MatRow): Promise<boolean> | undefined
    {
        // Open site child route
        //console.log(this.ActRoute);
        // this.ActRoute.params.subscribe((params: any): void => {
        //     console.log(params);
        //     //this.id = +params['id']; // (+) converts string 'id' to a number
        // });

        // this.ActRoute.parent.url.subscribe((params: any): void => {
        //     console.log(params);
        //     const url = params[params.length - 1].path;
        //     console.log(url)
        //     console.log(this.parentId)
        //     //this.id = +params['id']; // (+) converts string 'id' to a number
        // });

        return (row as Site).id
            ? this.Router.navigate(
                [ routeNames.sites + '/', (row as Site).id],
                { relativeTo: this.ActRoute.parent, state: { parent: this.Router.url } }
                //{ relativeTo: this.ActRoute.parent, state: { parentId: this.parentId } }
            )
            : undefined;
    }


    //
    // Private functions
    //
    // private showOnMap(): void
    // {
    //     // console.log("Show device " + this.data.id + ":" + (<any>this).data.name + "on map");
    //     // this.TabsService.tabActiveReq = 'map';
    //     if (this.data) {
    //         const id: string = this.data.tag + ":" + this.data.id;
    //         console.log("Show " + id + " on map" + (this.data.geolocation ? ": " + this.data.geolocation.locationStr : ""));
    //         this.Router.navigate([ routeNames.map , id] );
    //     }
    // }
}