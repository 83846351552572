import { CommonModule }      from '@angular/common';
import { NgModule }          from '@angular/core';

import { ReportsKpiService } from './reports-kpi.service';


@NgModule({
    imports: [
        CommonModule
    ],
    
    declarations: [
    ],

    providers: [
    ],

    exports: [
    ]
})
export class ReportsModule { }