<div id="map-container">
    <div id="gmap" #map></div>
</div>


@if (! data) {
    <qp-map-layers-control-container></qp-map-layers-control-container>

    <qp-map-controls id="map-controls" #mapControls></qp-map-controls>
    <!-- <qp-map-infowin-container id="map-infowin"></qp-map-infowin-container> -->

    @if (showLegend()) {
        <div id="map-legend2">
            <div id="map-legend" #mapLegend [hidden]="showLegend()"></div> <!-- don't use *ngIf -->
        </div>
    }

    <div id="map-print" #mapPrint></div>
}