import { Injectable }              from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    Subscription,

    filter,
    take
}                                  from 'rxjs';

import { BaseService }             from '@Base/';
import { IconsService }            from '@Icons/';
import { MessageService }          from '@Messaging/';
import { DataServiceEvents }       from '@Misc/Services/';
import {
    Device,
    DeviceI,
    DeviceHelper
}                                  from '@ObjElements/devices/';

import { DeviceCollectionService } from '../devices-active.service';
import { DevicesService }          from '../devices.service';


@Injectable()
// ({
//     providedIn: DeviceModule // Don't make singleton
// })
export class DeviceService extends BaseService
{
    private _data:     Device | undefined;
    private sub2:      Subscription                         = new Subscription();
    private subAsync:  Subscription;

    private _id:       number | string                      = "";
    private _updated$: BehaviorSubject<Device | undefined>;
 
 
    constructor(private readonly DeviceCollectionService: DeviceCollectionService,
                private readonly DevicesService:          DevicesService,
                private readonly IconsService:            IconsService,
                private readonly MessageService:          MessageService)
    {
        super();

        this._updated$ = new BehaviorSubject<Device | undefined>(this.device);
    }


    // Getters and setters
    public get device(): Device | undefined
    {
        return this._data;
    }


    public set device(d: Device | undefined)
    {
        this._updated$.next(this._data = d);
    }


    public get id(): number | string
    {
        return this._id;
    }


    public set id(i: number | string)
    {
        this.refresh(true, this._id = i);
    }


    public get updated$(): Observable<Device | undefined>
    {
        return this._updated$.asObservable();
    }


    //
    // Public methods
    //
    public disconnect(reconnect: boolean): Observable<any> | undefined
    {
        return this.MessageService.sendMsgSet(
            this.MessageService.messages.msgTypesAction.disconnect,
            this.MessageService.messages.msgTypesInfo.devices,
            {
                [this.MessageService.messages.msgTypesAttributes.id]:
                    this.device instanceof Device ? this.device.id : undefined,
                reconnect:                                            reconnect
            }
        ); // sendMsgSet
    }
 

    public sendAlert(): Observable<any> | undefined
    {
        return this.MessageService.sendMsgSet(
            this.MessageService.messages.msgTypesAction.find,
            this.MessageService.messages.msgTypesInfo.devices,
            { [this.MessageService.messages.msgTypesAttributes.id]:
                this.device instanceof Device ? this.device.id : undefined,
            }
        ); // sendMsgSet


        // return this.MessageService.sendMsg(
        //     this.MessageService.messages.msgTypes.devicecmd,
        //         {
        //             type:      this.MessageService.messages.msgTypesSub.find,
        //             serialNum: this.device ? this.device.id : null
        //         }
        // );
    }


    public sendConfig(): Observable<any> | undefined
    {
        return this.MessageService.sendMsgSet(
            this.MessageService.messages.msgTypesAction.config,
            this.MessageService.messages.msgTypesInfo.devices,
            { [this.MessageService.messages.msgTypesAttributes.id]: 
                this.device instanceof Device ? this.device.id : undefined,
            }
        ); // sendMsgSet
    }


    public sendPower(state: string): Observable<any> | undefined
    {
        return this.MessageService.sendMsgSet(
            this.MessageService.messages.msgTypesAction.update,
            this.MessageService.messages.msgTypesInfo.power,
            { 
                [this.MessageService.messages.msgTypesAttributes.id]:
                    this.device instanceof Device ? this.device.id : undefined,
                [this.MessageService.messages.msgTypesAttributes.data]: state,
            }
        ); // sendMsgSet
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp()
    {
        super.cleanUp();

        this._updated$.complete();
        if (this.sub2     instanceof Subscription) this.sub2.unsubscribe();
        if (this.subAsync instanceof Subscription) this.subAsync.unsubscribe();

        console.debug("Destroying DeviceService");
    }

    
    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Device service");
    }


    //
    // Private methods
    //
    private notify(id: string, deleted: boolean = false): void
    {
        console.debug("Device " + (deleted ? "deleted" : "added") + ": " + id);
        this.refresh(deleted, id);
    }


    private refresh(force: boolean = false, id?: number | string): void
    {
        this.device = undefined;
        // this.id = id; // DON'T do this here as will cause recursive crash

      //  if (this.subAsync) this.subAsync.unsubscribe();
        if (this.id && this.DeviceCollectionService) {
            this.subAsync = this.DeviceCollectionService.getDeviceAsync(id, true)
                .pipe(
                    take(1)
                )
                .subscribe((d: Device | undefined): void => {
                    if (d instanceof Device) this.update(d);
                }); // subscribe
        }
    }


    private update(d: Device | DeviceI | undefined): Device | undefined
    {
        if (! d) return undefined;

        // Clean up all existing subscriptions;
        // will create new Subscription object on next non-null assigment
        this.sub = undefined;

        const dev: Device | undefined = (d instanceof Device) ? d : DeviceHelper.get(d, this.IconsService);

        // Register for updates from active device
        // this.device = DeviceHelper.get(d, this.IconsService)
        let idCur: string = "";
        if (dev instanceof Device) {
            this.sub = (this.device = dev).notification
                .subscribe((dev2: Device): void => {
                    if (dev2) this.device = dev2;
                }); // subscribe
            idCur = this.device.id;

            // Register for updates
            const obs = this.DevicesService[DataServiceEvents.updated];
            if (obs instanceof Observable) this.sub = obs
                .pipe(
                    filter((id: string): boolean => id == idCur)
                )
                .subscribe((id: string): void => {
                    const d2 = this.DevicesService.get(id);
                    if (d2) {
                        if (this.device instanceof Device) this.device.merge(d2); // triggers device notif listener above
                        else this.device = d2 as Device;
                    }
                }); // subscribe

            // Also register for notifications of deletion of this device
            // const obs: Observable<string> | undefined =
            //     this.DeviceCollectionService.deviceDeleted$(idCur, DataServiceEvents.deleted);
            // console.log("STU dev 4")
            // console.log(obs)
            // if (obs instanceof Observable)
            this.sub = this.DeviceCollectionService.deviceAdded$
                .pipe(
                    filter((id: string): boolean => id == idCur)
                )
                .pipe(
                    take(1)
                )
                .subscribe((id: string): void => {
                    this.notify(id) // will call refresh()
                }); // subscribe

            this.sub = this.DeviceCollectionService.deviceDeleted$
                .pipe(
                    // filter((id: string): boolean => id == idCur)
                )
                .pipe(
                    take(1)
                )
                .subscribe((id: string): void => {
                    if (id === idCur)
                    this.notify(id, true); // will call refresh
                }); // subscribe
        }
        else {
            console.error("STU HERE3")
            // Device inactive; use received data
            // Create correct object for device type
            if (this.device = DeviceHelper.get(d, this.IconsService)) idCur = this.device.id;

            // Also register for notifications of addition of this device
            // const obs: Observable<string> | undefined =
            //     this.DeviceCollectionService.getObservable(idCur, DataServiceEvents.added);
            // if (obs instanceof Observable) this.sub = obs
            this.sub = this.DeviceCollectionService.deviceAdded$
                .pipe(
                    filter((id: string): boolean => id == idCur)
                )
                .pipe(
                    take(1)
                )
                .subscribe((id: string): void => {
                    this.notify(id) // will call refresh()
                }); // subscribe
        }

        // const obs: Observable<any> | undefined = 
        //     (this.device instanceof Device)
        //         ? this.DeviceCollectionService.deviceNotify(this.device.id, this.device.deviceType)
        //         : undefined;
        // if (this.sub2) this.sub2.unsubscribe();
        // if (obs instanceof Observable) this.sub2 = obs.subscribe((dev: Device): void => {
        //     console.log("STU dev 22")
        //     if (dev) {
        //         // Added
        //         this.device = dev;
        //         console.info("Device added: " + dev.id);
        //     }
        //     else {
        //         // Deleted
        //         console.info("Device deleted");
        //         this.device = undefined;
        //         this.notify(idOrig, true); // will call refresh 
        //     }
        // }); // subscribe

        return this.device;
    }
}