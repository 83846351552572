import { 
    Component,
    Input
}                        from '@angular/core';
import { MatRow }        from '@angular/material/table';
import {
    ActivatedRoute,
    Router
}                        from '@angular/router';

import { BaseComponent } from '@Base/';
import { Notification }  from '@ObjElements/notification';
import { IconsMaterial } from '@Icons/';

import { routeNames }    from '../app.routing.names';


@Component({
   selector:     'qp-notifications',
   templateUrl:  'notifications.component.html',
   styleUrls:   ['notifications.component.css']
})
export class NotificationsComponent extends BaseComponent
{
    public readonly icon: string = IconsMaterial.notifications;

    @Input()
    public  attrs:  string[];            // optionally passed in from parent HTML

    @Input()
    public  data:   Notification[] = []; // optionally passed in from parent HTML

    @Input()
    public  parent: number;              // optionally passed in from parent HTML

    @Input()
    public  title:  string = "Notifications";

    
    constructor(private readonly ActRoute: ActivatedRoute,
                private readonly Router:   Router)
    {
        super();
    }


    //
    // Public functions
    //
    public selected(row: MatRow): Promise<boolean> | undefined
    {
        // Open notification child route
        return (<Notification>row).id
            ? this.Router.navigate([routeNames.notifications + '/', (<Notification>row).id], {
                 relativeTo: this.ActRoute
                 //state: {data: this.data} // pass the data to the new route
            })

            : undefined;
    }
}