<div fxLayout="column" class="content">
    <qp-window-header fxFlex="none"
        [title]="name" 
        [value]="data ? (data.description ? (data.description | titlecase) : data.id) : ''"
        [state]="state" 
        [statusEnabled]=true
        [geolocationEnabled]="!!(data && data.geolocation)"
        [routeBack]="['..']"

        (geolocation)="buttonShowOnMap()"
        (refresh)="refresh()">
    </qp-window-header>

    <!-- <a [routerLink]="routeBack" *ngIf="routeBack.length > 0"><h4>Back to site</h4></a> -->
    <!-- <qp-route-back [route]="routeBack"></qp-route-back> -->

    <div fxFlex="auto" fxLayout="column" class="content2" [hidden]="! data"> <!-- can't use *ngIf here due to ng-template -->
        <!-- Reverse order from that displayed due to 'class="right"' -->

        <!-- Top-level device buttons/icons-->
        <div fxLayout="row" *ngIf="deviceButtonsEnabled">
            <!-- Power button -->
            <qp-power-button *ngIf="authorised && data && data.capabilityPowerControl"
                [blink]=data.powerReset [state]=data.powerStatus (selected)="buttonPower($event)">
            </qp-power-button>

            <!-- Battery level icon -->
            <qp-battery-level-icon *ngIf="battery"
                [charging]="battery.batteryCharging" [health]="battery.batteryHealth" [level]="battery.batteryLevel">
            </qp-battery-level-icon>

            <!-- Mobile cell signal level meter(s) -->
            <ng-container *ngIf="mobileCells">
                <ng-container *ngFor="let mc of mobileCells">
                    <qp-mobilecell-level-icon *ngIf="mc && mc.technology"
                    [large]=true
                    [mobilecell]="mc">
                    <!-- [technology]="mc.technology" [quality]="mc.signalquality" [strength]="mc.signalstrength"> -->
                    </qp-mobilecell-level-icon>
                </ng-container>
            </ng-container>

            <!-- Connection icon -->
            <qp-connection-icon *ngIf="connection && connection.type"
                [type]="connection.type">
            </qp-connection-icon>

            <!-- Geolocation button -->
            <!-- <qp-geolocation-button
                *ngIf="data && data.geolocation" (pressed)="buttonShowOnMap()">
            </qp-geolocation-button> -->
        
            <!-- Wifi signal level meter -->
            <button mat-button class="right" *ngIf="wifi && data.connectionType === 'wifi'" matTooltip="{{ wifi.ssid }}: {{ wifi.rssi }}">
                <mat-icon aria-label="Wifi">signal_wifi_4_bar</mat-icon>
            </button>
            <!-- <mat-icon class="right" *ngIf="wifi && data.connectionType === 'wifi'" aria-label="Wifi" matTooltip="{{ wifi.ssid}}: {{ wifi.rssi }}">signal_wifi_4_bar</mat-icon> -->
        </div>
        <!-- /Top-level device buttons/icons-->


        <!-- <qp-geolocation-button class="right" *ngIf="data && data.geolocation" (pressed)="buttonShowOnMap()"></qp-geolocation-button>
        <qp-power-button class="right" *ngIf="authorised && data && data.capabilityPowerControl" [blink]=data.powerReset [state]=data.powerStatus (selected)="buttonPower($event)"></qp-power-button> -->

        <!-- <mat-icon aria-label="Battery" matTooltip="Level: 1">signal_cellular_1_bar</mat-icon>
        <mat-icon aria-label="Battery" matTooltip="Level: 2">signal_cellular_2_bar</mat-icon>
        <mat-icon aria-label="Battery" matTooltip="Level: 3">signal_cellular_3_bar</mat-icon> -->

        <!-- <mat-icon aria-label="Battery" matTooltip="Level: 5">signal_wifi_0_bar</mat-icon>
        <mat-icon aria-label="Battery" matTooltip="Level: 6">signal_wifi_1_bar</mat-icon>
        <mat-icon aria-label="Battery" matTooltip="Level: 7">signal_wifi_2_bar</mat-icon>
        <mat-icon aria-label="Battery" matTooltip="Level: 8">signal_wifi_3_bar</mat-icon> -->

        <div class="banner" fxLayout="row" fxLayoutAlign="center">
            <h5 *ngIf="data && data.action">{{ data.action }} in progress</h5>
        </div>

        <!-- Device info -->
        <qp-element-info-container fxFlex="auto" class="content2"
            [data]="data"
            showDescription="! data.description"
            showId="!!data.description"
        >
        </qp-element-info-container>


        <!-- Notification list -->
        <!-- <qp-notifications *ngIf="data && data.notificationsV.length > 0"
            [title]="'Alarms'" [attrs]=notifAttrs [data]=data.notificationsV [parent]=+data.id>
        </qp-notifications> -->

        <!-- Device-specific sub-info -->
        <router-outlet name="device-sub" *ngIf="subView"></router-outlet>
    </div>

    <!-- Device action buttons -->
    <div fxFlex="none" *ngIf="actionButtonsEnabled">
        <button mat-button color="primary" (click)="buttonAlert()" matTooltip="Sound alert on device">
            <mat-icon aria-label="Alert device">phonelink_ring</mat-icon>
        </button>
        
        <button mat-button color="primary" (click)="buttonConfig()" matTooltip="Send config to device">
            <mat-icon aria-label="Send config to device">phonelink_setup</mat-icon>
        </button>

        <button mat-button color="primary" (click)="buttonDisconnect()" matTooltip="Disconnect device">
            <mat-icon aria-label="Get debug log from device">signal_wifi_off</mat-icon>
        </button>
    </div>
    <!-- /Device action buttons -->
    
</div>