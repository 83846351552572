import { Component }               from '@angular/core';
import {
    ActivatedRoute,
    ParamMap
}                                  from '@angular/router';
import {
    Observable,

    filter,
    take
}                                  from 'rxjs';

import { BaseComponent }           from '@Base/';
import { DeviceCollectionService } from '@Devices/devices-active.service';
import { IconsMaterial }           from '@Icons/';
import { MessageService }          from '@Messaging/';
import { DataServiceEvents }       from '@Misc/Services/';
import { Device }                  from '@ObjElements/devices';
import { Notification }            from '@ObjElements/notification';


@Component({
   selector:     'qp-notification',
   templateUrl:  'notification.component.html',
   styleUrls:   ['notification.component.css']
})
export class NotificationComponent extends BaseComponent
{
    public  readonly buttonsEnabled:  boolean = true;
    public  readonly icon:            string  = IconsMaterial.notifications;
    public  readonly iconClearButton: string  = IconsMaterial.notification_clear;
    public  readonly name:            string  = "Alarm";
    public  readonly state:           any     = undefined;

    public  data: Notification | undefined;

    private devId: string;
    private notId: string;
    
    constructor(private readonly ActRoute:                ActivatedRoute,
                private readonly DeviceCollectionService: DeviceCollectionService,
                private readonly MessageService:          MessageService)
    {
        super();
    }


    //
    // Public methods
    //
    public buttonClear(): void
    {
        if (this.data) console.log("[TBD] Button pressed - clearing notification " + this.data.id + " on device " + this.data.systemId);
        this.sendClear();
    }


    public refresh(): void
    {
        if (this.DeviceCollectionService && this.devId) {
            const obs: Observable<boolean> | undefined = this.DeviceCollectionService[DataServiceEvents.loading];
            if (obs instanceof Observable) this.sub = obs
                .pipe(filter((d: boolean): boolean => d == false))
                //.pipe(take(1))
                .subscribe((d: boolean): void => {
                    const dev: Device | undefined = this.DeviceCollectionService.get(this.devId);
                    if (dev instanceof Device) this.data = Object.values(dev.notifications).find((a: Notification): boolean => {
                            return a && (a.id == this.notId);
                    }); // find
                }); // subscribe
        }
    }


    //
    // Protected methods
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.log("Initialising Notification component");

        // Get notification ID
        if (this.ActRoute instanceof ActivatedRoute && this.ActRoute.paramMap instanceof Observable) {
            this.sub = this.ActRoute.paramMap
                .pipe(take(1))
                .subscribe((params: ParamMap): void => {
                    const s: string | null = params && params.has('id') ? params.get('id') : "";
                    if (s) this.notId = s;

                    // Get parent device ID
                    if (this.ActRoute instanceof ActivatedRoute && this.ActRoute.parent instanceof ActivatedRoute) {
                        const obs: Observable<ParamMap> = this.ActRoute.parent.paramMap;
                        if (obs instanceof Observable) this.sub = obs
                            .pipe(take(1))
                            .subscribe((params: ParamMap): void => {
                                const s: string | null = params && params.has('id') ? params.get('id') : "";
                                if (s) {
                                    this.devId = s;
                                    this.refresh();
                                }
                            }); // subscribe
                    }
                }); // subscribe
        }
    }


    //
    // Private methods
    //
    private sendClear(): Observable<any> | undefined
    {
        return this.MessageService.sendMsgSet(
            this.MessageService.messages.msgTypesAction.clear,
            this.MessageService.messages.msgTypesInfo.notifications,
            {
                [this.MessageService.messages.msgTypesAttributes.id]:       this.notId,
                [this.MessageService.messages.msgTypesAttributes.deviceId]: this.devId,
            }
        );
    }
}