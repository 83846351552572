import { Injectable }     from '@angular/core';

import {
    MapElementInfoWin,
    MapElementOverlayView
}                         from '../../map-elements/';

 
@Injectable({
    providedIn: 'root'
})
export class MapLayerTestsService
{
    constructor()
    {
        this.initialise();
    }


    //
    // Public Functions
    //
    public mapLayerTestsFactory(map: any, data: any): any
    {
        // Overlay
        const layer: any = MapElementOverlayView.get();
        const info: any  = new MapElementInfoWin()

        return this.addTestResults(map, layer, data);
    }


    //
    // Private functions
    //
    //
    private initialise(): boolean
    {
        console.log("Initialising MapLayerTests service");

        return true;
    }

  
    private addTestResults(map: any, layer: any, data: any): object
    {
        console.log(data);

        // [TBD]
        return layer;
    }


    private addTestResults2(results: any): void
    {
        // if (results && results.length > 0) {            
        //     mapLayers.testResults.layer = this.getMapOverlay();

        //     console.log("Adding " + results.length + " test result(s) to map layer '" + mapLayers.testResults.name + "'");
        //     for (var i = 0, len = results.length; i < len; ++i) {
        //         if (results[i]) {
        //             //WeightedLocation
        //             const data = {
        //                 location: getLoc(results[i].latitude, results[i].longitude),
        //                 weight:   10
        //             }
        //             mapLayers.testResults.layer.path.push(data);
        //         }
        //     } // for
        //     console.log("Added " + results.length + " test result(s) to map layer '" + mapLayers.testResults.name + "'");
        // }

        // if (mapLayers.testResults.layer.path && mapLayers.testResults.layer.path.length > 0) {

        //     // Create heatmap
        //     const heatMap = new google.maps.visualization.HeatmapLayer();
        //     heatMap.setData(mapLayers.testResults.layer.path);
        //     mapLayers.testResults.layer.addOverlay(heatMap);
        //     //mapLayers.testResults.layer.setMap(qp_MapFactory.getMap()); 
        // }
        // else {
        //     mapLayerTypes.testResults.layer.setMap(null);   
        //}
    }
}