import { Injectable }         from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    Subscription,

    take
}                             from 'rxjs';

import { BaseService }        from '@Base/';
import {
    MessageService,
    MessageProcessingService
}                             from '@Messaging/';
import {
    DataService,
    DataServiceEvents
}                             from '@Misc/Services/data-service.interface';
import {
    Element,
    ElementCommon as ElementC
}                             from '@ObjElements/';


@Injectable({
   providedIn: null//'root'
})
export class ElementService extends BaseService
{
    private          _data:      Element | ElementC | undefined = undefined;// new Element();
    private          _id:        number | undefined;
    private          _subUpdate: Subscription | undefined;

    private          _event:     string;
    private          _srv:       DataService | undefined;
    private          _type:      string;

    private   readonly _loading$:  BehaviorSubject<boolean>;
    protected readonly _updated$:  BehaviorSubject<Element | ElementC | undefined>;


    constructor(protected readonly MessageService:           MessageService,
                protected readonly MessageProcessingService: MessageProcessingService)
    {
        super();

        this._updated$ = new BehaviorSubject<Element | ElementC | undefined>(this.el);
        this._loading$ = new BehaviorSubject<boolean>(false);
    }

    
    //
    // Getters and setters
    //
    public get srv(): DataService | undefined
    {
        return this._srv;
    }

    public set srv(d: DataService | undefined)
    {
        this._srv = d;
    }


    public get id(): number | undefined
    {
        return this._id;
    }

    public set id(i: number | undefined)
    {
        // this.refresh(this._id = i);
        this.get(this._id = i);
    }


    public get loading$(): Observable<boolean>
    {
        return this._loading$.asObservable();
    }


    public get el(): Element | ElementC | undefined
    {
        return this._data;
    }

    public set el(o: Element | ElementC | undefined)
    {
        this._data = o;
    }


    public get event(): string
    {
        return this._event;
    }

    public set event(d: string)
    {
        this._event = d;
    }


    public get type(): string
    {
        return this._type;
    }

    public set type(d: string)
    {
        this._type = d;
    }


    public get updated$(): Observable<Element | ElementC | undefined>
    {
        return this._updated$.asObservable();
    }


    //
    // Public methods
    //
    public get(id: number| undefined): Subscription | undefined
    {
        this.sub = new Subscription();

        // Use service if specified
        if (this.srv) {
            const d: object | Subscription | undefined = (this.srv && id !== undefined) ? this.srv.getL(id) : undefined;
            if (d) {
                if (! (d instanceof Subscription)) this.update(d);

                // Register for ongoing updates
                let obs: Observable<boolean | number | object[] | string | string[]> | undefined = this.srv?.getObservable("", DataServiceEvents.added);
                if (obs instanceof Observable) this.sub = obs.subscribe((d: boolean | number | object[] | string | string[]): void => {
                    console.error("STU H1a");
                    console.error(d);
                    const o: object | undefined = (id !== undefined) ? this.srv?.get(id) : undefined;
                    if (o) this.update(o);
                }); // obs.subscribe()

                obs = this.srv?.getObservable("", DataServiceEvents.updated);
                if (obs instanceof Observable) this.sub = obs.subscribe((d: boolean | number | object[] | string | string[]): void => {
                    const o: object | undefined = (id !== undefined) ? this.srv?.get(id) : undefined;
                    console.error("STU H2b");
                    console.error(d);
                    if (o) this.update(o);
                }); // obs.subscribe()
            }
        }

        // Send msg direct
        else {
            const obs: Observable<any> | undefined = this.MessageService.sendMsgGet(
                this.type,
                {
                    [this.MessageService.messages.msgTypesAttributes.id]:      id,
                    [this.MessageService.messages.msgTypesAttributes.summary]: false//true
                }
            ); // sendMsgGet

        return (obs instanceof Observable) 
            ? (this.sub = obs
                .pipe(
                    take(1)
                ) // only listen once

                .subscribe({
                    // Message
                    next: (msg: any): void => {
                        console.debug("Msg get success");
                        if (msg && this.MessageService.messages.msgTypesAttributes.data in msg) {
                            this.update(msg[this.MessageService.messages.msgTypesAttributes.data]);
                        }

                        // Now start listeing for future updates
                        if (this.event) {
                            const obs2: Observable<any> | undefined = this.MessageProcessingService.getObs$(this.event);
                            if (obs2 instanceof Observable) this.sub = obs2.subscribe((d: any): void => {
                                console.debug("Event received: ");
                                this.update(d);
                            }); // subscribe
                        }
                    }, // next

                    // Error
                    error: (err: object): void => {
                        console.error("Msg get error");
                        console.log(err);
                    }, // error

                        // Complete
                    complete: (): void => {
                        // Do nothing
                    } // complete
                }) //subscribe()
            )
            
            : undefined;

        }

        return undefined;
    }


    // [TBD] option to register for future updates?
    public set(cmd: string, id: number| undefined): Subscription | undefined
    {
        const obs: Observable<any> | undefined = (cmd != null)
            ? this.MessageService.sendMsgSet(
                cmd,
                this.type,
                {
                    [this.MessageService.messages.msgTypesAttributes.id]: id
                }
            ) // sendMsgSet

            : undefined;

        return (obs instanceof Observable) 
            ? (this.sub = obs
                .pipe(
                    take(1)
                ) // only listen once

                .subscribe({
                    // Message
                    next: (msg: any): void => {
                        console.debug("Msg set success");
                                // Check for quantityOnly
                        if (msg && this.MessageService.messages.msgTypesAttributes.data in msg) {
                            this.update(msg[this.MessageService.messages.msgTypesAttributes.data]);
                            // if (Messages.msgTypesAttributes.quantity in data) {
                            //     this.clear();
                            //     this.sizeI = data[Messages.msgTypesAttributes.quantity];
                            // }

                            // if (data && Array.isArray(data)) this.processMsgData(data)
                            // else                             this.updateObservables(false, true); // essential for 'quantity' processing
                        }
                    }, // next

                    // Error
                    error: (err: object): void => {
                        console.error("Msg set error");
                        console.log(err);
                    }, // error

                        // Complete
                    complete: (): void => {
                        // Do nothing
                    } // complete
                }) //subscribe()
            )
            
            : undefined;
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        this._loading$.complete();
        this._updated$.complete();

        if (this._subUpdate instanceof Subscription) {
            this._subUpdate.unsubscribe();
            this._subUpdate = undefined;
        }
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Element service");
    }


    protected update2(d: Element | ElementC | undefined): void
    {
        this._updated$.next(this.el = d);
    }


    //
    // Private methods
    //
    private refresh(id: number| undefined, msg?: any): Subscription | undefined
    {
        this.sub = new Subscription();

        const d: object | Subscription | undefined = (this.srv && id !== undefined) ? this.srv.getL(id) : undefined;
        if (d) {
            if (! (d instanceof Subscription)) this.update(d);

            // Register for ongoing updates
            let obs: Observable<boolean | number | object[] | string | string[]> | undefined = this.srv?.getObservable("", DataServiceEvents.added);
            if (obs instanceof Observable) this.sub = obs.subscribe((d: boolean | number | object[] | string | string[]): void => {
                console.error("STU H1");
                console.error(d);
                const o: object | undefined = (id !== undefined) ? this.srv?.get(id) : undefined;
                if (o) this.update(o);
            }); // obs.subscribe()

            obs = this.srv?.getObservable("", DataServiceEvents.updated);
            if (obs instanceof Observable) this.sub = obs.subscribe((d: boolean | number | object[] | string | string[]): void => {
                const o: object | undefined = (id !== undefined) ? this.srv?.get(id) : undefined;
                console.error("STU H2");
                console.error(d);
                if (o) this.update(o);
            }); // obs.subscribe()
        }

        return undefined;
        // const obs: Observable<any> | undefined =
        //     (id !== undefined)
        //         ? this.MessageService.sendMsgGet(
        //             this.MessageService.messages.msgTypesInfo.devicegroups,
        //             { 
        //                 [this.MessageService.messages.msgTypesAttributes.id]:      id,
        //                 [this.MessageService.messages.msgTypesAttributes.summary]: false//true
        //             }
        //         ) // sendMsgGet()

        //         : undefined;

        // if (obs instanceof Observable) {
        //     this.loadingSubject.next(true);

        //     return (this.sub = obs.subscribe((data: any): void => {
        //         this.loadingSubject.next(false);
        //         if (data) {
        //             this.update(
        //                 (data.data && data.data[0])
        //                     ? data.data[0]
        //                     : (data[0] ? data[0] : data)
        //             ); // update()

        //             // Now register for element updates
        //             console.error(this.srv)
        //             if (this.srv) {
        //                 const obs: Observable<any> | undefined = this.srv[DataServiceEvents.updated];
        //                 console.error(obs)
        //                 if (obs instanceof Observable) this.sub = obs
        //                     .subscribe((d: any) : void => {
        //                         console.error(d)
        //                         this.update(d);

        //                     }); // subscribe
        //             }
        //         }
        //     } )); // subscribe())
        // }
        // else {
        //     this.update2(undefined);
        //     return undefined;
        // }
    }


    private update(d: any): void
    {
        if (d) this.update2(Array.isArray(d) ? d[0] : d);
        else console.warn("No data received to update Element service");
    }
}