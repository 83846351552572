import { Component }           from '@angular/core';

import { BaseComponent }       from '@Base/';
import { ChartService }        from '@Charts/';
// import { ServiceLevelsModule } from '@Common/';

import { ServicesService }     from './services.service';


@Component({
   selector:     'qp-services',
   templateUrl:  'services.component.html',
   styleUrls:   ['services.component.css']
})
export class ServicesComponent extends BaseComponent
{
    private chart:      any;
    private chartData:  any[] = [];
    private group:      any;

    public  start:      any;
    public  updateTime: any;

    private static readonly div = 'service-chart-div';

    constructor(private readonly qp_ChartsFactory:        ChartService,
                // private readonly qp_ServiceLevelsFactory: ServiceLevelsModule,
                private readonly qp_ServicesFactory:      ServicesService)
    {
        super();
    }

        
// Observable

        // $scope.$on(qp_MsgFactory.messages.msgTypesSub.servicelist, function(event, data) {
        //     qp_ServicesFactory.update(data ? data.data : data);
        // });
        // $scope.$on(qp_MsgFactory.messages.msgTypesSub.servicesos,  processData);
        // $scope.$on(qp_MsgFactory.messages.msgTypesSub.servicesp,   processData);


    //
    // Public functions
    //
    public buttonRefresh(): any
    {
        console.debug("Button pressed - refreshing services");
        this.refresh();
    }


    public buttonClear(): any
    {
        console.debug("Button pressed - clearing services");
        this.refresh();
    }


//Incoming test/periodically, check if service, if so, calc score per group, store score + classification
// Score/class is worst results in last 24 hours (rolling), or since reset pressed if < 24 hrs
//Web: request latest score per service per group; send score plus classification

// Graph: top level, 1 x pie chartshowing lowest score per servie across all groups



    //
    // Protected functions
    //
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Services Controller");

        // Subscribe to Services service for updates
        this.sub = this.qp_ServicesFactory.updated.subscribe((num: any) => {
            this.refresh();
        });

        if (this.chartData.length > 0) {
            this.chart = this.qp_ChartsFactory.getChart('pie', 'Services', 'Services2', true, "", null, this.chartData);

            //this.refresh();
            this.showChart();
        }
    }


    //
    // Private functions
    //
    private processData(event: any, data: any): void
    {
        console.log(event);
        console.log(data);
        if (data && data.data && data.data.length > 0) {
            console.log("Received updated service results: " + data.data.length);
            let newResults: any[] = [];
            for (let i: number = 0, len = data.data.length; i < len; ++i) {
                if (this.group == data.data[i].groupName) {
                    newResults.push({
                        service:   data.data[i].serviceName,
                        group:     data.data[i].groupName,
                        result:    data.data[i].rating,
                        // resultStr: this.qp_ServiceLevelsFactory.getString(data.data[i].rating)
                    });
                }
            } // for

            console.log(newResults);
            this.refreshChart(this.chart, newResults);
        }
        else {
            console.warn("Received null service data");
        } // if data
    }


    private refresh(regen?: boolean, serialNum?: string, startDate?: string, endDate?: string): void
    {
        this.qp_ServicesFactory.refresh2(regen, serialNum, startDate, endDate);
    }


    private refreshChart(chart: any, results: any)
    {
        if (chart && results && typeof(chart.refresh) === 'function') {
            chart.refresh(results);
        }
    }


    private showChart(): any
    {
        if (this.chart) {
            console.log(this.chart);
            return this.chart.write(ServicesComponent.div);
        }
    }
}