import { Injectable } from '@angular/core';

//import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";

declare var AmCharts: any;


@Injectable({
    providedIn: 'root'
})
export class ChartGanttService
{
    constructor() {
        //constructor(private AmCharts: AmChartsService) { 
        this.initialise();
    }


    //
    // Public Functions
    //
    public getChart(title: string, valueAxisTitle: string, data: any, chartOptions: any, graphOptions: any): object
    {
        let AmChart: any
        let chart = new AmCharts.AmGanttChart(chartOptions.theme);
        Object.assign(chart, chartOptions);

        let newData: any = [];
        let legendData: any = {};
        Object.keys(data).forEach(function(key) {
            if (data[key]) {
                newData.push({ category: key, segments: data[key] });

                // Populate legend data
                Object.keys(data[key]).forEach(function(key2) {
                    if (data[key][key2].val && data[key][key2].color && ! legendData[data[key][key2].val]) {
                        legendData[data[key][key2].val] = data[key][key2].color;
                    }
                });
            }
        });

        // Set chart legend
        chart.legend                  = {};
        //chart.legend.horizontalGap    = 10;
        //chart.legend.maxColumns       = 1;
        chart.legend.position         = "right";
        chart.legend.useGraphSettings = true;
        chart.legend.markerSize       = 10;
        //chart.legend.marginTop        = 20;
        if (Object.keys(legendData).length > 0) {
            chart.legend.data = [];
            Object.keys(legendData).sort().forEach(function(key) {
                chart.legend.data.push({ title: key, color: legendData[key] });
            });
        }

// [TBD]
function handleInit(): void {
chart.legend.addListener("rollOverItem", handleRollOver);
}

function handleRollOver(e: any): void {
var wedge = e.dataItem.wedge.node;
wedge.parentNode.appendChild(wedge);
}
        chart.dataProvider = newData;
        // [
        //     {
        //         "cat": "test1",
        //         "seg": [{ "start": "2017-01-01", "end": "2017-01-02", "color": "#b9783"}, { "start": "2017-01-04", "end": "2017-01-04"}]
        //     },
        //     {
        //         "cat": "test2",
        //         "seg": [{ "start": "2017-02-01", "end": "2017-02-02", "color": "#cc4748"}, { "start": "2017-02-04", "end": "2017-02-04"}]
        //     }
        // ],
        
        // Don't use this if dates need to be displayed in web browser's timezone
        //chart.dataDateFormat                          = "YYYY-MM-DDTJJ-NN-SS.QQQZ";

              // chart.marginLeft = 1;
              //   chart.marginRight = 1;
              //   chart.marginTop = 1;
              //   chart.marginBottom = 1;

        chart.categoryField                           = "category";
        chart.segmentsField                           = "segments";
        chart.startDateField                          = "start";
        chart.endDateField                            = "end";
        chart.colorField                              = "color";
        chart.rotate                                  = true;
        chart.period                                  = "DD";
        chart.startEffect                             = "easeOutSine";
        chart.brightnessStep                          = 0//7;
        chart.columnWidth                             = 0.5;

        chart.valueScrollbar                          = { "autoGridCount": true };

        chart.graph = new AmCharts.AmGraph(chartOptions.theme);
        Object.assign(chart.graph, graphOptions);
        chart.graph.balloonText                       = "<b>[[value]] - [[val]] [[val2]]</b>";
        chart.graph.balloonDateFormat                 = 'YYYY-MM-DD JJ-NN-SS';

        //chart.graph.labelText                         = "[[value]]";
        //chart.graph.type                              = "column";
        //chart.graph.valueField                        = "score";
        //chart.graph.topRadius                         = 1;
        //chart.graph.autoColor                         = true;

// chart.graph = [{
//             balloonText: "<b>[[category]]: [[value]]</b>",
//             fillColorsField: "color",
//             fillAlphas: 0.9,
//             labelPosition: "middle",
//             labelText: "[[value]]",
//             lineAlpha: 0.2,
//             lineColor: "#fff",
//             type: "column",
//             valueField: "score",
//             topRadius: 1,
//             autoColor: true
// }];
        //chart.addGraph(graph);


        //chart.categoryAxis.labelRotation            = 0;
        //chart.categoryAxis.gridPosition             = "start"
        //chart.categoryAxis.gridAlpha                = 0;
        //chart.categoryAxis.tickPosition             = "start";
        //chart.categoryAxis.tickLength               = 20;
        //chart.categoryAxis.axisAlpha                = 0;

        chart.valueAxis = {};
        //chart.valueAxis.axisAlpha                   = 0;
        //chart.valueAxis.gridColor                   = "#FFFFFF";
        //chart.valueAxis.gridAlpha                   = 0.2;
        //chart.valueAxis.dashLength                  = 0;
        //chart.valueAxis.axisAlpha                   = 0;
        chart.valueAxis.title                       = valueAxisTitle;
        //chart.valueAxis.titleRotation               = 0;
        //chart.valueAxis.position                    = "left";
        chart.valueAxis.type                        = "date";
        //chart.valueAxes                             = [valueAxis];

        chart.chartCursor                           = {};
        chart.chartCursor.categoryBalloonEnabled    = true;    
        chart.chartCursor.valueBalloonsEnabled      = false;
        chart.chartCursor.cursorAlpha               = 0;
        chart.chartCursor.zoomable                  = false;
        chart.chartCursor.valueZoomable             = true;
        chart.chartCursor.valueLineAlpha            = 0.5;
        chart.chartCursor.valueLineBalloonEnabled   = true;
        chart.chartCursor.valueLineEnabled          = true;


        //                               "valueAxes": [
        //                        {
        //                            "axisAlpha":          0,
        //    "gridColor": "#FFFFFF",
        //    "gridAlpha": 0.2,
        //    "dashLength": 0,
        //                            "title":              title,
        //                            "titleRotation":      0
        //                        }
        //                    ],

        return chart
    }


    //
    // Private functions
    //
    private initialise(): boolean
    {
        console.debug("Initialising ChartGantt service");

        return true;
    }
}