import { 
    ElementRef,
    Injectable
}                                 from '@angular/core';
import {
    BehaviorSubject,
    Observable,

    filter,
    take
}                                 from 'rxjs';

import { BaseService }            from '@Base/';

import {
    MapElementInfoWin,
    MapElementLatLng,
    MapElementLegend,
    MapElementMap,
    MapElementMarker,
    MapElementStreetViewPanorama
}                                 from './map-elements/';
import { MapInfoWinService }      from './map-infowin/';


@Injectable({
    providedIn: 'root',
})
export class MapService extends BaseService
{
    private          created:               boolean                        = false;

    private readonly divMap:                string                         = "map";
    private readonly divMapControls:        string                         = "map-controls";
    private readonly divMapLegend:          string                         = "map-legend";
    private readonly divMapLegendCanv:      string                         = "map-legend-canv";

    // private readonly canvasHeight:          number = 365;
    // private readonly canvasWidth:           number = 140;
    private readonly gMaps:                 any                            = google.maps;
    private          _map:                  MapElementMap;                       // Main Google map object
    private          mapPanel:              any;
    private          mapLegend:             MapElementLegend;                    // Single mapLegend associated with map
    private          mapLegendElems:        any                            = {}; // Holds contents for mapLegend key'd by layer name
    private          mapPanorama:           MapElementStreetViewPanorama;        // Default StreetView panorama
    private          infoWin:               MapElementInfoWin;                   // Single infoWindow associated with map
    private readonly _initialised$:         BehaviorSubject<boolean>;

    // Selected location (initialise to SFN HQ, Bowman House Business Centre, Royal Wootton Bassett, UK, SN4 7DB)
    private readonly mapPosDefault:         MapElementLatLng | undefined   = MapElementLatLng.get(51.539522, -1.9164167);
    private readonly mapTypeDefault:        any                            = this.gMaps.MapTypeId.ROADMAP;
    private readonly zoomDefault:           number                         = 8;

    private          _mapPosInitial:        MapElementLatLng;

    private readonly customControlLocation: string                         = this.gMaps.ControlPosition.BOTTOM_CENTER

    private readonly mapOptions:            any                            = {
        zoom:                    this.zoomDefault,
        zoomControl:             true,                         // Set to true if using zoomControlOptions below, or false to remove all zoom controls.
        zoomControlOptions: {
            style:    this.gMaps.ZoomControlStyle.DEFAULT,     // Change to SMALL to force just the + and - buttons.
            position: this.gMaps.ControlPosition.RIGHT_BOTTOM
        },
        center:                  this.mapPosDefault,

        scrollwheel:             false,                        // Disable Mouse Scroll zooming (Essential for responsive sites!)
        // All of the below are set to true by default, so simply remove if set to true:
        panControl:              true,                         // Set to false to disable

        //scaleControl:            false,                      // Set to false to hide scale
        //streetViewControl:       false,                      // Set to disable to hide street view
        //overviewMapControl:      false,                      // Set to false to remove overview control
        rotateControl:           true,                         // Set to false to disable rotate control
        fullscreenControl:       false,                        // Enabled by default on non-IOS mobile devices, disable by default on desktops
                                                               // Set to disabled all the time as causes issues on mobiles if it goes full-screen
        streetViewControl:       true,

        mapTypeId:               this.mapTypeDefault,          // Set the type of Map
        mapTypeControl:          true,                         // Disable Map/Satellite switch
            mapTypeControlOptions: {
            style: this.gMaps.MapTypeControlStyle.DROPDOWN_MENU,
            //style: gMaps.MapTypeControlStyle.HORIZONTAL_BAR,
            mapTypeIds: [
                this.gMaps.MapTypeId.ROADMAP,
                this.gMaps.MapTypeId.SATELLITE,
                this.gMaps.MapTypeId.HYBRID,
                this.gMaps.MapTypeId.TERRAIN
            ]
        }
    }; // mapOptions


    public constructor(private readonly MapInfoWinService: MapInfoWinService)
    {
        super(); // Don't do here; will be initialised by component when required DOM elements are available

        this._initialised$ = new BehaviorSubject<boolean>(false);

        if (this.mapPosDefault instanceof MapElementLatLng) this._mapPosInitial = this.mapPosDefault;  // Will be updated from geolocation is possible
        this.created = true; // used for initialisation
    }
    

    // // As we are attached to DOM element, need to wait until document is loaded to guarantee
    // // element is available
    // angular.element(document).ready(() => {
    //     initialise();
    // });


    //
    // Getters & setters
    //
    public get initialised(): Observable<boolean>
    {
        return this._initialised$.asObservable();
    }


    public get map(): MapElementMap
    {
        return this._map;
    }


    private set mapI(d: MapElementMap)
    {
        this._map = d;
    }


    public set MapPosInitial(d: MapElementLatLng)
    {
        if (d instanceof MapElementLatLng && this.map instanceof MapElementMap) this.map.setCenter(this._mapPosInitial = d);
    }


    //
    // Public methods
    //
    public center(arg: MapElementLatLng | number , lng?: number)
    {
        const pos: MapElementLatLng | undefined = 
            arg instanceof MapElementLatLng
                ? arg
                : (! isNaN(arg) ? MapElementLatLng.get(arg, lng) : undefined);

        this.sub = this.initialised
            .pipe(
                filter((available: boolean): boolean => available === true) // only emit when true
            )
            .pipe(
                take(1)
            )
            .subscribe((available: boolean): void => {
                if (this.map instanceof MapElementMap && pos instanceof MapElementLatLng) this.map.setCenterZoom(pos);
            });
    }
    

    public infoClose(): void
    {
        if (this.MapInfoWinService) this.MapInfoWinService.close();
    }


    public infoOpen(marker: MapElementMarker): void
    {
        this.sub = this.initialised
            .pipe(
                filter((available: boolean): boolean => available === true)
            )
            .pipe(
                take(1)
            )
            .subscribe((available: boolean): void => {
                if (this.MapInfoWinService && marker instanceof MapElementMarker) this.MapInfoWinService.open(this.map, marker);
            });
    }


    public infoToggle(marker: MapElementMarker): void
    {   
        this.sub = this.initialised
            .pipe(
                filter((available: boolean): boolean => available === true)
            )
            .pipe(
                take(1)
            )
            .subscribe((available: boolean): void => {
                if (this.MapInfoWinService && marker instanceof MapElementMarker) this.MapInfoWinService.toggle(this.map, marker);
            });
    }


    public refresh(): void
    {
        if (this.map instanceof MapElementMap) this.map.refresh();
    }


    public resize(): any
    {
        if (this.map instanceof MapElementMap) return this.map.resize();
    }


    public showLegend(): boolean
    {
        return !!(Object.keys(this.mapLegendElems).length !== 0);
        // return (Object.keys(this.mapLegendElems).length !== 0 ? true : false);
    }


    // Override
    public override initialise(): void
    {
        console.debug("Initialising Map service");

        super.initialise();

        console.debug("Map service initialised");

    }


    public configure(elemMap?: ElementRef, elemMapControls?: ElementRef, elemMapLegend?: ElementRef): boolean
    {
        console.debug("Configuring Map service");

        this._initialised$.next(false);


        if (! (this.map instanceof MapElementMap) || elemMap) {
            // Create a new map and place in the div 'map'
            const mapElem: any = elemMap ? elemMap.nativeElement : document.getElementById(this.divMap);
            if (! mapElem) {
                console.log("Unable to find DOM element '" + this.divMap + "' for map");
                return false;
            }

            this.mapI = new MapElementMap(mapElem, this.mapOptions);
            if (this.map instanceof MapElementMap) {
                this.map.click.subscribe((click: boolean): void => {
                    if (this.MapInfoWinService) this.MapInfoWinService.close();
                }); // subscribe
                // Add custom controls
                //const mapPanelElem: any = document.createElement('div');
                //mapPanelElem.innerHTML = '<qp-map-controls></qp-map-controls>';//require('./map-controls.component.html');//panelHtml;
                //console.log(mapPanelElem);
                const mapPanelElem: any = elemMapControls
                    ? elemMapControls.nativeElement
                    : document.getElementById(this.divMapControls);
console.debug(elemMapControls);
console.debug(mapPanelElem);
                // if (mapPanelElem) this.map.controls[this.customControlLocation].push(mapPanelElem);

                if (! (this.mapPanorama instanceof MapElementStreetViewPanorama))
                    this.mapPanorama = MapElementStreetViewPanorama.get(this.map.getStreetView() as MapElementStreetViewPanorama);

                this.map.setCenter(this._mapPosInitial);
            }
            //mapPanorama.setPosition(mapPosDefault);
        }


        // Create a new legend for use with the various map layers
        if (! this.mapLegend) { // don't check for instanceof MapElementLegend as get() returns 'any' 
            this.mapLegend = MapElementLegend.get(
                elemMapLegend
                    ? elemMapLegend.nativeElement
                    : document.getElementById(this.divMapLegend)
            ); // get
// console.debug(this.mapLegend)
console.debug(elemMapLegend);
console.debug(this.mapLegend );
            if (! this.mapLegend) { // don't check for instanceof MapElementLegend as get() returns 'any' 
                console.log("Unable to find DOM element '" + this.divMapLegend + "' for map legend");
                // return false;
            }
        }

        //gMaps.event.clearInstanceListeners(this);

        //this.map.refresh(); // force a re-draw to fix sporadic blank page issue

        console.debug("Map service configured");
        this._initialised$.next(true); // inform listeners to MapService

        return true;
    }


    // function updateHeatMap(locations)
    // {
    //     var heatMapData = [];

    //     if (locations && locations.length > 0) {
    //         for (var i = 0, len = locations.length; i < len; i++) {
    //             if (locations[i]) {

    //                 heatMapData.push(
    //                     {
    //                         location: new gMaps.LatLng(locations[i].latitude, locations[i].longitude), 
    //                         weight: 1
    //                     }
    //                 );
    //             } // if
    //         } // for
    //     } //if

    //     if (map && heatMapData) {
    //         heatMap.setData(heatMapData);
    //         heatMap.setMap(map);
    //     }
    //     else {
    //         heatMap.setMap();
    //     }
    // }

    //
    // Protected methods
    //
    protected override cleanUp(): void
    {
        super.cleanUp();

        if (this.map instanceof MapElementMap) this.map.cleanUp();
    }
    

    //
    // Private methods
    //

    // Don't use setter as would have to be public to match getter
    // private setMap(d: MapElementMap)
    // {
    //     this._map = d;
    // }
}