<!-- <div class="buttonAndTextOuter"> -->
<div>
    <qp-route-back             *ngIf="routeBack"      [route]="routeBack"></qp-route-back>

    <div class="buttonAndTextInner">
        <qp-refresh-button     *ngIf="refreshEnabled" (pressed)="refreshFn()"></qp-refresh-button>
        <qp-status-indicator   *ngIf="statusEnabled"  [state]=state></qp-status-indicator>
    </div>

    <div class="buttonAndTextInner">
        <qp-window-title [title]="title" [value]="value"></qp-window-title>
    </div>

    <!-- <div class="buttonAndTextInner"> -->
    <div>
        <qp-geolocation-button *ngIf="geolocationEnabled" (pressed)="geolocationFn()"></qp-geolocation-button>
    </div>
</div> <!-- buttonAndTextOuter -->