import { Injectable }     from '@angular/core';
import {
    Observable,
    Subscription
}                         from 'rxjs';

import { MessageService } from '@Messaging/';
import {
    DataService,
    DataServiceEvents
}                         from '@Misc/Services/';
import {
    Organisation,
    Site
}                         from '@ObjElements/';
// import { OrganisationsService } from '@Organisations/';


@Injectable({
    providedIn: 'root'
})
export class SitesService extends DataService
{
    protected static readonly _title: string = "Sites";


    private orgs: Map<string, Organisation> = new Map();


    public constructor(                 MessageService:       MessageService)
                    //    private readonly OrganisationsService: OrganisationsService)
    {
        super(MessageService, SitesService._title);

         // Don't call from within initialise() as that is called by parent constructor and this.MessageService not available then
        //this.refresh(MessageService.messages.msgTypesSub.organisations);
    }

    
    //
    // Interface
    //

    // Override
    public get(id: string | number): object
    {
        return this.getSite(id as string);
    }


    // Override
    public override getObservable(type: string, event: string): Observable<any> | undefined
    {
        switch (event) {
            case DataServiceEvents.added:
                return this[DataServiceEvents.added];

            case DataServiceEvents.deleted:
                return this[DataServiceEvents.deleted];
        }

        return undefined;   
    }


    // Override
    protected processData(data: any): void
    {
        //this.update(data && data.data ? data.data : data);
    }


    // Override
    // protected get title(): string
    // {
    //     return SitesService._title;
    // }


    //
    // Public methods
    //

    // Override
    public override clear(update: boolean = false): void
    {
        this.orgs.clear();

        super.clear(update);
    }


    // Override
    public override refresh(): Subscription | undefined
    {
        console.debug("Refreshing " + this.Title + " service");

        if ([...this.orgs.keys()].length == 0) {
            // No point querying DB - no results
            this.dataLoading$.next(false); // need to do here as parent class not involved in this section
            this.update([]);
        }
        else {
            this.dataLoading$.next(true); // need to do here as parent class not involved in this section
            // Use observable
            const obs: Observable<any> | undefined = this.MessageService.sendMsgGet(
                this.MessageService.messages.msgTypesInfo.sites, { orgId: [...this.orgs.keys()] }
            );
                
            return obs instanceof Observable
                ? (this.sub = obs.subscribe((d: any): void => {
                    if (d) this.update(
                        d.data
                            ? d.data
                            : []
                    ); // update
                    this.dataLoading$.next(false); // need to do here as parent class not involved in this section
                }) ) // subscribe()

                : undefined;
        }

        return undefined
    }


    // Special case - called by map-layers-control-organisations
    public setData(d: Organisation[]): void
    {
        if (Array.isArray(d)) {
            this.orgs.clear();
            d.forEach((o: Organisation): void => {
                if (o instanceof Organisation) this.orgs.set(o.id, o);
            }); // forEach
            this.refresh();
        }
    }


    //
    // Private methods
    //
    public getSite(id: string): Site
    {
        return this.getElement(id) as Site;
    }


    private update(d: any[]): void
    {
        console.debug(d);

        if (Array.isArray(d)) {
            console.debug("Updating " + this.Title + " service with data: " + d.length);
            this.clear(true); // clear existing stored elements
            Object.entries(d).forEach(([k, v]): void => {
                const s: Site | undefined = Site.get(v);
                if (s instanceof Site) this.add(s.id, s, false);
            }); // forEach
            this.updateObservables();
        }
        else {
            console.log("No data received to update " + this.Title + " service");
        }
    }
}