import {
    distinctUntilChanged,
    filter,
    Observable
}                                    from 'rxjs';
    
import { IconsService }              from '@Icons/';
import {
     DataServiceEvents,
     DataServiceI
}                                    from '@Misc/Services/';
import {
    Element,
    ElementMainCommon as ElementMain
}                                    from '@ObjElements/';

import {
    MapElementAnimation,
    MapElementMarker,
    MapElementSymbol
}                                    from '../../map-elements';

import { MapLayersService }          from '../map-layers.service';
import { MapService }                from '../../map.service';

import { MapLayer }                  from './map-layer.class';
import { MapLayerType }              from './map-layer-type.class';
import { MapLayerUtils }             from './map-layer-utils.class';

 
export class MapLayerElements extends MapLayer
{
    private created: boolean                  = false;

    public  _type:   MapLayerType | undefined;


    protected constructor(                 MapService:       MapService,
                          private readonly MapLayersService: MapLayersService,
                                           DataService:      DataServiceI | undefined,
                          private readonly IconsService:     IconsService,
                                           name?:            string,
                                           displayName?:     string)
    {
        super(MapService, DataService, name, displayName);

        this.created = true;
        if (name) this._type = MapLayerType.get(name);
        
        this.initialise();
    }


    static get(MapService:      MapService,
               MapLayerService: MapLayersService,
               DataService:     DataServiceI | undefined,
               IconsService:    IconsService,
               name?:           string,
               displayName?:    string): MapLayerElements
    {
        return new MapLayerElements(
            MapService,
            MapLayerService,
            DataService,
            IconsService,
            name,
            displayName
        ); // MapLayerElements
    }


    // Interface
    protected getDataForMapPoint(id: string | undefined): Element | ElementMain | undefined
    {
        //super.getDataForMapPoint(id); // don't call

        const obj: object | undefined = (typeof id === 'string') ? this.DataService?.get(id) : undefined;
        return (obj instanceof Element)
            ? obj as Element
            : ((obj instanceof ElementMain)
                  ? obj as ElementMain
                  : undefined
            );
    }

    // Override
    public override set data(d: Element[] | ElementMain[] | undefined)
    {
        super.refresh();
        this.addMapPoints(super.data = d, false);
    }


    //
    // Public methods
    //

    // Override
    public override refresh(): void
    {
        super.refresh();

        // Need to re-add listeners for each marker?
        // console.log(this.name)
        // console.log(this.DataService.getAll(this.name))


        // Need to wait for service to indicate data loaded

        if (this.DataService) {
            console.debug("Waiting for data service to load: " + this.name);
            const obs: Observable<boolean> | undefined = this.DataService?.loading;
            if (obs instanceof Observable) this.sub = obs
                .pipe(
                    distinctUntilChanged()
                )
                .pipe(
                    filter((d: boolean): boolean => d === false)
                ) // not loading, i.e. loaded
                .subscribe((d: boolean): void => {
                    console.debug("Data service loaded, creating map points: " + this.name);
                    this.addMapPoints(this.DataService?.getAll(this.name), false);
                }); // subscribe
        }
    }

    
    // Override
    public override update(type: string, data: object): void
    {
    //     if (! type) {
    //         console.log("No message included");
    //         return;
    //     }

    //     if (! data) {
    //         console.log("No message to process");
    //         return;
    //     }


    //     if ('phonesPopulated' == type) {
    //         console.log("Updating all markers for devices");
    //         return this.refresh();
    //     }
    //     else {
    //         if (! data.serialNum) {
    //             console.log("Unknown device '" + data.serialNum + "'");
    //             return;
    //         }

    //         switch (type) {
    //             case 'phoneAdded':
    //                 // UE connected or re-connected
    //                 // Server already added this to database so client can assume any database queries
    //                 // include all newly-discovered UEs

    //                 // First check if marker already exists; remove if it does
    //                 //console.log("Looking for marker for device '" + data.serialNum);
    //                 let isNew = true;
    //                 if (this.getMapPoint(data.serialNum)) {
    //                     console.log("Marker for device '" + data.serialNum + "' found; deleting");
    //                     //deleteMapPoint(layer, data.serialNum, false);
    //                     isNew = false;
    //                 }

    //                 // Now add new map point
    //                 console.log("Adding marker for device '" + data.serialNum + "' (" + isNew + ")");
    //                 return this.addOrUpdateMapPoint(this.layer, data.serialNum, isNew);
    //                 break;

    //         //case 'phonePendingDelete': {
    //         //    console.log("Setting marker to pending delete for device '" + data.serialNum + "'");
    //         //    return updateMapPoint(null, data.serialNum);
    //         //    break;
    //         //}

    //             case 'phoneDeleted':
    //                 console.log("Deleting marker for device '" + data.serialNum + "'");
    //                 return this.deleteMapPoint(this.layer, data.serialNum, true);
    //                 break;

    //             default:
    //                 // Also handles pendingDelete
    //                 //return updateMapPoint(null, data.serialNum);
    //                 return this.addOrUpdateMapPoint(this.layer, data.serialNum, true);
    //         } // switch
    //     }
    } // update


    //
    // Protected methods
    //

    // Override
    protected override initialise(): void
    {
        if (! this.created ) return;

        console.debug("Initialising MapLayerElements object: " + this.name);

        //super.initialise();

    
        this.refresh(); // deletes existing map points and adds new ones

        let sub: Observable<any> | undefined =
            this.name
                ? this.DataService?.getObservable(this.name, DataServiceEvents.added)
                : undefined;
        if (sub instanceof Observable) this.sub = (sub as Observable<number | string>)
            .subscribe((d: number | string): MapElementMarker => {
                // Device connected or re-connected
                // Server already added this to database so client can assume any database queries
                // include all newly-discovered devices

                // First check if marker already exists; remove if it does
                const isNew: boolean = this.getMapPoint(d as string) ? false : true;
    
                // Now add new map point
                // console.log("Adding marker for element '" + d + "' (" + isNew + ")");
                return this.addOrUpdateMapPoint(d as string, isNew);
            }); // subscribe

        sub =
            this.name
                ? this.DataService?.getObservable(this.name, DataServiceEvents.deleted)
                : undefined;
        if (sub instanceof Observable) this.sub = (sub as Observable<number | string>)
            .subscribe((d?: number | string): any => {
                if (d) {
                    //console.log("Deleting marker for element '" + d + "'");
                    return this.deleteMapPoint(d as string, true);
                }
                else {
                    console.info("Deleting markers for all elements");
                    return this.deleteMapPoints();   
                }
            }); // subscribe

        sub = this.MapLayersService.elementVisible;
        if (sub instanceof Observable) this.sub = (sub as Observable<string>)
            .subscribe((d: string): void => {
                this.setMapPointVisibility(d, true);
            }); // subscribe

        sub = this.MapLayersService.elementNotVisible;
        if (sub instanceof Observable) this.sub = (sub as Observable<string>)
            .subscribe((d: string): void => {
                this.setMapPointVisibility(d, false);
            }); // subscribe
    }


    // Override
    protected override updateMapPoint(marker: MapElementMarker | undefined, id?: string | Element | ElementMain): MapElementMarker | undefined
    {
        super.updateMapPoint(marker, id);

        if (! (marker instanceof MapElementMarker) && id) marker = this.getMapPoint(id);
        if (! (marker instanceof MapElementMarker)) {
            console.info("Marker for element '" + (id ? id : MapLayerUtils.unknownStr) + "' not found");
            return undefined;
        }

        // Only concerned with geolocation and image; other device attributes are shown in
        // info window which is automatically updated

        // 'Element' object and 'marker' child objects (references to the same object)
        // have already been updated by the main DataService service.
        // So just need to move the icon on the map and update the title and image
        const el: Element | ElementMain | undefined = (id instanceof Element || id instanceof ElementMain) ? id : this.getDataForMapPoint(id);
        if (! (el instanceof Element) && ! (el instanceof ElementMain)) {
            console.info("Element " + id + " not found");
            console.debug(el);
            console.debug(marker);
            return undefined;
        }

        // Update location
        // if (   device.latitude  && device.longitude
        //     && device.latitude  != marker.getPosition().lat()
        //     && device.longitude != marker.getPosition().lng()) {
        //     marker.setPosition(MapElementLatLng.get(+device.latitude, +device.longitude, + device.accuracy));
            //console.log("Update existing map point");
            // marker.setPosition({
            //     lat: parseFloat(device.latitude),
            //     lng: parseFloat(device.longitude)
            // });

            // if (marker.circle) {
            //     //marker.circle.setCenter(marker.getPosition());
            //     if (! isNaN(device.accuracy)) {
            //         marker.circle.setRadius(parseFloat((device.accuracy > MapLayerDevicesService.maxAccuracy
            //                                            ? MapLayerDevicesService.maxAccuracy
            //                                            : device.accuracy)) );
            //         marker.circle.setMap(marker.getMap());
            //     }
            //     else {
            //         marker.circle.setRadius(0);
            //         marker.circle.setMap(null);
            //     }
            // }

            //marker.updateTitle();
        //} // location
        // if (marker.getIcon() != MapLayerDevices.iconPhoneRed) (marker as any).setIconStandard();


        const ssStatus = "eventstatus";
        // // If pendingDelete, set grey icon
        // if      (device.pendingDelete) {
        //     // Stop any existing timeouts which will interfere with icon setting
        //     if (marker.timeout) window.clearTimeout(marker.timeout); 

        //     // Stop any icon animation
        //     if (marker.getAnimation != MapLayerDevices.gMaps.Animation.NONE) 
        //         marker.setAnimation(MapLayerDevices.gMaps.Animation.NONE);

        //     console.log("Pending delete so greying out icon");
        //     marker.setIcon(MapLayerDevices.iconPhoneGrey);
        // }
        // If test in progress, change and bounce marker icon
        if ((el as any).testResultType) {
            marker.timeout = undefined;

            if ((el as any).testResultType.substring(0, ssStatus.length) != ssStatus) {
                // Update icon to 'test running' icon
                //console.log("Stu: " + device.testResultType);
                //console.log(this.IconsService.getIconTest(device.testResultType.replace("start", "").replace("progress", "")) );
                marker.setIcon(this.IconsService.getIconTest(
                    (el as any).testServiceName,
                    (el as any).testResultType.replace("eventstart", "").replace("eventprogress", ""))
                );

                // Set icon animation
                // [TBD]
                if ((marker.getAnimation as any) != MapElementAnimation.BOUNCE)
                    marker.setAnimation(MapElementAnimation.BOUNCE);
            }
            else {
                // Stop any icon animation
                if (marker.getAnimation != null) marker.setAnimation(null);

                // if ((el as any).sims && ((el as any).sims[1].plmn || (el as any).sims[1].operator)) {
                //     this.IconsService.getOperatorIconAsync(
                //         (el as any).sims[1].plmn, (el as any).sims[1].operator)
                //         //.pipe(take(1)) // auto-unsubscribe after response
                //         .subscribe((data: MapElementSymbol): void => {
                //             if (data) marker.setIcon(data);
                //         });
                //     //marker.setIcon(this.IconsService.getOperatorIconAsync(device.sims[1].plmn, device.sims[1].operator));
                // }
                // else {
                //     this.IconsService.getOperatorIconAsync()
                //         //.pipe(take(1)) // auto-unsubscribe after response
                //         .subscribe((data: MapElementSymbol): void => {
                //             if (data) marker.setIcon(data);
                //         });
                //     //marker.setIcon(this.IconsService.getOperatorIconAsync());
                // }
            }
        }

        //        this.MapService.infoUpdate(marker);

        return marker;
    }
}