import {
    Component,
    // ComponentFactory,
    // ComponentFactoryResolver,
    ComponentRef,
    ViewContainerRef
}                             from '@angular/core';

import { BaseComponent }      from './base.component';


@Component({
    template: ''
})
export class BaseWithDynamicComponent extends BaseComponent
{
    private _compRef: ComponentRef<any> | undefined;


    // public constructor(private readonly ComponentFactoryResolver: ComponentFactoryResolver)
    public constructor()
    {
        super();
    }


    //
    // Getters & setters
    //
    protected get compRef(): ComponentRef<any> | undefined
    {
        return this._compRef
    }

    protected set compRef(d: ComponentRef<any> | undefined)
    {
        this._compRef = d;
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();
        
        this.close();
    }

    
    protected close(): void
    {
        this.cleanComponentRef();
    }


    protected openT(v: ViewContainerRef, t: any | undefined, d: any = undefined): ComponentRef<any> | undefined
    {
        if (v
            && t 
            && (! (this.compRef instanceof ComponentRef) || this.compRef.componentType !== t)
        ) {
            this.cleanComponentRef();


            // const component = v.createComponent(YourElement);


            // Not needed from Anuglar 14
            // Dynamically create component if not already created
            // const cf: ComponentFactory<any> | undefined
            //     = this.ComponentFactoryResolver
            //         ? this.ComponentFactoryResolver.resolveComponentFactory(t)
            //         : undefined;

            // Create new component
            const cr: ComponentRef<any> | undefined
                =  v.createComponent instanceof Function
                // = cf && v.createComponent instanceof Function
                    // ? v.createComponent(cf)
                    ? v.createComponent(t)
                    : undefined;
            
            if (cr instanceof ComponentRef) this.compRef = cr;
            if (d) this.setData(d);
        }

        return this.compRef;
    }

    protected setData(d: any)
    {
        if (this.compRef instanceof ComponentRef && d) this.compRef.instance.data = d;
    }
        
        
    //
    // Private methods
    //
    private cleanComponentRef(): void
    {
        // Clean up any existing dynamic component
        if (this.compRef instanceof ComponentRef) {
            this.compRef.destroy();
            this.compRef = undefined;
        }
    }
}