// Must be in separate module to other Map elements, to avoid circular dependcies
// when Element class import modules that cause an import of the main Map module
// which itself might use Element

import { GeolocationCommon } from '@Common/Elements/Devices/Attributes/Geolocation.class';


interface MapElementLatLngI
{
    latitude:  number,
    longitude: number,
    noWrap?:   boolean,
    accuracy?: number,
    speed?:    number
}

//declare var google: any;

export class MapElementLatLng extends google.maps.LatLng
{
    public  static readonly locationAccMax:     number = 500; // metres
    private static readonly locationAccSpeedDP: number = 2;
    private static readonly locationLatLngDP:   number = 5;

    private                 _accuracy:          number = 0;//MapElementLatLng.locationAccMax;
    private                 _speed:             number = 0;


    private constructor(lat: number, lng: number, noWrap: boolean = false, accuracy?: number | string, speed?: number | string)
    {
        super(lat, lng, noWrap);

        if (accuracy != null) this._accuracy = (typeof accuracy === 'string') ? parseFloat(accuracy) : accuracy;
        if (speed    != null) this._speed    = (typeof speed    === 'string') ? parseFloat(speed)    : speed;
    }


    //
    // Static methods
    //
    public static get2(d: GeolocationCommon): MapElementLatLng | undefined
    {
        return d
            // ? MapElementLatLng.get(d.latitude, d.longitude, d.noWrap, d.accuracy, d.speed)
            ? MapElementLatLng.get(d.latitude, d.longitude, true, d.accuracy, d.speed)
            : undefined;
    }


    public static get(arg1:      number | string | MapElementLatLngI,
                      lng?:      number | string,
                      noWrap?:   boolean,
                      accuracy?: number | string,
                      speed?:    number | string): MapElementLatLng | undefined
    {
        if (arg1) {
            if      (typeof arg1 === 'number' || typeof arg1 === 'string') {
                return (
                    (arg1 != undefined && lng !== undefined)
                    ? new MapElementLatLng(
                        typeof arg1 === 'string' ? +arg1 : arg1,
                        typeof lng  === 'string' ? +lng  : lng,
                        noWrap, accuracy, speed)
                    : undefined
                );
            }
            else {
                arg1 = arg1 as MapElementLatLngI;
                if (arg1.latitude != undefined && arg1.longitude !== undefined)
                    return new MapElementLatLng(arg1.latitude, arg1.longitude, arg1.noWrap, arg1.accuracy, arg1.speed);
            }
        }

        return undefined;
    }


    //
    // Getters
    //
    public get accuracy(): number
    {
        return this._accuracy;
    }
    

    public get accuracyStr(): string
    {
        return this.accuracy.toFixed(MapElementLatLng.locationAccSpeedDP);
    }


    public get latitude(): number
    {
        return this.lat();
    }


    public get latitudeStr(): string
    {
        return this.latitude.toFixed(MapElementLatLng.locationLatLngDP)
    }


    public get locationStr(): string
    {
        return (this.latitudeStr + ", " + this.longitudeStr);
    }


    public get longitude(): number
    {
        return this.lng();
    }


    public get longitudeStr(): string
    {
        return this.longitude.toFixed(MapElementLatLng.locationLatLngDP)
    }


    public get speed(): number
    {
        return this._speed;
    }


    public get speedStr(): string
    {
        return this.speed.toFixed(MapElementLatLng.locationAccSpeedDP)
    }


    public get speedKphStr(): string
    {
        return (this.speed * 3600 / 1000).toFixed(MapElementLatLng.locationAccSpeedDP);
    }


    //
    // Public methods
    //
    public update(data: any): MapElementLatLng | undefined
    {
        return MapElementLatLng.get(data);
    }
}