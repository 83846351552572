import { 
    Subject,
    Observable
}                                      from 'rxjs';

import { MapElementLatLng }            from './map-element-latlng/';
import { MapElementMapsEventListener } from './map-element-mapseventlistener.interface';
import { MapElementMouseEvent }        from './map-element-mouseevent.interface';

const gMaps = google.maps;


export class MapElementMap extends gMaps.Map
{
    private readonly _click:      Subject<boolean> = new Subject<boolean>();
    private readonly _rightclick: Subject<boolean> = new Subject<boolean>();

    private readonly _listeners:  MapElementMapsEventListener[] = [];


    constructor(private readonly elem:    any,
                private readonly options: any)
    {
        super(elem, options);


        //
        // Listeners
        //
        // Close any marker if map is clicked
        this.listener = this.addListener('click',             (event: MapElementMouseEvent): void => {
            this._click.next(true);
            //console.log("Map left click");
        });

        //gMaps.event.addListener(map,    'bounds_changed',    function(event) {
        this.listener = this.addListener('bounds_changed',    ():           void => {
            //console.log("Map bounds changed: " + this.getBounds().toString());
        });

        this.listener = this.addListener('center_changed',    (event: any): void => {
            //console.log("Map centre changed: " + this.getCenter().toString());
        });

        this.listener = this.addListener('maptypeid_changed', (event: any): void => {
            //console.log("Map type changed: " + this.getMapTypeId());
        });

        this.listener = this.addListener('resize',            (event: any): void => {
            //console.log("Map resized");
        });

        this.listener = this.addListener('rightclick',        (event: MapElementMouseEvent): void => {
            console.log("Map right click");
            this._rightclick.next(true);
        });

        this.listener = this.addListener('zoom_changed',      (event: any): void => {
            //console.log("Map zoom changed: " + this.getZoom());
        });
    }


    //
    // Static methods
    //
    public static getPathLength(paths: MapElementLatLng[]): number
    {
        return paths
            ? gMaps.geometry.spherical.computeLength(paths)
            : 0;
        // if (MapLayer.gMaps && MapLayer.gMaps.geometry && MapLayer.gMaps.geometry.spherical) {
        //     return MapLayer.gMaps.geometry.spherical.computeLength(this.paths);
        // }
    }


    //
    // Getters and setters
    //
    public get click(): Observable<boolean>
    {
        return this._click.asObservable();
    }


    private set listener(d: MapElementMapsEventListener | undefined)
    {
        if (d) this._listeners.push(d)
        else {
            this._listeners.forEach((l: MapElementMapsEventListener): void => {
                if (l) l.remove();
            });
            this._listeners.length = 0;
        }
    }

    
    public get rightClick(): Observable<boolean>
    {
        return this._rightclick.asObservable();
    }


    //
    // Public methods
    //
    public cleanUp(): void
    {
        this.listener = undefined;

        this._click.complete();
        this._rightclick.complete();
    }


    public refresh(): void
    {
        if (this.options) {
            this.setCenterZoom(this.options.center, this.options.zoom);
            this.setMapTypeId(this.options.mapTypeId);
        }

        //if (this.infoWin) this.infoWin.close();

        this.resize();
    }


    public resize(): void
    {
        const centre: any = this.getCenter();
        gMaps.event.trigger(this, 'resize');
        if (this.getCenter() != centre) this.setCenter(centre);

        //console.log("[TBD] Resize canvas if necessary");
    }


    public setCenterZoom(latLng: MapElementLatLng, zoom?: number): void
    {
        if (latLng            && this.getCenter() !== latLng) this.setCenter(latLng);
        if (zoom && zoom >= 0 && this.getZoom()   !== zoom)   this.setZoom(zoom);
    }
}