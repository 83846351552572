import { Base }                  from '@Base/';
import { IconsService }          from '@Icons/';
import { DataServiceI }          from '@Misc/Services/';

import { MapLayer }              from './map-layer.class';
import { MapLayersService }      from '../map-layers.service';
import { MapLayerType }          from './map-layer-type.class';
// import { MapLayerCellsService }  from './map-layer-cells.service';
import { MapLayerElements }      from './map-layer-elements.class';
import { MapLayerSurvey   }      from './map-layer-survey.class';
// import { MapLayerRoutesService } from './map-layer-routes.service';
// import { MapLayerServices }      from './map-layer-services.service';
// import { MapLayerTestsService }  from './map-layer-tests.service';
import { MapService }            from '../../map.service';


export class MapLayerHelper extends Base
{
    public static get(type:            MapLayerType | undefined,
                      MapService:      MapService,
                      MapLayerService: MapLayersService,
                      DataService:     DataServiceI | undefined,
                      IconsService:    IconsService,
                      name?:           string,
                      displayName?:    string): MapLayer | undefined
    {
        let fn: Function | undefined = undefined;
        if (type) {
            switch (type) {
                case MapLayerType.devices:
                case MapLayerType.liveDevices:
                case MapLayerType.organisations:
                case MapLayerType.sites:
                    fn = MapLayerElements.get;//MapLayerHelper.getElements

                case MapLayerType.survey:
                    fn = MapLayerSurvey.get
                break;
            } // switch
        }

        return (fn instanceof Function)
            ? fn(
                MapService,
                MapLayerService,
                DataService,
                IconsService,
                name        ? name        : type?.key,
                displayName ? displayName : type?.value.displayName
            ) // fn
            : undefined;
    }


    // private static getElements(
    //     MapService:      MapService,
    //     MapLayerService: MapLayersService,
    //     DataService:     DataServiceI,
    //     IconsService:    IconsService,
    //     name:            string,
    //     displayName:     string): MapLayerElements
    // {
    //     return MapLayerElements.get(
    //         MapService,
    //         MapLayerService,
    //         DataService,
    //         IconsService,
    //         name,
    //         displayName
    //     ); // MapLayerElements
    // }
}