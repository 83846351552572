import { Component }     from '@angular/core';
import {
    ActivatedRoute,
    Navigation,
    NavigationExtras,
    ParamMap,
    Router
}                        from '@angular/router';

import { BaseComponent } from '@Base/';
import {
    ElementHelper,
    ElementState,
    Site
}                        from '@ObjElements/';

import { routeNames }    from '../../app.routing.names';

import { SiteService }   from './site.service';


@Component({
   selector:     'qp-site',
   templateUrl:  'site.component.html',
   styleUrls:   ['site.component.css'],
   providers:   [ SiteService ] // scope service to this component only
})
export class SiteComponent extends BaseComponent
{
    public          data:      Site;
    public readonly name:      string       = "Site";
    public readonly routeBack: string[]     = [];

    private         id:        number       = -1;
    private          _state:   ElementState = ElementState.None;


    constructor(private readonly ActRoute:    ActivatedRoute,
                private readonly Router:      Router,
                private readonly SiteService: SiteService)
    {
        super();

        // const nav = this.Router.getCurrentNavigation();
        // if (nav && nav.extras && nav.extras.state) {
            // this.parentId = nav.extras.state.parentId;//console.log(nav.extras.state);

            // if (typeof this.parentId !== 'undefined') this.routeBack.push(this.parentId)

            const nav: Navigation | null = this.Router.getCurrentNavigation();
            if (nav && nav.extras && nav.extras.state && nav.extras.state.parent) {
                // console.error(nav.extras.state.parent)
                this.routeBack.push(nav.extras.state.parent);//console.log(nav.extras.state);
            }
        //}
    }


    //
    // Getters and setters
    //
    public get state(): ElementState
    {
        return this._state;
    }

    public set state(s: ElementState)
    {
        this._state = s;
    }


    //
    // Public functions
    //
    public buttonShowOnMap(): void
    {
        this.showOnMap();
    }


    public refresh(id?: number): void
    {
        const idL = id ? id : this.id;
        if (idL >= 0) this.SiteService.id = idL;
    }


    // Override
    public select(row: any): void
    {
        console.debug(row);

        // Open site child route
    }


    //
    // Protected functions
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Site component");

        this.data = new Site();
        this.sub = this.ActRoute.paramMap
            .subscribe((params: ParamMap): void => {
                const s: string | null = params && params.has('id') ? params.get('id') : "";
                if (! isNaN(Number(s)) ) this.refresh(this.id = Number(s));
            }); // subscribe

        this.sub = this.SiteService.updated$
            .subscribe((data: Site): void => {
                this.state = (this.data = data) instanceof Site ? this.data.state : ElementState.None;
                // this.state = StatusIndicatorComponent.getState((this.data = data) instanceof Site ? this.data.state : undefined);
            }); // subscribe

        //console.log(history.state);
    }


    //
    // Private functions
    //
    private showOnMap(): void
    {
        if (this.data instanceof Site) {
            console.debug("Show site " + this.data.id + ":" + this.data.name + " on map" + (this.data.geolocation ? ": " + this.data.geolocation.locationStr : ""));
            //this.Router.navigate([ routeNames.map , Site.tag + ':' + this.id ]);
            //this.Router.navigate([ routeNames.map , 'queryParams': { 'element': Site.tag, 'id': this.id }]);
            this.Router.navigate(
                [ routeNames.map ],
                { queryParams: { 'element': ElementHelper.getTag(this.data), 'id': this.id } }
            ); // navigate
        }
    }
}