import { Injectable }     from '@angular/core';

import { MessageService } from '@Messaging/';


@Injectable({
    providedIn: 'root'
})
export class TestsService
{
    private readonly services    = {}; // stores service objects

    private readonly baseIcons   = "https://maps.google.com/mapfiles/ms/icons/";
    private readonly imagesBase  = '../../assets/images/';
    private readonly serviceBase = this.imagesBase  + "services/";

    private readonly ftpIcon     = this.baseIcons   + "red-dot.png";
    private readonly icmpIcon    = this.baseIcons   + "blue-dot.png";
    private readonly udpIcon     = this.baseIcons   + "red-dot.png";
    private readonly urlIcon     = this.baseIcons   + "green-dot.png";
    private readonly voiceIcon   = this.serviceBase + "phone-trans.png";
    //private readonly voiceIcon   = this.baseIcons   + "yellow-dot.png";
    private readonly webviewIcon = this.baseIcons   + "green-dot.png";
    private readonly youtubeIcon = this.serviceBase + "youtube-trans.png";
    //private readonly youtubeIcon = this.baseIcons   + "purple-dot.png";

    constructor(private readonly MsgService: MessageService)
    {
        this.initialise();
    }


    //
    // Public Functions
    //
    public getIcon(test: string): string | undefined
    {
        let icon: string = "";

        if (test) {
            switch (test) {
                case 'ftp':
                    icon = this.ftpIcon;
                    break;

                case 'icmp':
                    icon = this.icmpIcon;
                    break;

                case 'udp':
                    icon = this.udpIcon;
                    break;

                case 'url':
                    icon = this.urlIcon;
                    break;

                case 'voice':
                    icon = this.voiceIcon;
                    break;

                case 'webview':
                    icon = this.webviewIcon;
                    break;

                case 'youtube':
                    icon = this.youtubeIcon;
            }
        }

        return icon;
    }


    public update(data: any): void
    {
        if (data) {
            console.log("Updating Tests list");
            console.log(data);
        }
    }


    //
    // Private functions
    //
    private initialise(): any
    {
        console.log("Initialising Tests service");

        return this.refresh();
    }

  
    private refresh(): any
    {
        console.log("Refreshing Tests service - getting all tests");

        //const qpMF: any = this.qp_MsgFactory; // used to disable future type checking
        return this.MsgService.sendMsg(this.MsgService.messages.msgTypes.getdata,
                                          {type: this.MsgService.messages.msgTypesInfo.testlist});
    }
}