import { Injectable }     from '@angular/core';
import { 
    Observable,
    Subject
}                         from 'rxjs';

import { MessageService } from '@Messaging/';


@Injectable({
    providedIn: 'root'
})
export class ServicesService
{
    private readonly services     = new Map(); // stores service objects

    private readonly imagesBase   = '../../assets/images/';
    private readonly iconBlue     = this.imagesBase  + 'phones_blue.png';
    private readonly iconGrey     = this.imagesBase  + 'phones_grey.png';
    private readonly iconRed      = this.imagesBase  + 'phones_red.png';

    private readonly serviceBase  = this.imagesBase  + "services/";
    private readonly facebookIcon = this.serviceBase + "facebook-trans.png";
    private readonly voiceIcon    = this.serviceBase + "phone-trans.png";
    private readonly webviewIcon  = this.imagesBase  + "green-dot.png";
    private readonly youtubeIcon  = this.serviceBase + "youtube-trans.png";

    private updatedSource = new Subject<void>();
    public  updated       = this.updatedSource.asObservable();

    constructor(private readonly MsgService: MessageService)
    { 
        this.initialise();
    }


    //
    // Public Functions
    //
    public getIcon(service: any): string | undefined
    {
        const srv: any = service ? this.getService(service) : undefined;
        return srv ? this.serviceBase + srv.getIcon() : undefined;
    }


    public refresh2(regen?: boolean, serialNum?: string, startDate?: string, endDate?: string): any
    {
        console.debug("Refreshing Services service - getting subset services");

        return this.MsgService.sendMsg(
            this.MsgService.messages.msgTypes.getdata,
            {
                type: this.MsgService.messages.msgTypesSub.services,
                regen: regen,
                serialNum: serialNum,
                startDate: startDate,
                endDate: endDate
            }
        );
    }


    public serviceFactory(obj: any): any
    {
        // Constructor
        function Service(this: any, obj: any): void
        {
            let that: any = this; // private var; used to allow 'this' to be accessed from member methods
            let name_:         string;
            let category_:     string;
            let categoryName_: string;
            let icon_:         string;
            let categoryIcon_: string;


            if (obj) {
                name_         = obj.name         ? obj.name         : "";
                category_     = obj.category     ? obj.category     : "";
                categoryName_ = obj.categoryName ? obj.categoryName : "";
                icon_         = obj.icon         ? obj.icon         : "";
                categoryIcon_ = obj.categoryIcon ? obj.categoryIcon : "";
            }


            this.getIcon = function(): string {
                return icon_;
            };


            this.getName = function(): string {
                return name_;
            };
        }

        return new (Service as any)(obj);
    }


    public update(data: any): void
    {
        if (data) {
            console.debug("Updating services list");

            if (data.length > 0) {
                for (let i : any = 0, len = data.length; i < len; ++i) {
                    if (data[i]) {
                        const service: any = this.serviceFactory(data[i]);
                        if (service && service.getName()) {
                            this.services.set(service.getName(), service);
                        }
                    }
                } // for

                this.updateObservables(this.services.size);
            }
        }
    }


    //
    // Private functions
    //
    private initialise(): boolean
    {
        console.debug("Initialising Services service");
        return this.refresh();
    }


    private getService(service: string): object
    {
        return this.services.get(service);
    }


    private refresh(): any
    {
        console.debug("Refreshing Services service - getting all services");

        //const qpMF: any = this.MsgService; // used to disable future type checking
        return this.MsgService.sendMsg(
            this.MsgService.messages.msgTypes.getdata,
            {type: this.MsgService.messages.msgTypesSub.servicelist}
        ); // sendMsg
    }


    private updateObservables(arg: any): void
    {
        this.updatedSource.next();
    }
}