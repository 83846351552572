import '@angular/compiler';
import {
    Component,
    NgModule,
    NgZone
}                                   from '@angular/core';
import { BrowserAnimationsModule }  from '@angular/platform-browser/animations';
// import { BrowserModule }            from '@angular/platform-browser';
import { FlexLayoutModule }         from '@angular/flex-layout';
import 'hammerjs';
import { 
    HttpClientModule,
    HTTP_INTERCEPTORS
}                                   from '@angular/common/http';
import { UpgradeModule }            from '@angular/upgrade/static';

import { AppRoutingModule }         from './app.routing.module';

import {
    AppComponent,
    AppModule
}                                   from '@App/';
import { BaseModule }               from '@Base/';
import { ChartsModule }             from '@Charts/';
// import { DashboardModule }          from '@Dashboard/';
import {
    DevicesModule,
    DeviceInfoComponent,
    DevicesListComponent
}                                   from '@Devices/';
import {
    DeviceGroupsListComponent,
    DeviceGroupsModule
}                                   from '@DeviceGroups/';
import { GuiElementsModule }        from '@GuiElements/';
import { InsightsModule }           from '@Insights/';
import { HeaderModule }             from '@Header/';
import { KpisModule }               from '@Kpis/';
import {
    ErrorInterceptor,
    LoginModule,
    JwtInterceptor
}                                   from '@Login/';
// import {
//     // MainComponent,
//     // MainModule
// }                                   from '@Main/';
import {
    MapInfoWinContainerComponent,
    MapModule
}                                   from '@Map/';
import { MaterialCustomModule }     from '@Material/';
import { MessagingModule }          from '@Messaging/';
import {
    AboutComponent,
    DialogComponent,
    MiscModule,
    ProgressSpinnerDialogComponent
}                                   from '@Misc/';
// import { MobileCellsModule }        from '@MobileCells/';
import { MobileNetworksModule }     from '@MobileNetworks/';
import {
    NotificationsModule,
    NotificationsComponent
}                                   from '@Notifications/';
import {
    DeviceInfoContainerComponent,
    // DeviceBeaconInfoComponent,
    // DeviceMobilePhoneInfoComponent,
    // DeviceRepeaterInfoComponent,
    ElementInfoComponent,
    ObjElementsModule,
    OrganisationInfoComponent,
    SiteInfoComponent,
    SurveyInfoComponent
}                                   from '@ObjElements/';
// import { OrganisationsModule }      from '@Organisations/';
import { ReportsModule }            from '@Reports/';
import { ResultsModule }            from '@Results/';
// import { SideBarModule }            from '@SideBar/';
import { SitesModule }              from '@Sites/';
// import { SurveysModule }            from '@Surveys/';
// import { TabsModule }               from '@Tabs/';
import { UtilsModule }              from '@Utils/';
// import { WifiApsModule }            from '@Wifi/';


// AngularJS services - TBD to be converted Angular 2+
// import {
//     qpGlobalsServiceProvider,
//     qpKpiNamesServiceProvider,
//     qpKpiObjectsServiceProvider,
//     qpProgressCircularServiceProvider,
//     qpServiceLevelsServiceProvider,
//     qpStatusServiceProvider,
//     qpTimeoutServiceProvider,
//     qpVersionServiceProvider,
//     qpWebMsgsServiceProvider,
//     qpWindowServiceProvider
// }                                       from '../ajs-upgraded-providers';

@NgModule({
    imports: [
        BrowserAnimationsModule,

        // Not needed; auto imported via other modules (e.g. BrowserAnimationsModule)
        //BrowserModule,
        
        FlexLayoutModule,
        HttpClientModule,
        UpgradeModule,

        AppRoutingModule,
        MaterialCustomModule,

        AppModule,
        BaseModule,
        ChartsModule,
        DevicesModule,
        DeviceGroupsModule,
        GuiElementsModule,
        HeaderModule,
        InsightsModule,
        KpisModule,
        LoginModule,
        MiscModule,
        MobileNetworksModule,
        NotificationsModule,
        ObjElementsModule,
        ReportsModule,
        ResultsModule,
        SitesModule,
        UtilsModule,

        // Now lazy-loaded
        // MainModule,
        // DashboardModule,
        MapModule,
        // MobileCellsModule,
        // OrganisationsModule,
        // SideBarModule,
        // SurveysModule,
        // TabsModule,
        // WifiApsModule
    ],
    
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor,   multi: true }

        // qpGlobalsServiceProvider,
        // qpKpiNamesServiceProvider,
        // qpKpiObjectsServiceProvider,
        // qpProgressCircularServiceProvider,
        // qpServiceLevelsServiceProvider,
        // qpStatusServiceProvider,
        // qpTimeoutServiceProvider,
        // qpVersionServiceProvider,
        // qpWebMsgsServiceProvider,
        // qpWindowServiceProvider
    ],

    bootstrap: [
        AppComponent // default app to bootstrap, needed after AJS removal
    ],
    
    declarations: [
    ]

    // // Not needed A10+, removed A16+
    // entryComponents: [
    //     AboutComponent,
    //     AppComponent,
    //     DialogComponent,
    //     DeviceInfoContainerComponent,
    //     // DeviceBeaconInfoComponent,
    //     DeviceGroupsListComponent,
    //     DeviceInfoComponent,
    //     // DeviceMobilePhoneInfoComponent,
    //     // DeviceRepeaterInfoComponent,
    //     DevicesListComponent,
    //     ElementInfoComponent,
    //     // MainComponent,
    //     MapInfoWinContainerComponent,
    //     NotificationsComponent,
    //     OrganisationInfoComponent,
    //     ProgressSpinnerDialogComponent,
    //     SiteInfoComponent,
    //     SurveyInfoComponent
    // ]
})
export class AppModuleNg2
{
    constructor(private upgrade: UpgradeModule) { }


    ngDoBootstrap(app: any): void
    {
        // Do nothing
    } 
}