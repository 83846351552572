import { MobileCell } from '@ObjElements/mobile-cells/mobile-cell';


export class MobileNetworkBands
{
    private static readonly mhz = " MHz - ";


    //
    // Static methods
    //
    private static readonly bands = new Map([
        [
            MobileCell.Technology.Gsm.technology as string, 
            new Map<number, string>([
                [2,  1900 + MobileNetworkBands.mhz + "PCS-1900"],
                [3,  1800 + MobileNetworkBands.mhz + "DCS-1800"],
                [5,   850 + MobileNetworkBands.mhz + "GSM-850"],
                [8,   900 + MobileNetworkBands.mhz + "E-GSM-900"]
            ])
        ],

        [
            MobileCell.Technology.Lte.technology as string,
            new Map<number, string>([
                [1,  2100 + MobileNetworkBands.mhz + "IMT"],
                [2,  1900 + MobileNetworkBands.mhz + "PCS-A-F"],
                [3,  1800 + MobileNetworkBands.mhz + "DCS"],
                [4,  1700 + MobileNetworkBands.mhz + "AWS-1"],
                [7,  2600 + MobileNetworkBands.mhz + "IMT-E"],
                [8,   900 + MobileNetworkBands.mhz + "E-GSM"],
                [20,  800 + MobileNetworkBands.mhz + "EUDD"],
                [22, 3500 + MobileNetworkBands.mhz + "LTE-3500"],
                [28,  700 + MobileNetworkBands.mhz + "APT"],
                [31,  450 + MobileNetworkBands.mhz + "LTE-450"],
                [32, 1500 + MobileNetworkBands.mhz + "L-BAND"],
                [38, 2600 + MobileNetworkBands.mhz + "IMT-E"],    // TDD
                [40, 2300 + MobileNetworkBands.mhz + "LTE-2300"]  // TDD
            ])
        ],

        [
            MobileCell.Technology.Nr.technology as string,
            new Map<number, string>([
                // TBD
            ])
        ],

        [
            MobileCell.Technology.Umts.technology as string,
            new Map<number, string>([
                [1,  2100 + MobileNetworkBands.mhz + "IMT"],
                [2,  1900 + MobileNetworkBands.mhz + "PCS-A-F"],
                [3,  1800 + MobileNetworkBands.mhz + "DCS"],
                [4,  1700 + MobileNetworkBands.mhz + "AWS-1"],
                [5,   850 + MobileNetworkBands.mhz + "CLR"],
                [6,   800 + MobileNetworkBands.mhz + "UMTS-800"], // replaced by B19
                [7,  2600 + MobileNetworkBands.mhz + "IMT-E"],
                [8,   900 + MobileNetworkBands.mhz + "E-GSM"],
                [9,  1800 + MobileNetworkBands.mhz + "UMTS-1700"],
                [10, 1700 + MobileNetworkBands.mhz + "EAWS-A-G"],
                [11, 1500 + MobileNetworkBands.mhz + "LPDC"],
                [19,  800 + MobileNetworkBands.mhz + "LB-800"],   // replaced B6
                [20,  800 + MobileNetworkBands.mhz + "EUDD"]
            ])
        ],
    ]); // Map


    //
    // Public methods
    //
    public static getBandInfo(technology: string, band: number): string | undefined
    {
        const o: Map<number, string> | undefined = this.bands.get(technology);
        return (o instanceof Map) ? o.get(band): undefined;
    }
}