export * from './device/';

export * from './devices.service';
export * from './devices-active.service';

export * from './devices.component';
export * from './devices-container.component';

export * from './device-info.component';
export * from './devices-active.component';
export * from './devices-list.class';

export { devicesRoutes } from './devices.routes';

export * from './devices.module';
export * from './devices.module-lazy-load';