export class MapElementLegend
{
    private static readonly divMapLegend:     string = "mapKey";
    private static readonly divMapLegendCanv: string = "mapKeyCanv";

    private static readonly canvasHeight:     number = 365;
    private static readonly canvasWidth:      number = 140;
    private static          mapLegendElems:   any    = {};      


    constructor() { }


    public static get(legend: any): any
    {
        if (legend) {
            legend.mapLegendElems        = {}; 

            legend.innerHTML             += '<canvas id="' + this.divMapLegendCanv + '" width="' + this.canvasWidth + '" height="' + this.canvasHeight + '"></canvas>'
            legend.style.color            = 'rgba(255, 255, 255, 1.0)';
            legend.style.backgroundColor  = 'rgba(0,   0,   0,   0.70)';

            legend.getCanvas              = function(): any {
                return document.getElementById(this.divMapLegendCanv);
            };

            legend.getContext             = function(): any | undefined {
                const canvas: any = this.getCanvas();
                if (canvas) {
                    const context: any = canvas.getContext('2d');
                    if (context) {
                        context.clear = context.clear || this.clearCanvas;
                        context.font  = context.font  || "1-px Arial";

                        context.clear();

                        canvas.height = 0;
                        //canvas.width  = 0;

                        return context;
                    }
                }

                return undefined;
            };

            legend.add                    = function(layerName: string, legendElem: any): any | undefined
            {
                if (layerName && legendElem) {
                    this.mapLegendElems[layerName] = legendElem;

                    return this.update();
                }

                return undefined;
            };

            legend.clearCanvas            = function(preserveTransform: boolean): void {
                if (preserveTransform) {
                    this.save();
                    this.setTransform(1, 0, 0, 1, 0, 0);
                }

                this.clearRect(0, 0, this.canvas.width, this.canvas.height);

                if (preserveTransform) this.restore();
            };

            legend.delete                 = function(layerName: string): any | undefined
            {
                if (layerName) {
                    delete this.mapLegendElems[layerName];

                    return this.update();
                }

                return undefined;
            };

            legend.update                 = function(): void
            {
                let xPos          = 0;
                let xPosMargin    = 10;
                let xPosCol2Start = 80;
                let xPosCol2End   = xPosCol2Start + 30;
                let yPos          = 0;
                let yPosLineGap   = 10;

                let canvasTemp: any    = document.createElement('canvas'); // temp canvas used as changing height blanks main one
                canvasTemp.height = window.innerHeight;
                canvasTemp.width  = this.getCanvas() ? this.getCanvas().width : this.canvasWidth;
                let contextTemp: any   = canvasTemp.getContext('2d');

                if (contextTemp) {
                    let context = this.getContext();
                    if (context) {
                        if (Object.keys(this.mapLegendElems).length > 0) {
                            Object.keys(this.mapLegendElems).sort().forEach((key: any): any => {
                                contextTemp.beginPath();
                                contextTemp.fillStyle = 'white';
                                contextTemp.fillText(key + " ", xPos, yPos += yPosLineGap);
                                if (this.mapLegendElems[key]) {
                                    Object.keys(this.mapLegendElems[key]).forEach((key2: any): any => {
                                        if (this.mapLegendElems[key][key2] && this.mapLegendElems[key][key2].colour) {
                                            contextTemp.beginPath();
                                            contextTemp.fillText(key2 + " ", xPosMargin, yPos += (2 * yPosLineGap));
                                            contextTemp.moveTo(xPos + xPosCol2Start, yPos);
                                            contextTemp.lineTo(xPos + xPosCol2End, yPos);
                                            contextTemp.strokeStyle = this.mapLegendElems[key][key2].colour;
                                            contextTemp.stroke();
                                        }
                                        else {
                                            contextTemp.fillText(key2 + ": " + this.mapLegendElems[key][key2], xPosMargin, yPos += (2 * yPosLineGap));
                                        }
                                    }); // mapLegendElems[key2]

                                    contextTemp.beginPath();
                                    contextTemp.fillText("", xPos, yPos += yPosLineGap);
                                    contextTemp.fillText("", xPos, yPos += yPosLineGap);
                                }
                            }); // mapLegendElements

                            this.getCanvas().height = (yPos -= yPosLineGap);
                            context.drawImage(canvasTemp, 0, 0); // copy temp canvas to this context
                        }
                        else {
                            context.clear();
                        }
                    } // if context
                } // if contextTemp
            };

            if (legend.getCanvas()) {
                legend.getCanvas().height = this.canvasHeight;
                legend.getCanvas().width  = this.canvasWidth;
            }

            legend.update();
        } // if legend

        return legend;
    }


    // private getMapLegend2(): any
    // {
    //     // Add legend to map
    //     const legend: any = document.getElementById('mapKey');
    //     if (legend) {
    //         const colours: any = {
    //             good:    '#990000',
    //             average: '#ff0000',
    //             poor:    '#ffcccc'
    //         };

    //         //legend.innerHTML              = '<h4 style="text-align: center;">Key</h4>';
    //         legend.innerHTML             += '<canvas id="canvId" width="100" height=165"></canvas>'
    //         legend.style.backgroundColor  = 'rgba(158, 167, 184, 1.0)';
    //         //legend.appendChild(div);


    //         const canv: any = this.Document.getElementById('canvId');
    //         const context: any = canv.getContext("2d");
    //         context.font = "10px Arial";

    //         context.beginPath();
    //         context.fillText("Technology ", 0, 10);

    //         context.beginPath();
    //         context.fillText("LTE ", 10, 30);
    //         context.moveTo(70, 30);
    //         context.lineTo(100, 30);
    //         context.strokeStyle = this.MapLayerUtilsService.getTechnologyColour('lte');
    //         context.stroke();

    //         context.beginPath();
    //         context.fillText("UMTS ", 10, 50);
    //         context.moveTo(70, 50);
    //         context.lineTo(100, 50);
    //         context.strokeStyle = this.MapLayerUtilsService.getTechnologyColour('umts');
    //         context.stroke();

    //         context.beginPath();
    //         context.fillText("GSM ", 10, 70);
    //         context.moveTo(70, 70);
    //         context.lineTo(100, 70);
    //         context.strokeStyle = this.MapLayerUtilsService.getTechnologyColour('gsm');
    //         context.stroke();


    //         context.beginPath();
    //         context.fillText("Signal Strength ", 0, 100);

    //         context.beginPath();
    //         context.fillText("Good ", 10, 120);
    //         context.moveTo(70, 120);
    //         context.lineTo(100, 120);
    //         context.strokeStyle = this.MapLayerUtilsService.getSignalStrengthColour2('good');
    //         context.stroke();

    //         context.beginPath();
    //         context.fillText("Average ", 10, 140);
    //         context.moveTo(70, 140);
    //         context.lineTo(100, 140);
    //         context.strokeStyle = this.MapLayerUtilsService.getSignalStrengthColour2('average');
    //         context.stroke();

    //         context.beginPath();
    //         context.fillText("Poor ", 10, 160);
    //         context.moveTo(70, 160);
    //         context.lineTo(100, 160);
    //         context.strokeStyle = this.MapLayerUtilsService.getSignalStrengthColour2('poor');
    //         context.stroke();
    //     }
    //     else {
    //         console.log("Unable to set up map legend");
    //     }

    //     return legend;
    // }
}