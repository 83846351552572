import { MapElementLatLng } from './map-element-latlng/';


export class MapElementCircle extends google.maps.Circle
{
    // Override
    public override setCenter(pos: MapElementLatLng) 
    {
        super.setCenter(pos)

        if (pos) this.setRadius(
            (pos.accuracy == null || pos.accuracy > MapElementLatLng.locationAccMax)
            ? MapElementLatLng.locationAccMax
            : pos.accuracy);
    }
}