import { Component }     from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
   selector:     'qp-device-chart',
   templateUrl:  'device-chart.component.html',
   styleUrls:   ['device-chart.component.css']
})
export class DeviceChartComponent extends BaseComponent
{
	
}