import { Injectable }     from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    Subscription
}                         from 'rxjs';

import { BaseService }    from '@Base/';
import { MessageService } from '@Messaging/';
import { Site }           from '@ObjElements/';

// import { SiteModule }     from './site.module';


// [TBD] notify if device updated

@Injectable({
    providedIn: 'root'//SiteModule // Don't make singleton 
})
export class SiteService extends BaseService
{
    private readonly _dataOrig: Site                  = new Site();
    private          _data:     Site                  = this._dataOrig;
    private readonly _devices:  any[]                 = [];

    private          _id:       number;
    private          _updated$: BehaviorSubject<Site> = new BehaviorSubject<Site>(this.site);
 
 
    public constructor(private readonly MessageService: MessageService)
    {
        super();
    }


    // Override
    public override ngOnDestroy(): void
    {
        super.ngOnDestroy();

        console.debug("Destroying Site service");
    }
    

    // Getters and setters
    public get site(): Site
    {
        return this._data;
    }

    public set site(s: Site)
    {
        this._data = s;
    }

    private get siteOrig(): Site
    {
        return this._dataOrig;
    }


    public get id(): number
    {
        return this._id;
    }

    public set id(i: number)
    {
        this.refresh(this._id = i);
    }


    public get updated$(): Observable<Site>
    {
        return this._updated$.asObservable();
    }


    //
    // Public functions
    //
    public refresh(id: number): Subscription | undefined
    {
        this.site = this.siteOrig;

        // Use observable
        const obs: Observable<any> | undefined = this.MessageService.sendMsgGet(
            this.MessageService.messages.msgTypesInfo.sites, { id: id ? id : this.id }
        );
        
        return obs instanceof Observable
            ? (this.sub = obs.subscribe((data: any): void => {
                if (data) this.update(data.data && data.data[0]
                    ? data.data[0]
                    : (data[0] ? data[0] : data));
                }) // update()
              )
            : undefined;
    }


    public update(data: any): boolean
    {
        if (data) {
            console.debug("Updating Site service with data");
console.debug(data);
            const s2: Site | undefined = Site.get(data);
            if (s2 instanceof Site) {
                this.site = s2;
console.debug(this.site);
                if (this._updated$ instanceof BehaviorSubject) this._updated$.next(this.site);

                return true;
            }
        }
        else {
            console.debug("No data received to update Site service");
        }

        return false;
    }


    //
    // Protected functions
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Site service");
    }
}