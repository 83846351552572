import {
    Component,
    Input
}                                    from '@angular/core';
import { Observable }                from 'rxjs';

import { 
    ClientConfigService,
    DisplayModeService
}                                    from '@Misc/Services/';

import { HeaderFooterBaseComponent } from './header-footer-base.component';
import {
    HeaderDisplayOptions,
    HeaderService
}                                    from './header.service'


@Component({
    selector:     'qp-header',
    templateUrl:  'header.component.html',
    styleUrls:   ['header.component.css']
})
export class HeaderComponent extends HeaderFooterBaseComponent
{
    // This needs to be here to allow require() to work; doesn't appear to work within templates
    private static readonly imgSrcLargeLight: any     = '../../assets/images/organisation/sfn_full_nontrans.png';
    private static readonly imgSrcSmallLight: any     = '../../assets/images/organisation/sfn.png';
    private static readonly imgSrcLargeDark:  any     = '../../assets/images/organisation/sfn_full.png';
    private static readonly imgSrcSmallDark:  any     = '../../assets/images/organisation/sfn.png';


    @Input()
    public about:           boolean = false; // passed in from parent HTML

    @Input()
    public all:             boolean = false; // passed in from parent HTML

    @Input()
    public devices:         boolean = false; // passed in from parent HTML

    @Input()
    public deviceGroups:    boolean = false; // passed in from parent HTML

    @Input()
    public displayMode:     boolean = false; // passed in from parent HTML

    @Input()
    public notifications:   boolean = false; // passed in from parent HTML

    @Input()
    public settings:        boolean = false; // passed in from parent HTML

    @Input()
    public user:            boolean = false; // passed in from parent HTML


    public imgSrcLarge:     any;
    public imgSrcSmall:     any;


    constructor(                 ClientConfigService: ClientConfigService,
                private readonly DisplayModeService:  DisplayModeService,
                private readonly HeaderService:       HeaderService)
    {
        super(ClientConfigService);

        if (this.HeaderService) {
            const obs: Observable<HeaderDisplayOptions> | undefined = this.HeaderService.displayOptions;
            if (obs instanceof Observable) this.sub = obs
                .subscribe((d: HeaderDisplayOptions): void => {
                    if (d) {
                        this.about         = d.about;
                        this.all           = d.all;
                        this.devices       = d.devices;
                        this.deviceGroups  = d.deviceGroups;
                        this.displayMode   = d.displayMode;
                        this.notifications = d.notifications;
                        this.settings      = d.settings;
                        this.user          = d.user;
                    }
                }); // subscribe
        }

        if (this.DisplayModeService && Object(this.DisplayModeService).mode$ instanceof Observable) {
            const obs: Observable<boolean> | undefined = this.DisplayModeService.mode$;
            if (obs instanceof Observable) this.sub = obs
                .subscribe((darkMode: boolean): void => {
                    if (darkMode) {
                        this.imgSrcLarge = HeaderComponent.imgSrcLargeDark;
                        this.imgSrcSmall = HeaderComponent.imgSrcSmallDark;
                    }
                    else {
                        this.imgSrcLarge = HeaderComponent.imgSrcLargeLight;
                        this.imgSrcSmall = HeaderComponent.imgSrcSmallLight;
                    }
                }); // subscribe
        }
    }


    //
    // Protected functions
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Header component");
    }
}