import { Injectable }            from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscriber,

    take
}                                from 'rxjs';

import { BaseService }           from '@Base/';
import { 
    Device,
    DeviceCollectionService
}                                from '@Devices/';
import { IconsService }          from '@Icons/';
import { KpisChartService }      from '@Kpis/';
import { MessageService }        from '@Messaging/';
import { 
    DataServiceEvents,
    DataServiceI
}                                from '@Misc/Services/';
import {
    DeviceCommon as DeviceC,
    ElementHelper,
    MobileCell,
    Organisation,
    Site,
    SurveyPoint
}                                from '@ObjElements/';
import { OrganisationsService }  from '@Organisations/';

import { MapService }            from '../map.service';
import { MapLayer }              from '../map-layers/map-layer/map-layer.class';
import { MapLayerType }          from '../map-layers/map-layer/map-layer-type.class';
import { MapLayerCellsService }  from '../map-layers/map-layer/map-layer-cells.service';
import { MapLayerHelper }        from '../map-layers/map-layer/map-layer-helper.class';
import { MapLayerRoutesService } from '../map-layers/map-layer/map-layer-routes.service';
import { MapLayerServices }      from '../map-layers/map-layer/map-layer-services.service';
import { MapLayerSitesService }  from '../map-layers/map-layer/map-layer-sites.service';
import { MapLayerTestsService }  from '../map-layers/map-layer/map-layer-tests.service';
// import { MapLayersModule }       from './map-layers.module';


export interface MapLayerHighlightData {
    type: string
    id:   string
}; // MapLayerHighlightData


@Injectable({
    providedIn: 'root' //MapLayersModule
})
export class MapLayersService extends BaseService
{
    protected readonly _dataElements$:      BehaviorSubject<MapLayer[]>;
    protected readonly _elementVisible$:    Subject<string>;
    protected readonly _elementNotVisible$: Subject<string>;

    private   readonly _mapLayers                                             = new Map();
    private            _configured:         boolean                           = false;
    private            _highlightData:      MapLayerHighlightData | undefined;


    constructor(private readonly DeviceCollectionService: DeviceCollectionService,
                private readonly IconsService:            IconsService,
                private readonly KpisChartService:        KpisChartService,
                private readonly MapService:              MapService,
                private readonly MapLayerCellsService:    MapLayerCellsService,
                private readonly MapLayerRoutesService:   MapLayerRoutesService,
                //private readonly MapLayerServicesService: MapLayerServicesService,
                private readonly MapLayerSitesService:    MapLayerSitesService,
                private readonly MapLayerTestsService:    MapLayerTestsService,
                private readonly MessageService:          MessageService,
                private readonly OrganisationsService:    OrganisationsService)
    {
        super();

        this._dataElements$      = new BehaviorSubject<MapLayer[]>(this.layers);
        this._elementVisible$    = new Subject<string>();
        this._elementNotVisible$ = new Subject<string>();
    }


    //
    // Getters
    //
    public get [DataServiceEvents.data](): Observable<MapLayer[]> | undefined
    {
        return (this._dataElements$ instanceof Subject) ? this._dataElements$.asObservable() : undefined;
    }


    public get elementNotVisible(): Observable<string> | undefined
    {
        return (this._elementNotVisible$ instanceof Subject) ? this._elementNotVisible$.asObservable() : undefined;
    }


    public get elementVisible(): Observable<string> | undefined
    {
        return (this._elementVisible$ instanceof Subject) ? this._elementVisible$.asObservable() : undefined;
    }


    private get layers(): MapLayer[]
    {
        return (this.mapLayers instanceof Map)
            ? [...this.mapLayers.values()]
            : [];
    }


    public get layersEnabled(): boolean
    {
        return this.layers.some((l: MapLayer): boolean => {
            return l instanceof MapLayer && this.getLayerState(l);
        }); // some
    }


    public get mapLayers(): Map<any, any>
    {
        return this._mapLayers;
    }


    public set data2(d: any[] | undefined)
    {
        // Support device and surveypoint at this time

        // [TBD] Need to keep track of manually create layer(s)
        let type;

        console.warn("S1")
        if (Array.isArray(d)) {
            console.warn("S2")
            console.warn(d)
            // Get first element and find out type
            const obj = d[0];
            if      (obj instanceof DeviceC)     type = MapLayerType.devices?.key;
            else if (obj instanceof SurveyPoint
                 || obj instanceof MobileCell)  type = MapLayerType.survey?.key;
            if (type) {
                console.warn("S3")
                let l: MapLayer | undefined = this.getLayer(type);
                if (! (l instanceof MapLayer)) l = this.createLayer(type, undefined); // create layer
                if (l instanceof MapLayer) {
                    console.warn("S4")
                    console.warn(l)
                    console.warn(d)
                    l.data = d;
                    console.warn(d)
                    console.warn("S4a")
                    this.enableLayer(l, true, true);
                    console.warn("S4b");
                    console.warn(l)
                }
            }
            else {
                console.error("Invalid map layer type given: " + type);
                console.debug(obj);
            }
        }
        else if (! d) {
            console.warn("S5")
            this.clearLayer(type, true); // delete all map layers
        }
    }


    //
    // Public methods
    //
    
    // Override
    public override ngOnDestroy(): void
    {
        super.ngOnDestroy();

        console.debug("Destroying MapLayers service");
    }

    
    // Override
    public override cleanUp(): void
    {
        console.debug("Cleaning up MapLayers service");

        super.cleanUp();
        
        this._dataElements$.complete();
        this._elementVisible$.complete();
        this._elementNotVisible$.complete();

        this.clearMapLayers(this.layers, true); // delete all map layers
        this._configured = false; // essential
    }


    // Called by MapLayersComponent, triggered by ngAfterViewInit() lifecycle hook.  If called
    // within service as part of initialise() (called by constructor), MapService and
    // DeviceCollectionService not guaranteed to be available
    public configure(): void
    {
        if (! this._configured) { // only do if not already done once
            console.debug("Configuring MapLayers service");

            // Listen for devices
            if (this.DeviceCollectionService) {
                // Need to get status first, as device types may already exist
                let obs: Observable<any> | undefined = this.DeviceCollectionService[DataServiceEvents.status];
                if (obs instanceof Observable) this.sub = obs
                    .pipe(
                        take(1)
                    ) // just get once
                    .subscribe((type: string[] | object): void => {
                        // Convert to array if necessary
                        const dTypes: string[] = type ? (Array.isArray(type) ? type : Object.keys(type)) : [];
                        if (Array.isArray(dTypes)) dTypes.forEach((t: string): void => {
                            if (t) {
                                const l: MapLayer | undefined = this.getLayer(t);
                                //if (l) l.refresh(); // refresh existing layer, don't enable here
                                if (! (l instanceof MapLayer)) this.createLayer(MapLayerType.liveDevices?.key, this.DeviceCollectionService, t, t); // create layer
                            }
                        }); // forEach
                    }); // subscribe

                // Listen for new device type
                obs = this.DeviceCollectionService[DataServiceEvents.added];
                if (obs instanceof Observable) this.sub = obs
                    .subscribe((type: string): void => {
                        if (type) {
                            if (! this.getLayer(type)) {
                                console.debug("Creating layer: " + type);
                                this.createLayer(MapLayerType.liveDevices?.key, this.DeviceCollectionService, type, type);
                            }
                            else {
                                console.debug("Layer already present: " + type);
                            }

                            // if (this._highlightData) {
                            //     console.info("Now highlighting (1): " + this._highlightData.type + ", " + this._highlightData.id);
                            //     this.highlight(this._highlightData);
                            // }
                        }
                    }); // subscribe

                // Listen for device type removal
                obs = this.DeviceCollectionService[DataServiceEvents.deleted];
                if (obs instanceof Observable) this.sub = obs
                    .subscribe((type: string): void => {
                        console.debug("Deleting layer: " + type);
                        const l: MapLayer | undefined = this.getLayer(type);
                        if (l instanceof MapLayer) this.clearLayer(l, true);
                    }); // subscribe
            }


            // Listen for organisations
            if (this.OrganisationsService) {
                const obs: Observable<any> | undefined = this.OrganisationsService[DataServiceEvents.status];
                if (obs instanceof Observable) this.sub = obs
                    .subscribe((d: any): void => {
                        if (d && Object.keys(d).length !== 0) {
                            const l: MapLayer | undefined = this.getLayer(MapLayerType.organisations?.key);
                            if (! (l instanceof MapLayer)) this.createLayer(
                                MapLayerType.organisations?.key,
                                this.OrganisationsService,
                                MapLayerType.organisations?.key,
                                MapLayerType.organisations?.key
                            ); // createLayer
                        }
                    }); // subscribe
            }
        

            // Listen for map initialisation
            if (this.MapService) this.sub = this.MapService.initialised
                .subscribe((available: boolean) : void => {
                    if (available) {
                        console.debug("MapService available; enable all layers");
                        this.enableDefaultLayers();    
                    }
                    else {
                        console.debug("MapService unavailable; disable all layers");
                        this.disableLayers();
                    }
                }); // subscribe

            this._configured = true;
            console.debug("MapLayers service configured");
        } // if _configured
    }



    public highlight(d: MapLayerHighlightData): boolean // used to centre on element and open infoWin
    {
        let ret: boolean = false;

        if (d && d.type && d.id) {
            console.info("Map highlight: " + d.type + ": " + d.id);
            switch (d.type) {
                case Device.tag:
                    console.info(d.id);
                    this.sub = this.findLayer(d.id)
                        .pipe(take(1))
                        .subscribe((l: MapLayer): void => {
                            this.highlightLayer(l, d.id);        
                        }); // subscribe
                    ret = true;
                break;

                case ElementHelper.getTag(new Organisation()):
                    ret = this.highlightLayer(this.getLayer(MapLayerType.organisations?.key), d.id);
                break;

                case ElementHelper.getTag(new Site()):
                    ret = this.highlightLayer(this.getLayer(MapLayerType.sites?.key), d.id);
                break;
            } // switch

            if (ret) this._highlightData = undefined;
            else {
                // If here, likely that this has been triggered by a direct URL and therefore
                // the required data may not yet have loaded.  Store and re-check on next data load
                console.info("Unable to highlight: " + d.type + ": " + d.id + ", storing for later");

                this._highlightData = d;
            }
        }

        return ret;

        // if (type) {
        //     switch (type) {
        //         case 'cell':
        //             // let layerName = 'mobileCellReports';
        //             // let layer     = this.getLayer(layerName);
        //             // this.setLayer(layerName, this.MapLayerCellsService.mapLayerCellsFactory(this.MapService.map, data));
        //             // this.setLayerEnabled(layerName);
        //             break;

        //         case 'device':
        //             const layerName: string = this.mapLayers.liveDevices.name;
        //             const layer: any        = this.getLayer(layerName);
        //             if (layer && layer.highlight) {
        //                 this.setLayerEnabled(layerName);
        //                 return layer.highlight(data);
        //             }
        //             break;

        //         default:
        //     } // switch
        // }
    }


    public getLayerData(layerName: string): any
    {
        const layer: any = this.getLayer(layerName)
        return layer ? layer.data : undefined;
    }


    public getLayerState(l: string | MapLayer): boolean
    {
        if (l) {
            const ln: MapLayer | undefined = typeof l === 'string' ? this.getLayer(l) : l;
            return !!(ln instanceof MapLayer && ln.map);
        }

        return false;
    }

    
    public processCells(type: any, data: any): void
    {
        if (this.processData(
                 this.MapLayerCellsService.mapLayerCellsFactory(this.MapService.map, data), data, type)) {
            this.enableLayer(this.mapLayers.get(MapLayerType.mobileCellReportsH).name);
        }
        // let layerName = this.mapLayers.mobileCellReports.name;
        // this.setLayer(layerName, this.MapLayerCellsService.mapLayerCellsFactory(this.MapService.map, data));
        // this.enableLayer(layerName);
    }


    public processDeviceLocations(type: any, data: any): void
    {
        if (this.processData(
                this.MapLayerRoutesService.mapLayerRoutesFactory(this.MapService.map, type, data), data, type)) {
            // Set campaign (i.e. start/end) and geoRoute layers enabled by default
            for (let s of [
                this.mapLayers.get(MapLayerType.campaign).name,
                this.mapLayers.get(MapLayerType.geoRoute).name,
                this.mapLayers.get(MapLayerType.serviceResults).name]) {
                this.enableLayer(s);
                // this.setLayerEnabled(this.mapLayers.campaign.name);
                // this.setLayerEnabled(this.mapLayers.geoRoute.name);
                // this.setLayerEnabled(this.mapLayers.serviceResults.name);
            } // for
        }
    }


    public refresh(): void
    {
        this.clearMapLayers(this.layers, false);
        this.enableDefaultLayers();
    }


    public setLayerMap(l: MapLayer, state: boolean, inform: boolean = true): MapLayer | undefined
    {
        const ret: MapLayer | undefined =
             !!(l instanceof MapLayer && (l.map = (state ? this.MapService.map : undefined)) )
                ? l
                : undefined;

        if (inform && this._dataElements$ instanceof Observable) this._dataElements$.next(this.layers);

        return ret;
    }


    public setMapPointVisibility(id: string, show: boolean): void
    {
        // Need to inform layers - send out notification
        if      (show && this._elementVisible$ instanceof Observable) this._elementVisible$.next(id);
        else if (this._elementNotVisible$ instanceof Observable)      this._elementNotVisible$.next(id);
    }

  
    public showGraph(layerName: string): any // <-- [TBD] return type
    {
        const layer: any = this.getLayer(layerName);
        return ((layer && layer.data)
            ? this.showChart(undefined, this.KpisChartService.getKpiChart('ratTypeOverTime', undefined, layer.data))
            : undefined
        );
    }


    public toggleLayersAll(): void
    {
        const allLayers: boolean = this.layersEnabled;
        this.layers.forEach((l: MapLayer) => {
            if (l instanceof MapLayer && ! allLayers) this.enableLayer(l, true, true)
            else                                      this.disableLayer(l);
        }); // forEach
        // for (let i in this.MapLayersService.mapLayers) {
        //     if (i) {
        //         ! this.allLayers()
        //             ? this.MapLayersService.enableLayer((<any>i).name)
        //             : this.MapLayersService.disableLayer((<any>i).name);
        //     }
        // } // for
    }


    public update(type: string, data: any): void
    {
        if (! type || ! data) {
            console.info("Invalid message received");
            return;
        }

        let layer: any;
        let newLayers: any;
        switch (type) {
            // case 'phoneAdded':
            // case 'phoneDeleted':
            // case 'phoneUpdated':
            // case 'phonesPopulated':
            //     layer = this.getLayer(this.mapLayers.liveDevices.name);
            //     if (layer) layer.update(type, data);
            //     break;

            case 'cell':
                //this.MapLayerCellsService.update(this.MapService.map, type, data);
                //if (! this.mapLayers.liveCells.layer) {
                //    this.setLayer('liveCells', this.MapLayerCellsService.layer);
                //}
                break;

            case this.MessageService.messages.msgTypesSub.mobilecellsightings:
            case this.MessageService.messages.msgTypesSub.wifiapsightings:
                this.processCells(type, data);
                break;

            // case this.MessageService.messages.msgTypesSub.devicelocations:
            //     this.processDeviceLocations(type, data);
            //     break;

            case this.MessageService.messages.msgTypesSub.testresults:
                this.storeLayer(
                    this.mapLayers.get(MapLayerType.testResults).name,
                    this.MapLayerTestsService.mapLayerTestsFactory(this.MapService.map, data)
                );
                this.enableLayer(this.mapLayers.get(MapLayerType.testResults).name);
                // [TBD]
                break;

            case 'servicesoneshot':
            case 'servicesperiodic':
                //setLayer(mapLayers.serviceResults.name,
                //         MapLayerServicesService.mapLayerServicesFactory(qp_MapFactory.getMap(), data));
                //enableLayer(mapLayers.serviceResults.name);
                break;

            default:
                // Also handles pendingDelete
                // layer = this.getLayer(this.mapLayers.liveDevices.name);
                // if (layer) layer.update(type, data);
                break;
        } //switch
    }


    //
    // Protected methods
    //

    // Override
    protected override initialise(): void
    {
        console.debug("Initialising MapLayers service");

        super.initialise();

        console.debug("MapLayers service initialised");
    }


    //
    // Private methods
    //
    public clearLayer(ln: string | MapLayer | undefined, doDelete: boolean = true): void
    {
        const l: MapLayer | undefined = this.getLayer(ln);
        if (l instanceof MapLayer) {
            this.disableLayer(l, false);

            if (! doDelete) {
                l.refresh();
            }
            else {
                l.cleanUp();
                this.mapLayers.delete("" + l.name);
            }

            if (this._dataElements$ instanceof Observable) this._dataElements$.next(this.layers); // inform new layer
        }
    }


    private clearMapLayers(layers: object[] = this.layers, doDelete = true): void
    {
        if (Array.isArray(layers)) this.layers.forEach((l: MapLayer): void => {
            if (l instanceof MapLayer) this.clearLayer(l, doDelete);
        }); // forEach
    }


    public createLayer(type: string | MapLayerType | undefined,
                       dSrv: DataServiceI | undefined,
                       name?: string,
                       displayName?: string): MapLayer | undefined
    {
        // const type2: MapLayerType = MapLayerType.get(type); // will accept both string and MapLayerType
        console.debug("Creating " + type + " layer: " + name + ", " + displayName);

        const l: MapLayer | undefined = MapLayerHelper.get(
            MapLayerType.get(type),
            this.MapService,
            this,
            dSrv,
            this.IconsService,
            name,
            displayName
        ); // MapLayerHelper.get

        if (l instanceof MapLayer) {
            this.storeLayer(l); // do this first
            this.enableLayer(l, true, !!this._highlightData); // force if highlighting data set

            // Attempt to highlight element
            if (this._highlightData) {
                console.debug("Now highlighting: " + this._highlightData.type + ", " + this._highlightData.id);
                this.highlight(this._highlightData);
            }
        }
        else {
            console.warn("Unable to create " + type + " layer: " + name + ", " + displayName);
        }

        return l;
    }


    private disableLayer(l: MapLayer, inform: boolean = true): MapLayer | undefined
    {
        return this.setLayerMap(l, false, inform);
    }


    private disableLayers(ls: MapLayer[] = this.layers): void
    {
        if (Array.isArray(ls)) ls.forEach((l: MapLayer): void => {
            this.disableLayer(l);
        }); // forEach
    }


    private enableDefaultLayers(): void
    {
        this.enableLayers(this.getDefaultLayers());
    }


    // Will only enable if a default layer, unless 'force' is set
    private enableLayer(l: MapLayer, inform: boolean = true, force: boolean = false): MapLayer | undefined
    {
        return ((force ||
                (l instanceof MapLayer
                    && this.getDefaultLayers().find((d: MapLayer): boolean => d.name === l.name)) )
            ? this.setLayerMap(l, true, inform)
            : undefined
        );
    }


    private enableLayers(ls: MapLayer[] = this.layers): void
    {
        if (Array.isArray(ls)) ls.forEach((l: MapLayer): void => {
        // if (Array.isArray(ls)) for(const l of ls) {
            this.enableLayer(l, true, true);
        }); // forEach
        // } // for
    }


    private findLayer(id: string): Observable<MapLayer>
    {
        // Look up deviceType from DeviceCollectionService
        return new Observable((sub: Subscriber<any>): any => {
            if (this.DeviceCollectionService) {
                this.sub = this.DeviceCollectionService.getTypeOfDevice(id)
                .pipe(
                    take(1)
                )
                .subscribe((t: string): void => {
                    if (sub instanceof Subscriber) {
                        sub.next(t ? this.getLayer(t) : undefined);
                        sub.complete();
                    }
                }); // subscribe
            }
            else {
                if (sub instanceof Subscriber) {
                    sub.next(undefined);
                    sub.complete();
                }
            }
        }); // Observable
    }


    private getDefaultLayers(): MapLayer[]
    {
        return this.layers.filter((l: MapLayer): boolean => {
            return (l instanceof MapLayer
                && !!l.type
                && MapLayerType.defaults.includes(l.type.key));
        }); // filter
    }


    public getLayer(ln: string | MapLayer | undefined): MapLayer | undefined
    {
        return ((ln instanceof MapLayer)
            ? ln
            : ((ln && this.mapLayers.get(ln) instanceof MapLayer)
                ? this.mapLayers.get(ln)
                : undefined
            )
        );
    }


    private highlightLayer(ln: string | MapLayer | undefined, id: string): boolean
    {
        const l: MapLayer | undefined = this.getLayer(ln);
        if (l instanceof MapLayer) {
            // Disable other layers
            this.disableLayers();
            // Enable just the appropriate layer
            this.enableLayer(l, true, true);
        }
        console.debug(l);
        return l ? l.highlight(id) : false;
    }


    private processData(layers: any, data: any, type?: any): boolean
    {
        this.clearMapLayers();

        return layers && Object.keys(layers).every((ln): boolean => {
            return !!this.storeLayer(layers[ln]);
        }); // every
    }


    private storeLayer(l: MapLayer, ln?: string): MapLayer | undefined
    {            
        if (l instanceof MapLayer) {
            // Remove existing layer
            this.clearLayer("" + (ln = ln ? ln : l.name));
            // const l2: MapLayer | undefined = this.getLayer(ln = ln ? ln : l.name);
            // if (l2 instanceof MapLayer) l2.cleanUp();

            // Add new layer
            if (ln) {
                this.mapLayers.set(ln, l);
                if (this._dataElements$ instanceof Observable) this._dataElements$.next(this.layers); // inform new layer
                return this.mapLayers.get(ln);
            }
        }

        return undefined;
    }


    private showChart($event: any, chart: any)
    {
        const div = "chart-div2";

        // if (chart) {
        //     //AmCharts.ready(function() {
        //         $mdDialog.show({
        //             clickOutsideToClose: true,
        //             escapeToClose:       true,
        //             //title:               'Chart',
        //             // template:
        //             //     '<md-dialog id="chart2">' +
        //             //     '    <md-dialog-content>' +
        //             //     '        <div align="center" id="' + div + '" class="chart-min" style="height:100%;width:100%;overflow:auto;text-align:center;vertical-align:middle;"></div>' +
        //             //     '    </md-dialog-content>' +
        //             //     '    <md-dialog-actions>' +
        //             //     '        <md-button data-ng-click="closeDialog()" class="md-raised md-mini md-primary">' +
        //             //     '            Close' +
        //             //     '        </md-button>' +
        //             //     '    </md-dialog-actions>' + 
        //             //     '</md-dialog>',
        //             templateUrl:         '../fragments/window-rat-chart.html',
        //             controller:          'qp_MapLayersChartController',
        //             controllerAs:        'qp_MapLayersChartController',
        //             // controller: [ 'chart', function(chart) {
        //             //     console.info("h1");
        //             //     console.info(chart);
        //             //     if (chart) {
        //             //         console.info("h2");
        //             //         chart.write('chart-div2');
        //             //         console.info(chart);
        //             //     }
        //             // }],
        //             locals:              {chart: chart, chartDiv: div},
        //             ok:                  'Close',
        //             targetEvent:         $event,
        //             onShowing:           chartShowing,
        //             onComplete:          chartShowed,
        //             ariaLabel:           'Chart',
        //             //preserveScope: true,
        //             parent:              angular.element(document.getElementById('map'))
        //         })
        //         .then(
        //             (res) => {
        //                 // Finished
        //             },
        //             () => {
        //                 // Error
        //             }
        //         )
        //         .finally(() => {
        //             // Clean-up
        //         }); //$mdDialog.show()
        //     //}); // AmCharts.ready()
        // } // if

        // function chartShowing()
        // {
        //     //console.info("Displaying chart");
        // }


        // // Function defined within outer function to allow access to 'chart' and 'div' objects
        // function chartShowed()
        // {
        //     if (chart) chart.write(div);
        // }
    }
}
        

    // function qp_MapLayersChartController($scope, $mdDialog, chart, chartDiv)
    // {
    //     $scope.chartDiv = chartDiv;

    //     $scope.closeDialog = () => {
    //         return $mdDialog.hide();
    //     };
    // }
