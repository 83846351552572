import { CommonModule }  from '@angular/common';
import { NgModule }      from '@angular/core';
import { RouterModule }  from '@angular/router';

import { DevicesModule } from './devices.module';

import { devicesRoutes } from './devices.routes';


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(devicesRoutes), // lazy loading

        DevicesModule
    ],
    
    declarations: [
    ],

    providers: [
    ],

    exports: [
    ]
})
export class DevicesModuleLazyLoad { }