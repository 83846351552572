import { NgModule }               from '@angular/core';
import { Routes }                 from '@angular/router';

import { AuthenticationGuard }    from '@Login/';
import {
    sitesRoutes,
    SitesContainerComponent
}                                 from '@Sites/';

import { OrganisationComponent }  from './organisation/';
import { OrganisationsComponent } from './organisations.component';

import { routeNames }             from '../app.routing.names';


// Need A2 component (downgraded and on index.html so AJS knows what to do), which includes router-outlet

export const organisationsRoutes: Routes = [
    {
        path:        '',
        component:   OrganisationsComponent, // list
        data:        { animation: 'organisations' },
        canActivate: [ AuthenticationGuard ]
    },

    {
        path:        ':id',
        component:   OrganisationComponent,  // individual
        data:        { animation: 'organisation' },
        canActivate: [ AuthenticationGuard ]
    },

    {
        path:        routeNames.sites,
        component:   SitesContainerComponent,
        data:        { animation: 'sites' },
        canActivate: [ AuthenticationGuard ],
        children:    sitesRoutes
    }
]; // organisationsRoutes