import { CommonModule }                 from '@angular/common';
import { NgModule }                     from '@angular/core';

import { NgbModule }                    from '@ng-bootstrap/ng-bootstrap';

import { GuiElementsModule }            from '@GuiElements/';
import { MaterialCustomModule }         from '@Material/';
import { ObjElementsModule }            from '@ObjElements/';

import { MapInfoWinContainerComponent } from './map-infowin-container.component';


@NgModule({
    imports: [
        CommonModule,
        GuiElementsModule,
        MaterialCustomModule,
        NgbModule,
        
        ObjElementsModule
    ],
    
    declarations: [
        MapInfoWinContainerComponent
    ],

    providers: [
    ],

    exports: [
        MapInfoWinContainerComponent
    ]
})
export class MapInfoWinModule { }