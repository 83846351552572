import { CommonModule }           from '@angular/common';
import { NgModule }               from '@angular/core';
import { RouterModule }           from '@angular/router';

import { MapLayerControlsModule } from './map-layer-controls/map-layers-control.module';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        MapLayerControlsModule,
    ],
    
    declarations: [
    ],

    providers: [
    ],

    exports: [
        // MapLayerControlsModule
    ]
})
export class MapLayersModule { }