import { CommonModule }        from '@angular/common';
import { NgModule }            from '@angular/core';
import { RouterModule }        from '@angular/router';

import { organisationsRoutes } from './organisations.routes';

import { OrganisationsModule } from './organisations.module';


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(organisationsRoutes), // needed for lazy loading

        OrganisationsModule
    ],
    
    declarations: [
    ],

    providers: [
    ],

    exports: [
    ]
})
export class OrganisationsModuleLazyLoad { }