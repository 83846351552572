<qp-window-header
    [title]="name"
    [value]="data.name"
    [state]="state" 
    [statusEnabled]=true
    [geolocationEnabled]="!!(data && data.geolocation)"
    [routeBack]="['..']"

    (geolocation)="buttonShowOnMap()"
    (refresh)="refresh()">
</qp-window-header>

<!-- <a [routerLink]="['..']"><h4>Back to organisations list</h4></a> -->
<!-- <qp-route-back [route]="['..']"></qp-route-back> -->

    <!-- class="right" *ngIf="data && data.geolocation" (pressed)="buttonShowOnMap()"> -->
<!-- <qp-geolocation-button
    *ngIf="data && data.geolocation" (pressed)="buttonShowOnMap()">
</qp-geolocation-button> -->


<!-- <qp-organisation-info
    [data]=data>
</qp-organisation-info> -->
<qp-organisation-info
    [data]="data"
    [showName]="false">
</qp-organisation-info>

<div class="sites" *ngIf="data">
    <qp-sites
        *ngIf="data.sites.length > 0" [data]=data.sites [parentId]=+data.id>
    </qp-sites>
</div>