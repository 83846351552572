import {
    Component,
    Input
}                               from '@angular/core';
import {
    ActivatedRoute,
    Router 
}                               from '@angular/router';

import { ElementInfoComponent } from '../element/element/element-info.component';

import { DeviceGroup }          from './';
// [TBD]
import { SurveyPoint }          from '../survey/';

import { routeNames }           from '../../app.routing.names';


@Component({
    selector:     'qp-device-group-info',
    templateUrl:  'device-group-info.component.html',
    styleUrls:   ['device-group-info.component.css']
})
export class DeviceGroupInfoComponent extends ElementInfoComponent
{
    @Input()
    public showName: boolean = true;


    constructor(ActRoute: ActivatedRoute,
                Router:   Router)
    {
        super(ActRoute, Router);
    }


    //
    // Getters
    //
    public get d(): DeviceGroup
    {
        return this.data as DeviceGroup; // [TBD]
    }

    
    //
    // Public methods
    //
    public selectedFn(d2: any): Promise<boolean> | undefined
    {
 

this.select(d2);
return undefined;
        // onst ret: Promise<boolean> = this.Router.navigate(
        //     SurveysComponent.outlet
        //         ? [ {outlets: {[SurveysComponent.outlet]: [(row as Survey).id ]}} ]
        //         : [ (row as Survey).id ],
                
        //     { relativeTo: this.ActRoute.parent }
        // ) // navigate()


        return (d2 instanceof SurveyPoint && (d2 as SurveyPoint).id !== undefined)
            // Route to surveypoint
            ? this.Router.navigate(
                // [ this.d.id, routeNames.surveypoints, (d2 as SurveyPoint).id ],
                [ {outlets: {'primary': [this.d.id, routeNames.surveypoints, (d2 as SurveyPoint).id ]}} ],
                { 
                    relativeTo: this.ActRoute.parent,
                    // state: { parent: this.Router.url } 
                }
            ) // navigate()

            // Route to main page [TBD]
            : ((d2 instanceof DeviceGroup && (d2 as DeviceGroup).id !== undefined)
                ? this.Router.navigate(
                    [ routeNames.surveys, (d2 as DeviceGroup).id ],
                    { 
                        state: { parent: this.Router.url } 
                    }
                ) // navigate()
                
                : undefined
            );
    }
}