import { CommonModule }                    from '@angular/common';
import { NgModule }                        from '@angular/core';
import { NgxPaginationModule }             from 'ngx-pagination'; 
import { OrderModule }                     from 'ngx-order-pipe';
import { RouterModule }                    from '@angular/router';

import { GuiElementsModule }               from '@GuiElements/';
import { MaterialCustomModule }            from '@Material/';

import { NotificationModule }              from './notification/';

import { NotificationsComponent }          from './notifications.component';
import { NotificationsContainerComponent } from './notifications-container.component';
import { NotificationsOldComponent }       from './notifications-old.component';


@NgModule({
    imports: [
        CommonModule,
        NgxPaginationModule,
        OrderModule,
        RouterModule,

        GuiElementsModule,
        MaterialCustomModule,
        NotificationModule
    ],
    
    declarations: [
        NotificationsComponent,
        NotificationsContainerComponent,
        NotificationsOldComponent
    ],

    providers: [
    ],

    exports: [
        NotificationsComponent,
        NotificationsContainerComponent,
        NotificationsOldComponent
    ]
})
export class NotificationsModule { }