<div id="map-panel">
    <button mat-raised-button color="primary" (click)="buttonRefresh()" matTooltip="Refresh">
        <mat-icon aria-label="Refresh">{{ iconRefresh }}</mat-icon>
    </button>
<!-- 
    <qp-map-layers-control-container></qp-map-layers-control-container> -->

    <div ngbDropdown placement="top-left" autoClose='outside' class="d-inline-block" *ngIf="groupInstances && groupInstances.length">
        <button mat-raised-button color="primary" ngbDropdownToggle id="dropdown2" matTooltip="Select test campaign to analyse">
            <mat-icon aria-label="Select test campaign">{{ iconCampaign }}</mat-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdown2">
            <div *ngFor="let val of groupInstances">
                <button ngbDropdownItem (click)="groupInstanceSelected(val)" [ngClass]="{'ngb-active':val == groupInstance}">
                    {{ val.id + ": " + val.name + " " + val.startDate + " (" + val.comment + ", devices=" + val.devices.length + ")" }}
                        <!--<a href="">{{ val.id + " - Start: " + (val.startDate | localdatetime) + ", End: " + (val.endDate | localdatetime) + ", Num devices: " + (val.devices.length + 1) + ", Lead device: " + val.leadPhone }}</a>-->
                </button>
            </div>
        </div>
    </div>


    <div ngbDropdown placement="top-left" autoClose='outside' class="d-inline-block" *ngIf="groupInstance">
        <button mat-raised-button color="primary" ngbDropdownToggle id="dropdown3" matTooltip="Select device to show">
            <mat-icon aria-label="Select device">{{ iconDevices }}</mat-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdown3">
            <div *ngFor="let val of devices">
                <button ngbDropdownItem (click)="deviceSelected(val)" [ngClass]="{'ngb-active':val == device}">
                    {{ val.serialNum + ": " + val.manufacturer + " " + val.model + " (" + val.operator + ")" }}
                </button>
            </div>
        </div>
    </div>

    <button mat-raised-button color="primary" *ngIf="groupInstance" (click)="showDeviceInsights(groupInstance)" matTooltip="Show insights for selected">
        <mat-icon aria-label="Show insights">{{ iconInsights }}</mat-icon>
    </button>
</div>