import { CommonModule } from '@angular/common';
import { NgModule }     from '@angular/core';

import { ChartService } from './charts.service';


@NgModule({
    imports: [
        CommonModule
    ],
    
    declarations: [
    ],

    providers: [
    ],

    exports: [
    ]
})
export class ChartsModule { }