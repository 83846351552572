import { Injectable }              from '@angular/core';
import { Observable }              from 'rxjs';

import { BaseService }             from '@Base/';
import { KpiTypes }                from '@Common/';
import { IconsService }            from '@Icons/';
import {
    Dateq,
    Globals
}                                  from '@Utils/';

import { MapLayerUtils }           from './map-layer-utils.class';

import { 
    MapElementLatLng,
    MapElementSymbolPath,
    MapElementPolyline,
    MapElementStreetViewPanorama,
    MapElementOverlayView
}                                  from '../../map-elements/'; 

declare var google: any;
 
 
@Injectable({
    providedIn: 'root'
})
export class MapLayerRoutesService extends BaseService
{
    private static readonly baseIcons      = "https://maps.google.com/mapfiles/ms/icons/";
    private static readonly iconRouteLive  = MapLayerRoutesService.baseIcons + "cabs.png";
    private static readonly iconRouteStart = MapLayerRoutesService.baseIcons + "blue-pushpin.png";
    private static readonly iconRouteEnd   = MapLayerRoutesService.baseIcons + "flag.png";

    private static readonly imagesBase     = "../assets/images";
    private static readonly imagesServices = MapLayerRoutesService.imagesBase + "/services/";

    private readonly routeSymbol = {
        path: MapElementSymbolPath.FORWARD_CLOSED_ARROW,
        fillOpacity: 1,
        scale: 3
    };

    private static readonly lineDashSymbol = {
        path:           'M 0,-1 0,1',
        strokeOpacity:  10.5,
        strokeWeight:   6
        //scale: 4
    };

    private readonly plOptionsConnectivity = {
        geodesic:       true,
        strokeColor:    MapLayerUtils.getConnectivityColour('connected', 'mobile'),
        strokeOpacity:  10.5,
        strokeWeight:   6,
        zIndex:         2,
        icons:          [] as any[]
    };

    private readonly plOptionsMobileTech = {
        geodesic:       true,
        strokeColor:    MapLayerUtils.getTechnologyColour('lte'),
        strokeOpacity:  10.5,
        strokeWeight:   6,
        zIndex:         2,
        icons:          [] as any[]
    };

    private static readonly plOptionsMobileSignal = {
        geodesic:       true,
        strokeColor:    null as any,
        strokeOpacity:  1.0,
        strokeWeight:   2,
        zIndex:         3,
        icons:          [] as any[]
    };

    private readonly plOptionsRoute = {
        geodesic:       true,
        strokeColor:    MapLayerUtils.getTechnologyColour('lte'),
        strokeOpacity:  1.0,
        strokeWeight:   5,
        zIndex:         1,
        icons:          [{
                icon: this.routeSymbol,
                offset: '0',
                repeat: '50px'
            }
        ] as any[]
    };

    public constructor(private readonly IconsService: IconsService)
    {
        super();
    }


    //
    // Public methods
    //
    public mapLayerRoutesFactory(map: any, type: any, data: any): any
    {
        const layerNames: string[] = [
            'campaign',
            'connectivity',
            'geoRoute',
            'geoRouteSpeed',
            'geoRouteReports',
            'mobileCellChanges',
            'mobileCellReports',
            'mobileSignal',
            'mobileTech',
            'serviceResults',
            'voiceResults'
            //'wifi'
        ];

        let layers: any = {};

        // Create overlays
        layerNames.forEach((d: string): void => {
            layers[d] = MapElementOverlayView.get();
        });

    // layers.campaign          = MapLayerUtils.mapLayerFactory();
    // layers.connectivity      = MapLayerUtils.mapLayerFactory();
    // layers.campaign          = MapLayerUtils.mapLayerFactory();
    // layers.geoRoute          = MapLayerUtils.mapLayerFactory();
    // layers.geoRouteSpeed     = MapLayerUtils.mapLayerFactory();
    // layers.geoRouteReports   = MapLayerUtils.mapLayerFactory();
    // layers.mobileCellChanges = MapLayerUtils.mapLayerFactory();
    // layers.mobileCellReports = MapLayerUtils.mapLayerFactory();
    // layers.mobileTech        = MapLayerUtils.mapLayerFactory();
    // layers.mobileSignal      = MapLayerUtils.mapLayerFactory();
    // layers.serviceResults    = MapLayerUtils.mapLayerFactory();
    // layers.voiceResults      = MapLayerUtils.mapLayerFactory();

    //layers.wifi              = MapLayerUtils.mapLayerFactory();


        // [TBD] - be notifed when Streetview is enabled or not so can change the cellChanges icons
        const mapPanorama: MapElementStreetViewPanorama | null | undefined = map ? MapElementStreetViewPanorama.get(map.getStreetView()) : undefined;
        if (mapPanorama && mapPanorama.visibility instanceof Observable) {
            this.sub = mapPanorama.visibility.subscribe((isVisible: boolean): void => {
                layers.mobileCellChanges.getOverlays().forEach((d: any) =>  {
                    if (d) {
                        let icon: any = d.getIcon();
                        if (icon) {
                            icon.strokeColor = isVisible ? '#ff0000' : '#000000';
                            icon.scale       = isVisible ? 30        : 6;
                            d.setIcon(icon);
                        }
                    }
                }); // forEach
            });
        }

        return this.processRoute(layers, map, data.locations, data.serialNum, data.plmn, data.operator);
    }


    //
    // Protected methods
    //

    // Override
    protected override initialise(): boolean
    {
        super.initialise();

        console.log("Initialising MapLayerRoutes service");

        return true;
    }


    //
    // Private methods
    //
    private getServiceResultTitleStr(loc: any): string
    {
        let str: string = "";

        if (loc) {
            str  = "Service: "
            + (loc.serviceName ? this.getStr(loc.serviceName) + (loc.serviceCategoryName ? " (" + loc.serviceCategoryName + ")" : "") 
                  : (loc.testResultType ? loc.testResultType : "Unknown"))
            + "\n" + this.getCommonTitleStr(loc);
        }

        return str;
    }


    private getConnectionTitleStr(loc: any): string
    {
        let str: string = "";

        if (loc) {
            str  = "State: "      + this.getStr(loc.connectionState)                                + "\n";
            
            const reason: string = this.getStr(loc.connectionReason);
            str += reason ? "Reason: " + reason                                                + "\n" : "";
            // if (reason) {
            //     str += "Reason: " + reason                                                     + "\n";
            // }

            str += "Type: "       + this.getStr(loc.networkType)                                    + "\n";

            const ipv4: any = this.getStr(loc.ipAddrV4);
            if (ipv4 && ipv4 != 0 && ipv4 != "none") {
                str += "IPv4: "   + this.getStr(loc.ipAddrV4) + "/" + this.getStr(loc.ipAddrv4PrefixLen) + "\n";
            }

            const ipv6: any = this.getStr(loc.ipAddrV6);
            if (ipv6 && ipv6 != 0 && ipv6 != "none") {
                str += "IPv6: "   + this.getStr(loc.ipAddrV6) + "/" + this.getStr(loc.ipAddrv6PrefixLen) + "\n";
            }
           
            str += "Network: "    + this.getStr(loc.apn)                                            + "\n"
                 + this.getCommonTitleStr(loc);
        }

        return str;
    }


    private getLocationTitleStr(loc: any, mobile: any): string
    {
        let str: string = "";

        if (loc) {
            str = "";

            if (mobile) {
                str += "Technology: "  + this.getStr(loc.rat)   + "\n"
                     + "Network: "     + this.getStr(loc.plmn)      + "\n"
                     + "Mobile cell: " + this.getStr(loc.gcid)      + "\n"
                     + "Area code: "   + this.getStr(loc.areaCode)  + "\n"
                     + "Cell code: "   + this.getStr(loc.cellCode)  + "\n";
            }

            str += this.getCommonTitleStr(loc);
        }

        return str;
    }


    private getCommonTitleStr(loc: any): string
    {
        let str: string = "";

        if (loc) {
            if ((loc.latitude || loc.latitude == 0) && (loc.longitude || loc.longitude == 0)) {
                str += "Latitude: "     + this.getStr(loc.latitude)  + "\n"
                     + "Longitude: "    + this.getStr(loc.longitude) + "\n";

                if (loc.accuracy  || loc.accuracy == 0) {    
                   str += "Accuracy: "  + this.getStr(loc.accuracy ? loc.accuracy.toFixed(2) : "")  + " m" + "\n"
                }
            }
            
            if (loc.eventDate) {
                // str += "Time: "         + Dateq.get(loc.eventDate).displayStr;
            }
        }

        return (str != "" ? "\n" + str : str);
    }


    private getStr(obj: any): string
    {
        return (obj ? obj : "");
    }


    private getPolyline(opts: any, colour: any, path: any, dashed?: boolean): any
    {
        let opts2: any = opts;
        let pl: any    = null;

        if (dashed) {
            opts2.icons         = [MapLayerRoutesService.lineDashSymbol]; // this is needed for dashed symbols to show up
            opts2.strokeOpacity = 0;
        }

        if (path) {
            pl = new MapElementPolyline(opts2);
            if (pl) {
                if (colour) pl.strokeColor = colour;
                pl.setPath(path);

                pl.addListener('mouseover', this.info);
            }
            else {
                console.log("Polyline is null");    
            }
        }
        else {
            console.log("Polyline path is null");
        }

        return pl;
    };


    private info(): void
    {
//      console.log("mouseover");
    }


    private processRoute(layers: any, map: any, locations: any[], serialNum: string, plmn: string, operator: string): any
    {
        if (locations && locations.length > 0) {
            let connectedUniqueIpV4s: number = 0;
            let connectedUniqueIpV6s: number = 0;
            let connectionOrig: any;
            let connectionStored: any;
            let gcidOrig: any;
            let ratOrig: any;
            let routeSpeedOrig: any;
            let sigStrengthValueOrig: any;
            let serviceOrig: any;

            let startDate: Dateq | undefined = undefined;
            let endDate:   Dateq | undefined = undefined;

            console.log("Adding " + locations.length + " geolocation(s) to map");
            for (let i = 0, len = locations.length; i < len; ++i) {
                if (locations[i]) {
                    const loc: any = MapElementLatLng.get(locations[i].latitude, locations[i].longitude);

// [TBD] Check validity - accuracy plus speed in time since last result

                    if (loc && locations[i].accuracy <= Globals.gpsDistanceAccuracy) {
                        // Add location to various layers for accurate route mapping      
                        layers.connectivity.addPath(loc);                      

                        layers.mobileSignal.addPath(loc);
                        layers.mobileTech.addPath(loc);

                        if (layers.geoRoute.getPaths().length <= 0) {
                            startDate = Dateq.get(locations[i].eventDate);

                            const titleString = 
                                  "Mobilty test: start" + "\n"
                                + this.getLocationTitleStr(locations[i], false);

                            // const icon: any = (plmn || operator) ? this.IconsService.getOperatorIconAsync(plmn, operator) : MapLayerRoutesService.iconRouteStart;
                            // layers.campaign.addOverlay(
                            //     MapLayerUtils.createMapPointOther(
                            //         map,
                            //         locations[i].latitude,
                            //         locations[i].longitude,
                            //         icon
                            //             ? icon
                            //             : MapLayerRoutesService.iconRouteStart, titleString
                            //     )
                            // );
                        }
                        layers.geoRoute.addPath(loc);
                        layers.geoRoute.updateMapBounds(loc);
                    }

                    // Update speed layer
                    if (loc && locations[i].accuracy <= Globals.gpsDistanceAccuracy) {
                        layers.geoRouteSpeed.addPath(loc);
                        if (locations[i].speed || locations[i].speed == 0) {
                            const routeSpeed: any = MapLayerUtils.getRouteSpeedCat(locations[i].speed);
                            if      (! routeSpeedOrig) {
                                routeSpeedOrig = routeSpeed;
                            }
                            else if (routeSpeed != routeSpeedOrig) {
                                layers.geoRouteSpeed.addOverlay(
                                    this.getPolyline(MapLayerRoutesService.plOptionsMobileSignal,
                                                MapLayerUtils.getRouteSpeedCatColour(routeSpeedOrig),
                                                layers.geoRouteSpeed.getPaths())
                                );
                                layers.geoRouteSpeed.deletePaths();
                                routeSpeedOrig = routeSpeed;
                                layers.geoRoute.addPath(loc);
                            }
                        }
                    }


                    // Update connectivity layer
                    if (locations[i].connectionState || (loc && connectionStored) ) {
                        if (! loc) {
                            connectionStored = locations[i];
                        }
                        else {
                            if (! layers.connectivity.data) {
                                layers.connectivity.data = {type: KpiTypes.ipAddressOverTime, data: {}};
                            }

                            const conLoc = connectionStored ? connectionStored : locations[i];
                            if (conLoc.ipAddrV4 == 0) {
                                conLoc.ipAddrV4 = "none";
                            }

                            if (conLoc.ipAddrV6 == 0) {
                                conLoc.ipAddrV6 = "none";
                            }                    

                            if (! layers.connectivity.data.data[conLoc.ipAddrV4]) {
                                layers.connectivity.data.data[conLoc.ipAddrV4] = [];
                            }

                            if (! layers.connectivity.data.data[conLoc.ipAddrV6]) {
                                layers.connectivity.data.data[conLoc.ipAddrV6] = [];
                            }

                            layers.connectivity.addOverlay(
                                MapLayerUtils.createMapPointOther(
                                    map,
                                    locations[i].latitude,
                                    locations[i].longitude, 
                                    {scale: 3, path: MapElementSymbolPath.CIRCLE}, 
                                    this.getConnectionTitleStr(conLoc)
                                )
                            );

                            if (! connectionOrig) {
                                connectionOrig = {
                                    ipAddrV4:  conLoc.ipAddrV4,
                                    ipAddrV6:  conLoc.ipAddrV6,
                                    startDate: conLoc.eventDate,
                                    endDate:   conLoc.eventDate
                                };
                            }
                            connectionOrig.endDate = conLoc.eventDate;

                            // Keep tally of IPv4 addresses used
                            if (conLoc.ipAddrV4 != connectionOrig.ipAddrV4) {
                                ++connectedUniqueIpV4s;
                                layers.connectivity.data.data[connectionOrig.ipAddrV4].push({
                                    start: connectionOrig.startDate,
                                    end:   connectionOrig.endDate,
                                    color: MapLayerUtils.getConnectivityColour(connectionOrig.connectionState, connectionOrig.connectionType)
                                });
                                connectionOrig.ipAddrV4  = conLoc.ipAddrV4;
                                connectionOrig.startDate = conLoc.eventDate;
                            }

                            // Keep tally of IPv6 addresses used
                            if (conLoc.ipAddrV6 != connectionOrig.ipAddrV6) {
                                ++connectedUniqueIpV6s;
                                layers.connectivity.data.data[connectionOrig.ipAddrV4].push({
                                    start: connectionOrig.startDate,
                                    end:   connectionOrig.endDate,
                                    color: MapLayerUtils.getConnectivityColour(connectionOrig.connectionState, connectionOrig.connectionType)
                                });
                                connectionOrig.ipAddrV6  = conLoc.ipAddrV6;
                                connectionOrig.startDate = conLoc.eventDate;
                            }

                            if (conLoc.connectionState != connectionOrig.connectionState || conLoc.networkType != connectionOrig.connectionType) {
                                layers.connectivity.addOverlay(
                                    this.getPolyline(this.plOptionsConnectivity,
                                                MapLayerUtils.getConnectivityColour(connectionOrig.connectionState, connectionOrig.connectionType),
                                                layers.connectivity.getPaths())
                                );
                                layers.connectivity.deletePaths();

                                connectionOrig.connectionState = conLoc.connectionState;
                                connectionOrig.connectionType  = conLoc.networkType;

                                if (locations[i].accuracy <= Globals.gpsDistanceAccuracy) {
                                    layers.connectivity.addPath(loc);
                                }
                            }
                            
                            connectionStored = null;
                        }
                    }

                    // Update voice results layer
                    if (locations[i].testResultType && locations[i].testResultType.toLowerCase().includes("voice")) { // ES6
                        if (locations[i].testResultType && locations[i].testResultType.toLowerCase().includes("statusvoice")) { // ES6
                            //console.log(location[i]);
                            layers.voiceResults.addOverlay(
                                this.getPolyline(this.plOptionsConnectivity,
                                            '#ffffff',
                                            layers.voiceResults.getPaths())
                            );
                            layers.voiceResults.deletePaths();
                        }
                        else {
                            if (locations[i].accuracy <= Globals.gpsDistanceAccuracy) {
                                layers.voiceResults.addPath(loc);
                            }
                        }
                    }

                    // Update service results layer
                    if (locations[i].testResultType && locations[i].testResultType.toLowerCase().includes("status")) { // ES6
                        serviceOrig = serviceOrig ? serviceOrig : locations[i];
                        if (locations[i].testsequencesId != serviceOrig.testsequencesId) {
                            //console.log(location[i]);
                            layers.serviceResults.addOverlay(
                                MapLayerUtils.createMapPointOther(
                                    map,
                                    serviceOrig.latitude,
                                    serviceOrig.longitude, 
                                    serviceOrig.serviceIcon
                                        ? MapLayerRoutesService.imagesServices + serviceOrig.serviceIcon
                                        : {scale: 3, path: MapElementSymbolPath.CIRCLE},
                                    this.getServiceResultTitleStr(serviceOrig)
                                )
                            );
                            serviceOrig = locations[i];
                        }
                    }

                    // Add marker for each location report to georoute layer
                    if (loc) {
                        layers.geoRouteReports.addOverlay(
                            MapLayerUtils.createMapPointOther(
                                map,
                                locations[i].latitude,
                                locations[i].longitude, 
                                {scale: 3, path: MapElementSymbolPath.CIRCLE},
                                this.getLocationTitleStr(locations[i], false)
                            )
                        );
                    }

                    if (loc && locations[i].rat) {
                        if (ratOrig) ratOrig.endDate = Dateq.get(locations[i].eventDate);
                    
                        // Used to plot Gantt chart of RAT types over time
                        if (! layers.mobileTech.data) {
                            layers.mobileTech.data = {type: KpiTypes.mobileTechnologyOverTime, data: {}};
                            layers.mobileTech.data.data[serialNum] = [];
                        }

                        // if (! layers.mobileTech.data.data[locations[i].rat]) {
                        //     layers.mobileTech.data.data[locations[i].rat] = [];
                        // }

                        if (locations[i].accuracy <= Globals.gpsDistanceAccuracy) {
                            // Add marker for each cell report
                            if (locations[i].sigStrengthType && locations[i].sigStrengthValue) {
                                const titleString = 
                                    "Strength: " + locations[i].sigStrengthValue + " dB (" +  locations[i].sigStrengthType + ")\n"
                                        + (locations[i].sigQualityType && locations[i].sigQualityValue ?
                                            "Quality: " + locations[i].sigQualityValue + " (" + locations[i].sigQualityType + ")\n" : "")
                                        + this.getLocationTitleStr(locations[i], true);

                                layers.mobileCellReports.addOverlay(
                                    MapLayerUtils.createMapPointOther(
                                        map,
                                        locations[i].latitude,
                                        locations[i].longitude, 
                                        {scale: 3, path: MapElementSymbolPath.CIRCLE},
                                        titleString
                                    )
                                );

                                // Set up on entering loop for first time
                                if (! sigStrengthValueOrig) sigStrengthValueOrig = locations[i].sigStrengthValue;
                            }

                            // Set up on entering loop for first time
                            if (! ratOrig) {
                                ratOrig = {rat:       locations[i].rat,
                                           startDate: Dateq.get(locations[i].eventDate),
                                           endDate:   Dateq.get(locations[i].eventDate)};
                            }

                            if (locations[i].rat != ratOrig.rat) {
                                // Technology changed, so create polyline for current set of results, push to layer and create new one
                                layers.mobileSignal.addOverlay(
                                    this.getPolyline(MapLayerRoutesService.plOptionsMobileSignal,
                                                MapLayerUtils.getSignalStrengthColour(ratOrig.rat, sigStrengthValueOrig),
                                                layers.mobileSignal.getPaths())
                                );
                                layers.mobileSignal.deletePaths();
                                sigStrengthValueOrig = locations[i].sigStrengthValue;
                                layers.mobileSignal.addPath(loc);

                                layers.mobileTech.addOverlay(
                                    this.getPolyline(this.plOptionsMobileTech,
                                                MapLayerUtils.getTechnologyColour(ratOrig.rat),
                                                layers.mobileTech.getPaths())
                                );
                                layers.mobileTech.deletePaths();
                                //layers.mobileTech.data.data[ratOrig.rat].push(
                                //    {startTime: new Date(ratOrig.startDate), endTime: new Date(ratOrig.endDate), color: MapLayerUtils.getTechnologyColour(ratOrig.rat)});
                                layers.mobileTech.data.data[serialNum].push({
                                    start: ratOrig.startDate,
                                    end:   ratOrig.endDate,
                                    val:   ratOrig.rat,
                                    color: MapLayerUtils.getTechnologyColour(ratOrig.rat)
                                });
                                layers.mobileTech.addPath(loc);
                                
                                ratOrig.rat       = locations[i].rat;
                                ratOrig.startDate = Dateq.get(locations[i].eventDate);
                                ratOrig.endDate   = null;
                            }
                            else if (locations[i].sigStrengthValue != sigStrengthValueOrig && locations[i].sigStrengthValue != 0) {
                                // Signal strength changed, so create polyline for current set of results, push to layer and create a new one
                                layers.mobileSignal.addOverlay(
                                    this.getPolyline(MapLayerRoutesService.plOptionsMobileSignal,
                                                MapLayerUtils.getSignalStrengthColour(ratOrig.rat, sigStrengthValueOrig),
                                                layers.mobileSignal.getPaths())
                                );
                                layers.mobileSignal.deletePaths();
                                sigStrengthValueOrig = locations[i].sigStrengthValue;
                                layers.mobileSignal.addPath(loc);
                            }


                            if (locations[i].gcid && locations[i].gcid != gcidOrig) {
                                layers.mobileCellChanges.addOverlay(
                                    MapLayerUtils.createMapPointOther(
                                        map,
                                        locations[i].latitude,
                                        locations[i].longitude,
                                        {scale: 6, path: MapElementSymbolPath.CIRCLE},
                                        this.getLocationTitleStr(locations[i], true)
                                    )
                                );
                                gcidOrig = locations[i].gcid;
                            }
                        }
                    } // rat

                    // Add final polylines to map
                    if (loc && i == (len - 1)) {
                        //endDate = new Date(locations[i].eventDate);
                        endDate = Dateq.get(locations[i].eventDate);

                        // Last position; add route end icon
                        const titleString = 
                              "Mobilty test: end" + "\n"
                            //+ "Distance: " + MapLayerRoutesService.gMaps.geometry.spherical.computeLength(pl.getPath().getArray())
                            + this.getLocationTitleStr(locations[i], false);

                        layers.campaign.addOverlay(MapLayerUtils.createMapPointOther(map, locations[i].latitude, locations[i].longitude, MapLayerRoutesService.iconRouteEnd, titleString));

                        if (ratOrig) {
                            layers.mobileSignal.addOverlay(
                                this.getPolyline(MapLayerRoutesService.plOptionsMobileSignal,
                                            MapLayerUtils.getSignalStrengthColour(ratOrig.rat, sigStrengthValueOrig),
                                            layers.mobileSignal.getPaths())
                            );
                            layers.mobileSignal.deletePaths();

                            layers.mobileTech.addOverlay(
                                this.getPolyline(this.plOptionsMobileTech,
                                            MapLayerUtils.getTechnologyColour(ratOrig.rat),
                                            layers.mobileTech.getPaths())
                            );
                            layers.mobileTech.deletePaths();
                            // if (layers.mobileTech.data && layers.mobileTech.data.data && layers.mobileTech.data.data[ratOrig.rat]) {
                            if (layers.mobileTech.data && layers.mobileTech.data.data && layers.mobileTech.data.data[serialNum]) {
                            //     layers.mobileTech.data.data[ratOrig.rat].push(
                            //         {startTime: new Date(ratOrig.startDate), endTime: new Date(locations[i].eventDate), color: MapLayerUtils.getTechnologyColour(ratOrig.rat)});
                                layers.mobileTech.data.data[serialNum].push({
                                    start: ratOrig.startDate,
                                    end:   Dateq.get(locations[i].eventDate),
                                    val:   ratOrig.rat,
                                    color: MapLayerUtils.getTechnologyColour(ratOrig.rat)
                                });
                            }
                        }

                        if (connectionOrig) {
                            layers.connectivity.addOverlay(
                                this.getPolyline(this.plOptionsConnectivity,
                                            MapLayerUtils.getConnectivityColour(connectionOrig.connectionState, connectionOrig.connectionType),
                                            layers.connectivity.getPaths())
                            );
                            layers.connectivity.deletePaths();

                            if (layers.connectivity.data.data[connectionOrig.ipAddrV4]) {
                                layers.connectivity.data.data[connectionOrig.ipAddrV4].push({
                                    start: connectionOrig.startDate,
                                    end:   Dateq.get(locations[i].eventDate),
                                    color: MapLayerUtils.getConnectivityColour(connectionOrig.connectionState, connectionOrig.connectionType)
                                });

                                if (layers.connectivity.data.data[connectionOrig.ipAddrV4].length <= 0) {
                                    delete layers.connectivity.data.data[connectionOrig.ipAddrV4];
                                }
                            }


                            if (layers.connectivity.data.data[connectionOrig.ipAddrV6]) {
                                if (connectionOrig.ipAddrV6 != "none") {
                                    layers.connectivity.data.data[connectionOrig.ipAddrV6].push({
                                        start: connectionOrig.startDate,
                                        end:   Dateq.get(locations[i].eventDate),
                                        color: MapLayerUtils.getConnectivityColour(connectionOrig.connectionState, connectionOrig.connectionType)
                                    });
                                }

                                if (layers.connectivity.data.data[connectionOrig.ipAddrV6].length <= 0) {
                                    delete layers.connectivity.data.data[connectionOrig.ipAddrV6];
                                }
                            }
                        }

                        if (serviceOrig) {
                            layers.serviceResults.addOverlay(
                                MapLayerUtils.createMapPointOther(
                                    map,
                                    serviceOrig.latitude,
                                    serviceOrig.longitude, 
                                    serviceOrig.serviceIcon
                                        ? MapLayerRoutesService.imagesServices + serviceOrig.serviceIcon
                                        : {scale: 3, path: MapElementSymbolPath.CIRCLE},
                                    this.getServiceResultTitleStr(serviceOrig)
                                )
                            );
                        }

//createMarker(paths['' + start + '_to_' + end].getPath().getAt(0), 'start', 'start', 'green'); 
//createMarker(paths['' + start + '_to_' + end].getPath().getAt(paths['' + start + '_to_' + end].getPath().getLength()-1), 'end', 'end', 'red');
                    }
                } // if

            } // for
            console.log("Added " + locations.length + " geolocation(s) to map");
            //console.log(locations);

            // Add (single) polyline for route
            layers.geoRoute.addOverlay(
                this.getPolyline(
                    this.plOptionsRoute,
                    'black',
                    layers.geoRoute.getPaths()
                )
            );

            // Note: Don't delete georoute paths as needed by geoRoute key code below

            // Add final speed path
            layers.geoRouteSpeed.addOverlay(
                this.getPolyline(
                    MapLayerRoutesService.plOptionsMobileSignal,
                    MapLayerUtils.getRouteSpeedCatColour(routeSpeedOrig),
                    layers.geoRouteSpeed.getPaths()
                )
            );
            layers.geoRouteSpeed.deletePaths();


            // Populate map keys
            layers.mobileTech.setKey("Mobile technology",
                {
                    lte:           {colour: MapLayerUtils.getTechnologyColour('lte')},
                    umts:          {colour: MapLayerUtils.getTechnologyColour('umts')},
                    gsm:           {colour: MapLayerUtils.getTechnologyColour('gsm')},
                    'no coverage': {colour: MapLayerUtils.getTechnologyColour('unknown')}
                }
            );
            // [TBD]
            delete layers.mobileTech.data;

            layers.mobileSignal.setKey("Mobile signal strength",
                {
                    good:          {colour: MapLayerUtils.getSignalStrengthColour2('good')},
                    average:       {colour: MapLayerUtils.getSignalStrengthColour2('average')},
                    poor:          {colour: MapLayerUtils.getSignalStrengthColour2('poor')},
                    unreported:    {colour: MapLayerUtils.getSignalStrengthColour2('none')}
                }
            );

            layers.mobileCellChanges.setKey("Mobile cell changes",       {number: layers.mobileCellChanges.getOverlays().length});
            // [TBD] Unique cells seen, per RAT?
            layers.mobileCellReports.setKey("Mobile cell reports",       {number: layers.mobileCellReports.getOverlays().length});
            layers.geoRouteReports.setKey(  "Route geolocation reports", {number: layers.geoRouteReports.getOverlays().length});

            layers.geoRouteSpeed.setKey("Route speed",
                {
                    fast:          {colour: MapLayerUtils.getRouteSpeedCatColour('fast')},
                    average:       {colour: MapLayerUtils.getRouteSpeedCatColour('average')},
                    slow:          {colour: MapLayerUtils.getRouteSpeedCatColour('slow')},
                    stopped:       {colour: MapLayerUtils.getRouteSpeedCatColour('stopped')}
                }
            );

            layers.connectivity.setKey("Connectivity\n(IP changes: " + (connectedUniqueIpV4s + connectedUniqueIpV6s) + ")",
                {
                    mobile:        {colour: MapLayerUtils.getConnectivityColour('connected', 'mobile')},
                    wifi:          {colour: MapLayerUtils.getConnectivityColour('connected', 'wifi')},
                    suspended:     {colour: MapLayerUtils.getConnectivityColour('suspended')},
                    disconnected:  {colour: MapLayerUtils.getConnectivityColour('disconnected')},
                    unreported:    {colour: MapLayerUtils.getConnectivityColour('unknown')}
                }
            );
            // [TBD]
            delete layers.connectivity.data;

            layers.serviceResults.setKey("Service Results",
                {
                    good:          {colour: MapLayerUtils.getSignalStrengthColour2('good')},
                    average:       {colour: MapLayerUtils.getSignalStrengthColour2('average')},
                    poor:          {colour: MapLayerUtils.getSignalStrengthColour2('poor')},
                    unreported:    {colour: MapLayerUtils.getSignalStrengthColour2('none')}
                }
            );

            const routeLength: number = layers.geoRoute.getPathLength(); // in metres
            const campaignKey: any    = {device: serialNum};

            if (startDate) campaignKey.start = startDate.displayStr;
            if (endDate)   campaignKey.end   = endDate.displayStr;

            campaignKey.distance = (routeLength / 1000).toFixed(2) + " km";
            if (startDate && endDate) {
                const routeDuration: any = (endDate.getTime() - startDate.getTime()) / 1000; // convert to secs
                const duration: any      = {hours: Math.floor(routeDuration / (60 * 60)), mins: Math.floor((routeDuration % (60 * 60)) / 60), secs: Math.floor((routeDuration % (60 * 60)) % 60)};
                campaignKey.duration     =  duration.hours + ":" 
                                      + (duration.mins < 10 ? "0" : "") + duration.mins + ":"
                                      + (duration.secs < 10 ? "0" : "") + duration.secs;
                // campaignKey.duration =        duration.hours + " hour" + (duration.hours == 1 ? "" : "s") 
                //                       + " " + duration.mins  + " min"  + (duration.mins  == 1 ? "" : "s")
                //                       + " " + duration.secs  + " sec"  + (duration.secs  == 1 ? "" : "s");

                if (routeDuration > 0) {
                    campaignKey['avg speed'] = ( (routeLength / 1000) / (routeDuration / (60 * 60)) ).toFixed(2) + " km/h";
                }
            }
            layers.campaign.setKey("Campaign", campaignKey);

            // [TBD]
            // Num phones
            // Type
            // Total # of tests
        } //if

        return layers;
    } // processRoute()
}