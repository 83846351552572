import { Routes }              from '@angular/router';

import { AuthenticationGuard } from '@Login/';

import { SiteComponent }       from './Site/';
import { SitesComponent }      from './sites.component';

import { routeNames }          from '../app.routing.names';


export const sitesRoutes: Routes = [
    {
        path:        '',
        component:   SitesComponent, // list
        data:        { animation: routeNames.sites },
        canActivate: [ AuthenticationGuard ]
    },

    {
        path:        ':id',
        component:   SiteComponent,  // individual
        data:        { animation: 'site' },
        canActivate: [ AuthenticationGuard ]
    }
];