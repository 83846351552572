import {
    Component,
    // ComponentFactoryResolver,
    Inject,
    Input,
    ViewChild,
    ViewContainerRef
}                            from '@angular/core';
import {
    MAT_DIALOG_DATA,
    
    MatDialogRef
}                            from "@angular/material/dialog";

import {
    BaseComponent,
    BaseWithDynamicComponent
}                            from '@Base/';


@Component({
    selector:     'qp-dialog',
    templateUrl:  'dialog.component.html',
    styleUrls:   ['dialog.component.css']
})
export class DialogComponent extends BaseWithDynamicComponent
{
    @ViewChild('container', { static: true, read: ViewContainerRef })
    container: ViewContainerRef;

    
    // Disable close button
    @Input()
    public disableCloseButton: boolean = false;


    // public constructor(                 ComponentFactoryResolver: ComponentFactoryResolver,
    public constructor(private readonly MatDialogRef:             MatDialogRef<any>,

                       @Inject(MAT_DIALOG_DATA)
                       private readonly data:                     {
                                                                      component:          typeof BaseComponent | undefined
                                                                      disableCloseButton: boolean
                                                                  })
    {
        // super(ComponentFactoryResolver);
        super();
    }


    // Override
    public override ngOnInit(): void // can't do in ngAfterViewInit() as will give ExpressionChangedAfterItHasBeenCheckedError
    {
        super.ngOnInit();

        // Must do this in ngOnInit() as ViewChild not ready until then
        if (this.data) {
            if (this.data.component)               this.component          = this.data.component; // will trigger dynamic component creation
            if ('disableCloseButton' in this.data) this.disableCloseButton = !! this.data.disableCloseButton;
        }
    }

        
    //
    // Getters and setters
    //
    @Input()
    public set component(d: typeof BaseComponent | undefined)
    {
        if (d) this.open(d);
        else   this.close();
    }


    //
    // Public methods
    //

    // Override
    public override close(): void
    {
        if (this.MatDialogRef instanceof MatDialogRef) {
            // Listen for close event
            this.MatDialogRef.afterClosed().subscribe((d: any | undefined) => {
                if (this.container && this.container.clear instanceof Function) this.container.clear();
            }); // subscribe
            this.MatDialogRef.close();
        }

        super.close();
    }


    //
    // Private methods
    //
    private open(d: typeof BaseComponent | undefined): void
    {
        super.openT(this.container, d);
  
        if (this.compRef && this.compRef.instance && this.compRef.instance.selected) {
            this.compRef.instance.selected
                .subscribe((d: any) => {
                    this.close()
                }); // subscribe
        }
    }
}