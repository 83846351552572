import { CommonModule }          from '@angular/common';
import { NgModule }              from '@angular/core';
import { OrderModule }           from 'ngx-order-pipe';
import { RouterModule }          from '@angular/router';

// include qp-refresh-button
import { GuiElementsModule }     from '@GuiElements/';
// include qp-organisation-info
import { MaterialCustomModule }  from '@Material/';
// include qp-element-info-container
import { ObjElementsModule }     from '@ObjElements/';
// include qp-sites directive
import { SitesModule }           from '@Sites/';

import { OrganisationComponent } from './organisation.component';


@NgModule({
    imports: [
        CommonModule,
        OrderModule,
        RouterModule,

        GuiElementsModule,
        MaterialCustomModule,
        ObjElementsModule,
        SitesModule
    ],
    
    declarations: [
        OrganisationComponent
    ],

    providers: [
    ],

    exports: [
        OrganisationComponent,
    ]
})
export class OrganisationModule { }