import { CommonModule }                 from '@angular/common';
import { FlexLayoutModule }             from '@angular/flex-layout';
import { NgModule }                     from '@angular/core';
import { RouterModule }                 from '@angular/router';

import { MaterialCustomModule }         from '@Material/';
import { MiscModule }                   from '@Misc/misc.module';

import { HeaderAboutComponent }         from './header-about.component';
import { HeaderBadgeComponent }         from './header-badge.component';
import { HeaderBaseComponent }          from './header-base.component';
import { HeaderDeviceGroupsComponent }  from './header-device-groups.component';
import { HeaderDevicesComponent }       from './header-devices.component';
import { HeaderDisplayModeComponent }   from './header-display-mode.component';
import { HeaderFooterBaseComponent }    from './header-footer-base.component';
import { HeaderNotificationsComponent } from './header-notifications.component';
import { HeaderSettingsComponent }      from './header-settings.component';
import { HeaderUserComponent }          from './header-user.component';
import { HeaderComponent }              from './header.component';
import { FooterComponent }              from './footer.component';


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        RouterModule,

        MaterialCustomModule,
        MiscModule
    ],
    
    declarations: [
        FooterComponent,
        HeaderAboutComponent,
        HeaderBadgeComponent,
        HeaderBaseComponent,
        HeaderComponent,
        HeaderDevicesComponent,
        HeaderDeviceGroupsComponent,
        HeaderDisplayModeComponent,
        HeaderFooterBaseComponent,
        HeaderNotificationsComponent,
        HeaderSettingsComponent,
        HeaderUserComponent
    ],

    providers: [
    ],

    exports: [
        FooterComponent,
        HeaderComponent
    ]
})
export class HeaderModule { }