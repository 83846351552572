import { Component }             from '@angular/core';
import { MatDialogRef}           from "@angular/material/dialog";

import { BaseComponent }         from '@Base/';
import { InsightsWindowService } from '@Insights/';

import { DeviceGroupsService }   from './device-groups.service';
// import { qp_WindowService }      from '@Utils-ajs/qp_WindowFactory';


@Component({
   selector:     'qp-device-groups-list',
   templateUrl:  'device-groups-list.component.html',
   styleUrls:   ['device-groups-list.component.css']
})
export class DeviceGroupsListComponent extends BaseComponent
{
    public Object = Object; // required for HTML to use Object.keys()

    private static readonly maxGroupsSelected:          number       = 2;

    public                  compareAvailable:           boolean      = true;
    public                  compareCheckboxesAvailable: boolean      = false;

    public                  data:                       any[]        = [];
    public readonly         name:                       string       = "Device Groups";
    public                  selected:                   any          = {};

    // Used for user-configurable table sorting
    public                  propertyName:               string       = "name";
    public                  doReverse:                  boolean      = false;
    public                  search:                     any          = '';
    public                  currentPage:                number       = 1;

    public                  newPageNumber:              number;
    public                  oldPageNumber:              number;


    constructor(private readonly DeviceGroupsService:     DeviceGroupsService,
                // private readonly qp_WindowFactory:        qp_WindowService,
                private readonly qp_WindowInsightFactory: InsightsWindowService,
                private readonly dialogRef:               MatDialogRef<DeviceGroupsListComponent>)
    {
        super();
    }


    //
    // Public functions
    //
    public refresh(): void
    {
        this.DeviceGroupsService.refresh();
    }


    public close(): void
    {
        if (this.dialogRef) this.dialogRef.close();
    }


    public compare(): void
    {
        const groupsSelected: any[] = [];
        for (let i: number = 0, len = this.data.length; i < len; ++i) {
            if (this.data[i] && this.data[i].name && this.selected[this.data[i].name]) {
                groupsSelected.push(this.data[i]);
            }
        } // for

        if (groupsSelected.length > 0) {
            // Send to KpiController
            this.qp_WindowInsightFactory.showScreen({groups: groupsSelected}, {});
        }
    }


    public getColour(key: any, cell: any): string
    {
        return (cell && cell.colour) ? cell.colour : "";
    }


    public groupSelected(group: any): void
    {
console.log(group);
        if (group && group.name) {
            this.selected[group.name] = ! this.selected[group.name];
        }

        // Check if max groups selected
        let compareAvailable               = true;
        let compareCheckboxesAvailable     = false;

        Object.keys(this.selected).forEach((key: string) => {
            if (this.selected[key]) {
                compareCheckboxesAvailable = true;
                if (++this.selected >= DeviceGroupsListComponent.maxGroupsSelected) {
                    compareAvailable       = false;
                }
            }
        });

        this.compareAvailable           = compareAvailable;
        this.compareCheckboxesAvailable = compareCheckboxesAvailable;
    }


    public pageChanged(new_: number, old: number): void
    {
        console.log("Page changed: " + new_ + " to " + old);
    }


    public update(): void
    {
        // const pData: any = this.DeviceGroupsService.groups;
        // for (let i: number = 0, len = pData.length; i < len; ++i) {
        //     Object.keys(pData[i]).forEach((key: any) => {
        //         if (this.isObject(pData[i][key])) {
        //             pData[i][key] = Object.keys(pData[i][key]).length;
        //         }
        //     });
        // } // for

        // //this.qp_TimeoutFactory.processViaTimeout(() => {
        //     this.data = pData;

        // //});
    }


    public showDetails($event: any, group: any): void
    {
        if (group) {
            console.log("Device group '" + group.name + "' selected for details");
            //qp_WindowFactory.showWindow($event, 'qp_DeviceInfoController', 'window-device-info.html', dev, null, null);//, angular.element(document.querySelector('#test')) );
        }
    }


    public sortBy(propertyName2: string): void
    {
        const propertyName: string = propertyName2;
        this.doReverse             = (this.propertyName === propertyName) ? ! this.doReverse : false;
        this.propertyName          = propertyName;
    }


    //
    // Protected functions
    //
    protected override initialise(): void
    {
        super.initialise();
        
        console.log("Initialising DeviceGroupsList Controller");

        // this.sub = this.DeviceGroupsService.updatedGroups.subscribe((num: any) => {
        //     this.update();
        // });
    }


    //
    // Private functions
    //
    private isObject(item: any): boolean
    {
        return (typeof item === "object" && ! Array.isArray(item) && item !== null);
    }
}