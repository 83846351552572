import { CommonModule }       from '@angular/common';
import { NgModule }           from '@angular/core';
import { RouterModule }       from '@angular/router';

import { DeviceGroupsModule } from './device-groups.module';

import { devicegroupsRoutes } from './device-groups.routes';


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(devicegroupsRoutes), // lazy loading

        DeviceGroupsModule
    ],
    
    declarations: [
    ],

    providers: [
    ],

    exports: [
    ]
})
export class DeviceGroupsModuleLazyLoad { }