import { Injectable } from '@angular/core';

//import { AmChartsService, AmChart} from "@amcharts/amcharts3-angular";

declare var AmCharts: any;


@Injectable({
    providedIn: 'root'
})
export class ChartBarService
{
    constructor() { 
        //constructor(private AmCharts: AmChartsService) { 
        this.initialise();
    }


    //
    // Public Functions
    //
    public getChart(title: string, valueAxisTitle: string, data: any, chartOptions?: any, graphOptions?: any): object
    {
        console.log(data);
        let chart                        = new AmCharts.AmSerialChart(chartOptions.theme);
        Object.assign(chart, chartOptions);

        chart.dataProvider               = data;
        chart.categoryField              = "cat";//"col";

        chart.graph = new AmCharts.AmGraph(chartOptions.theme);
        Object.assign(chart.graph, graphOptions);
        chart.graph.autoColor            = true;
        chart.graph.balloonText          = "[[value]] " + valueAxisTitle;

        chart.graph.balloonFunction      = function(item: any, graph: any): string {
            const ret = item.values.value + " " + valueAxisTitle;
            return item.category ? item.category + ": " + ret : ret;
        }

        //chart.graph.balloonText          = "<b>[[category]]: [[value]]</b>";
        chart.graph.fillColorsField      = "color";
        chart.graph.labelText            = "[[value]]";
        chart.graph.topRadius            = 1;  
        chart.graph.type                 = "column";
        chart.graph.valueField           = "val";
        chart.addGraph(chart.graph);

        chart.categoryAxis.labelRotation = 45;
        chart.categoryAxis.gridPosition  = "start"
        chart.categoryAxis.gridAlpha     = 0;
        chart.categoryAxis.tickPosition  = "start";
        chart.categoryAxis.tickLength    = 20;
        chart.categoryAxis.axisAlpha     = 0;

        let valueAxis: any                    = {};
        valueAxis.axisAlpha              = 0;
        valueAxis.gridColor              = "#FFFFFF";
        valueAxis.gridAlpha              = 0.2;
        valueAxis.dashLength             = 0;
        valueAxis.axisAlpha              = 0;
        valueAxis.title                  = valueAxisTitle;
        valueAxis.titleRotation          = 0;
        valueAxis.position               = "left";
        chart.valueAxes                  = [valueAxis];

        //chart.chartCursor = {};
        //chart.categoryBalloonEnabled = false;
        //chart.chartCursor.cursorAlpha = 0;
        //chart.chartCursor.zoomable = true;


        //                               "valueAxes": [
        //                        {
        //                            "axisAlpha":          0,
        //    "gridColor": "#FFFFFF",
        //    "gridAlpha": 0.2,
        //    "dashLength": 0,
        //                            "title":              title,
        //                            "titleRotation":      0
        //                        }
        //                    ],

        if (valueAxisTitle == "%") {
            chart.valueAxes[0].maximum       =  100;
            chart.valueAxes[0].autoGridCount =  false;
            chart.valueAxes[0].gridCount     =  5;
        }
        return chart
    }


    //
    // Private functions
    //
    private initialise(): boolean
    {
        console.debug("Initialising ChartBar service");

        return true;
    }
}