import { CommonModule }          from '@angular/common';
import { NgModule }              from '@angular/core';
import { RouterModule }          from '@angular/router';

import { GuiElementsModule }     from '@GuiElements/';
import { MaterialCustomModule }  from '@Material/';
import { ObjElementsModule }     from '@ObjElements/';

import { NotificationComponent } from './notification.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        GuiElementsModule,
        MaterialCustomModule,
        // ObjElementsModule
    ],
    
    declarations: [
        NotificationComponent
    ],

    providers: [
    ],

    exports: [
        NotificationComponent
    ]
})
export class NotificationModule { }