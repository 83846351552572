import { CommonModule }           from '@angular/common';
import { NgModule }               from '@angular/core';
import { RouterModule }           from '@angular/router';
import { NgbModule }              from '@ng-bootstrap/ng-bootstrap';

import { MaterialCustomModule }   from '@Material/';

import { MapComponent }           from './map.component';
import { MapContainerComponent }  from './map-container.component';

import { MapControlsModule }      from './map-controls/map-controls.module';
import { MapInfoWinModule }       from './map-infowin/map-infowin.module';
import { MapLayerControlsModule } from './map-layers/map-layer-controls/map-layers-control.module';
import { MapLayersModule }        from './map-layers/map-layers.module';
// import { MapService }             from './map.service';


@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,

        MaterialCustomModule,

        MapControlsModule,
        MapInfoWinModule,
        MapLayerControlsModule,
        MapLayersModule
    ],
    
    declarations: [
        MapComponent,
        MapContainerComponent
    ],

    providers: [
        // MapService
    ],

    exports: [
        MapComponent
    ]
})
export class MapModule { }