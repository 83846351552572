import {
    Component,
    ViewChild
}                              from '@angular/core';
import { Observable }          from 'rxjs';

import { NgbDropdown }         from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent }       from '@Base/';
import { DeviceGroupsService } from '@DeviceGroups/';
import { IconsMaterial }       from '@Icons/';
import { MessageService }      from '@Messaging/';

import { MapLayersService }    from '../map-layers/map-layers.service';
import { MapService }          from '../map.service';
// import {
//     //qp_WindowInsightFactory
// }                              from '@Utils-ajs/';


@Component({
    selector:     'qp-map-controls',
    templateUrl:  'map-controls.component.html',
    styleUrls:   ['map-controls.component.css']
})
export class MapControlsComponent extends BaseComponent
{
    public readonly iconCampaign = IconsMaterial.campaign;
    public readonly iconDevices  = IconsMaterial.devices;
    public readonly iconInsights = IconsMaterial.insights;
    public readonly iconRefresh  = IconsMaterial.refresh;

    @ViewChild('map', { static: false })
    private elemGroupInstances: NgbDropdown;
 
    public _device:         any    = {};
    public _devices:        any    = [];
    public _groupInstances: any    = [];
    public _groupInstance:  any    = null;
    public _serialNum:      string = "";


    constructor(
        //$scope, $document, $window,
                private readonly DeviceGroupsService: DeviceGroupsService,
                private readonly MapLayersService:    MapLayersService,
                private readonly MapService:          MapService,
                private readonly MessageService:      MessageService)
                //private readonly qp_WindowInsightFactory: qp_WindowInsightFactory)
    {
        super();
    }


    // Override - don't let base class call this.initialise()
    public override ngOnInit(): void
    {
        // Call this.initialise() in ngAfterContentInit() to ensure view fully set up
    }


    // Override
    public override ngAfterContentInit(): void // don't use ngAfterViewInit() as that can produce ExpressionChangedAfterItHasBeenCheckedError error
    {
        super.ngAfterContentInit();

        // Do here to call only after view fully set up
        this.initialise();
    }


    //
    // Getters and setters
    //
    public get device(): any
    {
        return this._device;
    }


    public set device(dev: any)
    {
        this._device = dev;
    }


    public get devices(): any
    {
        return this._devices;
    }


    public set devices(devs: any)
    {
        this._devices = devs;
    }


    public get groupInstance(): any
    {
        return this._groupInstance;
    }


    public set groupInstance(gi: any)
    {
        this._groupInstance = gi;
    }


    public get groupInstances(): any
    {
        return this._groupInstances;
    }


    public set groupInstances(gis: any)
    {
        this._groupInstances = gis;
    }


    //
    // Public functions
    //
    public buttonRefresh(): void
    {
        console.log("Button pressed - refreshing map");
        this.refresh(true);
    }


    public deviceSelected(dev: any): any
    {
        if (dev) {
            if (this._serialNum == dev.serialNum) {
                this._serialNum = "";
                console.log("No device now selected: " + this._serialNum);
                //if (! this.groupInstance) {
                    this.MapLayersService.refresh();
                //}
            }
            else {
                this._serialNum = dev.serialNum;
                console.log("Device selected is now: " + this._serialNum);
                console.log(dev);

                const obj: any = {
                    type:      this.MessageService.messages.msgTypesSub.devicelocations,
                    serialNum: this._serialNum,
                    operator:  dev.operator
                };
                if (this.groupInstance) {
                    obj.startDate = this.groupInstance.startDate;
                    obj.endDate   = this.groupInstance.endDate;
                }
                this.MessageService.sendMsg(this.MessageService.messages.msgTypes.getdata, obj);

                obj.type = this.MessageService.messages.msgTypesSub.testresults,
                this.MessageService.sendMsg(this.MessageService.messages.msgTypes.getdata, obj);
            }
        } // if dev

        return false;
    }


    public groupInstanceSelected(instance: any): any
    {
    //     if (instance) {
    //         if (this.groupInstance != instance) this.groupInstance = instance;
    //         this.devices = this.groupInstance.devices ? this.groupInstance.devices : [this.groupInstance.lead];
    //         this.device  = this.groupInstance.lead;

    //         console.log("Group instance selected is now:\n" + JSON.stringify(this.groupInstance));

    //         // Use observable
    //         const obs: Observable<any> | undefined = this.DeviceGroupsService.getInstance(instance);
    //         if (obs instanceof Observable) {
    //             return (this.sub = obs.subscribe((data: any): void => {
    //                 this.MapLayersService.processDeviceLocations(this.MessageService.messages.msgTypesSub.devicelocations, data);
    //             }));
    //         }

    //         if (this.elemGroupInstances.isOpen()) this.elemGroupInstances.close(); // close menu
    //  //       return false; // required to stop spurious routing to main page
    //     }
        // if (newGroupInstance) {
        //     if (this.groupInstance != newGroupInstance) this.groupInstance = newGroupInstance;
        //     const obj: any = {
        //         type:      this.MessageService.messages.msgTypesSub.devicelocations,
        //         serialNum: this._serialNum,
        //         startDate: this.groupInstance.startDate,
        //         endDate:   this.groupInstance.endDate,
        //         operator:  this.groupInstance.leadOperator
        //     };


        //     //if (this.groupInstance != newGroupInstance) {
        //         //this.groupInstance = newGroupInstance;
        //         this.devices       = this.groupInstance.devices ? this.groupInstance.devices : [newGroupInstance.lead];

        //         console.log("Group instance selected is now: ");
        //         console.log(this.groupInstance);
        //     //}

        //     let device: any = this.groupInstance.lead;
        //     if (! device && this.devices && this.devices.length > 0 && this.devices[0]) {
        //         device = this.devices[0].serialNum;
        //     }
        //     this._serialNum = device;
        //     obj.device = device;
        //     this.MessageService.sendMsg(this.MessageService.messages.msgTypes.getdata, obj);
        //     console.log(this.MessageService.messages.msgTypes.getdata);
        //     console.log(obj);

        //     this.MapLayersService.refresh(); // will call refresh() on MapService
        // } // if newGroupInstance
    }


    public refresh(getGroupInstances: boolean): boolean
    {
        //this.qp_TimeoutFactory.processViaTimeout(() => {
            this.device        = {};
            this.groupInstance = null;
            //$scope.$broadcast('closeL');
            //qp_MapSidebarFactory.closeNav('map-sidebar-layers');
        //});
        //refreshPhoneList();
        //if (getGroupInstances) this.DeviceGroupService.refresh();
        this.update();
        this.MapService.refresh();
        this.MapLayersService.refresh();



        return true;
    }


    public showDeviceInsights(group: any)
    {
        // Get any extra layer data to send to KPI screen
        const chartData: any[] = [];
        for (let i in this.MapLayersService.mapLayers) {
            if (i) {
                const data: any = this.MapLayersService.getLayerData(i);
                if (data) chartData.push({
                    type: data.type,
                    data: data.data
                });
            }
        } // for
        //this.qp_WindowInsightFactory.showScreen({startDate: group.startDate, endDate: group.endDate, groups: [group], charts: chartData});
    }


    public showGraph(): any
    {
        return this.MapLayersService.showGraph('mobileTech');
    }


    //
    // Protected functions
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.log("Initialising Map Controls component");

        // this.sub = this.DeviceGroupsService.updatedInstances.subscribe((num: number): void => {
        //     this.update();
        // });

        this.update();
    }


    //
    // Private functions
    //
    private processReceivedEvent(event: any, data: any): any
    {
        return this.MapLayersService.update(event.name, data);
    }


    private processReceivedEvent2(event: any, data: any): any
    {
        console.log("Received '" + event.name + "'" + (data.serialNum ? " (" + data.serialNum + ")" : ""));
    }


    private update(): void
    {
        // this.groupInstances = this.DeviceGroupsService.instances;
    }
}