<div class="container">
    <!-- Page header -->
    <header>
        <qp-header></qp-header>
    </header>

    <!-- Page content -->
    <div class="content">
        <router-outlet></router-outlet>
    </div>

    <!-- Page footer -->
    <footer>
        <qp-footer></qp-footer>
    </footer>
</div>