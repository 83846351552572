<div class="content">
    <!-- <a [routerLink]="['..']"><h4>Back to surveys list</h4></a> -->
    <!-- <qp-route-back [route]="['..']"></qp-route-back> -->

    <qp-window-header
        [title]="name" 
        [value]="data?.name ? (data?.name | titlecase) : data?.id"
        [routeBack]="['..']"

        (refresh)="refresh()">
    </qp-window-header>


    <!-- <qp-survey-info [data]=data></qp-survey-info> -->
    <div class="content2" [hidden]="! data"> 
        <!-- <qp-element-info-container (selected)="select($event)" fxFlex="auto" class="content2" -->
        <qp-device-group-info (selected)="select($event)" [showName]="false" fxFlex="auto" class="content2"
            [data]="data"
        >
        </qp-device-group-info>
        <!-- </qp-element-info-container> -->
    </div>


    <div>
        <button mat-button color="primary" [disabled]="! data?.online || (data?.isStarted() && ! data?.isFinished())" 
            (click)="button(ButtonType.play)" matTooltip="Start test">
            <mat-icon aria-label="Start test">play_circle</mat-icon>
        </button>
        
        <button mat-button color="primary" [disabled]="! data?.isStarted() || data?.isFinished()"
            (click)="button(ButtonType.stop)" matTooltip="Stop test">
            <mat-icon aria-label="Stop test">stop_circle</mat-icon>
        </button>
    </div>




    <mat-tab-group
        mat-stretch-tabs="false"
        dynamicHeight="true"
        preserveContent="false"
    >
        <mat-tab>
            <ng-template mat-tab-label>
                <!-- <mat-icon class="example-tab-icon">thumb_up</mat-icon> -->
                Devices
            </ng-template>

            <ng-template matTabContent>
                <qp-devices *ngIf="data?.deviceList.length > 0"
                    [data]=data?.deviceList [parentId]=+data?.id>
                </qp-devices>
            </ng-template>
        </mat-tab>

        
        <mat-tab>
            <ng-template mat-tab-label>
                <!-- <mat-icon class="example-tab-icon">thumb_up</mat-icon> -->
                Results
            </ng-template>

            <ng-template matTabContent>
                <qp-devices *ngIf="data?.deviceList.length > 0"
                    [data]=data?.deviceList [parentId]=+data?.id>
                </qp-devices>
            </ng-template>
        </mat-tab>


        @if (mapEnable) {
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>map</mat-icon>
                    View on Map
                </ng-template>

                <ng-template matTabContent>
                    <div class="map">
                        <qp-map [data]="data?.deviceList"></qp-map>
                    </div>
                </ng-template>
            </mat-tab>
        }
    </mat-tab-group>

</div>