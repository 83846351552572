import { Component }              from '@angular/core';
import {
    ActivatedRoute,
    ParamMap
}                                 from '@angular/router';

import { BaseComponent }          from '@Base/';
import { MessageService }         from '@Messaging/';
import { 
    // ElementService,
    ElementsService,
    ProgressSpinnerDialogService
}                                 from '@Misc/Services';
import {
    DeviceGroup,
    Element,
    ElementCommon as ElementC
}                                 from '@ObjElements/';

import { DeviceGroupService }     from './device-group.service';


enum ButtonType {
    play,
    stop
}; // enum


@Component({
    selector:     'qp-devicegroup',
    templateUrl:  'device-group.component.html',
    styleUrls:   ['device-group.component.css'],
    providers:   [ DeviceGroupService, ElementsService ] // scope services to this component only; need to this here for ElementsService
})
export class DeviceGroupComponent extends BaseComponent
{
    public readonly name:      string                  = "Device Group";

    private         _id:       number                  = -1;
    public          data:      DeviceGroup | undefined;
    public          mapEnable: boolean                 = true;


    constructor(private readonly ActRoute:                     ActivatedRoute,
                private readonly DeviceGroupService:           DeviceGroupService,

                // private readonly ElementService:               ElementService,
                private readonly MessageService:               MessageService,
                private readonly ProgressSpinnerDialogService: ProgressSpinnerDialogService)
    {
        super();

        // this.ElementService.srv  = DeviceGroupsService;
        // this.ElementService.event = this.MessageService.messages.events.devicegroup;
        // this.ElementService.type  = this.MessageService.messages.msgTypesInfo.devicegroups;
    }

    public get ButtonType(): typeof ButtonType
    {
        return ButtonType;
    }


    //
    // Public methods
    //
    public button(type?: ButtonType): void
    {
        let cmd: string | undefined = undefined;
        switch (type) {
            case ButtonType.play:
                cmd = this.MessageService.messages.msgTypesAction.start;
                break;

            case ButtonType.stop:
                cmd = this.MessageService.messages.msgTypesAction.stop;
                break;

            default:
                // Do nothing
        } // switch

        if (cmd) {
            // Will trigger messge to server
            this.DeviceGroupService.set(
                cmd,
                cmd === this.MessageService.messages.msgTypesAction.stop
                    ? this.data?.instanceid
                    : this._id
            ); // sendMsgSet
        }
    }


    public refresh(id?: number): void
    {
        const idL: number = id ? id : this._id;
        if (idL >= 0) this.DeviceGroupService.id = idL; // triggers data look-up
    }


    public select(d: any): any
    {
        console.error(d)
    }


    //
    // Protected functions
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising DeviceGroup component");
        
        this.ProgressSpinnerDialogService.show(true);
        this.sub = this.ActRoute.paramMap.subscribe((params: ParamMap): void => {
            // this.sub = this.ElementService.added.subscribe((data: Element | undefined): void => {
            //     this.data = DeviceGroup.get(data);
            //     this.ProgressSpinnerDialogService.clear();
            // }); // subscribe()

            const s: string | null = params?.get("id");
            if (! isNaN(Number(s)) ) this.refresh(this._id = Number(s));

            this.sub = this.DeviceGroupService.updated$.subscribe((data: Element | ElementC | undefined): void => {
                this.data = DeviceGroup.get(data);
                // console.error(this.data)
                this.ProgressSpinnerDialogService.clear();
            }); // subscribe()

        }); // subscribe()
    }


    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();
        this.DeviceGroupService.id = undefined; // clear service
    }
}