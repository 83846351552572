<!-- <div ngbDropdown *ngIf="enabled" placement="top-left" autoClose='outside' class="d-inline-block">
    <button mat-raised-button color="primary" ngbDropdownToggle id="dropdown1" matTooltip="Select organisations to show">
        <mat-icon aria-label="Select organisation">{{ icon }}</mat-icon>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdown1">
        <div *ngFor="let d of organisationsK">
            <mat-checkbox color="accent" ngbDropdownItem name="d.name" [checked]="isChecked(d)" (change)="changedState($event, d)">
                {{ d.name | titlecase }}
            </mat-checkbox>
        </div>
    </div>
</div> -->


<mat-form-field *ngIf="enabled" class="d-inline-block">
    <mat-label>
        Organisations
        <!-- <button mat-raised-button color="primary" id="dropdown1" matTooltip="Select organisations to show"> -->
            <mat-icon aria-label="Select organisation">{{ icon }}</mat-icon>
        <!-- </button> -->
    </mat-label>
    <!-- <mat-select [(ngModel)]="val" (selectionChange)="changedState2($event, undefined)" aria-labelledby="dropdown1" multiple> -->
    <mat-select [(ngModel)]="val" aria-labelledby="dropdown1" multiple>
        <mat-option *ngFor="let d of organisationsK" [value]="d">
            {{ d.name | titlecase }}
            <!-- <mat-checkbox color="accent" name="d.name" [checked]="isChecked(d)" (change)="changedState($event, d)">
                {{ d.name | titlecase }}
            </mat-checkbox> -->
        </mat-option>
    </mat-select>
</mat-form-field>