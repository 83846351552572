import { Injectable }             from '@angular/core';

import { ChartService }           from '@Charts/';
// import { KpiObjectsModule }       from '@Common/qp_KpiObjects';

import { KpisChartLayoutService } from './kpis-chart-layout.service'
import { KpisDataService }        from './kpis-data.service'


@Injectable({
    providedIn: 'root'
})
export class KpisChartService
{
    private readonly fnKpiPeriodic = 'getKpiPeriodic';
    private readonly fnKpiOneShot  = 'getKpiOneShot';

    constructor(private readonly qp_ChartsFactory:         ChartService,
                private readonly qp_KpiChartLayoutFactory: KpisChartLayoutService,
                private readonly qp_KpiDataFactory:        KpisDataService)
                // private readonly qp_KpiObjectsFactory:     KpiObjectsModule)
    {
        this.initialise();
    }


    //
    // Public Functions
    //
    public getKpiChart(service: any, kpi: any, kpiType: string, theme?: any, chartData?: any, listenerFn?: any): any
    {
        let chart: any;

        if (kpi) {
            const chartDetails: any = this.qp_KpiChartLayoutFactory.getChartDetails(kpi);
            if (! chartData) {
                //const kpiDataFn = kpiType == 'oneshot' ? fnKpiOneShot : fnKpiPeriodic;
                // if (kpiType == 'oneshot') {
                //     kpiDataFn  = 'getKpiOneShot';
                // }
                chartData = this.qp_KpiDataFactory[kpiType == 'oneshot' ? this.fnKpiOneShot : this.fnKpiPeriodic](kpi);

                console.log(chartData);
                if (chartData.result) {
                    console.log(chartData.result);
                    console.log(chartData.result.all);
                    console.log(chartData.result.all_);
                    console.log(chartData.result.all_.average);
                    console.log(chartData.result.all_.average_);
                    chartData = [{"cat" : "all", "val" : chartData.result.all_.average_}];
                }
            }
            if (chartDetails && chartData) {
                // Now generate chart
                chart = this.qp_ChartsFactory.getChart(chartDetails.type, chartDetails.chartTitle, chartDetails.yTitle, chartDetails.threeD, theme, listenerFn, chartData);
                if (chart) chart.name = kpi;
            }
        } // kpi

        return chart;
    }
    

    public getKpiChartDisplay(service: any, kpi: any, theme: any, data: any, ev: any, fn: any): any
    {
        const chartEvents: string[] = [
            'animationFinished',
            'dataUpdated',
            'drawn',
            'init',
            'rendered',
            'zoomed'
        ];

        const chartThemeDefault: string = "dark";


        const chart: any = this.getKpiChart(service, kpi, 'oneshot', theme ? theme : chartThemeDefault, data);
        if (chart && fn && typeof fn === 'function') {
            if (ev) chart.addListener(ev, fn);
            else {
                for (var i in chartEvents) {
                    if (chartEvents[i]) chart.addListener(chartEvents[i], fn);
               } // for
            } // ev
        }

        return chart;
    }


    //
    // Private Functions
    //
    private initialise(): boolean
    {
        console.log("Initialising KpisData service");

        return true;
    }
}