import { CommonModule }         from '@angular/common';
import { NgModule }             from '@angular/core';

import { MaterialCustomModule } from '@Material/';

import { ServicesComponent }    from './services.component';


@NgModule({
    imports: [
        CommonModule,

        MaterialCustomModule
    ],
    
    declarations: [
        ServicesComponent
    ],

    providers: [
    ],

    exports: [
        ServicesComponent
    ]
})
export class ResultsModule { }