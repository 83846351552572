import { CommonModule }                    from '@angular/common';
import { NgModule }                        from '@angular/core';
import { NgxPaginationModule }             from 'ngx-pagination'; 
import { OrderModule }                     from 'ngx-order-pipe';
import { RouterModule }                    from '@angular/router';

// qp-refresh-button
import { GuiElementsModule }               from '@GuiElements/';
import { MaterialCustomModule }            from '@Material/';
// qp-list 
import { MiscModule }                      from '@Misc/';

import { OrganisationsComponent }          from './organisations.component';
import { OrganisationsContainerComponent } from './organisations-container.component';


@NgModule({
    imports: [
        CommonModule,
        NgxPaginationModule,
        OrderModule,
        RouterModule,

        GuiElementsModule,
        MaterialCustomModule,
        MiscModule
    ],
    
    declarations: [
        OrganisationsComponent,
        OrganisationsContainerComponent
    ],

    providers: [
    ],

    exports: [
        OrganisationsComponent
        // OrganisationsContainerComponent
    ]
})
export class OrganisationsModule { }