import { CommonModule }                    from '@angular/common';
import { NgModule }                        from '@angular/core';
import { RouterModule }                    from '@angular/router';

import { NgbModule }                       from '@ng-bootstrap/ng-bootstrap';

import { MaterialCustomModule }            from '@Material/';

import { MapLayersComponent }              from './map-layers-control.component';
import { MapLayersContainerComponent }     from './map-layers-control-container.component';
import { MapLayersOrganisationsComponent } from './map-layers-control-organisations.component';


@NgModule({
    imports: [
        CommonModule,
        MaterialCustomModule,
        NgbModule,
        RouterModule
    ],
    
    declarations: [
        MapLayersComponent,
        MapLayersContainerComponent,
        MapLayersOrganisationsComponent
    ],

    providers: [
    ],

    exports: [
        MapLayersContainerComponent
    ]
})
export class MapLayerControlsModule { }