import { 
   Injectable,
   ComponentFactory
}                                       from '@angular/core';
import {
   ApplicationRef,
   ComponentFactoryResolver,
   ComponentRef,
   Injector
}                                       from '@angular/core';

import { BaseService }                  from '@Base/';

import {
   MapElementMarker,
   MapElementInfoWin,
   MapElementMap
}                                       from '../map-elements/';
import { MapInfoWinContainerComponent } from './map-infowin-container.component';
// import { MapModule }                    from '../map.module';


@Injectable({
   providedIn: 'root' //MapModule
})
export class MapInfoWinService extends BaseService
{
   private static readonly divName:  string = "div";

   private                 _compRef: ComponentRef<MapInfoWinContainerComponent> | undefined;
   private                 _div:     HTMLDivElement;
   private                 _infoWin: MapElementInfoWin; // Single infoWindow associated with map


   constructor(private readonly AppRef:   ApplicationRef,
               private readonly Injector: Injector,
               private readonly Resolver: ComponentFactoryResolver)
    {
        super();
    }


    //
    // Public methods
    //
    public close(): void
    {
        this.cleanUp();
    }


    // Override
    public override ngOnDestroy(): void
    {
        super.ngOnDestroy();

        this.cleanUp();

        console.debug("Destroyed MapInfoWin service");
    }


    public open(map: MapElementMap, marker: MapElementMarker): void
    {
        // Dynamically create component if not already created
        if (! (this._compRef instanceof ComponentRef)) {
            const compFactory: ComponentFactory<MapInfoWinContainerComponent>
                = this.Resolver.resolveComponentFactory(MapInfoWinContainerComponent);
            this._compRef = (compFactory instanceof ComponentFactory)? compFactory.create(this.Injector) : undefined;
            if (this._compRef instanceof ComponentRef) {
                this.AppRef.attachView(this._compRef.hostView);
                if (this._div = document.createElement('div')) {
                    this._div.appendChild(this._compRef.location.nativeElement);
                    this._infoWin.setContent(this._div);
                }
            }
        }

        // Set dynamic component marker and open infoWin
        if (this._infoWin instanceof MapElementInfoWin
                && marker instanceof MapElementMarker
                && this._compRef instanceof ComponentRef)
            this._infoWin.open(map, this._compRef.instance.marker = marker);
    }


    public toggle(map?: any, marker?: MapElementMarker): boolean
    {
        if (marker) {
            if (this._infoWin instanceof MapElementInfoWin
                    && this._infoWin.isOpenL
                    && marker.id === this._infoWin.id)
                this.close();
            else this.open(map ? map : marker.getMap(), marker);
        }
        else {
            if (this._infoWin instanceof MapElementInfoWin) this.close();
            // else                 this.open(map, marker);
        }

        return this._infoWin.isOpenL;
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        if (this._infoWin instanceof MapElementInfoWin) this._infoWin.close();

        if (this._compRef instanceof ComponentRef) this._compRef.destroy();
        this._compRef = undefined;
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        if (! (this._infoWin instanceof MapElementInfoWin)) this._infoWin = new MapElementInfoWin();

        console.debug("MapInfoWin service initialised");
    }
}