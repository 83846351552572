import {
    AfterViewInit,
    Component,
}                               from '@angular/core';
import {
    Observable,
    Subscription,

    map
}                               from 'rxjs';

import { BaseComponent }        from '@Base/';
import { IconsMaterial }        from '@Icons/';
import { MatCheckboxChange }    from '@Material/';
import { DataServiceEvents }    from '@Misc/Services/';
import { Organisation }         from '@ObjElements/';
import { OrganisationsService } from '@Organisations/';
import { SitesService }         from '@Sites/';
import { Utils }                from '@Utils/';

import { MapLayer }             from '../map-layer/map-layer.class';
import { MapLayerType }         from '../map-layer/map-layer-type.class';
import { MapLayersService }     from '../map-layers.service';


@Component({
    selector:     'qp-map-layers-control-organisations',
    templateUrl:  'map-layers-control-organisations.component.html',
    styleUrls:   ['map-layers-control-organisations.component.css']
    //providers:   [ MapLayersService ] // non-singleton, will destroy service when component destroyed
})
export class MapLayersOrganisationsComponent extends BaseComponent implements AfterViewInit
{
    public readonly  icon:            string                     = IconsMaterial.organisations;

    public           enabled:         boolean                    = false;

    private readonly _organisations:  Map<Organisation, boolean> = new Map();
    private          _organisations$: Subscription | undefined;

    private _val: any;
  
    
    public constructor(private readonly MapLayersService:     MapLayersService,
                       private readonly OrganisationsService: OrganisationsService,
                       private readonly SitesService:         SitesService)
    {
        super();
    }


    // Override
    public override ngOnInit(): void
    {
        // Prevent calling initialise() here
    }


    // Override
    public override ngAfterViewInit(): void
    {
        super.ngAfterViewInit();

        this.initialise();
    }


    //
    // Getters
    //
    public get organisationsK(): Organisation[]
    {
        return [...this._organisations.keys()].sort((a, b) => { // '...' spread operator
            return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0);
        }); // sort
    }


    private get organisationsV(): boolean[]
    {
        return [...this._organisations.values()]; // '...' spread operator
    }


    public get val(): any
    {
        return this._val;
    }

    public set val(d: any)
    {
        this._val = d;
    }


    //
    // Public functions
    //
    public changedState2(event: Event, d: Organisation | undefined): void
    {
        console.error(event)
        console.error(d)
        // e.checked is new, target value
        if (event && d instanceof Organisation && this.MapLayersService) {
            this.orgAdd(d, !!!this._organisations.get(d));// event.checked);

            this.MapLayersService.setMapPointVisibility(d.id, !!this._organisations.get(d));
        }
    }

    public changedState(event: MatCheckboxChange, d: Organisation): void
    {
        // e.checked is new, target value
        if (event && d instanceof Organisation && this.MapLayersService) {
            this.orgAdd(d, event.checked);
            this.MapLayersService.setMapPointVisibility(d.id, !!this._organisations.get(d));
        }
    }


    public isChecked(d: Organisation): boolean 
    {
        return !!this._organisations.get(d);
    }


    //
    // Protected functions
    //
    
    // Override
    protected override cleanUp(): void
    {
        this.cleanUpLocal();

        super.cleanUp();
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Map Layers Organisations component");

        if (this.MapLayersService instanceof MapLayersService) {
            // Listen for updates to organisations map layer

            const obs: Observable<MapLayer[]> | undefined = this.MapLayersService[DataServiceEvents.data];
            if (obs instanceof Observable) this.sub = obs
                .pipe(
                    map((d: MapLayer[]): MapLayer | undefined => {
                        return Array.isArray(d) ? d.find(l => l && l.name === MapLayerType.organisations?.key) : undefined;
                    }) // map - return the relevant layer object if found
                ) // pipe

                .subscribe((d: MapLayer | undefined): void => {
                    // Only do if not already enabled; else will trigger double-refresh
                    if (! this.enabled) {
                        // Workaround to avoid ExpressionChangedAfterItHasBeenCheckedError error
                        Utils.delaySecs(() => {
                            if (this.enabled = !!(d instanceof MapLayer && d.layer && d.layer.gmap)) {
                                if (! this._organisations$) {
                                    const obs2: Observable<object[]> | undefined = this.OrganisationsService[DataServiceEvents.data];
                                    if (obs2 instanceof Observable) this._organisations$ = obs2
                                        .subscribe((d2: object[]): void => {
                                            if (d2) {
                                                // Only update if not already present
                                                d2.filter(o => ! this.organisationsK.includes(o as Organisation)).forEach((o: object): void => {
                                                    this.orgAdd(o as Organisation, true, false);
                                                }); // filter

                                                this.organisationsK.filter(o => ! d2.includes(o)).forEach((o: object): void => {
                                                    this.orgDel(o as Organisation, false);
                                                }); // filter

                                                this.orgChange();
                                            }
                                        }); // subscribe
                                }
                            }
                            else {
                                this.cleanUpLocal(); // deletes SitesLayer
                            }
                        }); // Utils.delay
                    }
                }); // subscribe MapLayersService
        }
        else {
            console.debug("Unable to configure Map Layers Organisations Service");
        } // MapLayersService
    }


    //
    // Private methods
    //
    private cleanUpLocal(): void
    {
        this._organisations.clear();
        
        if (this._organisations$) this._organisations$.unsubscribe();
        this._organisations$ = undefined;

        this.deleteSitesLayer();
        this.SitesService.clear();
    }


    private createSitesLayer(): MapLayer | undefined
    {
        if (! this.MapLayersService.getLayer(MapLayerType.sites?.key)) {
            // Create sites layer if not already created
            this.MapLayersService.createLayer(
                MapLayerType.sites?.key,
                this.SitesService,
                MapLayerType.sites?.key,
                MapLayerType.sites?.key
            ); // createLayer
        }

        return this.MapLayersService.getLayer(MapLayerType.sites?.key);
    }


    private deleteSitesLayer(): void
    {
        if (this.MapLayersService.getLayer(MapLayerType.sites?.key)) {
            this.MapLayersService.clearLayer(MapLayerType.sites?.key, true);
        }
    }


    private orgAdd(d: Organisation, enabled: boolean, update: boolean = true): Map<Organisation, boolean>
    {
        const ret: Map<Organisation, boolean> = this._organisations.set(d, enabled);
        if (update && ret) this.orgChange();

        return ret;
    }


    private orgDel(d: Organisation, update: boolean = true): boolean
    {
        const ret: boolean = this._organisations.delete(d);
        if (update && ret) this.orgChange();

        return ret;
    }


    private orgChange(): void
    {
        if (this.organisationsV.includes(true)) {
            if (this.createSitesLayer()) {
            // if (this.MapLayersService.getLayer(MapLayerType.sites.key)) {
                this.SitesService.setData(this.organisationsK.filter(o => this._organisations.get(o)) );
            }
        }
        else this.deleteSitesLayer();
    }
}