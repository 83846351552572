import {
    EventEmitter,
    Injectable
}                             from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    Subscription
}                             from 'rxjs';

import { BaseService }        from '@Base/';
import { MessageService }     from '@Messaging/';

import { Organisation }       from '@ObjElements/';

import { OrganisationModule } from './organisation.module';


// [TBD] notify if device updated

@Injectable({
   // don't make singleton 
   providedIn: 'root'// OrganisationModule
})
export class OrganisationService extends BaseService
{
    private          _data:          Organisation     = new Organisation();
    private readonly _devices:       any[]            = [];

    private          _id:            number;
    private          _updated$: BehaviorSubject<Organisation>
                                                       = new BehaviorSubject<Organisation>(this.organisation);
    private          _loading$: BehaviorSubject<boolean>
                                                       = new BehaviorSubject<boolean>(false);


    constructor(private readonly MessageService: MessageService)
    {
        super();
    }


    // Override
    public override ngOnDestroy(): void
    {
        super.ngOnDestroy();

        console.debug("Destroying Organisation service");
    }

    
    //
    // Getters and setters
    //
    public get id(): number
    {
        return this._id;
    }

    public set id(i: number)
    {
        this.refresh(this._id = i);
    }


    public get loading$(): Observable<boolean>
    {
        return this._loading$.asObservable();
    }


    public get organisation(): Organisation
    {
        return this._data;
    }

    public set organisation(o: Organisation)
    {
        this._data = o;
    }


    public get updated$(): Observable<Organisation>
    {
        return this._updated$.asObservable();
    }


    //
    // Public methods
    //
    public refresh(id: number): Subscription | undefined
    {
        this._loading$.next(true);

        // Use observable
        const obs: Observable<any> | undefined = this.MessageService.sendMsgGet(
            this.MessageService.messages.msgTypesInfo.organisations, { id: id ? id : this.id }
        );

        return obs instanceof Observable
            ? (this.sub = obs.subscribe((data: any): void => {
                if (data) this.update(data.data && data.data[0] ? data.data[0]
                    : (data[0] ? data[0] : data));
                    this._loading$.next(false);
                })
              )
            : undefined;
    }


    public update(data: any): boolean
    {
        if (data) {
            console.debug("Updating Organisation service with data");

            // this.organisation = Organisation.get(data);
            // this.updateObservables();
            // return true;
            
            const s2: Organisation | undefined = Organisation.get(data);
            if (s2 instanceof Organisation) {
                this.organisation = s2;
console.debug(this.organisation);
                if (this._updated$ instanceof BehaviorSubject) this._updated$.next(this.organisation);

                return true;
            }
        }
        else {
            console.info("No data received to update Organisation service");
        }

        return false;
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        this._loading$.complete();
        this._updated$.complete();
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Organisation service");
    }


    //
    // Private methods
    //
    private updateObservables(): void
    {
        if (this._updated$) this._updated$.next(this.organisation);
    }
}