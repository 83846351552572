<!-- <div ngbDropdown *ngIf="layers && layers.length" placement="top-left" autoClose='outside' class="d-inline-block">
    <button mat-raised-button color="primary" ngbDropdownToggle id="dropdown1" matTooltip="Select layers to show">
        <mat-icon aria-label="Select layer">{{ icon }}</mat-icon>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdown1"> -->
                <!-- <mat-checkbox color="accent" ngbDropdownItem name="all" value="layersEnabled" (click)="toggleLayersAll()">
                    &lt;All layers&gt;
                </mat-checkbox> -->
        <!-- <div *ngFor="let l of layers">
            <mat-checkbox color="accent" ngbDropdownItem *ngIf="isLayer(l)" name="l.name" [checked]="isChecked(l)" (change)="changedState($event, l)">
                {{ l.displayName | titlecase }}
            </mat-checkbox>
        </div>
    </div>
</div> -->


<mat-form-field *ngIf="layers && layers.length" class="d-inline-block">
    <mat-label>
        Select layer
        <!-- <button mat-raised-button color="primary" id="dropdown1" matTooltip="Select organisations to show"> -->
            <mat-icon aria-label="Select layer">{{ icon }}</mat-icon>
        <!-- </button> -->
    </mat-label>
    <!-- <mat-select [(ngModel)]="val" (selectionChange)="changedState2($event, undefined)" aria-labelledby="dropdown1" multiple> -->
    <mat-select [(ngModel)]="val" #select="matSelect" aria-labelledby="dropdown1" multiple>
        <mat-option *ngFor="let d of layers" [value]="d">
            <div *ngIf="isLayer(d)">
                {{ d.displayName | titlecase }}
            </div>
        </mat-option>
    </mat-select>
</mat-form-field>