import { CommonModule }              from '@angular/common';
import { NgModule }                  from '@angular/core';
import { NgxPaginationModule }       from 'ngx-pagination'; 
import { OrderModule }               from 'ngx-order-pipe';
import { RouterModule }              from '@angular/router';

import { MaterialCustomModule }      from '@Material/';
import { UtilsModule }               from '@Utils/';

// qp-list-data, qp-refresh-button, qp-status-indicator, qp-window-header
import { GuiElementsModule }         from '@GuiElements/';
// qp-list
import { MiscModule }                from '@Misc/';
import { ObjElementsModule }         from '@ObjElements/';

import { DeviceGroupsComponent }     from './device-groups.component';
import { DeviceGroupsListComponent } from './device-groups-list.component';
import { DeviceGroupModule }         from './devicegroup/';


@NgModule({
    imports: [
        CommonModule,
        NgxPaginationModule,
        OrderModule,
        RouterModule,

        GuiElementsModule,
        MaterialCustomModule,
        MiscModule,
        ObjElementsModule,
        UtilsModule,

        // DeviceModule
    ],
    
    declarations: [
        DeviceGroupsComponent,
        DeviceGroupsListComponent
    ],

    providers: [
    ],

    exports: [
        // DeviceGroupsComponent
    ]
})
export class DeviceGroupsModule { }