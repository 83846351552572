import { Component }     from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
   selector:    'qp-organisations-container',
   templateUrl: 'organisations-container.component.html'
})
export class OrganisationsContainerComponent extends BaseComponent
{
	
}