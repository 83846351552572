<!--<div *ngIf="data && data.length > 0">-->
<div class="fill" flex layout-fill [hidden]="! data || data.length <= 0">
    <div class="header">
        <mat-form-field *ngIf="searchEnabled">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput type="search" placeholder="Search" class="search-input"
                            (keyup)="filterChange($event)">
                            <!-- (keyup)="applyFilter($event.target.value)"> -->
        </mat-form-field>

        <mat-paginator
            *ngIf="paginatorEnabled && dataQuantity > pageSizeDefault"

            [length]="dataQuantity"
            [pageSizeOptions]="pageSizeOptions"
            [pageSize]="pageSizeDefault"
            showFirstLastButtons=true

            (page)="pageChange($event)">
        </mat-paginator>
    </div>


    <!-- <div [hidden]="dataSrc.filteredData.length <= 0"> -->
    <div class="content">
        <table mat-table [dataSource]="dataSrc" [trackBy]="trackBy" matSort (matSortChange)="sortChange($event)" class="mat-elevation-z8">
            <!-- Columns definition -->
            <ng-container *ngFor="let key of attrsSrc" matColumnDef="{{ key.alias }}">
                <!-- can't use ngTemplateOutlet and *ngIf here as can't link to templates within table definition - context not valid -->
                <ng-container *ngIf="key.name != stateAttrG && key.name != statusAttrG">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ key.alias | titlecase }}</th>
                    <td mat-cell *matCellDef="let element" [ngClass]="element?.class">{{ element[key.name] }}</td>
                    <!-- <td mat-cell *matCellDef="let element" [ngClass]="element?.class">{{ element[key.name] }}</td> -->
                </ng-container>
                <ng-container *ngIf="key.name == stateAttrG && stateEnabled">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th> <!-- no title for state column -->
                    <td mat-cell *matCellDef="let element" [ngClass]="element?.class">
                    <!-- <td mat-cell *matCellDef="let element" [ngClass]="element?.class"></td> -->
                        <qp-status-indicator [state]="element[key.name]"></qp-status-indicator>
                    </td>
                </ng-container>
                <ng-container *ngIf="key.name == statusAttrG && stateEnabled">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th> <!-- no title for state column -->
                    <td mat-cell *matCellDef="let element" [ngClass]="element?.class">
                    <!-- <td mat-cell *matCellDef="let element" [ngClass]="element?.class"></td> -->
                        <qp-status-icon-survey [state]="element[key.name]"></qp-status-icon-survey>
                    </td>
                </ng-container>
            </ng-container>

            <!-- Rows definition - which order the columns defined above should be in -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr #tableRow      *matRowDef="let row; columns: displayedColumns;" (click)="select(row)" class="mat-row element-row">
                <ng-container cdkCellOutlet></ng-container>
                <div matRipple [matRippleTrigger]="tableRow" class="ripple-overlay"></div>
            </tr>
        </table>
    </div>
<!--        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 250]" [pageSize]="pageSizeDefault" *ngIf="paginatorEnabled && dataSrc.filteredData.length > pageSizeDefault" showFirstLastButtons></mat-paginator> -->
    <!-- </div> -->
</div>