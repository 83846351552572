import { Component }            from '@angular/core';
import {
    ActivatedRoute,
    ParamMap,
    Router
}                               from '@angular/router';

import { BaseComponent }        from '@Base/';
import {
    ElementHelper,
    ElementState,
    Organisation
}                               from '@ObjElements/';

import { routeNames }           from '../../app.routing.names';

import { OrganisationService }  from './organisation.service';


@Component({
    selector:     'qp-organisation',
    templateUrl:  'organisation.component.html',
    styleUrls:   ['organisation.component.css'],
    providers:   [ OrganisationService ] // scope service to this component only
})
export class OrganisationComponent extends BaseComponent
{
    private         id:     number        = -1;
    private         _state: ElementState  = ElementState.Good;

    // public readonly attrs:  string[]      = [
    //     'id',
    //     'name',
    //     'sites'
    // ];
    public          data:   Organisation;
    public readonly name:   string        = "Organisation";


    constructor(private readonly ActRoute:            ActivatedRoute,
                private readonly OrganisationService: OrganisationService,
                private readonly Router:              Router)
    {
        super();
    }


    // Getters and setters
    public get state() : ElementState
    {
        return this._state;
    }


    public set state(s: ElementState)
    {
        this._state = s;
    }


    //
    // Public methods
    //
    public buttonShowOnMap(): void
    {
        this.showOnMap();
    }


    public refresh(id?: number): void
    {
        const idL: number = id ? id : this.id;
        if (idL >= 0) this.OrganisationService.id = idL; // triggers data look-up
        this.state = ElementState.Good;
    }


    //
    // Protected functions
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Organisation component");
        
        this.data = new Organisation();
        this.sub = this.ActRoute.paramMap
            .subscribe((params: ParamMap): void => {
                const s: string | null = params && params.has('id') ? params.get('id') : "";
                if (! isNaN(Number(s)) ) this.refresh(this.id = Number(s));
            }); // subscribe

        this.sub = this.OrganisationService.updated$
            .subscribe((data: Organisation): void => {
                this.data = data;
            }); // subscribe
    }


    //
    // Private functions
    //
    private showOnMap(): void
    {
        // console.log("Show device " + this.data.id + ":" + (<any>this).data.name + "on map");
        // this.TabsService.tabActiveReq = 'map';
        if (this.data) {
            console.log("Show " +this.data.id + ":" + this.data.name + " on map" + (this.data.geolocation ? ": " + this.data.geolocation.locationStr : ""));
            //this.Router.navigate([ routeNames.map , id] );
            this.Router.navigate(
                [ routeNames.map ],
                { queryParams: { 'element': ElementHelper.getTag(this.data), 'id': this.data.id } });
        }
    }
}