import { Injectable }        from '@angular/core';
import { Subject, Observable }        from 'rxjs';

import {
    Device,
    DeviceI,
    DeviceHelper,
    Element
}                            from '@ObjElements/';

import {
    Messages,
    MessageService,
    MessageProcessingService 
}                            from '@Messaging/';
import {
    DataService,
    // DataServiceEvents
}                            from '@Misc/Services/';


@Injectable({
    providedIn: 'root'
})
export class DevicesService extends DataService
{
    protected static readonly _title: string = "Devices";


    public constructor(private readonly MessageProcessingService: MessageProcessingService,
                                        MessageService:           MessageService)
    {
        super(MessageService, DevicesService._title, MessageService.messages.msgTypesInfo.devices);
        // Don't call from within initialise() as that is called by parent constructor and required this.MessageService not available then
        this.refresh(); // default this to quantityOnly initially

        // Listen for events
        let obs: Observable<any> | undefined = this.MessageProcessingService.getObs$(Messages.events.device);
        if (obs instanceof Observable) this.sub = obs
            .subscribe((d: any): void => {
                this.process(d, true);
                // if (this.process(d, false)) this.sizeI = this.size + 1;
                this.updateObservables(false);
            }); // subscribe

        // Listen for events
        // obs = this.MessageProcessingService.getObs$(Messages.msgTypesInfo.devices);
        // if (obs instanceof Observable) this.sub = obs
        //     .subscribe((d: any): void => {
        //         console.debug(d)
        //         this.process(d);
        //     }); // subscribe
    }

    
    //
    // Interface
    //

    // Override
    public get(id: string | number): object
    {
        return this.getEl(id as string);
    }


    // Override
    protected override processMsgData(d: any[]): void
    {
        super.processMsgData(d);

        if (Array.isArray(d)) {
            console.debug("Updating " + this.Title + " service with data: " + d.length);
            this.clear(false);
            Object.values(d).forEach((v): void => {
                this.process(v, false);
            }); // forEach
            this.updateObservables(false);
        }
        else {
            console.warn("No data received to update " + this.Title + " service");
        }
    }


    //
    // Private methods
    //
    private getEl(id: string): Device
    {
        return this.getElement(id) as Device;
    }


    private process(d: any, update: boolean = true): boolean
    {
        // const s: Device | undefined = Device.get(d);
        const s: Device | undefined = DeviceHelper.get(d);//, this.IconsService);
        if (s instanceof Device) {
            // Check if already exists; update if so
            let ret: boolean = !! this.add(s.id, s, update);
            if (! ret) {
                if (!! this.update(s)) this.elementUpdated$?.next(s.id);
            }

            return ret;
        }

        return false;
    }


    private update(d: Device): Element | undefined
    {
        // const s: Device | undefined = (d instanceof Device) ? this.getEl(d.id) : undefined;
        const ret = ((d instanceof Device) ? this.getEl(d.id) : undefined)?.merge(d);

        // if (this.elementUpdated$ instanceof Subject) this.elementUpdated$.next(d.id);
        return ret;
    }
}