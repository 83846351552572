import { Injectable }  from '@angular/core';

import { MobileCell }  from '@ObjElements/';

import {
    ChartBarService,
    ChartGanttService,
    ChartPieService
}                      from './';

declare var AmCharts: any;


@Injectable({
    providedIn: 'root'
})
export class ChartService
{
    //private readonly chartTheme     = "none";
    //private readonly chartTheme     = "light";
    private readonly chartTheme     = "dark";

    private readonly chart3DOptions = {angle: 30, depth3D: 35};

    public readonly chartTypes: any     =
    {
        bar:             'bar',
        barStacked:      'barStacked',
        gantt:           'gantt',
        pie:             'pie'
    };

    private readonly graphOptions: any   = 
    {
        fillAlphas:      0.9,
        labelPosition:   "middle",
        lineAlpha:       0.2,
        lineColor:       "#fff"
    };

    private readonly chartOptions: any   = 
    {
        automargins:     false,
        creditsPosition: "bottom-right",
        export:          {enabled: true},
        //export:          {enabled: true, menu: []},
        //exported:        true,
        gridAboveGraphs: true,
        //marginBottom:    50,
        //marginLeft:      25,
        //marginRight:     25,
        //marginTop:       50,
        responsive:      {enabled: true},
        startDuration:   1,
        theme:           this.getTheme(this.chartTheme)     // need to be an object when NOT using AmCharts JSON interface
    };

    constructor(private readonly qp_ChartsBarFactory:   ChartBarService,
                private readonly qp_ChartsGanttFactory: ChartGanttService,
                private readonly qp_ChartsPieFactory:   ChartPieService) {
        this.initialise();
    }


        //AmCharts.theme = AmCharts.themes[chartTheme]; // can also set theme like this here, globally
        //AmCharts.checkEmptyData = checkEmptyData;     // used by AMCharts to display 'No data' message


    //
    // Public Functions
    //
    public getChart(type: string, title: string, valueAxisTitle: string, threeD: boolean, theme: string, listenerFn: any, data: any): any
    {    
        let fn: any;
        switch (type) {
            case this.chartTypes.bar:
                fn = this.qp_ChartsBarFactory.getChart;
                break;

            case this.chartTypes.barStacked:
                fn = this.getChartBarStacked;
                break;

            case this.chartTypes.gantt:
                fn = this.qp_ChartsGanttFactory.getChart;
                break;

            case this.chartTypes.pie:
                fn = this.qp_ChartsPieFactory.getChart;
                break;
        }


        // Now generate chart
        var chart;
        if (fn && typeof(fn) === 'function') {
            // Set theme
            const themeObj = this.getTheme(theme);
            if (themeObj) {
                this.chartOptions.theme = themeObj;
            }

            chart = fn(title, valueAxisTitle, data, this.chartOptions, this.graphOptions);
            if (chart) {
                // Add title
                chart.titles = [{text: title}];

                // Enable 3D if requested
                if (threeD) {
                    chart.depth3D = this.chart3DOptions.depth3D;
                    chart.angle   = this.chart3DOptions.angle;
                }

                // Add colour handler
                AmCharts.addInitHandler((chart: object): void => {
                    this.chartAddColours(chart);
                }, ['serial']);

                // Add listener
                fn = (listenerFn ? listenerFn : this.chartListener);
                //chart.addListener('clickChart', fn);
                //chart.addListener('clickGraph', fn);
                chart.addListener('clickGraphItem', fn);

                chart.refresh = this.refresh;
                chart.refresh();

                //AmCharts.checkEmptyData(chart);
            }
        } // if fn

        return chart;
    }


    //
    // Private functions
    //
    private chartAddColours(chart: any): void
    {
        // Check if there are os with autoColor: true set
        for (let i: number = 0, len = chart.graphs.length; i < len; ++i) {
            let graph: any = chart.graphs[i];
            if (graph.autoColor !== true) continue;

            const colorKey = "autoColor-" + i;
            graph.lineColorField  = colorKey;
            graph.fillColorsField = colorKey;
            if (chart.dataProvider) {
                for (let j: number = 0, len2 = chart.dataProvider.length; j < len2; ++j) {
                    chart.dataProvider[j][colorKey] = chart.colors[j];
                } // for
            }
            else {
                console.warn("Cannot set colour on null dataProvider");
                console.debug(chart);
            }
        } // for
    }


    private chartListener(event: any): void
    {
        console.debug("Chart '" + event.chart.name + "' clicked - handle event");
        console.debug(event);
    }


    private getTheme(theme: string): any
    {
        return (theme && AmCharts.themes[theme] ? AmCharts.themes[theme] : null);
    }


    private refresh(data: any): void
    {
        // if (this) {
        //     if (data) this.dataProvider = data;

        //     if (this.dataProvider && 0 <= this.dataProvider.length) {
        //         if (typeof(this.validateData) == 'function') {
        //             this.validateData();
        //         }
        //     }
        //     else if (typeof(this.validateNow) == 'function') {
        //         this.setChartEmpty(this);
        //         this.validateNow();
        //     }
        //}
    }


    private setChartEmpty(chart: any): any
    {
        if (chart) {
            // set min/max on the value axis
            if (chart.valueAxes && chart.valueAxes.length > 0) {
                chart.valueAxes[0].minimum = 0;
                chart.valueAxes[0].maximum = 100;
            }

            // Add dummy data point
            const dataPoint: any = { dummyValue: 0 };
            dataPoint[chart.categoryField] = '';
            chart.dataProvider = [dataPoint];

            // Add label
            chart.addLabel(0, '50%', 'The chart contains no data', 'center');

            // Set opacity of the chart div
            chart.chartDiv.style.opacity = 0.5;

            return chart;
        }
    }


    private getChartBarStacked(title: string, valueAxisTitle: string, data: any): any
    {
        let chart: any = this.qp_ChartsBarFactory.getChart(title, valueAxisTitle, data);
        if (chart) {
            chart.graphs = [
                {
                    "balloonText":        "<b>[[category]] [[title]]: [[value]]%</b>",
                    "fillColorsField":    "color",
                    "fillAlphas":         0.9,
                    "lineAlpha":          0.2,
                    "title":              MobileCell.Technology.Lte,
                    "topRadius":          1,
                    "type":               "column",
                    "valueField":         MobileCell.Technology.Lte
                },
                {
                    "balloonText":        "<b>[[category]] [[title]]: [[value]]%</b>",
                    "fillColorsField":    "color",
                    "fillAlphas":         0.9,
                    "lineAlpha":          0.2,
                    "title":              MobileCell.Technology.Umts,
                    "topRadius":          1,                    
                    "type":               "column",
                    "valueField":         MobileCell.Technology.Umts
                },
                {
                    "balloonText":        "<b>[[category]] [[title]]: [[value]]%</b>",
                    "fillColorsField":    "color",
                    "fillAlphas":         0.9,
                    "lineAlpha":          0.2,
                    "title":              MobileCell.Technology.Gsm,
                    "topRadius":          1,
                    "type":               "column",
                    "valueField":         MobileCell.Technology.Gsm
                }
            ];

            chart.valueAxes[0].stackType = "regular";

            chart.legend = {
                "horizontalGap":    10,
                "maxColumns":       1,
                "position":         "right",
                "useGraphSettings": true,
                "markerSize":       10
            };
        }

        return chart;
    }


    private initialise(): boolean
    {
        console.debug("Initialising Chart service");

        return true;
    }
}