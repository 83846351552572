import { Injectable }      from '@angular/core';

import { IconsService }    from '@Icons/';
import { MessageService }  from '@Messaging/';
import { Dateq }           from '@Utils/';
import { WifiApsService }  from '@Wifi/';

import {
     MapElementLatLng,
     MapElementOverlayView
}                          from '../../map-elements';

import { MapLayerUtils }   from './map-layer-utils.class';


declare var google: any;
 
 
@Injectable({
    providedIn: 'root'
})
export class MapLayerCellsService
{
    private static readonly gMaps = google.maps;

    private static readonly heatmapOptions: any = {
        dissipating: 'false', // default
        opacity:     1,       // default
        radius:      30       // in pixels
    };

    private static readonly imagesBase    = '../assets/images/';
    private static readonly iconCellTower = MapLayerCellsService.imagesBase + 'tower_blue.png';

    private static readonly unknownStr    = "<unknown>";


    constructor(private readonly IconsService:   IconsService,
                private readonly MessageService: MessageService)
                // private readonly WifiApsService: WifiApsService)
    {
        this.initialise();
    }


    //
    // Public Functions
    //
    public mapLayerCellsFactory(map: any, data: any): any
    {
        const  layers: any = {};

        // Overlays
        layers.mobileCellReportsH = MapElementOverlayView.get();
        layers.mobileCellReports  = MapElementOverlayView.get();

        const cellMarkers: any[] = [];

        return this.addCellSightings(map, layers, data);
    }


    //
    // Private functions
    //
    private initialise(): boolean
    {
        console.log("Initialising MapLayerCells service");

        return true;
    }


    private addCellSightings(map: any, layers: any, data: any)
    {
        let cellOrig: any;
        let key: any;
        let multiple: boolean     = false;
        let numSightings: number = 0;

        if (data && data.data) {
            console.log("Adding " + data.data.length + " cell sighting(s)");
            let points: any[] = [];
            for (let i = 0, len = data.data.length; i < len; ++i) {
                if (data.data[i] && data.data[i].sigStrengthValue) {
                    const cellSighting: any = data.data[i];

                    // Will go in here on first entry
                    if (! key) {
                        key = {};
                        switch (data.message) {
                            case this.MessageService.messages.msgTypesSub.mobilecellsightings:
                                cellOrig = {
                                    plmn:       cellSighting.plmn,
                                    cellId:     cellSighting.gcid,
                                    technology: cellSighting.rat
                                };

                                Object.assign(key, cellOrig);
                                key.type = "Mobile cell";

                                // key.plmn            = cellSighting.plmn;
                                // key.cellId          = cellSighting.gcid;
                                // key.technology      = cellSighting.rat;
                                break;

                            case this.MessageService.messages.msgTypesSub.wifiapsightings:
                                cellOrig = {
                                    bssid: cellSighting.bssid
                                };

                                Object.assign(key, cellOrig);
                                key.type            = "Wifi";

                                //key.bssid           = cellSighting.bssid;
                                
                                if (cellSighting.frequency) {
                                    // key.channel     = WifiApsService.getWifiChannel80211g(cellSighting.frequency);
                                    if (! key.channel) key.channel = MapLayerCellsService.unknownStr;
                                    key.frequency   = cellSighting.frequency + " Mhz";
                                }
                                                                    
                                key.ssid            = cellSighting.ssid ? cellSighting.ssid : MapLayerCellsService.unknownStr;
                                break;
                        } // switch
                    } // if key

                    // Check if multiple cells/APs returned; if so change key
                    if (! multiple && cellOrig) {
                        switch (data.message) {
                            case this.MessageService.messages.msgTypesSub.mobilecellsightings:
                                if (cellOrig.plmn != cellSighting.plmn || cellOrig.cellId != cellSighting.gcid || cellOrig.technology != cellSighting.rat) {
                                    key = {
                                        type: "Mobile cell",
                                        note: "multiple cells shown"
                                    };
                                    multiple = true;
                                }
                                break;

                            case this.MessageService.messages.msgTypesSub.wifiapsightings:
                                if (cellOrig.bssid != cellSighting.bssid) {
                                    key = {
                                        type: "Wifi",
                                        note: "multiple APs shown"
                                    };
                                    multiple = true;
                                }
                                break;
                        } // switch
                    } // multiple

                    const loc = MapElementLatLng.get(cellSighting.latitude, cellSighting.longitude);
                    points.push({
                        weight:   MapLayerUtils.getWeighting(cellSighting.rat ? cellSighting.rat : 'wifi',
                                                                       cellSighting.sigStrengthType,
                                                                       cellSighting.sigStrengthValue),
                        location: loc
                    });
                    layers.mobileCellReports.addOverlay(
                        MapLayerUtils.createMapPointOther(
                            map, 
                            cellSighting.latitude,
                            cellSighting.longitude, 
                            {scale: 3, path: MapLayerCellsService.gMaps.SymbolPath.CIRCLE}, 
                            this.getTitleStr(data.message, cellSighting)
                        )
                    );

                    layers.mobileCellReports.updateMapBounds(loc);
                    layers.mobileCellReportsH.updateMapBounds(loc);
                    ++numSightings;
                } // if
            } // for

            if (points.length > 0) {
                // Create heatmap
                const heatMap: any = new MapLayerCellsService.gMaps.visualization.HeatmapLayer(MapLayerCellsService.heatmapOptions);

    // const gradient = [
    //     'rgba('+Math.round(255*rate)+', '+Math.round(255*(1-rate))+', 0, 0)',
    //     'rgba('+Math.round(255*rate)+', '+Math.round(255*(1-rate))+', 0, 1)'];
    // heatMap.set('gradient', gradient);

// default gradient
// ["rgba(102, 255, 0, 0)",
// "rgba(102, 255, 0, 1)",
// "rgba(147, 255, 0, 1)",
// "rgba(193, 255, 0, 1)",
// "rgba(238, 255, 0, 1)",
// "rgba(244, 227, 0, 1)",
// "rgba(249, 198, 0, 1)",
// "rgba(255, 170, 0, 1)",
// "rgba(255, 113, 0, 1)",
// "rgba(255, 57, 0, 1)",
// "rgba(255, 0, 0, 1)"]

                // Set data
                heatMap.setData(points);

                // Add listener
                // heatMap.addListener('click',     function() {
                //     console.log("heatmap ");
                // });

                // heatMap.addListener('mouseover', function() {
                //     console.log("heatmap 2");
                // });

                // Add overlay
                layers.mobileCellReportsH.addOverlay(heatMap);
            }

            // Get key data from first sihgting; all sightings should have the same base data
            if (key) {
                layers.mobileCellReports.setKey(key.type + " sightings\n(" + numSightings + ")", key);
                delete key.type;
            }
        }

        return layers;
    }


    private getStr(obj: any): any
    {
        return (obj ? obj : "");
    }


    private getTitleStr(type: string, data: any): string
    {
        //console.log(data);
        let str: string = "";

        if (type && data) {
            let str2: string = "";
            switch (type) {
                case this.MessageService.messages.msgTypesSub.mobilecellsightings:
                    str2 += "Type: "            + "mobile"                                                            + "\n"
                          + "Technology: "      + this.getStr(data.rat)                                               + "\n"
                          + "Network: "         + this.getStr(data.plmn)                                              + "\n"
                          + "Mobile Cell: "     + this.getStr(data.gcid)                                              + "\n"
                          + "Signal Strength (" + this.getStr(data.sigStrengthType) + "): " 
                                                + this.getStr(data.sigStrengthValue) + " dB"                          + "\n"
                          + "Signal Quality ("  + this.getStr(data.sigQualityType) + "): " 
                                                + this.getStr(data.sigQualityValue)                                   + "\n";
                    break;

                case this.MessageService.messages.msgTypesSub.wifiapsightings:
                    str2 += "Type: "            + "wifi"                                                              + "\n"
                          + "Bssid: "           + this.getStr(data.bssid)                                             + "\n"
                          + "Ssid: "            + (this.getStr(data.ssid) ? this.getStr(data.ssid) : MapLayerCellsService.unknownStr) + "\n"
                        //   + "Channel: "         + WifiApsService.getWifiChannel80211g(data.frequency)                 + "\n"
                          + "Frequency: "       + this.getStr(data.frequency) + " MHz"                                + "\n"
                          + "Capabilities: "    + this.getStr(data.capabilities)                                      + "\n"
                          + "Signal Strength: " + this.getStr(data.sigStrengthValue) + " dB"                          + "\n";
                    break;

                default:
                    str2 += "Type: "            + MapLayerCellsService.unknownStr                                     + "\n";
            } // switch


            str += str2
                 + "\n"
                 + "Serial Number: "            + this.getStr(data.serialNum)                                         + "\n"
                 + "Latitude: "                 + this.getStr(data.latitude)                                          + "\n"
                 + "Longitude: "                + this.getStr(data.longitude);//                                         + "\n"
                //  + "Time: "                     + Dateq.get(data.createdDate).displayStr;
        }

        return str;
    }
}