import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Input,
    ViewChild,
    ViewContainerRef
}                             from '@angular/core';

import { BaseComponent }      from '@Base/';
import {
    ElementHelper,
    ElementInfoComponent
}                             from '@ObjElements/element/';

import { MapElementMarker }   from '../map-elements/';


@Component({
    selector:     'qp-map-infowin-container',
    templateUrl:  'map-infowin-container.component.html',
    styleUrls:   ['map-infowin-container.component.css'],
})
export class MapInfoWinContainerComponent extends BaseComponent
{
    @ViewChild('container', { static: true, read: ViewContainerRef })
    container: ViewContainerRef;

    private _compRef: ComponentRef<ElementInfoComponent> | undefined = undefined;
    private _marker:  MapElementMarker                   | undefined = undefined;


    constructor(private readonly Resolver: ComponentFactoryResolver)
    {
        super();
    }


    //
    // Getters and setters
    //
    @Input()
    public get marker(): MapElementMarker | undefined
    {
        return this._marker;
    }

    public set marker(d: MapElementMarker | undefined)
    {
        this._marker = d;
        if (d instanceof MapElementMarker) this.open(d);
    }


    //
    // Public methods
    //

    // Override
    public override ngOnInit(): void
    {
        // Prevent calling initialise() here
    }


    // Override
    public override ngAfterViewInit(): void
    {
        super.ngAfterViewInit();

        this.initialise();
    }


    // Override
    public override ngOnDestroy(): void
    {
        super.ngOnDestroy();

        console.debug("Destroying MapInfoWinContainerComponent");
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        if (this.container instanceof ViewContainerRef) this.container.clear();

        if (this._compRef instanceof ComponentRef) this._compRef.destroy();
        this._compRef = undefined;
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("MapInfoWinContainerComponent initialised");
    }


    //
    // Private methods
    //
    private open(d: MapElementMarker): void
    {
        // [TBD] If multiple elements, do specific infoWin

        // Get appropriate dynamic component type
        const t: typeof ElementInfoComponent | undefined
            = (d instanceof MapElementMarker)
                ? ElementHelper.getInfoType(d.element)
                : undefined;

        if (t && (! this._compRef || this._compRef.componentType !== t)) {
            // Clean up any existing dynamic component
            if (this._compRef) this._compRef.destroy();

            // Dynamically create component if not already created
            const compFactory: ComponentFactory<ElementInfoComponent>
                 = this.Resolver.resolveComponentFactory(t);

            // Create new component
            const cr: ComponentRef<ElementInfoComponent> | undefined
                = this.container && compFactory
                    ? this.container.createComponent(compFactory)
                    : undefined;
            this._compRef = (cr instanceof ComponentRef) ? cr : undefined;
        }

        // Pass data to new component
        if (this._compRef instanceof ComponentRef) {
            this._compRef.instance.data     = d.element;
            this._compRef.instance.light    = true; // signal CSS to change font colour
            this._compRef.instance.showType = true; // signal to show type and ID
        }
    }
}