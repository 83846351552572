import { MapElementLatLng }       from './map-element-latlng/';
import { MapElementLatLngBounds } from './map-element-latlngbounds.class';
import { MapElementMap }          from './map-element-map.class';

const gMaps = google.maps;


export class MapElementOverlayView extends gMaps.OverlayView
{
    private _name:     string                 = "";

    //this.overlays = []; // public var
    private _bounds:   MapElementLatLngBounds = new MapElementLatLngBounds();
    private _key:      object                 = {};
    private _overlays: any[]                  = []; // private var
    private _paths:    any[]                  = [];

    
    public constructor(name?: string)
    {
        super();

        if (name) this._name = name;
    }


    //
    // Getters and setters
    //
    public get bounds(): MapElementLatLngBounds
    {
        return this._bounds;
    }


    public get key(): object 
    {
        return this._key;
    }

    public set key(data: object)
    {
        this._key = data;
    }


    // Wrap Google calls - can't call it map() as it clashes with inherited Google object
    public get gmap(): MapElementMap | null | undefined
    {
        return (
            super.getMap() instanceof gMaps.Map
                ? super.getMap() as MapElementMap
                : undefined
        );
    }

    public set gmap(map: MapElementMap | null | undefined)
    {
        super.setMap(map instanceof MapElementMap ? map : null);
        
        // [TBD] setGMap(map) - fitBounds()?
    }


    public get name(): string
    {
        return this._name;
    }


    private get overlays(): any[]
    {
        return this._overlays;
    }


    public get paths(): any[]
    {
        return this._paths;
    }


    //
    // Static methods
    //
    private static deleteArray(a: any[]): number
    {
        return a ? a.length = 0 : -1;
    }


    public static get(): MapElementOverlayView
    {
        return new MapElementOverlayView();
    }


    //
    // Public methods
    //
    public addOverlay(overlay: object): object
    {
        if (overlay) this.overlays.push(overlay);
        return overlay;
    }


    public addPath(path: object): object
    {
        if (path) this.paths.push(path);
        return path;
    }


    public deleteOverlay(overlay: any): any
    {
        if (overlay) {
            for (var i = 0, len = this.overlays.length; i < len; ++i) {
                if (this.overlays[i] == overlay) {
                    this.overlays[i].setMap(null);
                    if (this.overlays[i].circle) this.overlays[i].circle.setMap(null);
                    this.overlays.splice(i, 1);

                    return overlay;
                }
            } // for
        } // if

        return null;
    }


    public deleteOverlays(): number
    {
        return MapElementOverlayView.deleteArray(this.overlays);
    }


    public deletePaths(): number
    {
        return MapElementOverlayView.deleteArray(this.paths);
    }


    // Override
    public override draw(): void
    {
        // No super call as super is abstract
    }


    public getPath(i: number): any
    {
        return this.paths[i] ? this.paths[i] : null;
    }


    // public getPathLength(): any
    // {
    //     return MapElementMap.getPathLength(this.paths);
    //     // if (MapLayer.gMaps && MapLayer.gMaps.geometry && MapLayer.gMaps.geometry.spherical) {
    //     //     return MapLayer.gMaps.geometry.spherical.computeLength(this.paths);
    //     // }
    // }


    // Override - called when super.setMap(map) is called
    public override onAdd(): void
    {
        // No super call as super is abstract

        this.updateOverlays();
    }


    // Override - called when super.setMap(map) is called
    public override onRemove(): void
    {
        // No super call as super is abstract

        this.updateOverlays();
    }
    

    // Override
    public setGMap(map: MapElementMap, setBounds: boolean = true): any
    {
        let ret;
        if (map) {
            ret = (this.gmap = map); // will call setMap() which calls OnAdd()
            //et = this.gmap;
            if (setBounds) this.setMapBounds(); // no return

            if (this.key && (map as any).legend) {
                ret = (map as any).legend.add((this.key as any).name, (this.key as any).val);
            }
        }
        else {
            if (this.key && this.gmap && (this.gmap as any).legend) {
                // Need to do this before setting layer map to null                            
                (this.gmap as any).legend.delete((this.key as any).name);
            }
            ret = ! (this.gmap = map); // map == null will call setMap() which calls OnRemove();
            //ret = ! this.gmap;
        }

        return ret;
    }


    public setMapBounds(bounds?: MapElementLatLngBounds): void
    {
        if (! bounds) bounds = this.bounds;

        if (this.gmap && bounds && ! bounds.isEmpty()) {
            this.gmap.fitBounds(bounds);

            //return this.gmap.fitBounds(bounds);
        }
    }


    public updateMapBounds(latLng: MapElementLatLng): any
    {
        if (latLng) return this.bounds.extend(latLng);
    }

    
    public updateOverlays(): void
    {
        this.overlays.forEach((o: any): void => {
            if (o) {
                o.setMap(this.gmap);
                if (o.circle) o.circle.setMap(this.gmap);
            }
        }); // forEach
    }
}