<div class="buttonAndTextOuter">
    <div class="buttonAndTextInner">
        <button mat-raised-button color="primary" (click)="buttonRefresh()" matTooltip="Reload">
            <mat-icon aria-label="Reload">refresh</mat-icon>
        </button>
    </div>
    
    <div class="buttonAndTextInner"><h3>Services</h3></div>
    <div class="buttonAndTextInner2" *ngIf="updateTime">  (Last updated: {{ updateTime }})</div>

    <br>

    <div class="buttonAndTextInner" *ngIf="(start && updateTime)">  <b><font color="red">Results filtered</font></b></div>
</div> <!-- buttonAndTextOuter -->

<div id="service-chart-div" class="chart-min" style="height: 100%;"></div>
