<qp-window-header [title]="name" [value]="data ? data.id : ''" [state]="state" [statusEnabled]=true></qp-window-header>
<a [routerLink]="['..']"><h4>Back</h4></a>

<div *ngIf="data">
    <!-- <qp-notification-info [data]=data></qp-notification-info> -->

    <p><p></p>

    <div *ngIf="buttonsEnabled">
        <button mat-fab color="primary" (click)="buttonClear()" matTooltip="Clear alarm">
            <mat-icon aria-label="Clear alarm">{{ iconClearButton }}</mat-icon>
        </button>
    </div>
</div>