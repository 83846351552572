import {
    Component,
    EventEmitter,
    Output
}                                from '@angular/core';
import { MatRow }                from '@angular/material/table';
import { Observable }            from 'rxjs';

import { BaseComponent }         from '@Base/';
import {
    DataService,
    DataServiceEvents,
    ProgressSpinnerDialogService
}                                from '@Misc/Services';


@Component({
    template: ''
})
export class ObjectElementsComponent extends BaseComponent
{
    @Output()
    public selected: EventEmitter<MatRow> = new EventEmitter<MatRow>(); 


    constructor(private readonly DataService:                  DataService,
                private readonly ProgressSpinnerDialogService: ProgressSpinnerDialogService)
    {
        super();

        if (DataService) {
            const obs: Observable<boolean> | undefined = DataService[DataServiceEvents.loading];
            if (obs instanceof Observable) this.sub = obs.subscribe((loading: boolean): void => {
                if   (loading) ProgressSpinnerDialogService.show();
                else           ProgressSpinnerDialogService.clear();
            }); // subscribe
        }
    }
 

    //
    // Getters
    //
    public get service(): DataService
    {
        return this.DataService;
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(full: boolean = false): void
    {
        this.service?.refresh(full); // Clear data and force quantity-only refresh
        
        super.cleanUp();

        this.ProgressSpinnerDialogService.clear();
    }


    //
    // Public methods
    //
    public select(row: MatRow): Promise<boolean> | undefined
    {
        // console.debug(row);

        this.selected.emit(row);

        return undefined;
    }
}