import { Component }     from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
   selector:     'qp-progress-spinner',
   templateUrl:  'progress-spinner.component.html',
   styleUrls:   ['progress-spinner.component.css']
})
export class ProgressSpinnerComponent extends BaseComponent
{
}