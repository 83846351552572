import { CommonModule }         from '@angular/common';
import { NgModule }             from '@angular/core';
import { NgxPaginationModule }  from 'ngx-pagination'; 
import { OrderModule }          from 'ngx-order-pipe';
import { RouterModule }         from '@angular/router';

// qp-devices
import { DevicesModule }        from '@Devices/';
// qp-list-data, qp-refresh-button, qp-status-indicator, qp-title, qp-window-header
import { GuiElementsModule }    from '@GuiElements/';
import { MaterialCustomModule } from '@Material/';
// qp-element-info-container
import { ObjElementsModule }    from '@ObjElements/';

import { SiteComponent }        from './site.component';


@NgModule({
    imports: [
        CommonModule,
        NgxPaginationModule,
        OrderModule,
        RouterModule,

        DevicesModule,
        GuiElementsModule,
        ObjElementsModule,
        MaterialCustomModule
    ],
    
    declarations: [
        SiteComponent
    ],

    providers: [
    ],

    exports: [
    ]
})
export class SiteModule { }