import { Injectable }       from '@angular/core';

// import { KpiNamesModule }   from '@Common/qp_KpiNames';
import { KpiTypes }         from '@Common/';
// import { KpiObjectsModule } from '@Common/qp_KpiObjects';
import { MessageService }   from '@Messaging/';
import { MobileCell }       from '@ObjElements/mobile-cells/mobile-cell/';


@Injectable({
    providedIn: 'root'
})
export class KpisDataService
{
    private cells: any = {}; // array of known cells

    private kpis: any;
    private oneshot: any;
    private periodic: any;

    // constructor(private readonly qp_KpiNamesFactory:   KpiNamesModule,
    // constructor(private readonly qp_KpiObjectsFactory: KpiObjectsModule,
    constructor(private readonly qp_MsgFactory:        MessageService)
    { 
        this.initialise();
    }


    //
    // Public functions
    //
    public clearOneShot(): boolean
    {
        return this.clear(this.oneshot);
    }


    public clearPeriodic(): boolean
    {
        return this.clear(this.periodic);
    }


    public getKpiChart(name: string): any
    {
        return null;
    }


    public getKpiListOneShot()
    {
        let keyList = [];

        const obj = this.getList(this.oneshot);
        const keys: any = obj ? Object.keys(obj) : undefined;
        if (keys && Array.isArray(keys)) {
            for (let i: number = 0, len = keys.length; i < len; ++i) {
                if (keys[i] == "none") {
                    const keys2 = keys[i] && obj[keys[i]] && obj[keys[i]].kpis ? Object.keys(obj[keys[i]].kpis) : undefined;
                    if (keys2 && Array.isArray(keys2)) {
                        for (let j: number = 0, len2 = keys2.length; j < len2; ++j) {
                            if (keys2[j]) keyList.push(keys2[j]);
                        } // for
                    }
                }
                else {
                    keyList.push(keys[i]);
                }
            } // for
        }

        return keyList;
    }


    public getKpiListPeriodic(): any
    {
        return Object.keys(this.getList(this.periodic));
    }


    public getKpiOneShot(name: string): any
    {
        return this.getKpiType(this.oneshot, name);
    }


    public getKpiPeriodic(name: string): any
    {
        console.log(name);
        return this.getKpiType(this.periodic, name);
    }


    public updateOneShot(msg: string): any
    {
        return this.update(this.oneshot, msg);
    }


    public updatePeriodic(msg: string): any
    {
        return this.update(this.periodic, msg);
    }


    public update(type: string, msg: any): any
    {
        console.log("Updating KPIs: " + type);

        // Get type of KPIs
        const type2 = type ? type : msg.data;
        if (! type2) {
            console.log("Unknown type of KPIs; cannot process");
            return null;
        }

        if (! msg || ! msg.data) {
            console.log("Received null '" + type2 + "' KPIs");
        }


        const list = this.getList(type2)
        this.clearList(list);


        // Services (i.e. groups of KPIs)
        if (msg.data.services && Object.keys(msg.data.services).length > 0) {
            console.log("Received '" + type2 + "' services: " + Object.keys(msg.data.services).length);
            this.processServices(msg.data.services, list ? list : null);
        }

        // Raw KPIs
        if (msg.data.kpis && Object.keys(msg.data.kpis).length > 0) {
            console.log("Received '" + type2 + "' raw KPIs: " + Object.keys(msg.data.kpis).length);

            //const list = getList(type2); // gets reference to appropriate entry in kpis object
            if (list) {
                list.none      = ! list.none      ? {} : list.none;
                list.none.kpis = ! list.none.kpis ? {} : list.none.kpis;
                //clearList(list2);

                console.log("Processing raw KPIs");
                this.processKpis(msg.data.kpis, list.none.kpis); // sets list in place
            }
        }

        console.log(list);
    }


    //
    // Private functions
    //
    private getKpiObject(kpi3: any): any
    {
        //const x = qp_KpiObjectsFactory.getKpi(kpi2);
        
        let kpi: any = null;
        let kpi2: any = undefined;// [tbd] kpi = this.qp_KpiObjectsFactory.getKpi(kpi3);//kpi2;

console.log("dd1");
console.log(kpi2);

        if (kpi2) {
            // Create specific KPI object
            let obj: any = [{col: "", score: kpi2}];

            if (kpi2.result_ && kpi2.result_.all_) {
                kpi = kpi2.result_.all_;
            }

            if (kpi[this.qp_MsgFactory.messages.attributes.average]) {
                obj = [{col: this.qp_MsgFactory.messages.attributes.average, score: kpi[this.qp_MsgFactory.messages.attributes.average][this.qp_MsgFactory.messages.attributes.value]}];
            }

            if (kpi[this.qp_MsgFactory.messages.attributes.raw] && (kpi[this.qp_MsgFactory.messages.attributes.raw].length > 0 || Object.keys(kpi[this.qp_MsgFactory.messages.attributes.raw]).length > 0)) {
                obj = kpi[this.qp_MsgFactory.messages.attributes.raw];
            }
            else {
                let tObj = kpi[this.qp_MsgFactory.messages.attributes.devices];
                if (kpi[this.qp_MsgFactory.messages.attributes.groups]) {
                    const keys = Object.keys(kpi[this.qp_MsgFactory.messages.attributes.groups]);
                    if (keys.length > 1) {
                        keys.forEach((key: any): any => {
                            if (kpi[this.qp_MsgFactory.messages.attributes.groups][key]
                                && kpi[this.qp_MsgFactory.messages.attributes.groups][key][this.qp_MsgFactory.messages.attributes.average]
                                && kpi[this.qp_MsgFactory.messages.attributes.groups][key][this.qp_MsgFactory.messages.attributes.average][this.qp_MsgFactory.messages.attributes.value]) {
                                obj.push({
                                    col:   key,
                                    score: kpi[this.qp_MsgFactory.messages.attributes.groups][key][this.qp_MsgFactory.messages.attributes.average][this.qp_MsgFactory.messages.attributes.value]
                                });
                            }
                        });
                    }
                    else {
                        tObj = (keys.length == 1 && keys[0] && ! kpi[this.qp_MsgFactory.messages.attributes.devices]) ? kpi[this.qp_MsgFactory.messages.attributes.groups][keys[0]][this.qp_MsgFactory.messages.attributes.devices] : tObj;
                    }
                }

                if (tObj && Object.keys(tObj).length > 0) {
                    Object.keys(tObj).forEach((key: any): any => {
                        if (tObj[key] && tObj[key][this.qp_MsgFactory.messages.attributes.value]) {
                            obj.push({
                                col: key,
                                score: tObj[key][this.qp_MsgFactory.messages.attributes.value]
                            });
                        }
                    });
                }

                if (kpi.operator) {
                    if (kpi.operator.length > 0) {
                        obj[0].operator = kpi.operator[0].toUpperCase() + kpi.operator.substring(1);
                    }
                    else {
                        obj[0].operator = kpi.operator;
                    }
                    obj[0].col = obj[0].operator;
                }

                if (kpi.dataConnectionType) {
                    obj[0].technology = kpi.dataConnectionType;
                    obj[0].col += " (" + obj[0].technology + ")";
                }
            } // raw

            return kpi2;
            //return obj;
        } // if
    }


    private getList(type: any): any
    {
        return (type && this.kpis[type]) ? this.kpis[type] : null;
    }


    private getKpiType(type: string, name: string): any
    {
        return (type && this.kpis[type] && name)
            // First try services
            ? (this.kpis[type][name]
                   ? this.kpis[type][name] 
                   : (this.kpis[type].none.kpis[name]
                          ? this.kpis[type].none.kpis[name]
                          : null
                     )
              )
            : null;
    }


    private processServices(data: any, list: any): any
    {
        list = list ? list : {};

        if (data) {
            console.log("Processing services");

            Object.keys(data).forEach((key: string): void => {
                if (data[key]) {
                    list[key] = data[key];
                    this.processKpis(list[key].kpis, list[key].kpis);
                }
            });
        }

        return list;
    }


    private processKpis(data: any, list: any): any
    {
        list = list ? list : {};
        
        if (data) {
            console.log("Processing KPIs");

            Object.keys(data).forEach((key: string): any => {
                const obj: any = this.getKpiObject(data[key]);
                list[key] = obj ? obj : list[key];//kpiList[key].concat(obj); // merge arrays (non-destructive) - [TBD] need to change to obj instead of array


                // Special treatment for certain KPIs
                switch (key) {
                    case KpiTypes.voiceCallSuccessRate:
                        list.voiceCallDropRate = list.voiceCallDropRate ? list.voiceCallDropRate : [];

                        // Need to add reverse for voiceCallDropRate
                        if (obj) {
                            const x: number = obj.sco
                            list.voiceCallDropRate.push({
                                "operator":   obj.operator,
                                "technology": obj.technology,
                                "score":      (parseFloat(String(obj.score <= 0 ? 0 : 1 - obj.score)) * 100).toFixed(2),
                                "col":        obj.col
                            });
                        }
                        break;

                    case 'timeOnLte':
                    case 'timeOnUmts':
                    case 'timeOnGsm':
                        let ratType;
                        switch (key) {
                            case 'timeOnLte':
                                ratType = MobileCell.Technology.Lte.technology;
                                break;
                            case 'timeOnUmts':
                                ratType = MobileCell.Technology.Umts.technology;
                                break;
                            case 'timeOnGsm':
                                ratType = MobileCell.Technology.Gsm.technology;
                                break;
                        }
                        list.timeOnRat = list.timeOnRat ? list.timeOnRat : {};
                        list.timeOnRat[obj.operator] = list.timeOnRat[obj.operator] ? list.timeOnRat[obj.operator] : {};
                        list.timeOnRat[obj.operator][ratType] = obj.kpiValue;
                        break;

                } // switch

                if (list.timeOnRat) {
                    list.timeOnRatType = list.timeOnRatType ? list.timeOnRatType : [];
                    Object.keys(list.timeOnRat).forEach((element: any) => {
                        const obj: any = {
                                "operator": element[0].toUpperCase() + element.substring(1),
                                [MobileCell.Technology.Lte.technology]:  list.timeOnRat[element][MobileCell.Technology.Lte.technology],
                                [MobileCell.Technology.Umts.technology]: list.timeOnRat[element][MobileCell.Technology.Umts.technology],
                                [MobileCell.Technology.Gsm.technology]:  list.timeOnRat[element][MobileCell.Technology.Gsm.technology]
                        };
                        obj.col = obj.operator; // used by graphs
                        list.timeOnRatType.push(obj);
                    });
                }

            }); // forEach

        } // if

        return list;
    }


    private initialise(): boolean
    {
        console.log("Initialising KpisData service");

        this.oneshot  = this.qp_MsgFactory.messages.msgTypesSub.kpisos;
        this.periodic = this.qp_MsgFactory.messages.msgTypesSub.kpispd;

        // one-shot; periodic () keep updating even if one-shot being displayed
        this.kpis = { [this.oneshot]: {"none": {}}, [this.periodic]: {"none" : {}} };

        return true;
    }


    private clearList(list: any): boolean
    {
        if (list) {
            for (let key in list) {
                if (list.hasOwnProperty(key)) delete list[key];
            } // for

            return true;
        }

        return false;
    }


    private clear(type: string): boolean
    {
        const list = this.getList(type); // gets reference to appropriate entry in kpis object
        return (list ? this.clearList(list) : false);
    }
}