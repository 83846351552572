import { CommonModule }         from '@angular/common';
import { NgModule }             from '@angular/core';
import { FlexLayoutModule }     from '@angular/flex-layout';
import { RouterModule }         from '@angular/router';

// qp-list-data, qp-refresh-button, qp-status-indicator, qp-window-header
import { GuiElementsModule }    from '@GuiElements/';
import { MaterialCustomModule } from '@Material/';
// qp-notifications
import { NotificationsModule }  from '@Notifications/';
// qp-element-info-container
import { ObjElementsModule }    from '@ObjElements/';
import { UtilsModule }          from '@Utils/';

import { DeviceChartComponent } from './device-chart.component';
import { DeviceComponent }      from './device.component';


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        RouterModule,

        GuiElementsModule,
        MaterialCustomModule,        
        NotificationsModule,
        ObjElementsModule,
        UtilsModule
    ],
    
    declarations: [
        DeviceComponent,
        DeviceChartComponent
    ],

    providers: [
    ],

    exports: [
        DeviceComponent,
        DeviceChartComponent
    ]
})
export class DeviceModule { }