import {
    Observable,
    Subscription,

    filter
}                           from 'rxjs';

import { MapElementMap }    from './map-element-map.class';
import { MapElementMarker } from './map-element-marker.class';


export class MapElementInfoWin extends google.maps.InfoWindow
{
    private _id:     string;
    private _isOpen: boolean      = false;
    private _notif:  Subscription

    
    constructor()
    {
        super();

        this._notif = new Subscription();

        // this.addListener('click',      (event: any): void => {
        //     console.log("Mouse click - [TBD] go to element page");
        // }); // addListener

        // Close when close button clicked
        this.addListener('closeclick', (event: any): void => {
            this.close();
        }); // addListener
    }


    //
    // Getters and setters
    //
    public get id(): string
    {
        return this._id;
    }

    public set id(d: string)
    {
        this._id = d;
    }


    public get isOpenL(): boolean
    {
        return this._isOpen;
        //return (<any>this).getMap(); // Warning: undocumented API
    }
    

    private get notif(): Subscription | undefined
    {
        return this._notif;
    }

    private set notif(d: Subscription | undefined)
    {
        if (d instanceof Subscription) this._notif.add(d)
        else {
            this._notif.unsubscribe();
            this._notif = new Subscription();
        }
    }


    //
    // Public methods
    //
    public override close(): string
    {
        super.close();

        this._isOpen = false;

        this.notif = undefined; // triggers unsubscribe
        const infoSerialNumOld: string = this.id;
        this.id = "";

        return infoSerialNumOld;
    }


    // Override
    public override open(map?: MapElementMap, marker?: MapElementMarker): void
    {
        super.open(map, marker);
        
        if (marker instanceof MapElementMarker) {
            if (this.isOpenL) this.close();

            // Would like to use info.getAnchor() but it doesn't work
            // Therefore, explicitly store marker serialNum of currently open infoWindow
            this.id = marker.id; // must do *after* close() as close() sets it to null
            super.open((map instanceof MapElementMap)? map : marker.getMap(), marker);
            this._isOpen = true;

            // Listen for updates to marker (and its constituent device)
            const obs: Observable<any> | undefined = marker.notification;
            if (obs instanceof Observable) this.notif = obs
                .subscribe((d: any): void => {
                    //this.update(marker)
                }); // subscribe

            // Respond if device being deleted
            this.notif = marker.deleting
                .pipe(
                    filter((d: MapElementMarker): boolean => d && d.id === this.id)
                )
                .subscribe((d: MapElementMarker): void    => {
                    this.close()
                }); // subscribe
        }
    }


    // public toggle(marker?: MapElementMarker, map?: any): boolean
    // {
    //     if (marker) {
    //         console.log("h9");
    //         console.log(marker)
    //         console.log(this.id + ", " + marker.id);
    //         if (this.isOpenL && marker.id === this.id) this.close();
    //         else                                       this.open(map ? map : marker.getMap(), marker);
    //     }
    //     else {
    //         if   (this.isOpenL) this.close();
    //         else                this.open();
    //     }

    //     return this.isOpenL;
    // }


    //
    // Private methods
    //
    private update(marker: MapElementMarker, force: boolean = false): void
    {
        // Only update content if info open for this marker
        if ((force || this.isOpenL) && marker instanceof MapElementMarker && marker.id === this.id) {
            //this.setPosition(marker.getPosition()); // this won't work if infoWin was opened with an anchor
            return this.setContent(marker.infoWinContent());
        }
    }
}