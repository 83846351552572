import { Injectable }                 from '@angular/core';

import * as html2canvas               from 'html2canvas';
import * as pdfMake                   from 'pdfmake/build/pdfmake.js';
import * as pdfFonts                  from 'pdfmake/build/vfs_fonts.js';

import { KpisChartService }           from '@Kpis/';
// import { qp_ProgressCircularService } from '@Utils-ajs/';


@Injectable({
    providedIn: 'root'
})
export class ReportsKpiService
{
    private static readonly chartDiv        = 'div-kpi-chart';  
    private                 chartsRemaining = 0;

    constructor(private readonly qp_KpiChartFactory:         KpisChartService)
                // private readonly qp_ProgressCircularFactory: qp_ProgressCircularService)
    {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        this.initialise();
    }    


    //
    // Public functions
    //
    public doReport(info: any, charts: any, chartsExtra: any, print: any): any
    {
        const date     = new Date();
        const fileName = "qpwireless_report_ " + date.toISOString() + ".pdf"

        // this.qp_ProgressCircularFactory.startProgress(ReportsKpiService.chartDiv, (err: any, result: any) => {
        //     if (! err) {
                // Get base64-encodings of all charts for report
                this.getReportData(charts, chartsExtra, (err2: any, data: any) => {
                    if (! err2) {
                        if (data && data.length > 0) {
                            //Now create report layout
                            const report = this.generateReport({info: info, charts: data}, date, (err3: any, report: any) => { 
                                if (! err3 && report) {
                                    // Generate PDF of report

                                    // this.qp_ProgressCircularFactory.stopProgress();
                                    try {
                                        // if (print) {
                                        //     return pdfMake.createPdf(report).print(); // not permitted on most browsers
                                        // }
                                        // else {
                                            // Use AmCharts download() function if valid chart passed it, otherwise fallback to pdfMake
                                            // if (chart && chart['export'] && chart['export'].toPDF && typeof chart['export'].toPDF === "function") {
                                            //     return chart["export"].toPDF(report, function(data) {
                                            //         return this.download(data, "application/pdf", fileName);
                                            //     });
                                            // }
                                            // else {
                                                return pdfMake.createPdf(report).download(fileName);
                                            //} // if chart
                                        //} // if print
                                    }
                                    catch(e) {
                                        //console.log(e.name);
                                        console.log(e.stack);
                                    } // try - catch
                                }
                                else {
                                    console.log("Unable to generate report: " + fileName + ": " + err3);
                                    // return this.qp_ProgressCircularFactory.stopProgress();
                                } // if report
                            }); // generateReport
                        }
                        else {
                            console.log("No data for which to generate report: " + fileName + (data ? ": " + data.length : ""));
                            // return this.qp_ProgressCircularFactory.stopProgress();
                        } // if data
                    }
                    else {
                        console.log("Unable to get report data: " + fileName + ": " + err2);
                        // return this.qp_ProgressCircularFactory.stopProgress();
                    } // if ! err
                    
                }); // genReportData
            // }
            // else {
            //     console.log("Unable to gproduce report: " + fileName + ": " + err);
            //     // return this.qp_ProgressCircularFactory.stopProgress();
            // }
        // }); // startProgress()
    }


    //
    // Private functions
    //
    private drawnFn(ev: any, callback: any): any
    {
        // This is called once the chart is rendered; need to wait for this
        // as if capture() is called earlier, actual chart data may not be ready
        
        ev.chart.removeListener(ev.chart, 'drawn', this.drawnFn);
        return this.dataUpdatedFn(ev, callback);
        //ev.chart.addListener('dataUpdated', dataUpdatedFn);
        //ev.chart.validateData(); // force a validation after first render to try and ensure graph data correctly populated
    }


    private mapToImage(id: string, callback: any): any {
        if (id) {
            const el = document.getElementById(id);
            if (el) {
                // html2canvas(el,
                //     {
                //         useCORS:    true, // required
                //     })
                // .then((canvas: any) => {
                //         return callback(null, canvas.toDataURL("image/png"));
                //         // var img = canvas.toDataURL("image/png");
                //         // img = img.replace('data:image/png;base64,', '');
                //         // var finalImageSrc = 'data:image/png;base64,' + img;
                //         // $('#googlemapbinary').attr('src', finalImageSrc);
                // });
            }
            else {
                return callback(true);    
            }
        }
        else {
            return callback(true);
        }
    }


    private dataUpdatedFn(ev: any, callback: any): any
    {
        let   chartImages: any[] = [];
        const chartMultiplier  = 2; //lossless?
        const chartTimeout     = 2; // secs


        if (ev.chart && ev.chart && ev.chart.export &&
            ev.chart.export.capture && typeof ev.chart.export.capture === "function" &&
            ev.chart.export.toPNG   && typeof ev.chart.export.toPNG   === "function") {

            // [TBD] Timeout needed as otherwise, chart graph data doesn't display in PNG properly
            setTimeout(() => {
            //qp_TimeoutFactory.processViaTimeout(function() {
                ev.chart.export.capture({}, (dataRaw: any) => {
                    dataRaw.export.toPNG({multiplier: chartMultiplier}, (dataPng: any, data2: any) => {
                        //this.download(data, "image/png", "image.png");

                        // Save chart data into array
                        console.log(this);
                        console.log(dataPng);
                        console.log(data2);
// if (dataPng && this.titles && this.titles.length > 0 && this.titles[0]) {
//     chartImages.push({title: this.titles[0], img: dataPng});
// }

                        // Check if we got all of the charts
//this.clear();
                        if (--this.chartsRemaining == 0) {
                            // Now get map

                            this.mapToImage('map', (err: any, img: any) => {
                                if (! err && img) {
                                    // [TBD]
                                    //chartImages.push({title: "map", img}); 
                                }

                                if (callback && typeof callback === 'function') {
                                    return callback(null, chartImages);
                                }
                            });
                        }
                    }); // toPNG()
                });  // capture()
            }, chartTimeout * 1000); // timeout
        }
        else {
            if (--this.chartsRemaining == 0) {
                if (callback && typeof callback === 'function') {
                    return callback(null, chartImages);
                }
            }
            //chart.clear();
        } // ev.chart.export
    }


    private getReportData(charts: any, chartsExtra: any, callback: any): any
    {
        const chartDivHidden   = ReportsKpiService.chartDiv + '-print';

        let chartImages: any[] = [];
        const chartList        = charts.sort();

        const date             = Date.now(); // msecs since epoch, used to generate temp chart Id
        let   id               = 0;          // temp chart Id for finding in AmCharts array


        if (chartList && chartList.length > 0) {
            this.chartsRemaining = chartList.length;
            for (let i = 0, len = chartList.length; i < len; ++i) {
                // Get passed in data set if present
                let chart: any;
                if (chartList[i]) {
                    let chartData: any = null;
                    if (chartsExtra && chartsExtra.charts && chartsExtra.charts.length > 0) {
                        for (let j = 0, len2 = chartsExtra.charts.length; j < len2; ++j) {
                            if (chartsExtra.charts[j] && chartList[i] === chartsExtra.charts[j].type && chartsExtra.charts[j].data) {
                                chartData = chartsExtra.charts[j].data;
                            }
                        } // for
                    }

                    chart = this.qp_KpiChartFactory.getKpiChartDisplay(null, chartList[i], "light", chartData, 'drawn', (data: any) => {
                        return this.drawnFn(data, callback);
                    });
                } // if chartList[i]

                if (chart) {
                    // Note: this will trigger call of inline fn 'drawnFn()' above once chart is rendered
                    this.showChartFull(chart, chartDivHidden); // write to hidden div to populate capture() function
                }
                else {
                    if (--this.chartsRemaining == 0) {
                        if (callback && typeof callback === 'function') {
                            return callback(null, chartImages);
                        }
                    }
                } // if chart
            } // for
        }
        else {
            if (callback && typeof callback === 'function') {
                return callback(null); // return null as no charts present
            }
        } // chartList    
    }


    private generateReport(data: any, date: any, callback: any): any
    {
// PENTA
        const company        = "Spry Fox Networks Limited";
        const email          = "contact@spryfoxnetworks.com";
        const companyLogo    = "../assets/images/organisation/sfn_full_nontrans.png";

        //const company        = "Penta Consulting Limited";
        //const email          = "info@pentaconsulting.com";
        //const companyLogo    ="../assets/images/organisation/penta_full.png";

        const product        = "QuantumPath";

        const months         = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
//new Intl.DateTimeFormat("en-US", { month: "long" }).format
        date                 = date ? date : new Date();
        const dateLocal      = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();

        const fontSizeFooter = 8;
        const fontSizeH1     = 18;
        const fontSizeTitle  = 26;

        const chartFit       = [515.28, 761.89 - 14.064 ]; // fits image to these dimensions (A4); 14.064 is the line height
        //const chartWidth     = 500;

        const footerMargins  = [40, 0];
        const pageMargins    = [40, 60]; // need to ensure large enough to contain header/footer


        let layout: any      = {
            pageSize:        'A4',
            pageOrientation: 'portrait', //'landscape',
            pageMargins:     pageMargins,
            info:
                {
                    title:    product + " Report",
                    author:   company,
                    creator:  product,
                    producer: product
                    //creationDate:     date.toISOString() // auto-added by pdfmake
                    //subject:  "subject of document",
                    //keywords: "keywords for document",
                },

            footer: (currentPage: number, pageCount: number, pageSize: number) =>
                {
                    let rows = [{text:   "All rights reserved",                                alignment: 'center', fontSize: fontSizeFooter}];
                    if (date) {
                        rows.unshift({text: "© " + date.getFullYear() + " " + company,         alignment: 'center', fontSize: fontSizeFooter});
                    }

                    let columns = [{}, rows];
                    columns.push({text: currentPage + " / " + pageCount,                       alignment: 'right',  fontSize: fontSizeFooter});

                    return {
                        margin:  footerMargins,
                        columns: columns
                    };
                },

            content:
                [
                    // Title page
                    {text:  "\n\n\n\n\n\n" + product + "\n" + "Report",                        alignment: 'center', fontSize: fontSizeTitle}
                ]
        }; // layout


        if (dateLocal) {
            layout.content.push({text: "\n\n" + "Created date: " + dateLocal + "\n\n" + email, alignment: 'center', fontSize: null});
        }


        if (data) {
            if (data.info && data.info.length > 0) {
                // Do page break
                layout.content.push({text: "Information\n\n",                                  alignment: 'left',   fontSize: fontSizeH1, pageBreak: 'before'});

                // Now add extra content
                for (let i in data.info) {
                    if (data.info[i]) {
                        layout.content.push({text:  data.info[i],                              alignment: 'left',   fontSize: null});
                    }
                } // for
            }

            if (data.charts) {
                for (let i in data.charts) {
                    if (data.charts[i] && data.charts[i].title && data.charts[i].img) {
                        layout.content.push({text:  data.charts[i].title,                      alignment: 'left',   fontSize: fontSizeH1, pageBreak: 'before'});
                        layout.content.push({text:  "\n\n",                                    alignment: 'left',   fontSize: null});
                        layout.content.push({image: data.charts[i].img,   fit: chartFit,       alignment: 'center', fontSize: null});
                    }
                } // for
            } // if data.charts

            if (callback && typeof callback === 'function') {
                // Finally, add company logo (async)
                this.toDataURL(companyLogo, (err: any) => {
                    if (! err) {
                        layout.content.unshift(
                            {text:  "\n", alignment: 'center', fontSize: fontSizeTitle},
                            {image: data, alignment: 'center', width:    300}
                        );
                    }
                    console.log(layout);
                    callback(null, layout);
                });
            }
            else {
                return layout;
            }
        }
        else {
            console.log("No data to produce report from");

            if (callback && typeof callback === 'function') {
                callback(true);
            }
            else {
                return null;
            }
        } // if data
    }


    private showChartFull(chart: any, divL: string): object | undefined
    {
        if (chart) {
            if (! divL) {
                divL = ReportsKpiService.chartDiv;
            }

            console.debug("Writing chart to div '" + divL + "'");
            chart.write(divL);
            return chart;
        }

        return undefined;
    }


    private toDataURL(url: string, cb: any): void
    {
        const xhr = new XMLHttpRequest();
        xhr.open('get', url);
        xhr.responseType = 'blob';

        xhr.onload = () => {
            const fr = new FileReader();

            fr.onload = () => {
                if (cb instanceof Function) cb(undefined);
            };

          fr.readAsDataURL(xhr.response); // async call
        };
        
        xhr.send();
    }


    private initialise(): boolean
    {
        console.debug("Initialising ReportsKpi service");

        return true;
    }
}