import {
    Observable,

    filter,
    take
}                                     from 'rxjs';

import { Base }                       from '@Base/';
import { DataServiceI }               from '@Misc/Services/';
import {
    Element,
    ElementMainCommon as ElementMain,
    Geolocation
}                                     from '@ObjElements/';

import {
    MapElementLatLng,
    MapElementMarker,
    MapElementOverlayView,
    MapElementMap,
    MapService
}                                     from '../../';

import { MapLayerType }               from './map-layer-type.class';


export abstract class MapLayer extends Base
{
    private   static readonly maxAccuracy:     number  = 100 * 1000;
    private   static readonly zoomLevel:       number  = 15;

    private          readonly combineMarkers:  boolean = true;

    private          readonly _accuracyCircle: boolean = true;
    private          readonly _markers                 = new Map();
    private          readonly _geoLocs:        Map<string, MapElementMarker> = new Map();

    private                   _layer:          MapElementOverlayView;

    public abstract           _type:           MapLayerType | undefined;


    constructor(protected readonly MapService:    MapService,
                protected readonly DataService:   DataServiceI | undefined,
                private   readonly _Name?:        string,
                private   readonly _DisplayName?: string,
                private            _Data?:        Element[] | ElementMain[])
    {
        super();

        super.initialise();

        this.layerI = MapElementOverlayView.get();
    }

    
    protected abstract getDataForMapPoint(id: string): Element | ElementMain | undefined


    //
    // Getters & setters
    //
    public get displayName(): string | undefined
    {
        return this._DisplayName;
    }


    public get data(): Element[] | ElementMain[] | undefined
    {
        return this._Data;
    }
    
    public set data(d: Element[] | ElementMain[] | undefined)
    {
        this._Data = d;
    }


    public get layer(): MapElementOverlayView
    {
        return this._layer;
    }


    private set layerI(d: MapElementOverlayView)
    {
        if (d) this._layer = d;
    }


    private get markers(): Map<any, any>
    {
        return this._markers;
    }


    public get name(): string | undefined
    {
        return this._Name;
    }


    public get map(): MapElementMap | null | undefined
    {
        return this.layer ? this.layer.gmap : null;
    }

    public set map(d: MapElementMap | null | undefined)
    {
        if (this.layer) this.layer.gmap = d;
    }


    public get type(): MapLayerType | undefined
    {
        return this._type;
    }


    //
    // Public methods
    //

    // Override
    public override cleanUp(): void
    {
        super.cleanUp();

        this.deleteMapPoints();
    }


    public highlight(id: string): boolean
    {
        console.debug("Layer " + this.name + " - highlight: " + id);

        // [TBD] Here need to wait until data loaded
        console.debug("Waiting for data service to load (2): " + this.name + ":" + id);
        if (this.DataService && this.DataService.loading instanceof Observable) {
            this.sub = this.DataService.loading
                .pipe(
                    filter((d: boolean): boolean => d === false) // not loading, i.e. loaded
                )
                .subscribe((d: boolean): void => {
                    console.debug("Data service loaded, highlighting if possible: " + this.name + ":" + id);

                    const marker: MapElementMarker | undefined = this.getMapPoint(id);
                    if (marker instanceof MapElementMarker) {
                        const pos: any = marker.getPosition();
                        if (pos && ! isNaN(pos.lat()) && ! isNaN(pos.lng()) ) {
                            // Layer 'map' will be null if layer not currently enabled
                            const mapLocal: MapElementMap | null | undefined = this.layer.gmap;//getMap();
                            console.debug(mapLocal);
                            console.debug(this._layer);
                    
                            if (mapLocal instanceof MapElementMap) {
                                console.debug(pos);
                                mapLocal.setCenter(pos);
                                mapLocal.setZoom(MapLayer.zoomLevel);
                                this.MapService.infoOpen(marker);
                            }
                        }
                        else {
                            console.info("Unable to highlight element '" + this.name + ":" + id + "' (1)");
                            console.debug(marker);
                        }
                    }
                    else {
                        console.info("Unable to highlight element '" + this.name + ":" + id + "' (2)");
                        console.debug(marker);
                        console.debug(this.markers);
                    }
                }); // subscribe

            return true;
        }

        return false;
    }


    public refresh(): void
    {
        this.deleteMapPoints();
        this._geoLocs.clear(); // must be cleared otherwise new map points won't be created as they'll be present in this
    }


    public update(type: string, data: object): void
    {

    }


    //
    // Private methods
    //
    protected addMapPoints(list: any[] | undefined, isNew: boolean): void
    {
        // Add markers to all objects in passed-in list, keeping local reference in 'markers' to
        // allow map manipulation even if passed-in list is modified by other controller
        // Loop through all of the JSON entries provided in the response
        if (Array.isArray(list)) list.forEach((i: any): void => {
            if (i) this.addOrUpdateMapPoint((i instanceof Element || i instanceof ElementMain) ? i : (typeof i.id === 'number' ? i.id.toString() : i.id), isNew);
        }); // forEach
    }


    protected addOrUpdateMapPoint(id: string | Element | ElementMain, isNew: boolean): MapElementMarker
    {
        let marker: MapElementMarker | undefined = this.getMapPoint(id);
        if (marker) this.updateMapPoint(marker, (typeof id === "string" ? id : undefined)); // shouldn't ever go here; marker itself listens to source object
        else {
            marker = this.createMapPoint(id, isNew);
            if (marker) this.markers.set(marker.id,
                    (this.layer instanceof MapElementOverlayView)
                        ? this.layer.addOverlay(marker)
                        : marker
            ); // set
            // if (info.q_serialNum == marker.serialNum) {
            //     // Info window for this marker was previously displayed; re-open

            //     console.log("Updating infoWindow with cell info");
            //     this.MapService.infoOpen(marker);
            // }
        }

        return marker;
    }


    protected createMapPoint(id: string | Element | ElementMain, isNew: boolean = false): MapElementMarker
    {
        const el: Element | ElementMain | undefined = (id instanceof Element || id instanceof ElementMain) ? id : this.getDataForMapPoint(id);
        if (el instanceof Element || el instanceof ElementMain) {
            const gl: MapElementLatLng = (el.geolocation instanceof Geolocation)
                ? MapElementLatLng.get2(el.geolocation)
                : el.geolocation;

            if (gl instanceof MapElementLatLng) {
                // Check if already known geolocation
                if (this.combineMarkers && this._geoLocs && this._geoLocs.has(gl.locationStr)) {
                    const el2: MapElementMarker | undefined = this._geoLocs.get("" + gl.locationStr);
                    if (el2 instanceof MapElementMarker) el2.element = el;
                }
                else {
                    const marker: MapElementMarker | undefined = MapElementMarker.get(el, isNew);
                    if (marker instanceof MapElementMarker) {
                        // Add a listener that checks for clicks; open info for marker
                        marker.clickNotif = marker.click.subscribe((): void => {
                            this.MapService.infoToggle(marker);
                        }); // subscribe
                        this.updateMapPoint(marker, el);
                        this._geoLocs.set(gl.locationStr, marker);

                        // [TBD]
                        // Delete device from previous geoloc array when moves

                        // Add listener that checks for geolocation change; detect if icon needs to be combined
                        marker.geoLocChangedNotif = marker.geoLocChanged.subscribe((el2: Element | ElementMain): void => {
                            if (el2) {
                                // Element moved - remove from marker, get it's loc and add to new marker
                                const gl2: MapElementLatLng = (el2.geolocation instanceof Geolocation)
                                    ? MapElementLatLng.get2(el2.geolocation)
                                    : el.geolocation;

                                // Remove element - should marker do this itself?
                                marker.delElement(el2);

                                // Remove map point if no element left
                                if (marker.elements.size <= 0) {
                                    this.deleteMapPoint(marker);
                                    this._geoLocs.delete("" + gl2.locationStr);
                                }

                                // Add element to existing map point if possible
                                if (this._geoLocs.has(gl2.locationStr)) {
                                    const el3: MapElementMarker | undefined = this._geoLocs.get("" + gl2.locationStr);
                                    if (el3 instanceof MapElementMarker) el3.element = el2;
                                }

                                // Otherwise create new map point for element
                                else this.createMapPoint(el2, false);
                            }
                        }); // subscribe

                        marker.setMap(this.map); // will set visibility and icon if necessary
                        el.marker = marker;
                    }
                }
            }

            else {
                // No element geolocation data in this case
                //
                // If a mobilephone newly-connects, often the geolocation data isn't available until
                // some time after the initial connect.  To cope with this, start listening for updates
                // from this device.  Once geolocation data is available, create the marker (which will
                // itself start listening for element updates) and stop listening here
                if (el instanceof Element) this.sub = el.notification
                // if (el instanceof Element || el instanceof ElementMain) this.sub = el.notification
                    .pipe(
                        filter((d: Element | ElementMain): boolean => {
                            return (d instanceof Element || d instanceof ElementMain) && typeof d.geolocation !== 'undefined';
                        })
                    )
                    .pipe(
                        take(1)
                    )
                    .subscribe((d: Element | ElementMain): void => {
                        // Stop listening? -  no need with take(1)
                        if (el.geolocation) this.addOrUpdateMapPoint(id, isNew); // do this, not create()
                    }); // subscribe
                    
            }
        } // el

        // Add (reference to) marker to associated device object and update
        return (el instanceof Element || el instanceof ElementMain) ? el.marker : undefined;
    }


    protected deleteMapPoint(arg: string | MapElementMarker, devDeleted?: boolean): string
    {
        // Clear infoWindow if device is deleted from system to prevent it re-opening
        // (in wrong place) if device re-connects

        // Otherwise don't update infoWindow ID here; marker refresh will check
        // to know if to re-open InfoWindow or not
        if (devDeleted) this.MapService.infoClose();
        const marker: MapElementMarker | undefined =
            typeof arg === 'string'
                ? (arg ? this.getMapPoint(arg) : undefined)
                : arg;

        if (marker instanceof MapElementMarker) {
            const id: string = marker.id; // save here as may be deleted during cleanUp()

            let geolocStr: string = "";
            if (typeof arg === 'string') {
                const el: Element | ElementMain | undefined = marker.getElement(arg);
                if (el instanceof Element || el instanceof ElementMain) {
                    geolocStr = el.geolocationStr;
                    marker.delElement(el);
                }
            }
            if (! geolocStr && marker.element) geolocStr = marker.element.geolocationStr;

            // Remove map point if no element left
            if (marker.elements.size <= 0 || arg instanceof MapElementMarker) {
                if (geolocStr) this._geoLocs.delete(geolocStr);

                if (this.layer) this.layer.deleteOverlay(marker);
                marker.cleanUp();
                this.markers.delete(id);
                return id;
            }
        }

        return "";
    }


    protected deleteMapPoints(elements?: any[]): void
    {
        this.markers.forEach((d: any): void => {
            if (d) this.deleteMapPoint(d as MapElementMarker);
        }); // forEach
    }


    protected getMapPoint(id: string | Element | ElementMain): MapElementMarker | undefined
    {
        if (typeof id === "string") {
            if (this.markers.has(id)) return this.markers.get(id);
            else {
                // Element must be part of 'parent' marker at same place; find it
                this.markers.forEach((d: any): void => {
                    if (d) (d as MapElementMarker).elements.forEach((d2a: any): void => {
                        const d2: Element = d2a as Element;
                        //console.log("h3: " + d2.id)
                        //console.log(d2)
                        if (d2 && d2.id == id) {
                            console.log(d2);
                            console.log("YYYYY2");
                        }
                    }); // forEach
                }); // forEach
            }
        }

        return undefined;
        // return (id && this.markers[id] ? this.markers[id] : undefined);
    }


    // private outputMapPoint(sn: string): void
    // {
    //     if (this.markers[sn]) {
    //         console.log("Marker data for '" + sn + "':");

    //         Object.keys(this.markers[sn]).forEach((d: string): void => {
    //             console.log(d + ': ' + this.markers[d]);
    //         }); // forEach
    //     }
    //     else {
    //         console.log("No marker exists for '" + sn + "'");
    //     }
    // }


    protected updateMapPoint(marker: MapElementMarker | undefined, sn?: string | Element | ElementMain): any
    {
    }


    public setKey(name: any, val: any): void
    {
        // if (name && val) {
        //     (this.key as any).name = name;
        //     (this.key as any).val  = val;
        // }
        // else {
        //     this.key = {};
        // }
    }


    protected setMapPointVisibility(id: string, show: boolean): boolean
    {
        const mp: MapElementMarker | undefined = this.getMapPoint(id);
        if (mp instanceof MapElementMarker) mp.setMap(show ? this.map : null);

        return show;
    }
}