import {
    Component,
    Input
}                              from '@angular/core';

import { IconsMaterial }       from '@Icons/';
import { ClientConfigService } from '@Misc/Services/';

import { ButtonComponent }     from './button.component';


@Component({
   selector:     'qp-refresh-button',
   templateUrl:  'refresh-button.component.html',
   styleUrls:   ['refresh-button.component.css']
})
export class RefreshButtonComponent extends ButtonComponent
{
    public readonly icon: string = IconsMaterial.refresh;


    @Input()
    public refreshEnabled: boolean = false;


    constructor(private readonly ClientConfigService: ClientConfigService)
    {
        super();
    }


    //
    // Private functions
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();
        //console.log("Initialising RefreshButton component");

        this.sub = this.ClientConfigService.refreshButtonEnabled$.subscribe((required: boolean): void => {
            this.refreshEnabled = required;
        });
    }
}