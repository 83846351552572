import { CommonModule }         from '@angular/common';
// import { FlexLayoutModule }     from '@angular/flex-layout';
import { NgModule }             from '@angular/core';
// import { OrderModule }          from 'ngx-order-pipe';
// import { RouterModule }         from '@angular/router';

// qp-devices
import { DevicesModule }        from '@Devices/';
// include qp-refresh-button
import { GuiElementsModule }    from '@GuiElements/';
import { MaterialCustomModule } from '@Material/';
// include qp-map
import { MapModule }            from '@Map/';
// include qp-element-info-container
import { ObjElementsModule }    from '@ObjElements/';

import { DeviceGroupComponent } from './device-group.component';


@NgModule({
    imports: [
        CommonModule,
        // FlexLayoutModule,
        // OrderModule,
        // RouterModule,

        GuiElementsModule,
        MapModule,
        MaterialCustomModule,
        ObjElementsModule,

        DevicesModule
    ],
    
    declarations: [
        DeviceGroupComponent
    ],

    providers: [
    ],

    exports: [
        // DeviceGroupComponent
    ]
})
export class DeviceGroupModule { }