<mat-dialog-content style="height: 100%; width: 100%; background-color: rgba(0,0,0,0.55);">
    <qp-window-header [title]="name" [value]="deviceInfo.serialNum.val" (refresh)="refresh()"></qp-window-header>

	<div style="overflow-x: auto; vertical-align: middle;">
	    <table class="tab table">
	        <!--<caption>Connected Phones</caption>-->
	        <tbody>
	            <tr *ngFor="let attr of Object.keys(deviceInfo)">
		            <td>
		                {{ attr }}
	                </td>
	                <td>
		                {{ deviceInfo[attr].val }}
	                </td>
	            </tr>
	        </tbody>
	    </table>
	</div> <!-- overflow -->
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="buttonAndTextInner" style="float: left;">
        <button mat-raised-button color="primary" (click)="buttonOnMap()" matTooltip="Show device location on map">
            <mat-icon aria-label="Show device on map">map</mat-icon>
        </button>
        <button mat-raised-button color="primary" (click)="buttonAlert()" matTooltip="Sound alert on device">
            <mat-icon aria-label="Alert device">phonelink_ring</mat-icon>
        </button>
        <button mat-raised-button color="primary" (click)="buttonConfig()" matTooltip="Send config to device">
            <mat-icon aria-label="Send config to device">phonelink_setup</mat-icon>
        </button>
        <button mat-raised-button color="primary" (click)="buttonShowEvents()" matTooltip="Show device events">
            <mat-icon aria-label="Show device events">find_in_page</mat-icon>
        </button>
        <button mat-raised-button color="primary" (click)="buttonGetDebugLog()" matTooltip="Upload debug log from device">
            <mat-icon aria-label="Get debug log from device">file_upload</mat-icon>
        </button>
        <button mat-raised-button color="primary" (click)="buttonDisconnect()" matTooltip="Disconnect device">
            <mat-icon aria-label="Get debug log from device">signal_wifi_off</mat-icon>
        </button>
    </div>

    <button mat-raised-button mat-dialog-close color="primary" (click)="close()" matTooltip="Close">Close</button>
</mat-dialog-actions>