import { CommonModule } from '@angular/common';
import { NgModule }     from '@angular/core';
import { RouterModule } from '@angular/router';

import { MapModule }    from './map.module';

import { mapRoutes }    from './map.routes';


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(mapRoutes), // lazy loading

        MapModule,
    ],
    
    declarations: [
    ],

    providers: [
    ],

    exports: [
    ]
})
export class MapModuleLazyLoad { }