import {
    EmbeddedViewRef,
    Injectable,
    TemplateRef
}                        from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarDismiss,
    MatSnackBarRef
    // TextOnlySnackBar
}                        from '@Material/';

import {
    BaseComponent,
    BaseService
}                        from '@Base/';


// [TBD]
// export { qp_StatusService } from '@Utils-ajs/';


enum StatusMessageLevelEnum {
    // Values must match those in top-level styles.css
    Error = 'error',
    Warn  = 'warn',
    Info  = 'info',
    None  = 'none'
}; // StatusMessageLevelEnum


@Injectable({
    providedIn: 'root'
})
export class StatusMessageService extends BaseService
{
    public  static readonly Level:          typeof StatusMessageLevelEnum = StatusMessageLevelEnum;

    private static readonly config:         MatSnackBarConfig             = {
        horizontalPosition: 'left',
        verticalPosition:   'bottom'
    }; // config

    private static readonly closeLabel:     string                        = "Dismiss";
    private static readonly duration:       number                        = 5;//10; // default duration, secs
    private static readonly levelCssPrefix: string                        = "status-message-";

    private static          currentMsg:     BaseComponent | string | TemplateRef<any>;
    private static          ref:            MatSnackBarRef<any> | undefined;


    constructor(private readonly MatSnackBar: MatSnackBar)
    {
        super();
    }  


    //
    // Public methods
    //
    public clear(): void
    {
        this.set("");
    }


    public get(): BaseComponent | string | TemplateRef<any>
    {
        return StatusMessageService.currentMsg;
    }


    public set(
        msg:         BaseComponent | string | TemplateRef<any>,
        level:       StatusMessageLevelEnum = StatusMessageLevelEnum.None,
        duration:    number                 = StatusMessageService.duration,
        showDismiss: boolean                = true
    ): MatSnackBarRef<any> |  undefined
    {
        return this.show(msg, level, duration, showDismiss);
    }


    //
    // Protected methods
    //
    
    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        this.clearMsg(true);
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising StatusMessage service");
    }


    //
    // Private methods
    //
    private clearMsg(dismiss: boolean = false): void
    {
        if (dismiss && StatusMessageService.ref instanceof MatSnackBarRef) StatusMessageService.ref.dismiss();

        StatusMessageService.ref = undefined;
        this.sub = undefined; // clear any subscriptions
    }


    private show(
        d:           BaseComponent | string | TemplateRef<any>,
        level:       StatusMessageLevelEnum,
        t:           number,
        showDismiss: boolean
    ): MatSnackBarRef<any> |  undefined
    // ): MatSnackBarRef<BaseComponent | EmbeddedViewRef<any> | TextOnlySnackBar> | undefined
    {
        if (d) {
            // Clear any existing subscriptions
            if (StatusMessageService.ref instanceof MatSnackBarRef) {
                //Ignore if same message
                if (StatusMessageService.currentMsg === d) return StatusMessageService.ref
                else                                       this.clearMsg();
            }

            // Will replace any existing open Snack Bar
            // ? this.MatSnackBar.openFromComponent(d)
            const config: MatSnackBarConfig = {
                duration:   (! Number.isNaN(t) && t >= 0)? t * 1000: 0, // convert secs to ms
                panelClass: level ? [StatusMessageService.levelCssPrefix + level] : "",
                ...StatusMessageService.config
            }; // config

            if (this.MatSnackBar) {                
                StatusMessageService.ref = 
                    (d instanceof BaseComponent)
                        ? ((this.MatSnackBar.openFromComponent instanceof Function)
                            // 'd as any' needed here
                            ? this.MatSnackBar.openFromComponent(d as any, config)
                            : undefined
                        )
                        
                        :((d instanceof TemplateRef)
                            ? ((this.MatSnackBar.openFromTemplate instanceof Function)
                                ? this.MatSnackBar.openFromTemplate(d, config)
                                : undefined
                            )

                        : ((this.MatSnackBar.open instanceof Function)
                                ? this.MatSnackBar.open(d, showDismiss ? StatusMessageService.closeLabel : undefined, config)
                                : undefined
                            )
                        );
                StatusMessageService.currentMsg = d;
            }

            if (StatusMessageService.ref instanceof MatSnackBarRef) {
                if (StatusMessageService.ref.afterDismissed instanceof Function) {
                    this.sub = StatusMessageService.ref.afterDismissed()
                        .subscribe((d: MatSnackBarDismiss): void => {
                            console.debug("Snack bar dismissed" + (d ? " by action: " + d.dismissedByAction : ""));
                            this.clearMsg();
                        }); // subscribe
                    }

                if (StatusMessageService.ref.afterOpened instanceof Function) {
                    this.sub = StatusMessageService.ref.afterOpened()
                        .subscribe((): void => {
                            console.debug("Snack bar opened: " + StatusMessageService.currentMsg);
                        }); // subscribe
                }

                if (StatusMessageService.ref.onAction instanceof Function) {
                    this.sub = StatusMessageService.ref.onAction()
                        .subscribe((): void => {
                            console.debug("Snack bar action occurred");
                        }); // subscribe
                }
            }
        }
        else {
            this.clearMsg(true);
        }

        return StatusMessageService.ref;
    }
}