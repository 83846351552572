import { Component }     from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
    selector:     'qp-map-layers-control-container',
    templateUrl:  'map-layers-control-container.component.html',
    styleUrls:   ['map-layers-control-container.component.css']
})
export class MapLayersContainerComponent extends BaseComponent
{

}